/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesGroupType } from './WebApiModelsEnumerationTypesGroupType';
import {
    WebApiModelsEnumerationTypesGroupTypeFromJSON,
    WebApiModelsEnumerationTypesGroupTypeFromJSONTyped,
    WebApiModelsEnumerationTypesGroupTypeToJSON,
} from './WebApiModelsEnumerationTypesGroupType';

/**
 * 
 * @export
 * @interface WebApiMemberDtoGroupDto
 */
export interface WebApiMemberDtoGroupDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiMemberDtoGroupDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoGroupDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiMemberDtoGroupDto
     */
    memberCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiMemberDtoGroupDto
     */
    isDefaultGroup?: boolean;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesGroupType}
     * @memberof WebApiMemberDtoGroupDto
     */
    groupType?: WebApiModelsEnumerationTypesGroupType;
}

/**
 * Check if a given object implements the WebApiMemberDtoGroupDto interface.
 */
export function instanceOfWebApiMemberDtoGroupDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoGroupDtoFromJSON(json: any): WebApiMemberDtoGroupDto {
    return WebApiMemberDtoGroupDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'memberCount': !exists(json, 'memberCount') ? undefined : json['memberCount'],
        'isDefaultGroup': !exists(json, 'isDefaultGroup') ? undefined : json['isDefaultGroup'],
        'groupType': !exists(json, 'groupType') ? undefined : WebApiModelsEnumerationTypesGroupTypeFromJSON(json['groupType']),
    };
}

export function WebApiMemberDtoGroupDtoToJSON(value?: WebApiMemberDtoGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'memberCount': value.memberCount,
        'isDefaultGroup': value.isDefaultGroup,
        'groupType': WebApiModelsEnumerationTypesGroupTypeToJSON(value.groupType),
    };
}

