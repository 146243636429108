import Table, { ColumnsType } from "antd/lib/table";
import { formatDateString } from "../../Helpers/DateHelpers";
import { TextService } from "../../Services/TextService";
import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Space } from "antd";
import { useState } from "react";
import { InviteService } from "../../Services/InviteService";

export const InviteList = (props: any) => {
    const [inviteToDelete, setInviteToDelete] = useState(0 as number);

    const GetText = TextService.GetText;
    interface DataType {
        key: string;
        customerName: string;
        customerEmail: string;
        id: number;
        times: any[];
        tags: string[];
    }

    const onDeleteClick = (memberId: number) => {
        setInviteToDelete(memberId);
    };

    const handleOk = async () => {
        await InviteService.DeleteInvite(inviteToDelete.toString());
        setInviteToDelete(0);
        props.loadEvent(props.event.eventUniqueId);
    };


    const inviteColumns: ColumnsType<DataType> = [
        {
            title: GetText("InviteList_EmailColumn"),
            dataIndex: 'email',
            key: 'email',
            render: (_, invite: any) => (
                <span>{invite.email}<br />
                </span>
            ),
        },
        {
            title: GetText("InviteList_SentColumn"),
            dataIndex: 'sent',
            key: 'sent',
            render: (_, invite: any) => (
                <span>{formatDateString(invite.createdAt)}<br />
                </span>
            ),
        },
        {
            title: GetText("InviteList_RepliedColumn"),
            dataIndex: 'Answer',
            key: 'Answer',
            render: (_, invite: any) => (
                <span>{invite.replyId === 0 ? GetText("Common_No") : GetText("Common_Yes")}<br />
                </span>
            ),
        },
        {
            title: GetText("InviteList_LinkColumn"),
            dataIndex: 'Invite',
            key: 'Invite',
            render: (_, invite: any) => (
                <Space size="middle">
                    <span><a target="_blank" href={process.env.REACT_APP_WEB_URL + "/Event/" + props.event.eventUniqueId + "/invite/" + invite.uniqueId}>{GetText("InviteList_InviteLinkText")}</a>
                    </span>
                    <DeleteOutlined style={{ color: "red", fontSize: "20px" }} onClick={() => onDeleteClick(invite?.id!)}></DeleteOutlined>

                    <Popconfirm
                        title={GetText("InviteList_DeleteInviteConfirmMessage")}
                        key={invite.id}
                        open={invite.id === inviteToDelete}
                        placement="topRight"
                        onConfirm={handleOk}
                        onCancel={() => setInviteToDelete(0)}
                    >
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return props.invites.length > 0 ? <Table columns={inviteColumns} rowKey="Id" dataSource={props.invites} /> : <span><i>{GetText("InviteList_NoInvitesYet")}</i></span>
};