import { useEffect, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { MailOutlined, UserOutlined, TeamOutlined, UserSwitchOutlined, CloudOutlined, SettingOutlined, MoneyCollectOutlined, DollarCircleOutlined, ApiOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { CompanyRole } from '../../Types/CompanyRole';

const LeftMenuSettings = (props: any) => {
    const [current, setCurrent] = useState('1');
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const GetText = TextService.GetText;
    const navigation = useNavigate();
    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        });

        setCurrent(props.selected);
    }, []);

    type MenuItem = Required<MenuProps>['items'][number];

    const onClick: MenuProps['onClick'] = (e: any) => {
        navigation("/" + getCurrentLanguage() + "/" + e.key);
    };

    function getItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const items: MenuItem[] = user?.role == CompanyRole.GlobalAdmin ? [
        getItem(GetText("Settings_Account"), 'settings', <UserOutlined />),
        getItem(GetText("Admins_Payments"), 'payments', <DollarCircleOutlined />),
        getItem(GetText("Admins_AdminsTitle"), 'admins', <UserSwitchOutlined />),
        getItem(GetText("PublicSiteForm_Title"), 'publicsite', <CloudOutlined />),
        getItem(GetText("MemberFieldSettings_Title"), 'memberSettings', <SettingOutlined />),
        getItem(GetText("EmailTemplates_TemplateTitle"), 'emailTemplates', <MailOutlined />),
        getItem(GetText("Integrations_IntegrationTitle"), 'integrations', <ApiOutlined />),

    ] : [
        getItem(GetText("Settings_Account"), 'settings', <UserOutlined />),
    ];

    return <Menu
        theme="light"
        onClick={onClick}
        defaultOpenKeys={['sub1']}
        selectedKeys={[current]}
        mode="inline"
        items={items}
    />
};

export default LeftMenuSettings;