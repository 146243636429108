/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiAdminDtoUserListResultDto,
  WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto,
} from '../models';
import {
    WebApiAdminDtoUserListResultDtoFromJSON,
    WebApiAdminDtoUserListResultDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoToJSON,
} from '../models';

export interface ApiAdminDeleteAllMembersDeleteRequest {
    companyId?: number;
}

export interface ApiAdminSetUserAsAdminPostRequest {
    body?: number;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     * 
     */
    async apiAdminDeleteAllMembersDeleteRaw(requestParameters: ApiAdminDeleteAllMembersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Admin/DeleteAllMembers`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiAdminDeleteAllMembersDelete(requestParameters: ApiAdminDeleteAllMembersDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiAdminDeleteAllMembersDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Gets all OneFront users
     */
    async apiAdminGetAllUsersGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiAdminDtoUserListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Admin/GetAllUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiAdminDtoUserListResultDtoFromJSON(jsonValue));
    }

    /**
     * Gets all OneFront users
     */
    async apiAdminGetAllUsersGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiAdminDtoUserListResultDto> {
        const response = await this.apiAdminGetAllUsersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set user
     */
    async apiAdminSetUserAsAdminPostRaw(requestParameters: ApiAdminSetUserAsAdminPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Admin/SetUserAsAdmin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoFromJSON(jsonValue));
    }

    /**
     * Set user
     */
    async apiAdminSetUserAsAdminPost(requestParameters: ApiAdminSetUserAsAdminPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto> {
        const response = await this.apiAdminSetUserAsAdminPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
