/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsMemberField } from './WebApiModelsMemberField';
import {
    WebApiModelsMemberFieldFromJSON,
    WebApiModelsMemberFieldFromJSONTyped,
    WebApiModelsMemberFieldToJSON,
} from './WebApiModelsMemberField';

/**
 * 
 * @export
 * @interface WebApiMemberFieldsDtoSaveMemberFieldSettingsDto
 */
export interface WebApiMemberFieldsDtoSaveMemberFieldSettingsDto {
    /**
     * 
     * @type {Array<WebApiModelsMemberField>}
     * @memberof WebApiMemberFieldsDtoSaveMemberFieldSettingsDto
     */
    fields?: Array<WebApiModelsMemberField> | null;
}

/**
 * Check if a given object implements the WebApiMemberFieldsDtoSaveMemberFieldSettingsDto interface.
 */
export function instanceOfWebApiMemberFieldsDtoSaveMemberFieldSettingsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberFieldsDtoSaveMemberFieldSettingsDtoFromJSON(json: any): WebApiMemberFieldsDtoSaveMemberFieldSettingsDto {
    return WebApiMemberFieldsDtoSaveMemberFieldSettingsDtoFromJSONTyped(json, false);
}

export function WebApiMemberFieldsDtoSaveMemberFieldSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberFieldsDtoSaveMemberFieldSettingsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : (json['fields'] === null ? null : (json['fields'] as Array<any>).map(WebApiModelsMemberFieldFromJSON)),
    };
}

export function WebApiMemberFieldsDtoSaveMemberFieldSettingsDtoToJSON(value?: WebApiMemberFieldsDtoSaveMemberFieldSettingsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': value.fields === undefined ? undefined : (value.fields === null ? null : (value.fields as Array<any>).map(WebApiModelsMemberFieldToJSON)),
    };
}

