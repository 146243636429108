import { ReplyApi } from "../Repository/eventbookyapi";
import { createReplyRequest, updateReplyRequest } from "../Types/RequestResponse/createReplyRequest";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class ReplyService {
    public static CreateReply = async (request: createReplyRequest) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/Reply/create', requestOptions);
        const data = await response.json();

        return data;
    };

    public static UpdateReply = async (request: updateReplyRequest) => {
        return new ReplyApi(GetApiConfiguration()).apiReplyUpdatePost({
            webApiServicesDataTransferObjectsReplyServiceUpdateReplyDto: {
                name: request.name,
                eventId: request.eventId,
                email: request.email,
                answer: request.answer,
                timeAnswers: request.timeAnswers,
                inviteId: request.inviteId,
                replyId: request.replyId
            }
        })
    };

    public static DeleteReply = async (replyId: number, message: string) => {
        return new ReplyApi(GetApiConfiguration()).apiReplyDelete({
            webApiServicesDataTransferObjectsReplyServiceDeleteReplyDto: {
                replyId: replyId,
                message: message,
            }
        })
    };

    public static GetReplyByInviteIdOrAuthCode = async (eventId: string, inviteId: string, authCode: string) => {
        return new ReplyApi(GetApiConfiguration()).apiReplyGetReplyByInviteGet({
            eventId: eventId,
            inviteId: inviteId,
            authCode: authCode,
        })
    };
}