import { SettingOutlined } from "@ant-design/icons";
import { Divider, Form, Input, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Title from "antd/lib/typography/Title";

export const BookyTitleWithIcon = (props: any) => {

    return (
        <>
            <div className="BookyTitleWithIcon" style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                <Space>
                    <span className="BookyTitleWithIcon-Icon">{props.children}</span><Title level={3} style={{ margin: 0, paddingLeft: "10px" }}> {props.text}
                    </Title>
                </Space>
                <div className="BookyTitleWithIcon-RightContent">{props.rightContent}</div>
            </div>
            <Divider className="BookyTitleWithIconDivider" />
        </>
    );

}