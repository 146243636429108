import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageSmall from '../../Components/Page/PageSmall';
import BookyBox from '../../Components/Layout/BookyBox';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import CreateUserForm from '../../Components/CreateUser/CreateUser';
import { CompanyService } from '../../Services/CompanyService';
import { WebApiCompanyDtoCompanyUserDto } from '../../Repository/eventbookyapi';
import NotFound from '../../Components/Page/NotFoundPage';

const CreateCompanyUser = () => {
    const navigation = useNavigate();
    let params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const companyInviteId = params.companyInviteId;
    const [errorText, setErrorText] = useState("" as string);
    const [inviteFound, setInviteFound] = useState(true as boolean);

    const GetText = TextService.GetText;

    const onCreateUserSubmit = async (formValues: any) => {

        // Connect to company
        let newUser: any = await CompanyService.ConnectUserToCompany({
            inviteId: companyInviteId,
            firstName: formValues.firstname,
            lastName: formValues.lastname,
            password: formValues.password
        });
        if (newUser && newUser.message != null) {
            setErrorText(newUser.message);
        }
        else {
            navigation("/" + getCurrentLanguage() + "/NewUserCreatedWithProvider");
        }
    }

    useEffect(() => {

        if (companyInviteId != null) {
            CompanyService.GetCompanyUserInvite(companyInviteId).then((result: WebApiCompanyDtoCompanyUserDto) => {
                setInviteFound(true);
            }).catch((info) => {
                setInviteFound(false);
            });
        }

        if (params.errorid) {
            setErrorText(GetText("CreateUser_ErrorAlreadyExist"));
        }

        if (token) {
            navigation("/" + getCurrentLanguage() + "/NewUserCreatedWithProvider");
        }
    }, [params])

    const onRedirectToGoogle = () => {
        window.location.href = process.env.REACT_APP_API_URL + "/ExternalUser/signUpWithGoogle?language=" + getCurrentLanguage() + "&inviteId=" + companyInviteId;
    }

    return <PageSmall>
        <BookyBox>
            {inviteFound ?
                <CreateUserForm finishSetupText={GetText("CreateCompanyUser_Info")} hideEmailInput={true} onRedirectToGoogle={onRedirectToGoogle} errorText={errorText} onCreateUserSubmit={onCreateUserSubmit} /> :
                <NotFound message={GetText("CreateCompanyUser_InviteNotFound")} />}
        </BookyBox>
    </PageSmall>
}

export default CreateCompanyUser;