/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiCompanyDtoCompanyUpdatePublicSiteDto
 */
export interface WebApiCompanyDtoCompanyUpdatePublicSiteDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyUpdatePublicSiteDto
     */
    siteTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyUpdatePublicSiteDto
     */
    siteUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyUpdatePublicSiteDto
     */
    siteDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyUpdatePublicSiteDto
     */
    logoPath?: string | null;
}

/**
 * Check if a given object implements the WebApiCompanyDtoCompanyUpdatePublicSiteDto interface.
 */
export function instanceOfWebApiCompanyDtoCompanyUpdatePublicSiteDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiCompanyDtoCompanyUpdatePublicSiteDtoFromJSON(json: any): WebApiCompanyDtoCompanyUpdatePublicSiteDto {
    return WebApiCompanyDtoCompanyUpdatePublicSiteDtoFromJSONTyped(json, false);
}

export function WebApiCompanyDtoCompanyUpdatePublicSiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiCompanyDtoCompanyUpdatePublicSiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'siteTitle': !exists(json, 'siteTitle') ? undefined : json['siteTitle'],
        'siteUrl': !exists(json, 'siteUrl') ? undefined : json['siteUrl'],
        'siteDescription': !exists(json, 'siteDescription') ? undefined : json['siteDescription'],
        'logoPath': !exists(json, 'logoPath') ? undefined : json['logoPath'],
    };
}

export function WebApiCompanyDtoCompanyUpdatePublicSiteDtoToJSON(value?: WebApiCompanyDtoCompanyUpdatePublicSiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'siteTitle': value.siteTitle,
        'siteUrl': value.siteUrl,
        'siteDescription': value.siteDescription,
        'logoPath': value.logoPath,
    };
}

