import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Select, Space, Tooltip, message, Image } from 'antd';
import 'moment-timezone'
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { AuthProvider } from '../../Providers/authProvider';
import LeftMenuSettings from './LeftSettings';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { BookyInput } from '../../Components/Forms/BookyInput';
import { CompanyService } from '../../Services/CompanyService';
import { WebApiCompanyDtoCompanyPublicSiteDto } from '../../Repository/eventbookyapi';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import UploadImage from '../../Components/Upload/UploadImage';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { OneFrontHtmlEditor } from '../../Components/Forms/OneFrontHtmlEditor';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';

const PublicSite = () => {
    const [hasCreatedSite, setHasCreatedSite] = useState(false as boolean);
    const [publicSite, setPublicSite] = useState({} as WebApiCompanyDtoCompanyPublicSiteDto);
    const [siteUrl, setSiteUrl] = useState("" as string);
    const [form] = Form.useForm();
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const GetText = TextService.GetText;
    const [initialHtml, setInitialHtml] = useState("" as any);
    const [html, setHtml] = useState("" as any);

    const FormCreateClick = async () => {
        await CompanyService.UpdateCompanyPublicSite({
            siteTitle: form.getFieldValue("siteTitle"),
            siteUrl: siteUrl,
        });
        loadSite();
    };

    const onHtmlChange = (html: string) => {
        form.setFieldValue("message", html);
        setHtml(html);
    }

    const hasLogoType = () => {
        return publicSite && publicSite.siteLogoPath && publicSite.siteLogoPath.length > 0;
    }

    const handleChange = (value: string) => {
    };

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
            handleChange(user?.companyLanguage!);
        })

        loadSite();
    }, []);

    const deleteSite = async () => {
        await CompanyService.UpdateCompanyPublicSite({
            siteDescription: "",
            siteTitle: "",
            siteUrl: ""
        });
        loadSite();
    }

    const onChange = (e: any) => {
        setSiteUrl(e.target.value);
    }

    const loadSite = async () => {
        CompanyService.GetCompanyPublicSite().then(result => {
            if (result && result.siteTitle && result.siteTitle.length > 0) {
                setHasCreatedSite(true);
                setPublicSite(result);
                setInitialHtml(result.siteDescription);
            }
            else {
                setHasCreatedSite(false);
                setPublicSite({});
            }
        });
    }

    const deleteEventBannerImage = async () => {
        await CompanyService.UpdateCompanyPublicSite({
            siteTitle: publicSite.siteTitle,
            siteUrl: publicSite.siteUrl,
            siteDescription: html ?? publicSite.siteDescription,
            logoPath: ""
        });

        message.success(GetText("MyEventDetails_HasBeenRemoved"));
        loadSite();
    }

    const saveEventBannerImage = async (uri?: string) => {

        await CompanyService.UpdateCompanyPublicSite({
            siteTitle: publicSite.siteTitle,
            siteUrl: publicSite.siteUrl,
            siteDescription: publicSite.siteDescription,
            logoPath: uri
        });

        message.success(GetText("MyEventDetails_Saved"));
        loadSite();
    }

    const saveHtml = async () => {

        await CompanyService.UpdateCompanyPublicSite({
            siteTitle: publicSite.siteTitle,
            siteUrl: publicSite.siteUrl,
            logoPath: publicSite.siteLogoPath,
            siteDescription: html
        });

        message.success(GetText("Common_Saved"));
        loadSite();
    }


    return <LayoutLeftMenu fullpageMain={true} title={GetText("Settings_Account")} menu={<LeftMenuSettings selected={"publicsite"} />}>
        <Col style={{ minHeight: "450px" }} span={16}>


            <Col className='bookyBox' style={{ marginLeft: "20px" }} span={24}>
                <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                    <Title >{GetText("PublicSiteForm_Title")}</Title>
                </Space>
                <Space>
                    <Paragraph>{hasCreatedSite ?
                        GetText("PublicSiteForm_Description_CreatedSite") :
                        GetText("PublicSiteForm_Description")
                    }</Paragraph>
                </Space>

                {!hasCreatedSite ? <Form
                    data-testid="company-form"
                    name="basic"
                    colon={false}
                    form={form}
                    labelCol={{
                        span: 2,
                    }}
                    onFinish={FormCreateClick}
                    wrapperCol={{
                        span: 6,
                    }}
                    layout="horizontal"
                    autoComplete="off"
                >

                    <BookyInput label={GetText("PublicSite_TitleColumn")} name="siteTitle" requiredMessage={GetText("PublicSite_TitleColumn_Validation")}></BookyInput>
                    <BookyInput label={GetText("PublicSite_SiteUrlColumn")} validateUrl name="siteUrl" onChange={onChange} requiredMessage={GetText("PublicSite_SiteUrlColumn_Validation")}></BookyInput>

                    <Form.Item label={" "}
                    >
                        {siteUrl.length > 0 ? <>
                            Dina medlemmar kommer kunna då din site på:<br />
                            <i>https://eventbooky.com/{siteUrl}</i>
                        </> : ""}
                    </Form.Item>

                    <Form.Item label={" "}>
                        <Button data-testid="submit-button" style={{ float: 'left' }} size="middle" type="primary" htmlType="submit">
                            {GetText("PublicSite_Create")}
                        </Button>
                    </Form.Item>
                </Form> : <><br></br>
                    {GetText("PublicSite_TitleColumn")}: {publicSite.siteTitle} <br />
                    {GetText("PublicSite_EditMember")}: <a target='_blank' href={process.env.REACT_APP_WEB_URL + "/" + getCurrentLanguage() + "/" + publicSite.siteUrl + "/editmember"}>{process.env.REACT_APP_WEB_URL + "/" + getCurrentLanguage() + "/" + publicSite.siteUrl + "/editmember"}</a><br></br>
                    {GetText("PublicSite_NewMember")}: <a target='_blank' href={process.env.REACT_APP_WEB_URL + "/" + getCurrentLanguage() + "/" + publicSite.siteUrl + "/newmember"}>{process.env.REACT_APP_WEB_URL + "/" + getCurrentLanguage() + "/" + publicSite.siteUrl + "/newmember"}</a><br></br><br></br>

                    <BookyButton type="default" icon={<DeleteOutlined />} onClick={deleteSite} text={GetText("PublicSite_Delete")}>
                    </BookyButton>

                </>

                }
            </Col>


            {hasCreatedSite ? <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }} span={24}>
                <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                    <h3>{GetText("PublicSite_LogoTypeTitle")}</h3>
                </Space>
                {hasLogoType() ? <div style={{ marginBottom: "20px" }}>
                    {GetText("PublicSite_PresentLogo")} <Tooltip title={GetText("MyEventDetails_RemoveBranding")}><DeleteOutlined style={{ color: "red" }} onClick={deleteEventBannerImage} /></Tooltip>: <br></br>
                    {publicSite && publicSite.siteLogoPath && publicSite.siteLogoPath.length > 0 ? <Image preview={true} src={process.env.REACT_APP_API_URL + "/Image/downloadImage?bloburl=" + publicSite.siteLogoPath} ></Image> : ""}
                </div> : <UploadImage crop={false} isBanner={false} uploadFinish={saveEventBannerImage} />}
            </Col> : ""}

            {hasCreatedSite ? <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }} span={24}>
                <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                    <h3>{GetText("PublicSite_SiteDescription")}</h3>
                </Space>
                <Paragraph>{GetText("PublicSite_SiteDescriptionHelpText")}</Paragraph>
                <OneFrontHtmlEditor hideVariablesMenu={true} initialHtml={initialHtml} onHtmlChange={onHtmlChange} name="messageFromTemplate" requiredMessage={GetText("EmailForm_MessageRequired")} />
                <BookyButton type="default" icon={<SaveOutlined />} onClick={saveHtml} text={GetText("Common_Save")} />

            </Col> : ""}
        </Col>
    </LayoutLeftMenu>
};

export default PublicSite;