export enum HistoryEventType {
    CreatedEvent = 0,
    DeletedEvent = 1,
    AnsweredEvent = 2,
    AnsweredEventUpdated = 3,
    AnsweredEventUpdatedByAdmin = 4,
    UpdateEvent = 5,
    UpdateEventWithBookings = 6,
    InviteCreated = 7,
    InviteDeleted = 8,
    ReminderCreated = 9,
    SubscriptionCreated = 10,
    GroupCreated = 11,
    GroupDeleted = 12,
    GroupUpdated = 13,
    ClosedEvent = 14,
    OpenEvent = 15,
    AddMembersToGroup = 16,
    AddMembersToCompany = 17,
    DeleteMember = 18,
    UpdatedMemberProperties = 19,
    MailToGroupSent = 20,
    MailToAllMembersSent = 21,
    DeleteReply = 22,
    AddMember = 23,
    AddAdminToCompany = 24,
    DeleteAdminFromCompany = 25,
    UpdatedAdmin = 26,
    UpdatedMemberPropertiesByMember = 27,
    AddMemberByMember = 28,
    UploadPaymentData = 29,
    MemberIsActivated = 30,
    EmailTemplateCreated = 31,
    EmailTemplateDeleted = 32,
    EmailTemplateUpdated = 33,
    MailToExternalEmailsHasBeenSent = 34
}