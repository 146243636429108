import { TextService } from "../Services/TextService";

const GetText = TextService.GetText;
export const AvailableRoles = [
    {
        label: GetText("Common_GlobalPermisison"),
        value: 0
    },
    {
        label: GetText("Common_EditPermission"),
        value: 1
    },
    {
        label: GetText("Common_ReadPermission"),
        value: 2
    }];