import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Checkbox, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { validateEmail } from "../../Helpers/Validators";


export const BookyCheckboxList = (props: any) => {

    return (

        <Form.Item label={props.label} name={props.name}
        >
            <Checkbox.Group
                options={props.options}
                onChange={props.onChange}
            />
        </Form.Item>
    );

}