import { Button, Result } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserService } from '../../Services/UserService';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { mapSubscriptionLevel, mapSubscriptionLevelFromString } from '../../Mappers/mapSubscriptionLevel';

const ConfirmEmail = () => {
    const [result, setResult] = useState([] as any);
    const navigation = useNavigate();
    const [subscriptionText, setSubscriptionText] = useState([] as any);
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const subscription: SubscriptionLevel = mapSubscriptionLevelFromString(searchParams.get("subscription"));
    const GetText = TextService.GetText;
    const paidLevels = [SubscriptionLevel.Enterprise, SubscriptionLevel.Premium, SubscriptionLevel.Basic]
    const hasRegisteredWithPaidAccount = paidLevels.some(p => p === subscription);

    useEffect(() => {
        if (params.code) {
            UserService.ConfirmEmail(params.code).then(result => {
                setResult(result);
            });
        }

        setSubscriptionText(hasRegisteredWithPaidAccount ?
            GetText("ConfirmEmail_ActiveAccountPaid") :
            GetText("ConfirmEmail_ActiveAccount"));
    }, [])

    const redirectToLogin = () => {
        if (hasRegisteredWithPaidAccount) {
            navigation("/" + getCurrentLanguage() + "/Login?redirect=/Pricing");
        }
        else {
            navigation("/" + getCurrentLanguage() + "/Login");
        }
    }

    return <div id="start">
        <Result
            status="success"
            title={GetText("ConfirmEmail_Success")}
            subTitle={subscriptionText}
            extra={[
                <Button type="primary" onClick={redirectToLogin} key="console">
                    {GetText("ConfirmEmail_Login")}
                </Button>
            ]}></Result>
    </div>
};

export default ConfirmEmail;