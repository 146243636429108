import { AuthProvider } from "../Providers/authProvider"
import { SubscriptionApi } from "../Repository/eventbookyapi";
import { createSubscriptionRequest } from "../Types/RequestResponse/createSubscriptionRequest";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class SubscriptionService {
    public static CreateSubscription = async (request: createSubscriptionRequest) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + AuthProvider().GetToken() },
            body: JSON.stringify(request)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/Subscription/create', requestOptions);
        const data = await response.json();

        return data;
    };

    public static GetAccount = async () => {
        return new SubscriptionApi(GetApiConfiguration()).apiSubscriptionGet({});
    };


};