/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto
     */
    bannerImageColorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto
     */
    primaryColorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto
     */
    secondaryColorCode?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto {
    return WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bannerImageColorCode': !exists(json, 'bannerImageColorCode') ? undefined : json['bannerImageColorCode'],
        'primaryColorCode': !exists(json, 'primaryColorCode') ? undefined : json['primaryColorCode'],
        'secondaryColorCode': !exists(json, 'secondaryColorCode') ? undefined : json['secondaryColorCode'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bannerImageColorCode': value.bannerImageColorCode,
        'primaryColorCode': value.primaryColorCode,
        'secondaryColorCode': value.secondaryColorCode,
    };
}

