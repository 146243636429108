/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesAnswerOptions } from './WebApiModelsEnumerationTypesAnswerOptions';
import {
    WebApiModelsEnumerationTypesAnswerOptionsFromJSON,
    WebApiModelsEnumerationTypesAnswerOptionsFromJSONTyped,
    WebApiModelsEnumerationTypesAnswerOptionsToJSON,
} from './WebApiModelsEnumerationTypesAnswerOptions';
import type { WebApiModelsEnumerationTypesDisplayType } from './WebApiModelsEnumerationTypesDisplayType';
import {
    WebApiModelsEnumerationTypesDisplayTypeFromJSON,
    WebApiModelsEnumerationTypesDisplayTypeFromJSONTyped,
    WebApiModelsEnumerationTypesDisplayTypeToJSON,
} from './WebApiModelsEnumerationTypesDisplayType';
import type { WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto } from './WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto';
import {
    WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoToJSON,
} from './WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto';

/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.RegisterAsync(WebApi.Services.DataTransferObjects.UserService.RegisterReqDto) request.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceCreateEventDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceCreateEventDto {
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    name?: string | null;
    /**
     * Max number of participants for event
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    maxParticipants?: number;
    /**
     * Max number of participants for event
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    deadline?: Date | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesDisplayType}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    displayType?: WebApiModelsEnumerationTypesDisplayType;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    sendAutomaticReminder?: boolean;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesAnswerOptions}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    answerOptions?: WebApiModelsEnumerationTypesAnswerOptions;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    timeZoneId?: string | null;
    /**
     * Gets or sets the password.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    description?: string | null;
    /**
     * Gets or sets the password.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    location?: string | null;
    /**
     * A list of available times
     * @type {Array<WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto>}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventDto
     */
    times?: Array<WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceCreateEventDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceCreateEventDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceCreateEventDto {
    return WebApiServicesDataTransferObjectsUserServiceCreateEventDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceCreateEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'maxParticipants': !exists(json, 'maxParticipants') ? undefined : json['maxParticipants'],
        'deadline': !exists(json, 'deadline') ? undefined : (json['deadline'] === null ? null : new Date(json['deadline'])),
        'displayType': !exists(json, 'displayType') ? undefined : WebApiModelsEnumerationTypesDisplayTypeFromJSON(json['displayType']),
        'sendAutomaticReminder': !exists(json, 'sendAutomaticReminder') ? undefined : json['sendAutomaticReminder'],
        'answerOptions': !exists(json, 'answerOptions') ? undefined : WebApiModelsEnumerationTypesAnswerOptionsFromJSON(json['answerOptions']),
        'timeZoneId': !exists(json, 'timeZoneId') ? undefined : json['timeZoneId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'times': !exists(json, 'times') ? undefined : (json['times'] === null ? null : (json['times'] as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSON)),
    };
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceCreateEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'maxParticipants': value.maxParticipants,
        'deadline': value.deadline === undefined ? undefined : (value.deadline === null ? null : value.deadline.toISOString()),
        'displayType': WebApiModelsEnumerationTypesDisplayTypeToJSON(value.displayType),
        'sendAutomaticReminder': value.sendAutomaticReminder,
        'answerOptions': WebApiModelsEnumerationTypesAnswerOptionsToJSON(value.answerOptions),
        'timeZoneId': value.timeZoneId,
        'description': value.description,
        'location': value.location,
        'times': value.times === undefined ? undefined : (value.times === null ? null : (value.times as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoToJSON)),
    };
}

