/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiEmailDtoBrevoCode } from './WebApiEmailDtoBrevoCode';
import {
    WebApiEmailDtoBrevoCodeFromJSON,
    WebApiEmailDtoBrevoCodeFromJSONTyped,
    WebApiEmailDtoBrevoCodeToJSON,
} from './WebApiEmailDtoBrevoCode';
import type { WebApiEmailDtoDkimRecord } from './WebApiEmailDtoDkimRecord';
import {
    WebApiEmailDtoDkimRecordFromJSON,
    WebApiEmailDtoDkimRecordFromJSONTyped,
    WebApiEmailDtoDkimRecordToJSON,
} from './WebApiEmailDtoDkimRecord';
import type { WebApiEmailDtoDmarcRecord } from './WebApiEmailDtoDmarcRecord';
import {
    WebApiEmailDtoDmarcRecordFromJSON,
    WebApiEmailDtoDmarcRecordFromJSONTyped,
    WebApiEmailDtoDmarcRecordToJSON,
} from './WebApiEmailDtoDmarcRecord';

/**
 * 
 * @export
 * @interface WebApiEmailDtoDnsRecords
 */
export interface WebApiEmailDtoDnsRecords {
    /**
     * 
     * @type {WebApiEmailDtoDkimRecord}
     * @memberof WebApiEmailDtoDnsRecords
     */
    dkimRecord?: WebApiEmailDtoDkimRecord;
    /**
     * 
     * @type {WebApiEmailDtoBrevoCode}
     * @memberof WebApiEmailDtoDnsRecords
     */
    brevoCode?: WebApiEmailDtoBrevoCode;
    /**
     * 
     * @type {WebApiEmailDtoDmarcRecord}
     * @memberof WebApiEmailDtoDnsRecords
     */
    dmarcRecord?: WebApiEmailDtoDmarcRecord;
}

/**
 * Check if a given object implements the WebApiEmailDtoDnsRecords interface.
 */
export function instanceOfWebApiEmailDtoDnsRecords(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiEmailDtoDnsRecordsFromJSON(json: any): WebApiEmailDtoDnsRecords {
    return WebApiEmailDtoDnsRecordsFromJSONTyped(json, false);
}

export function WebApiEmailDtoDnsRecordsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiEmailDtoDnsRecords {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dkimRecord': !exists(json, 'dkim_record') ? undefined : WebApiEmailDtoDkimRecordFromJSON(json['dkim_record']),
        'brevoCode': !exists(json, 'brevo_code') ? undefined : WebApiEmailDtoBrevoCodeFromJSON(json['brevo_code']),
        'dmarcRecord': !exists(json, 'dmarc_record') ? undefined : WebApiEmailDtoDmarcRecordFromJSON(json['dmarc_record']),
    };
}

export function WebApiEmailDtoDnsRecordsToJSON(value?: WebApiEmailDtoDnsRecords | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dkim_record': WebApiEmailDtoDkimRecordToJSON(value.dkimRecord),
        'brevo_code': WebApiEmailDtoBrevoCodeToJSON(value.brevoCode),
        'dmarc_record': WebApiEmailDtoDmarcRecordToJSON(value.dmarcRecord),
    };
}

