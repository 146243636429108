import { Button, Dropdown, Form, Menu, MenuProps, Select, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import ImageResize from 'tiptap-extension-resize-image';
import TextStyle from '@tiptap/extension-text-style'
import { Editor, EditorProvider, useCurrentEditor, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { BoldOutlined, FileImageOutlined, FunctionOutlined, GlobalOutlined, ItalicOutlined, MoreOutlined, OrderedListOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { BookyButton } from "../Buttons/BookyButton";
import { BookySelectDropDown } from "./BookySelectDropDown";
import { TextService } from "../../Services/TextService";

export const OneFrontHtmlEditor = (props: any) => {
  const [html, setHtml] = useState("" as string);
  let { editor } = useCurrentEditor();
  const GetText = TextService.GetText;
  const [selectedVariable, setSelectedVariable] = useState("" as string);

  const MenuBar = (props: any) => {
    const { editor } = useCurrentEditor();

    const setLink = useCallback(() => {
      const previousUrl = editor?.getAttributes('link').href
      const url = window.prompt(GetText("HtmlEditorParameter_UrlPopupText"), previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        editor?.chain().focus().extendMarkRange('link').unsetLink()
          .run()

        return
      }

      // update link
      editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
        .run()
    }, [editor]);

    // Image 
    const addImage = useCallback(() => {
      const url = window.prompt(GetText("HtmlEditorParameter_UrlImagePopupText"))

      if (url) {
        editor?.chain().focus().setImage({ src: url }).run()
      }
    }, [editor])

    if (!editor) {
      return null
    }

    if (!editor) {
      return null
    }

    const styleWidth = {
      width: "50px"
    }

    if (!editor) {
      return null
    }

    const items: MenuProps['items'] = [
      {
        key: GetText("HtmlEditor_MemberId"),
        label: GetText("EditMember_UniqueId")
      },
    ]

    const cleanEmailParam = (value: string) => {
      return value.toLocaleLowerCase().replaceAll("-", "").replaceAll(" ", "");
    }

    const groupMenu = () => {
      return (<Menu>
        <Menu.Item key="1"><BookyButton type="default" style={{ width: "250px" }} onClick={() => editor.commands.insertContent("{{params." + cleanEmailParam(GetText("HtmlEditorParameter_UniqueId")) + "}}")} text={GetText("EditMember_UniqueId")} /></Menu.Item>
        <Menu.Item key="1"><BookyButton type="default" style={{ width: "250px" }} onClick={() => editor.commands.insertContent("{{params." + cleanEmailParam(GetText("HtmlEditorParameter_FirstName")) + "}}")} text={GetText("EditMember_FirstName")} /></Menu.Item>
        <Menu.Item key="1"><BookyButton type="default" style={{ width: "250px" }} onClick={() => editor.commands.insertContent("{{params." + cleanEmailParam(GetText("HtmlEditorParameter_LastName")) + "}}")} text={GetText("EditMember_LastName")} /></Menu.Item>
        <Menu.Item key="1"><BookyButton type="default" style={{ width: "250px" }} onClick={() => editor.commands.insertContent("{{params." + cleanEmailParam(GetText("HtmlEditorParameter_Email")) + "}}")} text={GetText("EditMember_Email")} /></Menu.Item>
        <Menu.Item key="1"><BookyButton type="default" style={{ width: "250px" }} onClick={() => editor.commands.insertContent("{{params." + cleanEmailParam(GetText("HtmlEditorParameter_PhoneNumber")) + "}}")} text={GetText("EditMember_PhoneNumber")} /></Menu.Item>
        <Menu.Item key="1"><BookyButton type="default" style={{ width: "250px" }} onClick={() => editor.commands.insertContent("{{params." + cleanEmailParam(GetText("HtmlEditorParameter_Address")) + "}}")} text={GetText("EditMember_Address")} /></Menu.Item>
        <Menu.Item key="1"><BookyButton type="default" style={{ width: "250px" }} onClick={() => editor.commands.insertContent("{{params." + cleanEmailParam(GetText("HtmlEditorParameter_PostalCode")) + "}}")} text={GetText("EditMember_PostalCode")} /></Menu.Item>
        <Menu.Item key="1"><BookyButton type="default" style={{ width: "250px" }} onClick={() => editor.commands.insertContent("{{params." + cleanEmailParam(GetText("HtmlEditorParameter_PostalArea")) + "}}")} text={GetText("EditMember_PostalArea")} /></Menu.Item>
      </Menu>);
    }

    return (
      <>
        <BookyButton style={styleWidth} type="default" icon={<BoldOutlined />} onClick={() => editor.chain().focus().toggleBold().run()} />
        <BookyButton style={styleWidth} type="default" icon={<ItalicOutlined />} onClick={() => editor.chain().focus().toggleItalic().run()} />
        <BookyButton style={styleWidth} type="default" text="H1" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} />
        <BookyButton style={styleWidth} type="default" text="H2" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} />
        <BookyButton style={styleWidth} type="default" text="H3" onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} />
        <BookyButton style={styleWidth} type="default" icon={<OrderedListOutlined />} onClick={() => editor.chain().focus().toggleOrderedList().run()} />
        <BookyButton style={styleWidth} type="default" icon={<UnorderedListOutlined />} onClick={() => editor.chain().focus().toggleBulletList().run()} />
        <BookyButton style={styleWidth} type="default" icon={<GlobalOutlined />} onClick={setLink} />
        <BookyButton style={styleWidth} type="default" icon={<FileImageOutlined />} onClick={addImage} />

        {props.hideVariablesMenu ? "" : <Dropdown overlay={groupMenu}>
          <Button style={styleWidth} type="default" >
            <Space>
              <FunctionOutlined />
            </Space>
          </Button>
        </Dropdown>}
      </>
    )
  }

  const onUpdate = (updateEvent: any) => {
    props.onHtmlChange(updateEvent.editor.getHTML());
  }

  const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false,
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false,
      },
    },

    ),
    Link.configure({
      autolink: false,
      openOnClick: true
    }),
    Image.configure({
      inline: true,
      allowBase64: true
    }),
    ImageResize.configure()
  ]


  const rules: any = [];
  if (props.requiredMessage) {
    rules.push({
      required: true,
      message: props.requiredMessage,
    });
  }

  return (<Form.Item style={props.style} label={props.label} name={props.name}
    rules={rules}
  >
    <EditorProvider onUpdate={onUpdate} slotBefore={<MenuBar hideVariablesMenu={props.hideVariablesMenu} />} extensions={extensions} content={props.initialHtml} children={undefined} /></Form.Item>);
}