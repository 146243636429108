/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebApiModelsEnumerationTypesSubscriptionLevel = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type WebApiModelsEnumerationTypesSubscriptionLevel = typeof WebApiModelsEnumerationTypesSubscriptionLevel[keyof typeof WebApiModelsEnumerationTypesSubscriptionLevel];


export function WebApiModelsEnumerationTypesSubscriptionLevelFromJSON(json: any): WebApiModelsEnumerationTypesSubscriptionLevel {
    return WebApiModelsEnumerationTypesSubscriptionLevelFromJSONTyped(json, false);
}

export function WebApiModelsEnumerationTypesSubscriptionLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsEnumerationTypesSubscriptionLevel {
    return json as WebApiModelsEnumerationTypesSubscriptionLevel;
}

export function WebApiModelsEnumerationTypesSubscriptionLevelToJSON(value?: WebApiModelsEnumerationTypesSubscriptionLevel | null): any {
    return value as any;
}

