/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesCompanySubscriptionType } from './WebApiModelsEnumerationTypesCompanySubscriptionType';
import {
    WebApiModelsEnumerationTypesCompanySubscriptionTypeFromJSON,
    WebApiModelsEnumerationTypesCompanySubscriptionTypeFromJSONTyped,
    WebApiModelsEnumerationTypesCompanySubscriptionTypeToJSON,
} from './WebApiModelsEnumerationTypesCompanySubscriptionType';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
 */
export interface WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto {
    /**
     * 
     * @type {WebApiModelsEnumerationTypesCompanySubscriptionType}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    subscriptionType?: WebApiModelsEnumerationTypesCompanySubscriptionType;
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    nextSubscriptionRenewalDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    manageUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    smsCredits?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    state?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    nextChargeDisplay?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    allowDirectPayment?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    nextPossibleExtendedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    emailCountSentCurrentYear?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    countAccountAdmins?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto
     */
    countAccountMembers?: number;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto {
    return WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptionType': !exists(json, 'subscriptionType') ? undefined : WebApiModelsEnumerationTypesCompanySubscriptionTypeFromJSON(json['subscriptionType']),
        'nextSubscriptionRenewalDate': !exists(json, 'nextSubscriptionRenewalDate') ? undefined : (json['nextSubscriptionRenewalDate'] === null ? null : new Date(json['nextSubscriptionRenewalDate'])),
        'manageUrl': !exists(json, 'manageUrl') ? undefined : json['manageUrl'],
        'smsCredits': !exists(json, 'smsCredits') ? undefined : json['smsCredits'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'nextChargeDisplay': !exists(json, 'nextChargeDisplay') ? undefined : json['nextChargeDisplay'],
        'allowDirectPayment': !exists(json, 'allowDirectPayment') ? undefined : json['allowDirectPayment'],
        'nextPossibleExtendedDate': !exists(json, 'nextPossibleExtendedDate') ? undefined : (json['nextPossibleExtendedDate'] === null ? null : new Date(json['nextPossibleExtendedDate'])),
        'emailCountSentCurrentYear': !exists(json, 'emailCountSentCurrentYear') ? undefined : json['emailCountSentCurrentYear'],
        'countAccountAdmins': !exists(json, 'countAccountAdmins') ? undefined : json['countAccountAdmins'],
        'countAccountMembers': !exists(json, 'countAccountMembers') ? undefined : json['countAccountMembers'],
    };
}

export function WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDtoToJSON(value?: WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscriptionType': WebApiModelsEnumerationTypesCompanySubscriptionTypeToJSON(value.subscriptionType),
        'nextSubscriptionRenewalDate': value.nextSubscriptionRenewalDate === undefined ? undefined : (value.nextSubscriptionRenewalDate === null ? null : value.nextSubscriptionRenewalDate.toISOString()),
        'manageUrl': value.manageUrl,
        'smsCredits': value.smsCredits,
        'email': value.email,
        'state': value.state,
        'active': value.active,
        'nextChargeDisplay': value.nextChargeDisplay,
        'allowDirectPayment': value.allowDirectPayment,
        'nextPossibleExtendedDate': value.nextPossibleExtendedDate === undefined ? undefined : (value.nextPossibleExtendedDate === null ? null : value.nextPossibleExtendedDate.toISOString()),
        'emailCountSentCurrentYear': value.emailCountSentCurrentYear,
        'countAccountAdmins': value.countAccountAdmins,
        'countAccountMembers': value.countAccountMembers,
    };
}

