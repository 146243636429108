import { Alert, Button, Form } from 'antd';
import { useSearchParams } from "react-router-dom";
import { UserService } from '../../Services/UserService';
import { useState } from 'react';
import BookyBox from '../../Components/Layout/BookyBox';
import PageSmall from '../../Components/Page/PageSmall';
import { BookyPageTitle } from '../../Components/Layout/BookyTitle';
import { BookyInput, InputType } from '../../Components/Forms/BookyInput';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const [errorText, setErrorText] = useState("" as string);
    const [resetSuccess, setResetSuccess] = useState(false as boolean);

    const resetCode = searchParams.get("code") as string;
    const ResetClick = async (formValues: any) => {
        if (formValues.password !== formValues.passwordconfirm) {
            setErrorText("Please check your password.");
            return;
        }

        await UserService.PasswordResetConfirm(resetCode, formValues.email, formValues.password);
        setResetSuccess(true);
    }

    return (
        <PageSmall>
            <BookyBox>
                {resetSuccess === true ?
                    <Alert showIcon={true} type="success" message='Your password has been reset.' />
                    :
                    <Form
                        data-testid="create-form"
                        onFinish={ResetClick}
                        wrapperCol={{
                            span: 24,
                        }}
                        layout="vertical"
                        autoComplete="off"
                    >
                        <BookyPageTitle text="Reset password" subText="Reset your password to a new one" />
                        <BookyInput name="email" placeholder="input e-mail" type={InputType.email} label="Email" requiredMessage="Please input your e-mail" />
                        <BookyInput label="Password" placeholder="input password" type={InputType.password} name="password" requiredMessage="Please input your e-mail" />
                        <BookyInput label="Password" placeholder="input confirm password" type={InputType.password} name="passwordconfirm" requiredMessage="Please input your password" />

                        <Form.Item>
                            <Button data-testid="submit-button" style={{ float: 'left', width: '100%' }} size="middle" type="primary" htmlType="submit">
                                Reset password
                            </Button>
                        </Form.Item>

                        {errorText ? <Form.Item
                            label=" ">
                            <Form.Item>
                                {errorText ? <Alert showIcon={true} type="error" message='errorText'></Alert> : ""}
                            </Form.Item>
                        </Form.Item> : ""}
                    </Form>
                }
            </BookyBox>
        </PageSmall>)
};

export default ResetPassword;