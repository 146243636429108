/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiEmailDtoCommunicationEventDto
 */
export interface WebApiEmailDtoCommunicationEventDto {
    /**
     * 
     * @type {Date}
     * @memberof WebApiEmailDtoCommunicationEventDto
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoCommunicationEventDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoCommunicationEventDto
     */
    event?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoCommunicationEventDto
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoCommunicationEventDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoCommunicationEventDto
     */
    messageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoCommunicationEventDto
     */
    reason?: string | null;
}

/**
 * Check if a given object implements the WebApiEmailDtoCommunicationEventDto interface.
 */
export function instanceOfWebApiEmailDtoCommunicationEventDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiEmailDtoCommunicationEventDtoFromJSON(json: any): WebApiEmailDtoCommunicationEventDto {
    return WebApiEmailDtoCommunicationEventDtoFromJSONTyped(json, false);
}

export function WebApiEmailDtoCommunicationEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiEmailDtoCommunicationEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'event': !exists(json, 'event') ? undefined : json['event'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function WebApiEmailDtoCommunicationEventDtoToJSON(value?: WebApiEmailDtoCommunicationEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'email': value.email,
        'event': value.event,
        'from': value.from,
        'subject': value.subject,
        'messageId': value.messageId,
        'reason': value.reason,
    };
}

