/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiModelsNote
 */
export interface WebApiModelsNote {
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsNote
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsNote
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsNote
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsNote
     */
    text?: string | null;
    /**
     * Gets or sets the date when the role was created.
     * @type {Date}
     * @memberof WebApiModelsNote
     */
    createdAt?: Date;
    /**
     * Gets or sets the date when the role was updated.
     * @type {Date}
     * @memberof WebApiModelsNote
     */
    updatedAt?: Date | null;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsNote
     */
    createdBy?: number;
    /**
     * Gets or sets the description.
     * @type {string}
     * @memberof WebApiModelsNote
     */
    createdByName?: string | null;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsNote
     */
    updatedBy?: number;
}

/**
 * Check if a given object implements the WebApiModelsNote interface.
 */
export function instanceOfWebApiModelsNote(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiModelsNoteFromJSON(json: any): WebApiModelsNote {
    return WebApiModelsNoteFromJSONTyped(json, false);
}

export function WebApiModelsNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdByName': !exists(json, 'createdByName') ? undefined : json['createdByName'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function WebApiModelsNoteToJSON(value?: WebApiModelsNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'memberId': value.memberId,
        'companyId': value.companyId,
        'text': value.text,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'createdBy': value.createdBy,
        'createdByName': value.createdByName,
        'updatedBy': value.updatedBy,
    };
}

