/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsReplyTimes } from './WebApiModelsReplyTimes';
import {
    WebApiModelsReplyTimesFromJSON,
    WebApiModelsReplyTimesFromJSONTyped,
    WebApiModelsReplyTimesToJSON,
} from './WebApiModelsReplyTimes';

/**
 * CreateReply dto
 * @export
 * @interface WebApiServicesDataTransferObjectsReplyServiceGetReplyDto
 */
export interface WebApiServicesDataTransferObjectsReplyServiceGetReplyDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetReplyDto
     */
    replyId?: number;
    /**
     * Gets or sets the identifier.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetReplyDto
     */
    name?: string | null;
    /**
     * Gets or sets the identifier.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetReplyDto
     */
    email?: string | null;
    /**
     * Gets or sets the identifier.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetReplyDto
     */
    message?: string | null;
    /**
     * Gets or sets the identifier.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetReplyDto
     */
    lastUpdated?: Date;
    /**
     * Gets or sets the identifier.
     * @type {Array<WebApiModelsReplyTimes>}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetReplyDto
     */
    replyTime?: Array<WebApiModelsReplyTimes> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsReplyServiceGetReplyDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsReplyServiceGetReplyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsReplyServiceGetReplyDtoFromJSON(json: any): WebApiServicesDataTransferObjectsReplyServiceGetReplyDto {
    return WebApiServicesDataTransferObjectsReplyServiceGetReplyDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsReplyServiceGetReplyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsReplyServiceGetReplyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (new Date(json['lastUpdated'])),
        'replyTime': !exists(json, 'replyTime') ? undefined : (json['replyTime'] === null ? null : (json['replyTime'] as Array<any>).map(WebApiModelsReplyTimesFromJSON)),
    };
}

export function WebApiServicesDataTransferObjectsReplyServiceGetReplyDtoToJSON(value?: WebApiServicesDataTransferObjectsReplyServiceGetReplyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'replyId': value.replyId,
        'name': value.name,
        'email': value.email,
        'message': value.message,
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
        'replyTime': value.replyTime === undefined ? undefined : (value.replyTime === null ? null : (value.replyTime as Array<any>).map(WebApiModelsReplyTimesToJSON)),
    };
}

