

import { message, Upload, Button, UploadProps, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AuthProvider } from '../../Providers/authProvider';
import ImgCrop from 'antd-img-crop';
import { RcFile } from 'antd/lib/upload';
import { TextService } from '../../Services/TextService';

const UploadImage = (props: any) => {
    type NewType = UploadProps;
    const GetText = TextService.GetText;

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadProps: NewType = {
        name: "file",
        action: process.env.REACT_APP_API_URL_BASE + (props.isBanner ? "/api/Image/uploadImage" : "/api/Image/uploadLogo"),
        method: 'POST',
        showUploadList: false,
        headers: {
            Authorization: 'Bearer ' + AuthProvider().GetToken(),
            contentType: "multipart/form-data"
        },
        async onChange(info: any) {
            if (info.file.status === "done") {
                const { response } = info.file;
                props.uploadFinish(response.blob.relativeUri)
            } else if (info.file.status === "error") {
                message.error(GetText("UploadImage_Error"));
            }
        },
        onPreview: onPreview,
        beforeUpload(file: any) {
            const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                message.error(GetText("UploadImage_WrongFormat"));
            }
            const isLt2M = file.size / 1024 / 1024 < 1;
            const isGT20K = file.size / 1024 > 20;
            if (!isLt2M) {
                message.error(GetText("UploadImage_TooBig"));
            }
            if (isJpgOrPng && isLt2M) {
                return true;
            } else {
                return false;
            }
        }
    };

    return (
        props.crop ? <ImgCrop aspect={4 / 1}>
            <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>{GetText("UploadImage_UploadImageButton")}</Button>
            </Upload>
        </ImgCrop> : <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>{GetText("UploadImage_UploadImageButton")}</Button>
        </Upload>);
};

export default UploadImage;