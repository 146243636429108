/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto } from './WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto';
import {
    WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoToJSON,
} from './WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto
     */
    id?: number;
    /**
     * 
     * @type {Array<WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto>}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto
     */
    members?: Array<WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto {
    return WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'members': !exists(json, 'members') ? undefined : (json['members'] === null ? null : (json['members'] as Array<any>).map(WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSON)),
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'members': value.members === undefined ? undefined : (value.members === null ? null : (value.members as Array<any>).map(WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoToJSON)),
    };
}

