import { useEffect, useState } from 'react';
import { Alert, Button, Col, Layout, Row, Space } from 'antd';
import { useNavigate } from "react-router-dom";
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { SubscriptionService } from '../../Services/SubscriptionService';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import AccountForm from './AccountForm';
import LeftMenuSettings from './LeftSettings';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { CompanyRole } from '../../Types/CompanyRole';
import Page from '../../Components/Page/Page';
import { WebApiCompanyDtoCompanyDto, WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto } from '../../Repository/eventbookyapi';
import { CompanySubscriptionType } from '../../Types/CompanySubsriptionType';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import EmailDomainForm from './EmailDomainForm';
import { CompanyService } from '../../Services/CompanyService';
import { mapSubscriptionLevel } from '../../Mappers/mapSubscriptionLevel';

const Settings = () => {
  const navigation = useNavigate();
  const [accountInformation, setAccountInformation] = useState({} as WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto);
  const [user, setUser] = useState({} as AuthenticatedUser);
  const [company, setCompany] = useState({} as WebApiCompanyDtoCompanyDto | null);

  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user!);
    });
    loadCompanySettings();
  }, []);

  const loadCompanySettings = () => {

    CompanyService.GetCompany().then(company => {
      setCompany(company)
    });

    SubscriptionService.GetAccount().then(result => {
      setAccountInformation(result);
    });
  }


  const RedirectToCreateAccount = () => {
    navigation("/" + getCurrentLanguage() + "/pricing")
  }

  return <LayoutLeftMenu fullpageMain={true} title={GetText("Settings_Account")} menu={<LeftMenuSettings selected={"settings"} />}>
    <Col span={16}>
      <Col className='bookyBox' style={{ marginLeft: "20px" }}>
        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
          <h3>{GetText("Settings_Account")}</h3>
        </Space>
        {GetText("Settings_UserName")}: {user?.firstName + " " + user?.lastName}<br></br>
        {GetText("Settings_Subscription")}: {mapSubscriptionLevel(user?.subscriptionLevel)}
        {user?.subscriptionLevel == SubscriptionLevel.Free ? <Alert showIcon={true} type="info" message={GetText("Settings_FreeAccountDescription")} action={
          <Button onClick={RedirectToCreateAccount} size="small" type="primary">
            {GetText("Settings_ReadMorePremium")}
          </Button>} /> : ""}
      </Col>


      {user.role === CompanyRole.GlobalAdmin ? <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }} span={24}>
        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
          <h3>{GetText("Settings_SettingsTitle")}</h3>
        </Space>
        <AccountForm />
      </Col> : ""}

      {user.role === CompanyRole.GlobalAdmin && company && company.email && company.email.length > 0 ? <Col className='bookyBox' style={{ marginLeft: "20px", marginTop: "20px" }} span={24}>
        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
          <h3>{GetText("Settings_SettingsDomainTitle")}</h3>
        </Space>
        <EmailDomainForm loadCompanySettings={loadCompanySettings} company={company} />
      </Col> : ""}
    </Col>
  </LayoutLeftMenu>
};

export default Settings;