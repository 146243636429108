/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BlobDto,
  WebApiServicesDataTransferObjectsBlobResponseDto,
} from '../models';
import {
    BlobDtoFromJSON,
    BlobDtoToJSON,
    WebApiServicesDataTransferObjectsBlobResponseDtoFromJSON,
    WebApiServicesDataTransferObjectsBlobResponseDtoToJSON,
} from '../models';

export interface ApiImageDeleteImageDeleteRequest {
    bloburl?: string;
}

export interface ApiImageDownloadImageGetRequest {
    bloburl?: string;
}

export interface ApiImageUploadImagePostRequest {
    file?: Blob;
}

export interface ApiImageUploadLogoPostRequest {
    file?: Blob;
}

/**
 * 
 */
export class ImageApi extends runtime.BaseAPI {

    /**
     * Authenticates the user.
     */
    async apiImageDeleteImageDeleteRaw(requestParameters: ApiImageDeleteImageDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.bloburl !== undefined) {
            queryParameters['bloburl'] = requestParameters.bloburl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Image/deleteImage`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Authenticates the user.
     */
    async apiImageDeleteImageDelete(requestParameters: ApiImageDeleteImageDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiImageDeleteImageDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Authenticates the user.
     */
    async apiImageDownloadImageGetRaw(requestParameters: ApiImageDownloadImageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.bloburl !== undefined) {
            queryParameters['bloburl'] = requestParameters.bloburl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Image/downloadImage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Authenticates the user.
     */
    async apiImageDownloadImageGet(requestParameters: ApiImageDownloadImageGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiImageDownloadImageGetRaw(requestParameters, initOverrides);
    }

    /**
     * Authenticates the user.
     */
    async apiImageGetimagesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BlobDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Image/getimages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BlobDtoFromJSON));
    }

    /**
     * Authenticates the user.
     */
    async apiImageGetimagesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BlobDto>> {
        const response = await this.apiImageGetimagesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiImageUploadImagePostRaw(requestParameters: ApiImageUploadImagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsBlobResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/Image/uploadImage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsBlobResponseDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiImageUploadImagePost(requestParameters: ApiImageUploadImagePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsBlobResponseDto> {
        const response = await this.apiImageUploadImagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiImageUploadLogoPostRaw(requestParameters: ApiImageUploadLogoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsBlobResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/Image/uploadLogo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsBlobResponseDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiImageUploadLogoPost(requestParameters: ApiImageUploadLogoPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsBlobResponseDto> {
        const response = await this.apiImageUploadLogoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
