import { Button, Col, Divider, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CalendarOutlined, CheckCircleOutlined, ClockCircleOutlined, CloudOutlined, ContainerOutlined, CopyOutlined, CreditCardOutlined, DollarCircleOutlined, ExportOutlined, FormatPainterOutlined, LockOutlined, MailOutlined, MoneyCollectOutlined, NotificationOutlined, RobotOutlined, SafetyCertificateOutlined, SecurityScanFilled, SecurityScanOutlined, TeamOutlined, UserAddOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import Paragraph from 'antd/lib/typography/Paragraph';

import Page from '../../Components/Page/Page';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';

const Start = () => {
  require('./Start.css');
  const navigation = useNavigate();
  const GetText = TextService.GetText;

  const createClick = () => {
    navigation("/" + getCurrentLanguage() + "/CreateUser")
  };

  return <Page className='bookyStartPage' pageTitle={GetText("PageTitle_Start")}>
    <Row justify="center" align="top">
      <Col span={8} className='create-account-box'>
        <Title className="schedule-title" level={1}>{GetText("Start_Title")}</Title>
        <div className="free-service"><Paragraph> <span dangerouslySetInnerHTML={{ __html: GetText("Start_Description") }} /> <span dangerouslySetInnerHTML={{ __html: GetText("Start_Description1") }} /></Paragraph></div>
        <Button size="large" onClick={createClick} type="primary">{GetText("Start_Register")}<UserAddOutlined /></Button>
      </Col>
      <Col className='startPageImg' span={8}>
        <img alt="Start" src={require('../../start2.jpg')}></img>
      </Col>
    </Row>

    <Row justify="center" align="top">
      <Col span={24} className="elevatedWindow">

        <Row justify="center" align="top">
          <Col span={4} col-md={6} col-lg={4} col-xs={24} offset={1} className="box-feature">



            <h2 className="header"><span className="feature-icon"><TeamOutlined /></span> Flexibelt medlemsregister</h2>
            <div className="description">Få kontroll och spårbarhet med vårat flexibla medlemsregister.</div>
          </Col>

          <Col span={4} col-md={6} col-lg={4} col-xs={24} offset={1} className="box-feature">

            <h2 className="header"><span className='feature-icon'><RobotOutlined /></span>Smarta integrationer</h2>
            <div className="description">Koppla ihop med din hemsida, ekonomisystem eller automatisera dina medlemsinbetalningar.</div>
          </Col>

          <Col span={4} col-md={6} col-lg={4} col-xs={24} offset={1} className="box-feature">
            <h2 className="header"><span className='feature-icon'><SafetyCertificateOutlined /></span>Säkerhet</h2>
            <div className="description">Säker och krypterad hantering av data.</div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row justify="center" className='eventbookyfeatures' align="top">
      <Col span={14}>
        <h2 style={{ fontSize: "30px" }}>{GetText("Start_FeaturesTitle")}</h2>
        <Row justify="center" align="top" style={{ marginBottom: "20px" }}>
          <Col span={12} className="">
            <div className="feature-container">
              <div className='feature-icon'>
                <CalendarOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle1")}</div>
                <span>{GetText("Start_EventFeatureDescription1")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <LockOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle2")}</div>
                <span>{GetText("Start_EventFeatureDescription2")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <ClockCircleOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle3")}</div>
                <span>{GetText("Start_EventFeatureDescription3")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <CloudOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle4")}</div>
                <span>{GetText("Start_EventFeatureDescription4")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <DollarCircleOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle8")}</div>
                <span>{GetText("Start_EventFeatureDescription8")}</span>
              </div>
            </div>
          </Col>

          <Col span={12}>
            <div className="feature-container">
              <div className='feature-icon'>
                <ExportOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle5")}</div>
                <span>{GetText("Start_EventFeatureDescription5")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <MailOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle6")}</div>
                <span>{GetText("Start_EventFeatureDescription6")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <CopyOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle6b")}</div>
                <span>{GetText("Start_EventFeatureDescription6b")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <ContainerOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle6c")}</div>
                <span>{GetText("Start_EventFeatureDescription6c")}</span>
              </div>
            </div>

            <div className="feature-container">
              <div className='feature-icon'>
                <FormatPainterOutlined />
              </div>
              <div className='feature-title'>
                <div className="feature-headline">{GetText("Start_EventFeatureTitle7")}</div>
                <span>{GetText("Start_EventFeatureDescription7")}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </Page>
};

export default Start;