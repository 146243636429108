/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiIntegrationsObjectsIntegrationType } from './WebApiIntegrationsObjectsIntegrationType';
import {
    WebApiIntegrationsObjectsIntegrationTypeFromJSON,
    WebApiIntegrationsObjectsIntegrationTypeFromJSONTyped,
    WebApiIntegrationsObjectsIntegrationTypeToJSON,
} from './WebApiIntegrationsObjectsIntegrationType';

/**
 * 
 * @export
 * @interface WebApiIntegrationsDtoConnectIntegrationDto
 */
export interface WebApiIntegrationsDtoConnectIntegrationDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiIntegrationsDtoConnectIntegrationDto
     */
    accessCode?: string | null;
    /**
     * 
     * @type {WebApiIntegrationsObjectsIntegrationType}
     * @memberof WebApiIntegrationsDtoConnectIntegrationDto
     */
    integrationType?: WebApiIntegrationsObjectsIntegrationType;
}

/**
 * Check if a given object implements the WebApiIntegrationsDtoConnectIntegrationDto interface.
 */
export function instanceOfWebApiIntegrationsDtoConnectIntegrationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiIntegrationsDtoConnectIntegrationDtoFromJSON(json: any): WebApiIntegrationsDtoConnectIntegrationDto {
    return WebApiIntegrationsDtoConnectIntegrationDtoFromJSONTyped(json, false);
}

export function WebApiIntegrationsDtoConnectIntegrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiIntegrationsDtoConnectIntegrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessCode': !exists(json, 'accessCode') ? undefined : json['accessCode'],
        'integrationType': !exists(json, 'integrationType') ? undefined : WebApiIntegrationsObjectsIntegrationTypeFromJSON(json['integrationType']),
    };
}

export function WebApiIntegrationsDtoConnectIntegrationDtoToJSON(value?: WebApiIntegrationsDtoConnectIntegrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessCode': value.accessCode,
        'integrationType': WebApiIntegrationsObjectsIntegrationTypeToJSON(value.integrationType),
    };
}

