import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, Input, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isContainingOnlyAlphaNumeric, validateEmail } from "../../Helpers/Validators";
import Paragraph from "antd/lib/skeleton/Paragraph";
import { TextService } from "../../Services/TextService";

export enum InputType {
  email = "email",
  password = "password",
  multiline = "multiline",
  number = "number"
}

export const BookyInput = (props: any) => {
  const GetText = TextService.GetText;
  const rules: any = [];
  if (props.requiredMessage) {
    rules.push({
      required: true,
      message: props.requiredMessage,
    });
  }

  if (props.validateEmailIfFilledIn) {
    rules.push({
      message: GetText("Common_InvalidEmail"),
      validator: (_: any, value: any) => {
        if (value == undefined || value.length == 0 || validateEmail(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject('Some message here');
        }
      }
    });
  }

  if (props.validateNonAlphanumericTextIfFilledIn) {
    rules.push({
      message: GetText("Common_InvalidSmsSender"),
      validator: (_: any, value: any) => {
        if (value == undefined || value.length == 0 || (value.length < 11 && isContainingOnlyAlphaNumeric(value))) {
          return Promise.resolve();
        } else {
          return Promise.reject('Some message here');
        }
      }
    });
  }

  if (props.validateUrl) {
    rules.push({
      message: 'Du måste fylla i en giltig url',
      validator: (_: any, value: any) => {
        if (value && value.length > 0 && isValidUrl(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject('Some message here');
        }
      }
    });


  }

  const isValidUrl = (str: string) => {
    return /^[a-zA-Z0-9-]+$/.test(str);
  }

  return (

    <Form.Item style={props.style} label={props.label} name={props.name}
      rules={rules}
    >
      {(() => {
        switch (props.type) {
          case InputType.number:
            return <InputNumber min={0} max={1000000} placeholder={props.placeholder} />
          case InputType.multiline:
            return <TextArea placeholder={props.placeholder} onChange={props.onChange} showCount={props.showCount}
              maxLength={props.maxLength} />
          case InputType.password:
            return <Input.Password
              placeholder={props.placeholder}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          default:
            return <Input type={props.type} disabled={props.disabled} onChange={props.onChange} placeholder={props.placeholder} />;
        }
      })()}
    </Form.Item>
  );

}