/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiModelsPayment
 */
export interface WebApiModelsPayment {
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsPayment
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsPayment
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsPayment
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsPayment
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsPayment
     */
    year?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WebApiModelsPayment
     */
    paymentDate?: Date | null;
    /**
     * Gets or sets the date when the role was created.
     * @type {Date}
     * @memberof WebApiModelsPayment
     */
    createdAt?: Date;
    /**
     * Gets or sets the date when the role was updated.
     * @type {Date}
     * @memberof WebApiModelsPayment
     */
    updatedAt?: Date | null;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsPayment
     */
    createdBy?: number;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsPayment
     */
    updatedBy?: number;
}

/**
 * Check if a given object implements the WebApiModelsPayment interface.
 */
export function instanceOfWebApiModelsPayment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiModelsPaymentFromJSON(json: any): WebApiModelsPayment {
    return WebApiModelsPaymentFromJSONTyped(json, false);
}

export function WebApiModelsPaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsPayment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'paymentDate': !exists(json, 'paymentDate') ? undefined : (json['paymentDate'] === null ? null : new Date(json['paymentDate'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function WebApiModelsPaymentToJSON(value?: WebApiModelsPayment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'memberId': value.memberId,
        'companyId': value.companyId,
        'amount': value.amount,
        'year': value.year,
        'paymentDate': value.paymentDate === undefined ? undefined : (value.paymentDate === null ? null : value.paymentDate.toISOString()),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
    };
}

