/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesAddOnProduct } from './WebApiModelsEnumerationTypesAddOnProduct';
import {
    WebApiModelsEnumerationTypesAddOnProductFromJSON,
    WebApiModelsEnumerationTypesAddOnProductFromJSONTyped,
    WebApiModelsEnumerationTypesAddOnProductToJSON,
} from './WebApiModelsEnumerationTypesAddOnProduct';
import type { WebApiModelsEnumerationTypesCompanyOrderStatus } from './WebApiModelsEnumerationTypesCompanyOrderStatus';
import {
    WebApiModelsEnumerationTypesCompanyOrderStatusFromJSON,
    WebApiModelsEnumerationTypesCompanyOrderStatusFromJSONTyped,
    WebApiModelsEnumerationTypesCompanyOrderStatusToJSON,
} from './WebApiModelsEnumerationTypesCompanyOrderStatus';

/**
 * 
 * @export
 * @interface WebApiPaymentRepositoryPaysonDtoConfirmResponse
 */
export interface WebApiPaymentRepositoryPaysonDtoConfirmResponse {
    /**
     * 
     * @type {boolean}
     * @memberof WebApiPaymentRepositoryPaysonDtoConfirmResponse
     */
    hasSuccessfullyPayed?: boolean;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesCompanyOrderStatus}
     * @memberof WebApiPaymentRepositoryPaysonDtoConfirmResponse
     */
    paymentStatus?: WebApiModelsEnumerationTypesCompanyOrderStatus;
    /**
     * 
     * @type {Date}
     * @memberof WebApiPaymentRepositoryPaysonDtoConfirmResponse
     */
    nextSubscriptionRenewalDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebApiPaymentRepositoryPaysonDtoConfirmResponse
     */
    snippet?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesAddOnProduct}
     * @memberof WebApiPaymentRepositoryPaysonDtoConfirmResponse
     */
    addOnProduct?: WebApiModelsEnumerationTypesAddOnProduct;
}

/**
 * Check if a given object implements the WebApiPaymentRepositoryPaysonDtoConfirmResponse interface.
 */
export function instanceOfWebApiPaymentRepositoryPaysonDtoConfirmResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiPaymentRepositoryPaysonDtoConfirmResponseFromJSON(json: any): WebApiPaymentRepositoryPaysonDtoConfirmResponse {
    return WebApiPaymentRepositoryPaysonDtoConfirmResponseFromJSONTyped(json, false);
}

export function WebApiPaymentRepositoryPaysonDtoConfirmResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiPaymentRepositoryPaysonDtoConfirmResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasSuccessfullyPayed': !exists(json, 'hasSuccessfullyPayed') ? undefined : json['hasSuccessfullyPayed'],
        'paymentStatus': !exists(json, 'paymentStatus') ? undefined : WebApiModelsEnumerationTypesCompanyOrderStatusFromJSON(json['paymentStatus']),
        'nextSubscriptionRenewalDate': !exists(json, 'nextSubscriptionRenewalDate') ? undefined : (new Date(json['nextSubscriptionRenewalDate'])),
        'snippet': !exists(json, 'snippet') ? undefined : json['snippet'],
        'addOnProduct': !exists(json, 'addOnProduct') ? undefined : WebApiModelsEnumerationTypesAddOnProductFromJSON(json['addOnProduct']),
    };
}

export function WebApiPaymentRepositoryPaysonDtoConfirmResponseToJSON(value?: WebApiPaymentRepositoryPaysonDtoConfirmResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasSuccessfullyPayed': value.hasSuccessfullyPayed,
        'paymentStatus': WebApiModelsEnumerationTypesCompanyOrderStatusToJSON(value.paymentStatus),
        'nextSubscriptionRenewalDate': value.nextSubscriptionRenewalDate === undefined ? undefined : (value.nextSubscriptionRenewalDate.toISOString()),
        'snippet': value.snippet,
        'addOnProduct': WebApiModelsEnumerationTypesAddOnProductToJSON(value.addOnProduct),
    };
}

