import { useEffect, useState } from 'react';
import { Col, Divider, Row, Space, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { DashboardService } from '../../Services/DashboardService';
import { ColumnsType } from 'antd/lib/table';
import { formatDateString } from '../../Helpers/DateHelpers';


const AdminStart = () => {
  const [historyList, setHistoryList] = useState([] as any);
  const [historyData, setHistoryData] = useState([] as any);

  interface DataType {
    key: string;
    name: string;
    date: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, event) => (
        <span><b>{formatDateString(event.date)}</b><br />
        </span>
      ),
    },
    {
      title: 'Event',
      dataIndex: 'name',
      key: 'name',
      render: (_, event) => (
        <span><b>{event.name}</b><br />
        </span>
      ),
    }]


  useEffect(() => {
    DashboardService.GetDashBoardData().then(result => {
      var historyList: { key: Number; name: string; date: string; }[] = [];
      result.history.forEach((history: {
        customerName: string; historyType: any; entityName: string; createdAt: string; Id: Number
      }) => {
        switch (history.historyType) {
          case 0:
            historyList.push({ key: history.Id, name: "A new event " + history.entityName + " was created", date: history.createdAt });
            break;
          case 2:
            historyList.push({ key: history.Id, name: "Event " + history.entityName + " was deleted ", date: history.createdAt });
            break;
          case 2:
            historyList.push({ key: history.Id, name: "User " + history.customerName + " replied to event " + history.entityName, date: history.createdAt });
            break;
        }
      });

      setHistoryList(historyList.reverse());
      setHistoryData(result);
    });
  }, [])

  return <div id="start">
    <Row justify="center" align="top">
      <Col span={8} className='bookyBox'>
        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
          <Title >Last events</Title>
        </Space>
        <Table columns={columns} className="admin-table" rowKey="Id" dataSource={historyList} />

      </Col>
      <Col span={8}>
        You have <b>{historyData.nrOfEvents}</b> events created.
      </Col>
    </Row>
    <Divider />

  </div>
};

export default AdminStart;