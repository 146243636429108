import { authenticateRequest } from "../Types/RequestResponse/authenticateRequest";
import { createUserRequest } from "../Types/RequestResponse/createUserRequest";
import { AuthProvider } from "../Providers/authProvider"
import { AuthenticatedUser } from "../Types/AuthenticatedUser";
import { UserApi } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "./ServiceConfiguration";
import { getCurrentLanguage } from "../Helpers/RouteHelper";
import dayjs from "dayjs";

export abstract class UserService {
    public static Authenticate = async (request: authenticateRequest) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/User/authenticate', requestOptions);

        if (response.status !== 200) {
            return { success: false, message: await response.text() }
        }
        else {
            const data = await response.json();

            if (data.token != null) {
                AuthProvider().SetUser(data);
            }

            return data;
        }
    };

    public static AuthenticateWithToken = async (token: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        const response = await fetch(process.env.REACT_APP_API_URL + '/User/getloggedinuser', options);
        const data = await response.json();

        data.token = token;
        data.tokenExpires = dayjs().add(7, 'days');
        if (data.id != null) {
            AuthProvider().SetUser(data);
        }

        return data;
    };

    public static RefreshLoggedInUser = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider().GetToken()
            }
        }
        const response = await fetch(process.env.REACT_APP_API_URL + '/User/getloggedinuser', options);
        const data = await response.json();

        data.token = AuthProvider().GetToken();
        if (data.id != null) {
            AuthProvider().SetUser(data);
        }

        return data;
    };

    public static PasswordReset = async (email: string) => {
        return new UserApi(GetApiConfiguration()).apiUserPasswordResetPostRaw({
            webApiServicesDataTransferObjectsUserServicePasswordResetReqDto: {
                email: email,
                language: getCurrentLanguage()
            }
        });
    };

    public static PasswordResetConfirm = async (code: string, email: string, password: string) => {
        return new UserApi(GetApiConfiguration()).apiUserConfirmPasswordResetPost({
            code: code,
            email: email,
            password: password
        });
    };

    public static ConfirmEmail = async (code: string) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/User/ConfirmEmail?code=' + code);
        const data = await response.status;

        return data;
    };

    public static CreateUser = async (request: createUserRequest) => {
        return new UserApi(GetApiConfiguration()).apiUserRegisterPost({
            webApiServicesDataTransferObjectsUserServiceRegisterReqDto: {
                firstName: request.firstname,
                lastName: request.lastname,
                email: request.email,
                password: request.password,
                subscription: request.subscription,
                language: getCurrentLanguage()
            }
        });
    };

    public static GetCompanyId = (): number | null => {
        let sessionUser = sessionStorage.getItem("EventBookyUser");

        if (sessionUser != null) {
            let authUser = JSON.parse(sessionUser) as AuthenticatedUser;
            return authUser.companyId;
        }

        return 0;
    };


};