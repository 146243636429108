/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.RegisterAsync(WebApi.Services.DataTransferObjects.UserService.RegisterReqDto) request.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto {
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto
     */
    end?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto
     */
    startUtc?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto
     */
    endUtc?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto
     */
    isFullDay?: boolean;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto {
    return WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'startUtc': !exists(json, 'startUtc') ? undefined : (new Date(json['startUtc'])),
        'endUtc': !exists(json, 'endUtc') ? undefined : (new Date(json['endUtc'])),
        'isFullDay': !exists(json, 'isFullDay') ? undefined : json['isFullDay'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'startUtc': value.startUtc === undefined ? undefined : (value.startUtc.toISOString()),
        'endUtc': value.endUtc === undefined ? undefined : (value.endUtc.toISOString()),
        'isFullDay': value.isFullDay,
    };
}

