import { AuthProvider } from "../Providers/authProvider";
import { AuthProviderMember } from "../Providers/authProviderMember";
import { Configuration } from "../Repository/eventbookyapi";

export const GetApiConfiguration = () => {
    return new Configuration({
        basePath: process.env.REACT_APP_API_URL_BASE,
        headers: {
            'Authorization': 'Bearer ' + AuthProvider().GetToken(),
        }
    });
}

export const GetApiConfigurationMember = () => {
    return new Configuration({
        basePath: process.env.REACT_APP_API_URL_BASE,
        headers: {
            'Authorization': 'Bearer ' + AuthProviderMember().GetToken(),
        }
    });
}