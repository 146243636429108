import { BaseOptionType } from "antd/lib/select";
import { WebApiMemberDtoGroupDto } from "../Repository/eventbookyapi";
import { MemberStatusType } from "../Types/MemberStatusType";

export const mapGroupToListItem = (groupsDto: WebApiMemberDtoGroupDto[]) => {
    let groups: BaseOptionType[] = [];
    groupsDto.forEach((group: WebApiMemberDtoGroupDto) => {
        groups.push({ value: group.id, label: group.name })
    });

    return groups;
}

export const mapMemberStatusToListItem = () => {
    let groups: BaseOptionType[] = [];
    groups.push({ value: MemberStatusType.Active, label: "Aktiva" })
    groups.push({ value: MemberStatusType.Cancelled, label: "Avslutade" })
    groups.push({ value: MemberStatusType.UnconfirmedEmail, label: "Obekräftad e-post" })

    return groups;
}

export const mapMemberStatusToString = (type: MemberStatusType) => {
    switch (type) {
        case MemberStatusType.Active:
            return "Aktiv";
        case MemberStatusType.Cancelled:
            return "Avslutad";
        case MemberStatusType.UnconfirmedEmail:
            return "Obekräftad e-post"
        default:
            return "Aktiv"
    }
}

export const mapToMemberStatus = (statusAsString: string) => {
    if (statusAsString === "Avslutad" || statusAsString === "Cancelled") {
        return MemberStatusType.Cancelled
    }
    else if (statusAsString === "Obekräftad e-post" || statusAsString === "Unconfirmed email") {
        return MemberStatusType.UnconfirmedEmail
    }

    return MemberStatusType.Active;
}

export const mapGroupToListItemExcludeDefaultGroup = (groupsDto: WebApiMemberDtoGroupDto[]) => {
    let groups: BaseOptionType[] = [];
    groupsDto.forEach((group: WebApiMemberDtoGroupDto) => {
        if (group.isDefaultGroup == true) {
            return;
        }
        groups.push({ value: group.id, label: group.name })
    });

    return groups;
}