import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Select, Space, Table, message } from 'antd';
import { WebApiMemberDtoMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../Repository/eventbookyapi';
import Search from 'antd/lib/input/Search';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { TextService } from '../../Services/TextService';
import AddMember from './AddMember';
import { MemberService } from '../../Services/MemberService';
import { DeleteOutlined, DownloadOutlined, FileOutlined, MoreOutlined, SettingOutlined } from '@ant-design/icons';
import { mapGroupToListItem, mapMemberStatusToListItem } from '../../Mappers/mapGroupToListItem';
import { BookyButton } from '../Buttons/BookyButton';
import { MemberTable } from '../Table/MemberTable';
import { BookySelectPaymentStatus } from '../Forms/BookyPaymentStatusSelect';
import { MemberPaymentStatus } from '../../Types/MemberPaymentStatus';
import { useNavigate } from 'react-router-dom';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { MemberStatusType } from '../../Types/MemberStatusType';
import { PaymentConfiguration } from '../../Types/PaymentConfiguration';
import Cookies from 'universal-cookie';
import FullPageSpinner from '../Spinner/FullPageSpinner';

export const MemberList = (props: any) => {
    const [groups, setGroups] = useState([] as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto[]);
    const [selectedGroup, setSelectedGroup] = useState({} as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto);
    const [memberList, setMemberList] = useState([] as WebApiMemberDtoMemberDto[]);
    const [filteredMembers, setFilteredMembers] = useState([] as WebApiMemberDtoMemberDto[]);
    const [freeTextSearch, setFreeTextSearch] = useState('');
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(undefined as MemberPaymentStatus | undefined);
    const [selectedMemberStatus, setSelectedMemberStatus] = useState(MemberStatusType.Active as MemberStatusType);
    const [pageSizePreference, setPageSizePreference] = useState(10 as number);
    const [showFullPageSpinner, setShowFullPageSpinner] = useState(false as boolean);




    const navigation = useNavigate();

    const GetText = TextService.GetText;

    const loadMembers = () => {
        props.loadMembers();
    }

    useEffect(() => {
        setMemberList(props.memberResult?.members!);
        filterMembers(props.memberResult?.members!, selectedPaymentStatus, selectedGroup?.id, freeTextSearch, selectedMemberStatus);

        props.memberResult.groups.forEach((element: any) => {
            if (element.isDefaultGroup) {
                element.name = GetText("MemberList_DefaultAllMembersName");
            }
        });

        setGroups(props.memberResult.groups!);
        setSelectedGroup(props.memberResult.groups![0]);

        const pageSizePreference = new Cookies().get('customerPageSize');
        if (pageSizePreference != null) {
            setPageSizePreference(pageSizePreference);
        }
    }, [props])

    const handleChange = (value: { value: string; label: React.ReactNode }) => {
        groups.forEach((group: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto) => {
            if (group.id === Number(value)) {
                setSelectedGroup(group);
                filterMembers(memberList, selectedPaymentStatus, Number(value), freeTextSearch, selectedMemberStatus);
            }
        });
    };

    const handleMemberStatusChange = (value: MemberStatusType | null | undefined) => {
        filterMembers(memberList, selectedPaymentStatus, selectedGroup?.id, freeTextSearch, value!);
        setSelectedMemberStatus(value!);
    };

    const handlePageSizeChanged = (value: number) => {
        setPageSizePreference(value);
    };

    const filterMembers = (memberList: WebApiMemberDtoMemberDto[], status?: MemberPaymentStatus, groupId?: number, freeTextSearch?: string, selectedStatus?: MemberStatusType) => {
        let filteredMemberList: WebApiMemberDtoMemberDto[] = memberList;

        filteredMemberList = memberList.filter((member: WebApiMemberDtoMemberDto) => member.status === selectedStatus);

        if (groupId && groupId != -1) {
            filteredMemberList = memberList.filter((member: WebApiMemberDtoMemberDto) =>
                member.groups?.includes(Number(groupId)));
        }

        if (status === MemberPaymentStatus.Payed) {
            filteredMemberList = filteredMemberList.filter((member: WebApiMemberDtoMemberDto) => member.isPaidCurrentYear);
        }
        if (status === MemberPaymentStatus.Unpayed) {
            filteredMemberList = filteredMemberList.filter((member: WebApiMemberDtoMemberDto) => !member.isPaidCurrentYear);
        }

        if (freeTextSearch) {
            filteredMemberList = filteredMemberList.filter((member: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto) =>
                member.email?.toLowerCase()?.includes(freeTextSearch) ||
                member.uniqueId?.toLowerCase()?.includes(freeTextSearch) ||
                member.firstName?.toLowerCase()?.includes(freeTextSearch) ||
                member.lastName?.toLowerCase().includes(freeTextSearch) ||
                member.company?.toLowerCase().includes(freeTextSearch) ||
                member.phoneNumber?.toLowerCase().includes(freeTextSearch)
            );
        }

        setFilteredMembers(filteredMemberList);
    }

    const onSelectPaymentChanged = async (paymentStatus: MemberPaymentStatus) => {
        setSelectedPaymentStatus(paymentStatus);
        filterMembers(memberList, paymentStatus, selectedGroup.id, freeTextSearch, selectedMemberStatus);
    };

    const groupMenu = () => {
        return (<Menu>
            <Menu.Item><BookyButton type="dashed" style={{ width: "250px", textAlign: "left" }} onClick={() => navigation("/" + getCurrentLanguage() + "/uploadmembers")} icon={<FileOutlined />} text={GetText("UploadMembers_UploadFileText")} /></Menu.Item>
            <Menu.Item><BookyButton type="dashed" style={{ width: "250px", textAlign: "left" }} onClick={() => navigation("/" + getCurrentLanguage() + "/uploadpayments")} icon={<FileOutlined />} text={GetText("UploadPaymentData_UploadFileText")} /></Menu.Item>
        </Menu>);
    }

    return (
        <>
            {showFullPageSpinner ? <FullPageSpinner /> : ""}
            <Space direction="vertical">
                <Space wrap>
                    <AddMember user={props.user} nextMemberIdSuggestion={props.memberResult.nextMemberIdSuggestion} memberFieldSettings={props.memberFieldSettings} loadMembers={loadMembers} members={props.memberResult.members} groups={props.memberResult.groups} />
                </Space>
            </Space>

            {!props.user.hasOnlyReadRights ? <div style={{ float: 'right' }}>
                <Dropdown overlay={groupMenu}>
                    <Button style={{ marginLeft: "10px" }}>
                        <Space>
                            <MoreOutlined />{"Fler alternativ"}
                        </Space>
                    </Button>
                </Dropdown>
            </div> : ""}

            <br></br>

            <Space direction="horizontal">
                <Space wrap>
                    <Search className='memberlist-filter'
                        placeholder={GetText("MemberList_SearchGroupMember")}
                        value={freeTextSearch}
                        onChange={e => {
                            const currValue = e.target.value.toLowerCase();
                            setFreeTextSearch(currValue);
                            filterMembers(memberList, selectedPaymentStatus, selectedGroup.id, currValue, selectedMemberStatus);
                        }}
                    />
                </Space>

                <Space wrap>
                    <Select
                        className='memberlist-filter memberlist-filter-small'
                        value={{ value: selectedGroup.id?.toString()!, label: selectedGroup.name }}
                        options={mapGroupToListItem(groups)}
                        onChange={handleChange}
                    />
                </Space>

                <Select
                    className='memberlist-filter memberlist-filter-small'
                    value={selectedMemberStatus}
                    options={mapMemberStatusToListItem()}
                    onChange={handleMemberStatusChange}
                />

                {props.user.paymentConfiguration == PaymentConfiguration.YearlyPayments ? <BookySelectPaymentStatus className='memberlist-filter memberlist-filter-small' onSelectPaymentChanged={onSelectPaymentChanged} /> : ""}
            </Space>
            <div style={{ width: "100%", paddingTop: "10px" }}><i>{GetText("MemberList_MembersFoundCount1")} {filteredMembers.length} {GetText("MemberList_MembersFoundCount2")}</i></div>

            <MemberTable handlePageSizeChanged={handlePageSizeChanged} pageSizePreference={pageSizePreference} user={props.user} members={filteredMembers} memberFieldSettings={props.memberFieldSettings} groups={props.memberResult.groups} loadMembers={loadMembers} />
        </>

    );
};

export default MemberList;