import { useEffect, useState } from 'react';
import { Col, Form, Modal, message } from 'antd';
import { BookyInput, InputType } from '../Forms/BookyInput';
import { BookyButton } from '../Buttons/BookyButton';
import { GroupService } from '../../Services/GroupService';
import { DeleteOutlined } from '@ant-design/icons';
import { ReplyService } from '../../Services/ReplyService';
import { TextService } from '../../Services/TextService';

export const DeleteReplyIcon = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const GetText = TextService.GetText;
    const onCancel = () => {
        form.setFieldValue("message", "");
        setOpen(false);
    };

    useEffect(() => {
    }, [form])

    const onCreate = async (values: any) => {
        await ReplyService.DeleteReply(props.reply.id, values.message);
        form.setFieldValue("message", "");
        setOpen(false);

        props.onReplyDelete();
    }

    return (
        <>
            <Modal
                open={open}
                title={GetText("DeleteReply_DeleteHeader")}
                okText={GetText("Common_Delete")}
                cancelText={GetText("Common_Cancel")}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={onCancel}
            >
                {props.reply.customerName} <i>{"<" + props.reply.customerEmail + ">"}</i><br></br>
                <Form form={form} onFinish={onCreate}>
                    <Col span={17}>
                        <BookyInput name="message" type={InputType.multiline} requiredMessage={GetText("DeleteReply_MessageRequired")} placeholder={GetText("DeleteReply_MessagePlaceHolder")} />
                    </Col>
                </Form>
            </Modal >

            <div>
                <DeleteOutlined onClick={() => {
                    setOpen(true);
                }} />
            </div>
        </>
    );
};

export default DeleteReplyIcon;