import { FC } from 'react';
import { Button, Col, Collapse, Divider, Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';
import BookyBox from '../../Components/Layout/BookyBox';
import { TextService } from '../../Services/TextService';

const Contact = () => {
  const { Panel } = Collapse;
  const GetText = TextService.GetText;
  return <Page pageTitle={GetText("PageTitle_Contact")}>
    <BookyBox>
      <Title>{GetText("Contact_Title")}</Title>

      <Paragraph>{GetText("Contact_ReachOut")} <a href='mailto:support@onefront360.com'>support@onefront360.com</a>. {GetText("Contact_ReachOut2")}</Paragraph>

      <Title>FAQ</Title>

      <Collapse accordion>
        <Panel header={GetText("Contact_Question1Question")} key="1">
          <p>{GetText("Contact_Question1Reply")}</p>
        </Panel>
      </Collapse>
    </BookyBox>
  </Page>
};

export default Contact;