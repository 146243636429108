import { Alert, Button, Form } from 'antd';
import { UserService } from '../../Services/UserService';
import { useState } from 'react';
import BookyBox from '../../Components/Layout/BookyBox';
import PageSmall from '../../Components/Page/PageSmall';
import { BookyPageTitle } from '../../Components/Layout/BookyTitle';
import { BookyInput, InputType } from '../../Components/Forms/BookyInput';
import { TextService } from '../../Services/TextService';

const ForgotPassword = () => {
    const [errorText, setErrorText] = useState("" as string);
    const [loginSuccess, setLoginSuccess] = useState(false as boolean);
    const GetText = TextService.GetText;
    const LoginClick = async (formValues: any) => {
        const result = await UserService.PasswordReset(formValues.email);
        setLoginSuccess(true);
    }

    return (
        <PageSmall>
            <BookyBox>
                {loginSuccess === true ?
                    <Alert showIcon={true} type="success" message={GetText("ForgotPassword_EmailSent")} />
                    :
                    <Form
                        data-testid="create-form"
                        onFinish={LoginClick}
                        wrapperCol={{
                            span: 24,
                        }}
                        layout="vertical"
                        autoComplete="off"
                    >
                        <BookyPageTitle text={GetText("ForgotPassword_Title")} subText={GetText("ForgotPassword_Description")} />
                        <BookyInput name="email" placeholder="input e-mail" type={InputType.email} label={GetText("ForgotPassword_Email")} requiredMessage={GetText("ForgotPassword_Email_Placeholder")} />

                        <Form.Item>
                            <Button data-testid="submit-button" style={{ float: 'left', width: '100%' }} size="middle" type="primary" htmlType="submit">
                                {GetText("ForgotPassword_EmailButtonText")}
                            </Button>
                        </Form.Item>

                        {errorText ? <Form.Item
                            label=" ">
                            <Form.Item>
                                {errorText ? <Alert showIcon={true} type="error" message={errorText}></Alert> : ""}
                            </Form.Item>
                        </Form.Item> : ""}
                    </Form>
                }
            </BookyBox>
        </PageSmall>)
};

export default ForgotPassword;