import { useEffect, useState } from 'react';
import { WebApiModelsEmailTemplate } from '../../Repository/eventbookyapi';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import LeftMenuCommunicate from './LeftCommunicate';
import { EmailFormType } from '../../Types/EmailFormType';
import { EmailTemplateService } from '../../Services/EmailTemplateService';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import { SmsForm } from '../../Components/Users/SmsForm';

const AdminSendSmsExternal = () => {
  const [emailTemplates, setEmailTemplates] = useState([] as WebApiModelsEmailTemplate[]);
  const [user, setUser] = useState({} as any);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    EmailTemplateService.GetTemplates().then(result => {
      setEmailTemplates(result);
    });

  }, []);

  return <LayoutLeftMenu title={GetText("Communicate_SendSmsTitle")} menu={<LeftMenuCommunicate selected={"sendSmsExternal"} />}>
    <SmsForm user={user} emailTemplates={emailTemplates} type={EmailFormType.EmailFormByExternal} />
  </LayoutLeftMenu>
};

export default AdminSendSmsExternal;