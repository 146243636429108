import { Card, Col, Row, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CalendarOutlined, CloudOutlined, DollarCircleOutlined, FileExcelOutlined, LineChartOutlined, MailOutlined, SettingOutlined, TeamOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { AuthProvider } from '../../Providers/authProvider';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';

const FeaturesMemberOrganisations = () => {
  const navigation = useNavigate();
  const [user, setUser] = useState(null as AuthenticatedUser | null);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });
  }, []);

  return <Page pageTitle={GetText("PageTitle_MemberOrganisations")}>
    <Row justify="center" align="top" style={{ paddingTop: "40px" }}>
      <Col xs={16} span={16}>
        <Title style={{ textAlign: "center" }}>{GetText("FeaturesMemberOrganisations_Header")}</Title>
        <Paragraph>{GetText("FeaturesMemberOrganisations_Description")}</Paragraph>
        <Row gutter={16}>
          <Col span={8}>
            <Card className="feature-card" title={<><TeamOutlined style={{ color: "green", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature1Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature1Text") }}></p>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="feature-card" title={<><MailOutlined style={{ color: "darkorange", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature2Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature2Text") }}></p>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="feature-card" title={<><CloudOutlined style={{ color: "darkblue", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature3Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature3Text") }}></p>
            </Card>
          </Col>
        </Row>

        <br></br>
        <Row gutter={16}>
          <Col span={8}>
            <Card className="feature-card" title={<><LineChartOutlined style={{ color: "purple", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature4Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature4Text") }}></p>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="feature-card" title={<><SettingOutlined style={{ color: "grey", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature5Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature5Text") }}></p>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="feature-card" title={<><DollarCircleOutlined style={{ color: "darkgreen", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature6Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature6Text") }}></p>
            </Card>
          </Col>
        </Row>

        <br></br>
        <Row gutter={16}>
          <Col span={8}>
            <Card className="feature-card" title={<><CalendarOutlined style={{ color: "darkred", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature7Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature7Text") }}></p>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="feature-card" title={<><UserSwitchOutlined style={{ color: "brown", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature8Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature8Text") }}></p>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="feature-card" title={<><FileExcelOutlined style={{ color: "darkgreen", fontSize: "20px" }} /> {GetText("FeaturesMemberOrganisations_Feature9Title")}</>} bordered={false}>
              <p dangerouslySetInnerHTML={{ __html: GetText("FeaturesMemberOrganisations_Feature9Text") }}></p>
            </Card>
          </Col>

        </Row>
      </Col>
    </Row >
  </Page>
};

export default FeaturesMemberOrganisations;