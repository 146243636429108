import { AuthProvider } from "../Providers/authProvider"
import { ImageApi } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class ImageService {
    public static GetImage = async (url: string) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + AuthProvider().GetToken() },
        };
        return await fetch(process.env.REACT_APP_API_URL + '/Image/downloadImage?bloburl=' + url, requestOptions);
    };

    public static GetBannerImages = async () => {
        return new ImageApi(GetApiConfiguration()).apiImageGetimagesGet();
    };

    public static DeleteBannerImage = async (blobUri: string) => {
        return new ImageApi(GetApiConfiguration()).apiImageDeleteImageDelete({
            bloburl: blobUri
        });
    };
}