export const FreeFeatureList_EN = [
    "25 members",
    "Up to 25 invites per events",
]

export const BasicFeatureList_EN = [
    "2 administrators",
    "Meeting/events, max 1000 participants",
    "E-mails with history/follow-up",
    "Integration with website",
    "Administration of member payments",
    "Export of data",
    "Email templates",
]

export const PremiumFeatureList_EN = [
    "5 administrators",
    "Meeting/events, max 1000 participants",
    "E-mails with history/follow-up",
    "Integration with website",
    "Administration of member payments",
    "Export of data",
    "Email templates",
]



export const EnterpriseFeatureList_EN = [
    "10 administrators",
    "Meeting/events, max 1000 participants",
    "E-mails with history/follow-up",
    "Integration with website",
    "Administration of member payments",
    "Export of data",
    "Email templates",
]