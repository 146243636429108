/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiMemberDtoMemberHistoryChangeDto } from './WebApiMemberDtoMemberHistoryChangeDto';
import {
    WebApiMemberDtoMemberHistoryChangeDtoFromJSON,
    WebApiMemberDtoMemberHistoryChangeDtoFromJSONTyped,
    WebApiMemberDtoMemberHistoryChangeDtoToJSON,
} from './WebApiMemberDtoMemberHistoryChangeDto';
import type { WebApiModelsEnumerationTypesHistoryType } from './WebApiModelsEnumerationTypesHistoryType';
import {
    WebApiModelsEnumerationTypesHistoryTypeFromJSON,
    WebApiModelsEnumerationTypesHistoryTypeFromJSONTyped,
    WebApiModelsEnumerationTypesHistoryTypeToJSON,
} from './WebApiModelsEnumerationTypesHistoryType';

/**
 * 
 * @export
 * @interface WebApiMemberDtoMemberHistoryDto
 */
export interface WebApiMemberDtoMemberHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiMemberDtoMemberHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof WebApiMemberDtoMemberHistoryDto
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoMemberHistoryDto
     */
    entityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoMemberHistoryDto
     */
    createdByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoMemberHistoryDto
     */
    memberName?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesHistoryType}
     * @memberof WebApiMemberDtoMemberHistoryDto
     */
    type?: WebApiModelsEnumerationTypesHistoryType;
    /**
     * 
     * @type {Array<WebApiMemberDtoMemberHistoryChangeDto>}
     * @memberof WebApiMemberDtoMemberHistoryDto
     */
    changes?: Array<WebApiMemberDtoMemberHistoryChangeDto> | null;
}

/**
 * Check if a given object implements the WebApiMemberDtoMemberHistoryDto interface.
 */
export function instanceOfWebApiMemberDtoMemberHistoryDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoMemberHistoryDtoFromJSON(json: any): WebApiMemberDtoMemberHistoryDto {
    return WebApiMemberDtoMemberHistoryDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoMemberHistoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoMemberHistoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'createdByName': !exists(json, 'createdByName') ? undefined : json['createdByName'],
        'memberName': !exists(json, 'memberName') ? undefined : json['memberName'],
        'type': !exists(json, 'type') ? undefined : WebApiModelsEnumerationTypesHistoryTypeFromJSON(json['type']),
        'changes': !exists(json, 'changes') ? undefined : (json['changes'] === null ? null : (json['changes'] as Array<any>).map(WebApiMemberDtoMemberHistoryChangeDtoFromJSON)),
    };
}

export function WebApiMemberDtoMemberHistoryDtoToJSON(value?: WebApiMemberDtoMemberHistoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'entityId': value.entityId,
        'createdByName': value.createdByName,
        'memberName': value.memberName,
        'type': WebApiModelsEnumerationTypesHistoryTypeToJSON(value.type),
        'changes': value.changes === undefined ? undefined : (value.changes === null ? null : (value.changes as Array<any>).map(WebApiMemberDtoMemberHistoryChangeDtoToJSON)),
    };
}

