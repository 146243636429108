import { WebApiMemberDtoMemberHistoryDto } from "../Repository/eventbookyapi";
import { TextService } from "../Services/TextService";
import { HistoryEventType } from "../Types/HistoryEventTypes";

export const mapHistoryeToText = (history: WebApiMemberDtoMemberHistoryDto) => {
    const GetText = TextService.GetText;

    switch (Number(history.type)) {
        case HistoryEventType.AddMember:
            return history.createdByName + GetText("History_Added") + history.memberName + GetText("History_AsMember");
        case HistoryEventType.DeleteMember:
            return history.createdByName + GetText("History_Removed") + history.memberName + GetText("History_AsMember");
        case HistoryEventType.UpdatedMemberProperties:
            return history.createdByName + GetText("History_Updated") + history.memberName + GetText("History_ContactDetails")
        case HistoryEventType.UpdatedMemberPropertiesByMember:
            return history.memberName + GetText("History_ContactDetailsUpdate")
        case HistoryEventType.AddMemberByMember:
            return history.memberName + GetText("History_RegistredAsMember")
        case HistoryEventType.MemberIsActivated:
            return history.memberName + GetText("History_ActivatedAccount")
        default:
            return "Unknown";
    }
}