/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiEmailDtoCommunicationReportDto } from './WebApiEmailDtoCommunicationReportDto';
import {
    WebApiEmailDtoCommunicationReportDtoFromJSON,
    WebApiEmailDtoCommunicationReportDtoFromJSONTyped,
    WebApiEmailDtoCommunicationReportDtoToJSON,
} from './WebApiEmailDtoCommunicationReportDto';

/**
 * 
 * @export
 * @interface WebApiEmailDtoCommunicationEventReportDto
 */
export interface WebApiEmailDtoCommunicationEventReportDto {
    /**
     * 
     * @type {Array<WebApiEmailDtoCommunicationReportDto>}
     * @memberof WebApiEmailDtoCommunicationEventReportDto
     */
    events?: Array<WebApiEmailDtoCommunicationReportDto> | null;
}

/**
 * Check if a given object implements the WebApiEmailDtoCommunicationEventReportDto interface.
 */
export function instanceOfWebApiEmailDtoCommunicationEventReportDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiEmailDtoCommunicationEventReportDtoFromJSON(json: any): WebApiEmailDtoCommunicationEventReportDto {
    return WebApiEmailDtoCommunicationEventReportDtoFromJSONTyped(json, false);
}

export function WebApiEmailDtoCommunicationEventReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiEmailDtoCommunicationEventReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': !exists(json, 'events') ? undefined : (json['events'] === null ? null : (json['events'] as Array<any>).map(WebApiEmailDtoCommunicationReportDtoFromJSON)),
    };
}

export function WebApiEmailDtoCommunicationEventReportDtoToJSON(value?: WebApiEmailDtoCommunicationEventReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': value.events === undefined ? undefined : (value.events === null ? null : (value.events as Array<any>).map(WebApiEmailDtoCommunicationReportDtoToJSON)),
    };
}

