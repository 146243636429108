import { useState } from "react";
import { Input, List } from "antd";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import React from "react";
import { TextService } from "../../Services/TextService";

export const GooglePlaceAutoComplete = (props: any) => {
    const GetText = TextService.GetText;
    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_KEY,
    });
    const [value, setValue] = useState("");
    const [selected, setIsSelected] = useState(false);
    const [blurIsFired, setBlurIsFired] = useState(false);

    React.useEffect(() => {
        if (props.defaultValue) {
            setValue(props.defaultValue);
            setIsSelected(true);
        }

    }, [props]);


    const fireSleected = (item: any) => {
        setIsSelected(true);
        setValue(item.description);
        props.onPlaceSelected(item.description);
    }

    const hideList = (e: any) => {
        setTimeout(() => {
            setIsSelected(true);
        }, 200);
    }

    return (
        <div style={{ width: "100%" }}>
            <Input.Search
                style={{ color: "black" }}
                value={value}
                placeholder={GetText("GooglePlaceAutoComplete_Placeholder")}
                onBlur={(e) => { hideList(e) }}
                onChange={(evt) => {
                    setIsSelected(false);
                    getPlacePredictions({ input: evt.target.value });
                    setValue(evt.target.value);
                    props.onPlaceSelected(evt.target.value);
                }}
                loading={isPlacePredictionsLoading}
            />
            <div
                style={{
                    marginTop: "20px",
                    width: "200px",
                    height: "200px",
                    display: "contents",
                    flex: "1",
                    flexDirection: "column",
                    marginBottom: "100px",
                }}
            >
                {!isPlacePredictionsLoading && value && !selected ? (
                    <List
                        dataSource={placePredictions}
                        renderItem={(item) => (
                            <List.Item style={{ cursor: "pointer" }} onClick={() => fireSleected(item)}>
                                <List.Item.Meta title={item.description} />
                            </List.Item>
                        )}
                    />
                ) : ""}
            </div>
        </div>
    );
};