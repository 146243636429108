/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Create invite result dto
 * @export
 * @interface WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto
 */
export interface WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto
     */
    sentCount?: number;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto {
    return WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sentCount': !exists(json, 'sentCount') ? undefined : json['sentCount'],
    };
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDtoToJSON(value?: WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sentCount': value.sentCount,
    };
}

