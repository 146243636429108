import { Divider, Table, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CheckOutlined } from '@ant-design/icons';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { TextService } from '../../Services/TextService';
import { freeDataOptions, getPricingTableColumns } from './PricingTable';

const FeatureBox = (props: any) => {
    const GetText = TextService.GetText;
    const isCurrentPlan = () => {
        return props.user != null && props.user.subscriptionLevel === SubscriptionLevel.Free
    }

    const isDisabled = () => {
        return props.user?.hasPaidSubscription || isCurrentPlan()
    }

    const buttonText = isCurrentPlan() ? GetText("Pricing_CurrentPlan") : GetText("Pricing_SignUp");


    return <div className="bookyBox pricingBox">
        <div className='descriptionContainer'>
            <Title>{GetText("Pricing_Free")}</Title>
            <div className='description'>{GetText("Pricing_FreePayment")}</div>
            {props.description ? <div className="description">{props.description}</div> : ""}
        </div>
        <Divider style={{ backgroundColor: "var(--color-primary)", }} />
        <>
            <Table
                className='basic-feature-table'
                columns={getPricingTableColumns(TextService)}
                dataSource={freeDataOptions}
                pagination={false}
            />
        </>

        <BookyButton size="large" disabled={isDisabled()} onClick={() => { props.redirectToCreateUser(SubscriptionLevel.Free) }} fullWidth={true} type="primary" text={buttonText} />

        <Divider style={{ backgroundColor: "var(--color-primary)", }} />

        <div className='pricing-features'>
            {
                props.featureList?.map(function (item: string) {
                    return <><CheckOutlined /> <div className='feature'>{item}</div></>
                })
            }
        </div>
    </div >
};

export default FeatureBox;
