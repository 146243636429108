import '../Pricing/Pricing.css'
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';
import BookyBox from '../../Components/Layout/BookyBox';
import Title from "antd/lib/typography/Title";

const Terms_En = () => {
  return <Page>
    <BookyBox>
      <b>Terms of Use: Last Updated: 1 May 2024</b><br /> <Title level={2}>Introduction</Title> <Paragraph>OneFront360 provides a cloud-based service (SaaS) that makes it easy to manage and keep track of contact information in associations and and to which various functionalities can be added.</Paragraph>
      <Paragraph>The basic version includes member administration, event management and communication for automatic mailing. More information can be found in the OneFront360’s “User Guide”.</Paragraph>
      <Paragraph> The following sections list the user of the service as the client and the owner of Onefront360 as the provider.</Paragraph>


      <Title level={2}>1. Terms</Title> <Paragraph>By entering the <a href="https://www.OneFront360.com">https:// www. OneFront 360.com</a> and registering your association or company for a subscription, you agree to be bound by/obey our Terms of Service and agree that you are responsible for the agreement with applicable local laws. If you do not agree to any of these terms, you are prohibited from using this website. </Paragraph>
      The material on this website is protected by copyright and trademark laws. <Title level={2}>2. Rules of licensing</Title>
      <Paragraph>2.1. The Client acknowledges that all property rights and other intellectual property rights to the Service belong to the Provider. The Client is therefore obliged to use the Service only within the framework of the license provided.
        By entering into the Agreement, the Client acquires a non-exclusive license to the Service for one or more Users, depending on the subscription the Client has subscribed to.
        Permission is granted to temporarily download a copy of the material on the OneFront360 website for personal, non-commercial temporary viewing only.
        The license also applies to any updates or adjustments to the service that will be carried out by the provider.</Paragraph>
      <Paragraph>2.2 This is the granting of a license, not a transfer of ownership, and under this license you may not modify or copy the material, use the material for any commercial purpose or for any public display.
        The Client is not entitled to grant or assign a licence to a third party without the prior written consent of the Supplier. The Customer is also not entitled to rent out the service or otherwise make the service available, either free of charge or against remuneration, to third parties without the prior written consent of the Provider.
        Also, do not copy any software or remove any copyright or other proprietary designations of the applicable license.</Paragraph>
      <Paragraph> 2.3. In case of breach, the supplier will terminate the applicable license agreement unless the above restrictions are complied with. Upon termination, your right to view will also cease and you should destroy all downloaded material in your possession whether printed or in electronic format.</Paragraph> <Title level={2}> 3. Disclaimer</Title> All material on the OneFront360 website is provided "as it is". The Supplier gives no warranties, whether express or implied, therefore denies all other guarantees.
      Furthermore, OneFront360 makes no representations of the accuracy or reliability of the use of the material on its website or otherwise related to such material or any websites linked to this website. For more information, see the restrictions section below.

      <Title level={2}>4. Restrictions / force majeure</Title>
      <Paragraph>4.1. OneFront360 or its subcontractors will not be held liable for any damages that may arise and/or have been during use or due to the inability to apply the material on the OneФront360 website.
        The parties have agreed that the total liability of the provider for any claims based on legal relations arising from the agreement shall not exceed a maximum of 25% of the applicable annual fee for specific services subscribed to and provided through OneFront360.
        However, any errors/problems that fall within the responsibility of the supplier will be promptly remedied.
      </Paragraph>
      <Paragraph>4.2. The Supplier shall not be liable for any indirect damages arising as a result of the provision of the Services, such as lost profits, loss of revenue, data loss, financial or other indirect, special or consequential damages. In case of force majeure, the parties are exempt from their obligations under the agreement, and any breach (in whole or in part) or delay in the fulfilment of the obligation under this agreement will not be considered as a breach of the contract.
      </Paragraph>
      <Paragraph>4.3. Genom att ingå avtalet beaktar klienten att även med leverantörens bästa ansträngningar kan en kortvarig otillgänglighet av tjänsten inträffa, vilket orsakas av omständigheter utanför leverantörens kontroll (t.ex. internetanslutningsfel, serverproblem, …). The client therefore agrees and undertakes that all his data stored within the service will be backed up in a different geographical location(er). For more information, see Section Backup below.
      </Paragraph>
      <Paragraph>4.4. Incompatibility with other software, hardware configuration or partial malfunction is beyond the responsibility of the provider. </Paragraph>
      <Title level={2}>5. Audits and Errata</Title>
      The material displayed on the OneFront360 website may contain technical, typographical or photographic errors. OneFront360 does not warrant that any of the material on this site is accurate, complete or up-to-date and may be changed at any time without notice. <Title level={2}>6. Links</Title> OneФront360 has not reviewed all websites that are linked to the site and is not responsible for the content of any linked site. The existence of the linked site does not imply the provider's acceptance of the site but the use is at the user's own risk.
      <Title level={2}>7. Changes to the terms of use of the website</Title>
      The provider of OneFront360 may revise these terms of use on the website at any time without notice. By using the Site, you agree to be bound by the current version of these Terms of Use.
      <Title level={2}>8. Your privacy / protection of personal data.</Title> <Paragraph>Read our privacy policy on the OneFront360 website for more information.</Paragraph>
      <Paragraph>8.1. The client is responsible and aware of his legal obligations as data controller for users and customers. The method and processing of personal data for these entities within the service is determined by the client. The supplier is not responsible for the correct fulfilment of the client's legal obligations as the controller.</Paragraph>
      <Paragraph>8.2. Data may be collected by the provider from the client for the purpose of providing the service, improving quality and in order to be able to provide proper support.</Paragraph>
      <Paragraph>8.3. All communications within the service are encrypted using the SSL protocol. The Client hereby declares that he believes that this method of encryption is sufficiently secure.</Paragraph>
      <Paragraph>8.4. The Provider undertakes not to disclose any information provided by the Client at the time of registration of his/her User Account to third parties unless the Client expressly authorizes it.</Paragraph>
      <Paragraph>8.5. The customer's data is stored in the supplier's electronic system for the duration of the contractual relationship. After the termination of the contract, the supplier only stores the data necessary for compliance with the rules. The Customer agrees that the Supplier will use the Customer’s name and logo in the Supply’s Customer List during and after the term of the Agreement, unless otherwise agreed with the Client based on his brand guidelines or policies.
      </Paragraph>
      <Title level={2}> 9. Applicable law</Title>
      All claims relating to the OneFront360 website shall be governed by customary law without regard to its conflict of law provisions.
      <Title level={2}>10. Payment</Title>
      By registering and paying for a OneFront360 1-year subscription, you agree to our Terms of Use.
      Payment is made through a payment service that your association or your company is linked to the first time you register a subscription, after which the lease period for the selected subscription begins.
      The supplier is entitled to adjust the price of the service on an annual basis according to the applicable consumer price index KPI. The supplier also has the right to adjust the price of the service on an annual basis if our subcontractors change the price for their services.

      <Title level={2}>11. Terminate subscription / contract expires</Title>
      You can terminate your subscription at any time. Any remaining paid subscription time will not be reimbursed.
      <Title level={2}>12. Operating environment</Title>
      The provider provides the operating environment for OneFront360, which is Microsoft's Azure cloud service and installed on servers stationed in Europe. <Title level={2}>13. </Title>
      Backup in the Azure environment as well as logging is carried out rolling at 24.00 for 30 days to be able to restore the client's environment when the situation so requires. If it concerns improper handling by the client who wishes to read back the data, the action may be carried out by the provider against compensation.
      It is the responsibility of the client to ensure that his/her contact information is safely exported as a backup archive for re-read if problems arise with the client’s contact registry or if he/she wishes to archive data. Appropriately once/month or more often.
      <Title level={2}>14. Support</Title>
      Support is provided “online” for OneFront365 unless otherwise agreed. For more information on support options, see the Contact tab when you are connected to the service.

    </BookyBox>
  </Page>
};

export default Terms_En;