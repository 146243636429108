/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * CreateReply dto
 * @export
 * @interface WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto
 */
export interface WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto {
    /**
     * AccountId FastSpring
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto
     */
    accountId?: string | null;
    /**
     * SubscriptionId FastSpring
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto
     */
    subscriptionId?: string | null;
    /**
     * Product name
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto
     */
    product?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDtoFromJSON(json: any): WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto {
    return WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountId': !exists(json, 'accountId') ? undefined : json['accountId'],
        'subscriptionId': !exists(json, 'subscriptionId') ? undefined : json['subscriptionId'],
        'product': !exists(json, 'product') ? undefined : json['product'],
    };
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDtoToJSON(value?: WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountId': value.accountId,
        'subscriptionId': value.subscriptionId,
        'product': value.product,
    };
}

