import React, { useState } from 'react';
import { Badge, Button, Col, Form, List, Modal, message } from 'antd';
import { BookyInput, InputType } from '../Forms/BookyInput';
import { BookOutlined } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';
import { WebApiModelsNote } from '../../Repository/eventbookyapi';
import { MemberService } from '../../Services/MemberService';
import { BookyButton } from '../Buttons/BookyButton';
import { formatDateString } from '../../Helpers/DateHelpers';

export const AddNote = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const GetText = TextService.GetText;
    const [notes, setNotes] = useState([] as WebApiModelsNote[]);

    const onCancel = () => {
        form.setFieldValue("groupName", "");
        setOpen(false);
        props.loadMembers();
    };

    const onCreate = async () => {
        try {
            await MemberService.AddNote({
                memberId: props.member.memberId,
                text: form.getFieldValue("text"),
            });

            form.setFieldValue("text", "");
            loadNotes();
        } catch (error) {
            message.warning(GetText("EditMember_ErrorSaving"));
            return;
        }
    }

    const loadNotes = () => {
        MemberService.GetNotes(props.member.memberId).then(result => {
            setNotes(result);
        })
    }

    const deleteNote = (note: WebApiModelsNote) => {
        MemberService.DeleteNote(note.id!).then(result => {
            loadNotes();
        })
    }

    const locale = {
        emptyText: GetText("AddNote_NoNotes")
    }

    return (
        <>
            <Modal
                open={open}
                title={GetText("AddNote_ModalTitle") + " - (" + props.member.uniqueId + ") " + props.member.firstName + " " + props.member.lastName}
                width={700}
                okText={GetText("EditMember_ModalClose")}
                onOk={onCancel}
                onCancel={onCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form form={form} labelAlign={"left"} labelCol={{ span: 8, offset: 0 }} wrapperCol={{ span: 16 }} >
                    <Col span={17}>
                        <BookyInput type={InputType.multiline} onClick={onCreate} name="text" req placeholder={GetText("AddNote_NoteColumnPlaceHolder")} />
                        <BookyButton
                            type="primary"
                            disabled={props.user.hasOnlyReadRights}
                            fullWidth={false}
                            text={GetText("AddNote_SaveNote")}
                            onClick={onCreate}
                        >
                        </BookyButton>
                    </Col>
                </Form>

                <List
                    locale={locale}
                    itemLayout="horizontal"
                    dataSource={notes}
                    renderItem={(item, index) => (
                        <List.Item extra={<Button disabled={props.user.hasOnlyReadRights} size="small" onClick={() => deleteNote(item)}>{GetText("Common_Delete")}</Button>}>
                            <List.Item.Meta
                                title={<span>{item.createdByName} {formatDateString(item.createdAt!.toString())}</span>}
                                description={item.text}
                            />
                        </List.Item>
                    )}
                />
            </Modal>


            <Badge dot={true} color={"blue"} count={props.member.numberOfNotes}>
                <BookOutlined
                    style={{ fontSize: "20px" }}
                    type="primary"
                    onClick={() => {
                        setOpen(true);
                        loadNotes();
                    }}
                >
                </BookOutlined>
            </Badge>
        </>
    );
};

export default AddNote;