import { useNavigate } from "react-router-dom";
import '../Pricing/Pricing.css'
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';
import BookyBox from '../../Components/Layout/BookyBox';
import Title from "antd/lib/typography/Title";

const Terms = () => {
  return <Page>
    <BookyBox>
      <b>Användarvillkor: Senast uppdaterad: 1 maj 2024</b><br />
      <Title level={2}>Inledning</Title>
      <Paragraph>OneFront360 tillhandahåller en molnbaserad tjänst (SaaS) som gör det enkelt att administrera och hålla reda på kontaktuppgifter i föreningar och företag och till vilket olika funktionaliteter kan läggas till.</Paragraph>
      <Paragraph>I basversionen ingår medlemsadministration, eventhantering och kommunikation för automatiska mailutskick. Mer info hittar du i OneFront360:s ”User Guide”.</Paragraph>
      <Paragraph> I följande avsnitt nedan anges användaren av tjänsten som klienten och ägaren av Onefront360 som leverantören.</Paragraph>
      <Title level={2}>1. Villkor</Title>
      <Paragraph>Genom att gå in på <a href="https://www.OneFront360.com">https://www.OneFront360.com</a> och registrera din förening eller företag för ett abonnemang, samtycker du till att vara bunden av/följa våra användarvillkor för tjänsten och samtycker till att du är ansvarig för avtalet med tillämpliga lokala lagar. Om du inte håller med någon av dessa villkor, är du förbjuden att använda denna webbplats. </Paragraph>
      Materialet på denna webbplats är skyddat av upphovsrätt och varumärkeslagar.
      <Title level={2}>2. Regler licensnyttjande</Title>
      <Paragraph>2.1. Klienten beaktar att alla äganderätter och andra immateriella rättigheter till tjänsten tillhör leverantören. Klienten är därför skyldig att använda tjänsten endast inom ramen för den tillhandahållna licensen.
        Genom att ingå avtalet förvärvar klienten en icke-exklusiv licens till tjänsten för en eller flera användare beroende på vilket abonnemang klienten tecknat sig för.
        Tillåtelse ges att tillfälligt ladda ner ett exemplar av materialet på OneFront360s webbplats endast för personlig, icke-kommersiell tillfällig visning.
        Licensen gäller även för alla uppdateringar eller justeringar av tjänsten som kommer att utföras av leverantören.</Paragraph>
      <Paragraph>2.2 Detta är beviljande av en licens, inte en överföring av äganderätt, och under denna licens får du inte modifiera eller kopiera materialet, använda materialet för något kommersiellt syfte eller för någon offentlig visning.
        Klienten har inte rätt att bevilja eller överlåta licens till tredje part utan föregående skriftligt samtycke från leverantören. Klienten har heller inte rätt att hyra ut tjänsten eller på annat sätt göra tjänsten tillgänglig, varken gratis eller mot ersättning, för tredje parter utan föregående skriftligt samtycke från leverantören.
        Ej heller kopiera någon programvara eller ta bort all upphovsrätt eller andra äganderätts beteckningar för gällande licens.</Paragraph>
      <Paragraph> 2.3. Vid överträdelse kommer leverantören avsluta gällande licensavtal om inte restriktioner ovan följs. Vid uppsägning kommer även din visningsrätt att upphöra och du bör förstöra allt nedladdat material i din ägo oavsett om det är tryckt eller i elektroniskt format.</Paragraph>
      <Title level={2}> 3. Ansvarsfriskrivning</Title>
      Allt material på OneFront360:s webbplats tillhandahålls "i befintligt skick". Leverantören lämnar inga garantier, vare sig uttryckliga eller underförstådda, förnekar därför alla andra garantier.
      Vidare lämnar OneFront360 inga utfästelser om riktigheten eller tillförlitligheten av användningen av materialet på sin webbplats eller på annat sätt relaterat till sådant material eller några webbplatser som är länkade till denna webbplats. För mer information, se avsnittet begränsningar nedan.

      <Title level={2}>4. Begränsningar / force majeure</Title>
      <Paragraph>4.1. OneFront360 eller dess underleverantörer kommer inte att hållas ansvariga för eventuella skador som kan uppstå och/eller har anmälts vid användning eller på grund av oförmåga att tillämpa materialet på OneFront360:s webbplats.
        Parterna har kommit överens om att leverantörens totala ansvar för eventuella krav som grundas på rättsliga förhållanden som uppstår från avtalet inte får överstiga max 25% av den aktuella årsavgiften för specifika tjänster som prenumereras och tillhandahålls via OneFront360.
        Däremot kommer eventuella fel/problem som åligger leverantörens ansvar att snarast åtgärdas.
      </Paragraph>
      <Paragraph>4.2. Leverantören ansvarar inte för indirekta skador som uppstår till följd av tillhandahållande av tjänster, såsom förlorade vinster, förlust av intäkter, förlust av data, ekonomiska eller andra indirekta, särskilda eller följdsamma skador. Vid force majeure är parterna befriade från sina skyldigheter enligt avtalet, och eventuellt överträdelse (helt eller delvis) eller fördröjning i fullgörandet av skyldigheter enligt detta avtal kommer inte att anses som brott mot avtalet.
      </Paragraph>
      <Paragraph>4.3. Genom att ingå avtalet beaktar klienten att även med leverantörens bästa ansträngningar kan en kortvarig otillgänglighet av tjänsten inträffa, vilket orsakas av omständigheter utanför leverantörens kontroll (t.ex. internetanslutningsfel, serverproblem, …). Klienten samtycker därför till och åtar sig att all hans data som lagras inom tjänsten kommer att säkerhetskopieras på en annan geografisk plats(er). Mer information, se avsnitt Backup nedan.
      </Paragraph>
      <Paragraph>4.4. Inkompatibilitet med annan programvara, hårdvarukonfiguration eller delvis funktionsfel ligger utanför leverantörens ansvar.
      </Paragraph>
      <Title level={2}>5. Revisioner och Errata</Title>
      Materialet som visas på OneFront360:s webbplats kan innehålla tekniska, typografiska eller fotografiska fel. OneFront360 lovar inte att något av materialet på denna webbplats är korrekt, fullständigt eller aktuellt utan kan komma att ändras när som helst utan föregående meddelande.
      <Title level={2}>6. Länkar</Title>
      OneFront360 har inte granskat alla webbplatser som är länkade till webbplatsen och är inte ansvarig för innehållet på länkad webbplats. Förekomsten av länkad webbplats innebär inte att leverantören godkänner webbplatsen utan användning sker på användarens eget ansvar.
      <Title level={2}>7. Ändringar av webbplatsens användarvillkor</Title>
      Leverantören av OneFront360 kan när som helst revidera dessa användarvillkor på webbplatsen utan föregående meddelande. Genom att använda webbplatsen samtycker du till att vara bunden av den aktuella versionen av dessa användarvillkor.
      <Title level={2}>8. Din integritet / skydd av personuppgifter.</Title>
      <Paragraph>Läs vår integritetspolicy på webbplatsen för OneFront360 för mer information.</Paragraph>
      <Paragraph>8.1. Klienten är ansvarig och medveten om sina rättsliga skyldigheter som personuppgiftsansvarig för användare och kunder. Metod och behandling av personuppgifter för dessa enheter inom tjänsten bestäms av klienten. Leverantören ansvarar inte för korrekt uppfyllelse av klientens rättsliga skyldigheter som personuppgiftsansvarig.</Paragraph>
      <Paragraph>8.2. Data kan samlas in av leverantören från klienten i syfte att tillhandahålla tjänsten, förbättrad kvalitet och för att kunna ge korrekt support.</Paragraph>
      <Paragraph>8.3. All kommunikation inom tjänsten krypteras med SSL-protokollet. Klienten förklarar härmed att han anser att denna metod för kryptering är tillräckligt säker.</Paragraph>
      <Paragraph>8.4. Leverantören åtar sig att inte lämna ut någon information som anges av klienten vid registreringen av sitt användarkonto till tredje part, om inte klienten specifikt godkänner det.</Paragraph>
      <Paragraph>8.5. Klientens data lagras i leverantörens elektroniska system under hela avtalsförhållandet. Efter avtalets upphörande lagrar leverantören endast data som är nödvändiga för regeluppfyllelse. Klienten samtycker till att leverantören använder klientens namn och logotyp i leverantörens kundlista under och efter avtalets giltighetstid, om inte annat överenskommits med klienten baserat på hans varumärkesriktlinjer eller policy.
      </Paragraph>
      <Title level={2}> 9. Tillämplig lag</Title>
      Alla anspråk relaterade till OneFront360:s webbplats ska styras av lagarna i sed utan hänsyn till dess lagkonfliktbestämmelser.
      <Title level={2}>10. Betalning</Title>
      När du registrerar dig och betalar in för 1 årsabonnemang av OneFront360 samtycker du till våra användarvillkor.
      Betalningen sker via en betalningstjänst som er förening eller ert företag kopplas vidare till första gången när ni registrerar ett abonnemang varefter hyresperioden för valt abonnemang påbörjas.
      Leverantören äger rätt att på årlig basis justera priset för tjänsten enligt gällande konsumentprisindex KPI. Leverantören äger även rätt att på årlig basis justera priset på tjänsten om våra underleverantörer ändrar priset på sina tjänster.
      <Title level={2}>11. Avsluta abonnemang / avtalet upphör</Title>
      Du kan när som helst avsluta ert abonnemang. Eventuellt kvarstående betald prenumerationstid återbetalas inte.
      <Title level={2}>12. Driftsmiljö</Title>
      Leverantören tillhandahåller driftsmiljön för OneFront360 som är Microsofts molntjänst Azure och installerad på servrar stationerade i Europa.
      <Title level={2}>13. Backup</Title>
      Backup i Azure miljön liksom loggning utförs rullande kl. 24.00 under 30 dagar för att kunna återställa klientens miljö när situationen så påkallar. Om det berör felaktigt handhavande från klienten som önskar återläsning av data kan åtgärden utföras av leverantören mot ersättning.
      Det åligger klienten att se till att på ett säkert sätt exportera sina kontaktuppgifter som ett backuparkiv för återläsning ifall det uppstått problem med klientens kontaktregister eller man önskar arkivera uppgifter. Lämpligen en gång/månad eller oftare.
      <Title level={2}>14. Support</Title>
      Support ges ”online” för OneFront365 såvida inget annat avtalats. Mer info om möjligheterna till support hittar du under fliken Kontakta när du är ansluten till tjänsten.
    </BookyBox>
  </Page>
};

export default Terms;