
import { format, addMinutes } from "date-fns";
import moment from "moment-timezone";

export const formatDateRangeString = (date: string, date2: string, isFullDay: boolean, is24hrs: boolean) => {
    if (date == undefined) {
        return "";
    }
    if (isFullDay) {
        return format(new Date(date), "yyyy-MM-dd");
    }

    if (is24hrs) {
        return format(new Date(date), "HH:mm") + "-" + format(new Date(date2), "HH:mm");
    }

    if (format(new Date(date), "aaa") == format(new Date(date2), "aaa")) {
        return format(new Date(date), "H:mm") + "-" + format(new Date(date2), "H:mm aaa")
    }
    else {
        return format(new Date(date), "HH:mm aaa") + "-" + format(new Date(date2), "HH:mm aaa")
    }
}

export const formatDateRangeWithDateString = (date: string, date2: string, isFullDay: boolean, is24hrs: boolean) => {
    if (date == undefined) {
        return "";
    }
    if (isFullDay) {
        return format(new Date(date), "yyyy-MM-dd");
    }

    if (is24hrs) {
        return format(new Date(date), "yyyy-MM-dd HH:mm") + "-" + format(new Date(date2), "HH:mm");
    }

    if (format(new Date(date), "aaa") == format(new Date(date2), "aaa")) {
        return format(new Date(date), "yyyy-MM-dd H:mm") + "-" + format(new Date(date2), "H:mm aaa")
    }
    else {
        return format(new Date(date), "yyyy-MM-dd HH:mm aaa") + "-" + format(new Date(date2), "HH:mm aaa")
    }
}

export const getDateStringFromDate = (date: string) => {
    if (!date) {
        return "";
    }
    return format(new Date(date), "yyyy-MM-dd ");
}

export const formatToShortFormat = (date: Date | null | undefined) => {
    if (!date) {
        return "";
    }

    return format(date, "yyyy-MM-dd");
}

export const formatDateString = (date: string | Date | undefined | null) => {
    return date != undefined ? format(new Date(date), "yyyy-MM-dd HH:mm") : "";
}

export const getTimeStringFromDate = (date: string, is24hrs: boolean) => {
    if (date == undefined) {
        return "";
    }
    return is24hrs ? format(new Date(date), "HH:mm") : format(new Date(date), "HH:mm aaa");
}

export const getTimeRangeString = (date: string, date2: string, is24hrs: boolean) => {
    if (date == undefined) {
        return "";
    }

    if (is24hrs) {
        return format(new Date(date), "HH:mm") + "-" + format(new Date(date2), "HH:mm");
    }

    if (format(new Date(date), "aaa") == format(new Date(date2), "aaa")) {
        return format(new Date(date), "H:mm") + "-" + format(new Date(date2), "H:mm aaa")
    }
    else {
        return format(new Date(date), "HH:mm aaa") + "-" + format(new Date(date2), "HH:mm aaa")
    }
}

export const getUtcDate = (date: Date, timeZone: string) => {
    var utcDate = moment.utc(moment.tz(moment(date).format("YYYY-MM-DD HH:mm"), timeZone));
    return new Date(utcDate.year(), utcDate.month(), utcDate.date(), utcDate.hours(), utcDate.minutes(), utcDate.seconds(), utcDate.milliseconds())
}

export const getPreviousYear = (subTractNumberOfYears: number) => {
    return moment().subtract(subTractNumberOfYears, 'years').year();
}

export const getCurrentYearAsString = () => {
    return moment().format("YYYY");
}

export const getUpcomingYear = (addNumberOfYears: number) => {
    return moment().add(addNumberOfYears, 'years').year();
}