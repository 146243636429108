/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemIOStream
 */
export interface SystemIOStream {
    /**
     * 
     * @type {boolean}
     * @memberof SystemIOStream
     */
    readonly canRead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemIOStream
     */
    readonly canWrite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemIOStream
     */
    readonly canSeek?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemIOStream
     */
    readonly canTimeout?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SystemIOStream
     */
    readonly length?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemIOStream
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemIOStream
     */
    readTimeout?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemIOStream
     */
    writeTimeout?: number;
}

/**
 * Check if a given object implements the SystemIOStream interface.
 */
export function instanceOfSystemIOStream(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SystemIOStreamFromJSON(json: any): SystemIOStream {
    return SystemIOStreamFromJSONTyped(json, false);
}

export function SystemIOStreamFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemIOStream {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canRead': !exists(json, 'canRead') ? undefined : json['canRead'],
        'canWrite': !exists(json, 'canWrite') ? undefined : json['canWrite'],
        'canSeek': !exists(json, 'canSeek') ? undefined : json['canSeek'],
        'canTimeout': !exists(json, 'canTimeout') ? undefined : json['canTimeout'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'readTimeout': !exists(json, 'readTimeout') ? undefined : json['readTimeout'],
        'writeTimeout': !exists(json, 'writeTimeout') ? undefined : json['writeTimeout'],
    };
}

export function SystemIOStreamToJSON(value?: SystemIOStream | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'readTimeout': value.readTimeout,
        'writeTimeout': value.writeTimeout,
    };
}

