import { Col, Row, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { AuthProvider } from '../../Providers/authProvider';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';

const FeaturesCompanies = () => {
  const navigation = useNavigate();
  const [user, setUser] = useState(null as AuthenticatedUser | null);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });
  }, []);

  return <div id="start">
    <Row justify="center" align="top" style={{ paddingTop: "40px" }}>
      <Col xs={18} span={16}>
        <Title style={{ textAlign: "center" }}>{GetText("Pricing_Title")}</Title>

        <Row justify="center" align="top" style={{ paddingTop: "40px", marginBottom: "20px", marginLeft: "-50px", marginRight: "-50px" }}>
          <Col xs={24} xl={6} className="bookyBox pricingBox">
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
};

export default FeaturesCompanies;