/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiEmailDtoDnsRecords } from './WebApiEmailDtoDnsRecords';
import {
    WebApiEmailDtoDnsRecordsFromJSON,
    WebApiEmailDtoDnsRecordsFromJSONTyped,
    WebApiEmailDtoDnsRecordsToJSON,
} from './WebApiEmailDtoDnsRecords';

/**
 * 
 * @export
 * @interface WebApiEmailDtoEmailDomainCreationDto
 */
export interface WebApiEmailDtoEmailDomainCreationDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailDomainCreationDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailDomainCreationDto
     */
    domainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoEmailDomainCreationDto
     */
    message?: string | null;
    /**
     * 
     * @type {WebApiEmailDtoDnsRecords}
     * @memberof WebApiEmailDtoEmailDomainCreationDto
     */
    dnsRecords?: WebApiEmailDtoDnsRecords;
}

/**
 * Check if a given object implements the WebApiEmailDtoEmailDomainCreationDto interface.
 */
export function instanceOfWebApiEmailDtoEmailDomainCreationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiEmailDtoEmailDomainCreationDtoFromJSON(json: any): WebApiEmailDtoEmailDomainCreationDto {
    return WebApiEmailDtoEmailDomainCreationDtoFromJSONTyped(json, false);
}

export function WebApiEmailDtoEmailDomainCreationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiEmailDtoEmailDomainCreationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'domainName': !exists(json, 'domain_name') ? undefined : json['domain_name'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'dnsRecords': !exists(json, 'dns_records') ? undefined : WebApiEmailDtoDnsRecordsFromJSON(json['dns_records']),
    };
}

export function WebApiEmailDtoEmailDomainCreationDtoToJSON(value?: WebApiEmailDtoEmailDomainCreationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'domain_name': value.domainName,
        'message': value.message,
        'dns_records': WebApiEmailDtoDnsRecordsToJSON(value.dnsRecords),
    };
}

