import { Modal } from "antd";
require('./OneFrontModal.css');

export const OneFrontModal = (props: any) => {

    return (
        <Modal
            className={"onefront-modal"}
            open={props.open}
            title={props.title}
            okText={props.okText}
            okButtonProps={props.okButtonProps}
            cancelButtonProps={props.cancelButtonProps}
            cancelText={props.cancelText}
            confirmLoading={props.confirmLoading}
            width={700}
            onCancel={props.onCancel}
            onOk={props.onOk}
        >
            {props.children}
        </Modal >
    )
}