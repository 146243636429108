export const AuthProviderMember = () => {
  const SetToken = async (token: string) => {
    sessionStorage.setItem("onefront_membersession", token);
  };


  const GetToken = (): String | null => {
    console.log(sessionStorage.getItem("onefront_membersession"));
    return sessionStorage.getItem("onefront_membersession");
  };

  return {
    SetToken,
    GetToken,
  };
};