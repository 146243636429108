import { useState } from "react";
import { AutoComplete, Button, Input, List } from "antd";
import React from "react";
import parse from 'html-react-parser';
import { TextService } from "../../Services/TextService";
import { validateEmail, validatePhoneNumber } from "../../Helpers/Validators";
import { AutoCompleteType } from "../../Types/AutoCompleteType";

export const ContactAutoComplete = (props: any) => {

    const [value, setValue] = useState("");
    const [inviteEmails, setinviteEmails] = useState([] as any[]);
    const [options, setOptions] = useState<{ value: string }[]>([]);

    const GetText = TextService.GetText;

    React.useEffect(() => {
        setinviteEmails(props.emails);
    }, [props.emails]);

    const addToSavedEmails = (value: string) => {
        let newList = inviteEmails;
        if (value.indexOf(";") != -1) {
            const emails = value.split(";");
            emails.forEach(newMail => {
                if (newList.indexOf(newMail) == -1) {
                    newList.push(newMail);
                }
            })
        }
        else {
            if (props.AutoCompleteType == AutoCompleteType.Email && validateEmail(value)) {
                if (newList.indexOf(value) == -1) {
                    newList.push(value);
                }
            }

            if (props.AutoCompleteType == AutoCompleteType.PhoneNumber && validatePhoneNumber(value) && value.startsWith("+46")) {
                if (newList.indexOf(value) == -1) {
                    newList.push(value);
                }
            }
        }
        setValue('');
        props.onEmailsUpdated(newList);
    }

    const onSearch = async (searchText: string) => {
        setValue(searchText);

        if (!props.getSuggestions) {
            return;
        }

        var result = await props.getSuggestions(searchText);
        const filteredSuggestions = result.filter((itemData: any) => {
            return itemData.label.startsWith(searchText);
        })

        setOptions(
            !searchText ? [] : filteredSuggestions,
        );
    };

    const removeFromNewInviteList = (email: string) => {
        const index = inviteEmails.indexOf(email, 0);
        if (index > -1) {
            inviteEmails.splice(index, 1);
        }

        props.onEmailsUpdated(inviteEmails);
    }


    return (
        <div style={{ width: "100%" }}>
            <AutoComplete
                options={options}
                style={{ width: 500 }}
                onSelect={(data: any) => { addToSavedEmails(data); }}
                onSearch={onSearch}
                onKeyDown={(data: any) => { data.code == "Enter" ? addToSavedEmails(value) : data.code = "1" }}
                placeholder={props.AutoCompleteType == AutoCompleteType.Email ? GetText("AutoComplete_Placeholder") : GetText("AutoComplete_PlaceholderPhone")}
                value={value}>
            </AutoComplete> <Button type={"primary"} onClick={() => { addToSavedEmails(value) }}>{props.AutoCompleteType == AutoCompleteType.Email ? GetText("AutoComplete_Click") : GetText("AutoComplete_PhoneClick")}</Button><br></br>
            <div className="descriptionText">{parse(props.descriptionText)}</div>

            {props.showList && inviteEmails.length > 0 ?
                <div style={{ marginTop: "10px" }}><b>{props.listHeader} ({inviteEmails.length})</b><br></br>
                    <List
                        pagination={{ pageSize: 10 }}
                        style={{ width: "500px" }}
                        dataSource={inviteEmails}
                        renderItem={(item) => (
                            <List.Item extra={<Button size="small" onClick={() => removeFromNewInviteList(item)}>{GetText("Common_Delete")}</Button>}>
                                {item}
                            </List.Item>
                        )}></List>
                </div> : ""}
            <br></br><br></br>
        </div>
    );
};