/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesEmailTemplatePurpose } from './WebApiModelsEnumerationTypesEmailTemplatePurpose';
import {
    WebApiModelsEnumerationTypesEmailTemplatePurposeFromJSON,
    WebApiModelsEnumerationTypesEmailTemplatePurposeFromJSONTyped,
    WebApiModelsEnumerationTypesEmailTemplatePurposeToJSON,
} from './WebApiModelsEnumerationTypesEmailTemplatePurpose';

/**
 * The user role.
 * @export
 * @interface WebApiModelsEmailTemplate
 */
export interface WebApiModelsEmailTemplate {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiModelsEmailTemplate
     */
    id?: number;
    /**
     * Gets or sets the unique id of time.
     * @type {number}
     * @memberof WebApiModelsEmailTemplate
     */
    companyId?: number;
    /**
     * Gets or sets the unique id of time.
     * @type {string}
     * @memberof WebApiModelsEmailTemplate
     */
    templateName?: string | null;
    /**
     * Gets or sets the unique id of time.
     * @type {string}
     * @memberof WebApiModelsEmailTemplate
     */
    subject?: string | null;
    /**
     * Gets or sets the unique id of time.
     * @type {string}
     * @memberof WebApiModelsEmailTemplate
     */
    body?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesEmailTemplatePurpose}
     * @memberof WebApiModelsEmailTemplate
     */
    purpose?: WebApiModelsEnumerationTypesEmailTemplatePurpose;
    /**
     * 
     * @type {Date}
     * @memberof WebApiModelsEmailTemplate
     */
    createdAt?: Date;
    /**
     * Gets or sets the date when the role was updated.
     * @type {Date}
     * @memberof WebApiModelsEmailTemplate
     */
    updatedAt?: Date | null;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsEmailTemplate
     */
    createdBy?: number;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsEmailTemplate
     */
    updatedBy?: number;
}

/**
 * Check if a given object implements the WebApiModelsEmailTemplate interface.
 */
export function instanceOfWebApiModelsEmailTemplate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiModelsEmailTemplateFromJSON(json: any): WebApiModelsEmailTemplate {
    return WebApiModelsEmailTemplateFromJSONTyped(json, false);
}

export function WebApiModelsEmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsEmailTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'templateName': !exists(json, 'templateName') ? undefined : json['templateName'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'purpose': !exists(json, 'purpose') ? undefined : WebApiModelsEnumerationTypesEmailTemplatePurposeFromJSON(json['purpose']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function WebApiModelsEmailTemplateToJSON(value?: WebApiModelsEmailTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyId': value.companyId,
        'templateName': value.templateName,
        'subject': value.subject,
        'body': value.body,
        'purpose': WebApiModelsEnumerationTypesEmailTemplatePurposeToJSON(value.purpose),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
    };
}

