import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { TextService } from '../../../Services/TextService';
import { AuthProvider } from '../../../Providers/authProvider';
import { MemberService } from '../../../Services/MemberService';
import LeftMenu from '../../../PagesAdmin/Users/LeftMenu';
import Paragraph from 'antd/lib/typography/Paragraph';
import { WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../../Repository/eventbookyapi';
import UploadPaymentButton from './UploadPaymentButton';
import { PaymentService } from '../../../Services/PaymentService';
import { BookyButton } from '../../Buttons/BookyButton';
import { DownloadOutlined } from '@ant-design/icons';
import { BookySelectDropDown } from '../../Forms/BookySelectDropDown';
import { getLastThreeYearAsOptions } from '../../../Helpers/PaymentYearOptions';
import LayoutLeftMenu from '../../Layout/LayoutLeftMenu';

const UploadPayments = () => {
    const [memberResult, setMemberResult] = useState({} as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto);
    const [user, setUser] = useState({} as any);
    const [year, setSelectedYear] = useState({} as any);
    const [uploadYear, setUploadYear] = useState({} as any);

    const [form] = Form.useForm();
    const [formUpload] = Form.useForm();

    const GetText = TextService.GetText;

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
            setSelectedYear(getLastThreeYearAsOptions()[1].value);
            setUploadYear(getLastThreeYearAsOptions()[1].value);
            form.setFieldValue("year", getLastThreeYearAsOptions()[1].value);
            formUpload.setFieldValue("yearUpload", getLastThreeYearAsOptions()[1].value);

        });
    }, []);

    useEffect(() => {
        loadMembers();
    }, []);

    const loadMembers = () => {
        MemberService.GetAllMembers().then(result => {
            setMemberResult(result);
        });
    }

    const onYearChanged = (value: string) => {
        setSelectedYear(value);
    }

    const onYearUploadChange = (value: string) => {
        setSelectedYear(value);
    }

    const downloadExcel = async () => {
        const data = await PaymentService.GetPaymentsAsFile(Number(year));
        const url = window.URL.createObjectURL(await data.raw.blob());
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `OneFront_Payments_${year}"_"${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }

    return <LayoutLeftMenu title={GetText("UploadPayments_PaymentsFromFileTitle")} menu={<LeftMenu selected={"users"}></LeftMenu>}>
        <Paragraph>{GetText("UploadPaymentData_UploadCsvDescription1")}<br />
        </Paragraph>

        <h3>Steg 1. Ladda ner betalningar</h3>
        <Paragraph>Börja med att ladda ner betalningar för ett år.</Paragraph>
        <Form form={form} colon={false} labelAlign={"left"} labelCol={{ span: 2, offset: 0 }} wrapperCol={{ span: 2 }} >
            <BookySelectDropDown name="year" handleChange={onYearChanged} options={getLastThreeYearAsOptions()} />
        </Form>
        <BookyButton style={{ width: "250px" }} onClick={downloadExcel} icon={<DownloadOutlined />} text={GetText("Payments_DownloadPayments")} />
        <br /><br />
        När du är klar och har lagt in betalningarna i excel-filen, kan du ladda in den igen nedan.
        <br /><br />
        <h3>Steg 2. Ladda upp betalningar</h3>
        <Form form={formUpload} colon={false} labelAlign={"left"} labelCol={{ span: 2, offset: 0 }} wrapperCol={{ span: 2 }} >
            <BookySelectDropDown name="yearUpload" handleChange={onYearUploadChange} options={getLastThreeYearAsOptions()} />
        </Form>
        <UploadPaymentButton year={uploadYear} members={memberResult.members} user={user} />
    </LayoutLeftMenu>
};

export default UploadPayments;