import { useNavigate } from 'react-router-dom';
import { AuthProvider } from '../../Providers/authProvider';
import { useEffect, useState } from 'react';

const SideNav = () => {
  const [user, setUser] = useState([] as any);
  const navigation = useNavigate();
  const handleClick = async (event: any) => {
    navigation(event.key);
  };

  const items1 =

    useEffect(() => {
      let user = AuthProvider().GetLoggedInUser();
      setUser(user);
    }, [AuthProvider]);

  if (user?.token != null) {
    return <span id="sider">

    </span>
  } else {
    return null;
  }
};

export default SideNav;