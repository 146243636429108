/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesCustomerAnswerType } from './WebApiModelsEnumerationTypesCustomerAnswerType';
import {
    WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSON,
    WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSONTyped,
    WebApiModelsEnumerationTypesCustomerAnswerTypeToJSON,
} from './WebApiModelsEnumerationTypesCustomerAnswerType';

/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.ConfirmResetPasswordAsync(System.String,System.String,System.String) response.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto {
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto
     */
    name?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto
     */
    email?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesCustomerAnswerType}
     * @memberof WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto
     */
    answer?: WebApiModelsEnumerationTypesCustomerAnswerType;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceParticipantReplyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto {
    return WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'answer': !exists(json, 'answer') ? undefined : WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSON(json['answer']),
    };
}

export function WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'answer': WebApiModelsEnumerationTypesCustomerAnswerTypeToJSON(value.answer),
    };
}

