import { Checkbox, Col, Form, Row } from "antd";


export const BookyCheckbox = (props: any) => {

    if (props.alignLabelRight) {
        return (
            <Row>
                <Col>
                    <Form.Item name={props.name} valuePropName="checked"
                    >
                        <Checkbox onChange={props.onChange} />
                    </Form.Item>
                </Col>
                <Col style={{ marginLeft: "10px", marginTop: "3px" }}>
                    {props.label}
                </Col>
            </Row>
        )
    }
    else {
        return (
            <Form.Item label={props.label} name={props.name} valuePropName="checked"
            >
                <Checkbox onChange={props.onChange} />
            </Form.Item>
        );
    }

}








