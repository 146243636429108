/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * CreateReply dto
 * @export
 * @interface WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto
 */
export interface WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto
     */
    replyId?: number;
    /**
     * Gets or sets the identifier.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto
     */
    message?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDtoFromJSON(json: any): WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto {
    return WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDtoToJSON(value?: WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'replyId': value.replyId,
        'message': value.message,
    };
}

