import { useEffect, useState } from 'react';
import { Card, Modal, Popconfirm, Space } from 'antd';
import { WebApiIntegrationsDtoIntegrationDto, } from '../../Repository/eventbookyapi';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import LeftMenuSettings from './LeftSettings';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import { useSearchParams } from 'react-router-dom';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { IntegrationService } from '../../Services/IntegrationService';
import { IntegrationType } from '../../Types/IntegrationType';
import { IntegrationState } from '../../Types/IntegrationState';
import { formatDateString } from '../../Helpers/DateHelpers';

const AdminIntegrations = () => {
    const [activeIntegrations, setActiveIntegrations] = useState([] as WebApiIntegrationsDtoIntegrationDto[]);

    const [user, setUser] = useState({} as any);
    const GetText = TextService.GetText;
    const { confirm } = Modal;
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false as boolean);
    const [integrationToDelete, setIntegrationToDelete] = useState(0 as number);
    const [fortNoxIntegration, setFortNoxIntegration] = useState(null as WebApiIntegrationsDtoIntegrationDto | null);
    const [lastRunCount, setLastCountRun] = useState(null as WebApiIntegrationsDtoIntegrationDto | null);

    var hasInitConnection = false;

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        });

        AuthProvider().ValidateUserLogin();

        loadActiveIntegrations();

        if (searchParams.get('state') == "Connect_1" && !hasInitConnection) {
            hasInitConnection = true;
            var activationCode = searchParams.get('code');
            IntegrationService.ConnectIntegration({
                accessCode: activationCode,
                integrationType: IntegrationType.Fortnox
            }).then(loadActiveIntegrations);
        }
    }, []);

    const loadActiveIntegrations = () => {
        IntegrationService.GetActiveIntegrations().then(result => {
            setActiveIntegrations(result);

            const fortNoxIntegration = result.find(p => p.type == IntegrationType.Fortnox);
            if (fortNoxIntegration) {
                setFortNoxIntegration(fortNoxIntegration);
            }
            else {
                setFortNoxIntegration(null);
            }
        });


    }

    const navigateToFortnox = async () => {
        var response = await IntegrationService.GetIntegration(IntegrationType.Fortnox);
        window.location.href = response.activationUri!;
    }

    const GetfortNoxInvoices = async () => {
        setIsLoading(true);
        var response = await IntegrationService.GetInvoices(IntegrationType.Fortnox);
        loadActiveIntegrations();
        setIsLoading(false);
    }

    const onDeleteClick = (memberId: number) => {
        setIntegrationToDelete(memberId);
    };

    const handleOk = async () => {
        await await IntegrationService.RemoveIntegration(integrationToDelete);
        loadActiveIntegrations();
        setIsLoading(false);
    };
    return <LayoutLeftMenu title={GetText("Integrations_IntegrationTitle")} menu={<LeftMenuSettings selected={"integrations"} />}>
        <Space>
            <Paragraph>{GetText("Integrations_Description")}</Paragraph>
        </Space>

        <div style={{ width: "400px" }}>
            <Card
                cover={
                    <img
                        alt="example"
                        src={require('../../Images/fortnox.png')}
                    />
                }>
                Med fortnox kan du koppla ihop OneFront 360 och läsa in fakturor och betalningar för avprickning av medlemsavgift. Klicka här för att koppla ihop OneFront 360 med Fortnox.<br /><br />
                <br />
                {fortNoxIntegration ?
                    <>
                        {fortNoxIntegration.state == IntegrationState.Active ? <span><CheckCircleOutlined style={{ color: "green" }} /> Integrationen är aktiv.</span> : ""}

                        {fortNoxIntegration.state == IntegrationState.Error ? <span><ExclamationCircleOutlined style={{ color: "red" }} /> Fel inträffade vid senaste körningen. Tekniskt felmeddelande: <br /><i>{fortNoxIntegration.lastrunResponse}</i></span> : ""}


                        <br /><br />Senast synkroniserat: {formatDateString(fortNoxIntegration?.lastRun)}
                        <BookyButton style={{ marginTop: "10px" }} text={"Synkronisera fakturor och betalningar"} onClick={(e: any) => GetfortNoxInvoices()}></BookyButton>

                        <Popconfirm
                            title={GetText("Integrations_RemoveIntegrationConfirm")}
                            key={fortNoxIntegration?.id!}
                            open={fortNoxIntegration?.id! === integrationToDelete}
                            placement="topRight"
                            onConfirm={handleOk}
                            onCancel={() => setIntegrationToDelete(0)}
                        >
                        </Popconfirm>
                        <br />
                        {fortNoxIntegration.state == IntegrationState.Error ? <><BookyButton loading={isLoading} style={{ marginTop: "10px" }} text={GetText("Integrations_Connect")} onClick={navigateToFortnox}></BookyButton><br /> </> : ""}

                        <BookyButton style={{ marginTop: "10px" }} danger text={GetText("Integrations_RemoveIntegrationButton")} onClick={(e: any) => onDeleteClick(fortNoxIntegration?.id!)}></BookyButton>

                    </> : <BookyButton loading={isLoading} disabled={fortNoxIntegration} style={{ marginTop: "10px" }} text={GetText("Integrations_Connect")} onClick={navigateToFortnox}></BookyButton>
                }

            </Card>
        </div>
    </LayoutLeftMenu >
};

export default AdminIntegrations;