import { useEffect, useState } from 'react';
import { WebApiModelsMemberField, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../Repository/eventbookyapi';
import MemberList from '../../Components/Users/MemberList';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import LeftMenu from './LeftMenu';
import { MemberService } from '../../Services/MemberService';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';

const AdminUsers = () => {
  const [memberResult, setMemberResult] = useState({} as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto);
  const [memberFieldSettings, setMemberFieldSettings] = useState([] as WebApiModelsMemberField[]);

  const [user, setUser] = useState({} as AuthenticatedUser);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user!);
    });
  }, []);

  useEffect(() => {
    loadMembers();
  }, []);

  const loadMembers = () => {
    MemberService.GetAllMembers().then(result => {
      setMemberResult(result);
    });

    MemberService.GetMemberFields().then(result => {
      setMemberFieldSettings(result);
    });
  }

  return <LayoutLeftMenu title={GetText("Users_UsersTitle")} menu={<LeftMenu selected={"users"}></LeftMenu>}>
    {memberResult.members != null ? <>
      <MemberList user={user} memberResult={memberResult} loadMembers={loadMembers} memberFieldSettings={memberFieldSettings} />
    </>
      : <i>{GetText("Groups_NoGroupsSelected")}</i>}

  </LayoutLeftMenu>
};

export default AdminUsers;