/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiCompanyDtoCompanyUserDto } from './WebApiCompanyDtoCompanyUserDto';
import {
    WebApiCompanyDtoCompanyUserDtoFromJSON,
    WebApiCompanyDtoCompanyUserDtoFromJSONTyped,
    WebApiCompanyDtoCompanyUserDtoToJSON,
} from './WebApiCompanyDtoCompanyUserDto';

/**
 * 
 * @export
 * @interface WebApiCompanyDtoCompanyAdministratorResultDto
 */
export interface WebApiCompanyDtoCompanyAdministratorResultDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiCompanyDtoCompanyAdministratorResultDto
     */
    numberOfGlobalAdministrators?: number;
    /**
     * 
     * @type {Array<WebApiCompanyDtoCompanyUserDto>}
     * @memberof WebApiCompanyDtoCompanyAdministratorResultDto
     */
    administrators?: Array<WebApiCompanyDtoCompanyUserDto> | null;
}

/**
 * Check if a given object implements the WebApiCompanyDtoCompanyAdministratorResultDto interface.
 */
export function instanceOfWebApiCompanyDtoCompanyAdministratorResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiCompanyDtoCompanyAdministratorResultDtoFromJSON(json: any): WebApiCompanyDtoCompanyAdministratorResultDto {
    return WebApiCompanyDtoCompanyAdministratorResultDtoFromJSONTyped(json, false);
}

export function WebApiCompanyDtoCompanyAdministratorResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiCompanyDtoCompanyAdministratorResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfGlobalAdministrators': !exists(json, 'numberOfGlobalAdministrators') ? undefined : json['numberOfGlobalAdministrators'],
        'administrators': !exists(json, 'administrators') ? undefined : (json['administrators'] === null ? null : (json['administrators'] as Array<any>).map(WebApiCompanyDtoCompanyUserDtoFromJSON)),
    };
}

export function WebApiCompanyDtoCompanyAdministratorResultDtoToJSON(value?: WebApiCompanyDtoCompanyAdministratorResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfGlobalAdministrators': value.numberOfGlobalAdministrators,
        'administrators': value.administrators === undefined ? undefined : (value.administrators === null ? null : (value.administrators as Array<any>).map(WebApiCompanyDtoCompanyUserDtoToJSON)),
    };
}

