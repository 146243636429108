/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto,
  WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto,
  WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto,
  WebApiServicesDataTransferObjectsInviteServiceGetInvitesResultDto,
  WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto,
} from '../models';
import {
    WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDtoFromJSON,
    WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDtoToJSON,
    WebApiServicesDataTransferObjectsInviteServiceCreateReminderDtoFromJSON,
    WebApiServicesDataTransferObjectsInviteServiceCreateReminderDtoToJSON,
    WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDtoFromJSON,
    WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDtoToJSON,
    WebApiServicesDataTransferObjectsInviteServiceGetInvitesResultDtoFromJSON,
    WebApiServicesDataTransferObjectsInviteServiceGetInvitesResultDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoToJSON,
} from '../models';

export interface ApiInviteCreatePostRequest {
    emails?: Array<string>;
    eventId?: number;
    message?: string;
    subject?: string;
    files?: Array<Blob>;
}

export interface ApiInviteEventIdGetRequest {
    eventId: string;
}

export interface ApiInviteInviteIdDeleteRequest {
    inviteId: number;
}

export interface ApiInviteSendReminderForInvitesPostRequest {
    webApiServicesDataTransferObjectsInviteServiceCreateReminderDto?: WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto;
}

/**
 * 
 */
export class InviteApi extends runtime.BaseAPI {

    /**
     * Craetes new event with connected times
     */
    async apiInviteCreatePostRaw(requestParameters: ApiInviteCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.emails) {
            requestParameters.emails.forEach((element) => {
                formParams.append('Emails', element as any);
            })
        }

        if (requestParameters.eventId !== undefined) {
            formParams.append('EventId', requestParameters.eventId as any);
        }

        if (requestParameters.message !== undefined) {
            formParams.append('Message', requestParameters.message as any);
        }

        if (requestParameters.subject !== undefined) {
            formParams.append('Subject', requestParameters.subject as any);
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/api/Invite/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDtoFromJSON(jsonValue));
    }

    /**
     * Craetes new event with connected times
     */
    async apiInviteCreatePost(requestParameters: ApiInviteCreatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto> {
        const response = await this.apiInviteCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets invites by eventId
     */
    async apiInviteEventIdGetRaw(requestParameters: ApiInviteEventIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsInviteServiceGetInvitesResultDto>> {
        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling apiInviteEventIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Invite/{EventId}`.replace(`{${"EventId"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsInviteServiceGetInvitesResultDtoFromJSON(jsonValue));
    }

    /**
     * Gets invites by eventId
     */
    async apiInviteEventIdGet(requestParameters: ApiInviteEventIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsInviteServiceGetInvitesResultDto> {
        const response = await this.apiInviteEventIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiInviteInviteIdDeleteRaw(requestParameters: ApiInviteInviteIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling apiInviteInviteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Invite/{InviteId}`.replace(`{${"InviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiInviteInviteIdDelete(requestParameters: ApiInviteInviteIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto> {
        const response = await this.apiInviteInviteIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send reminder for invites without reply
     */
    async apiInviteSendReminderForInvitesPostRaw(requestParameters: ApiInviteSendReminderForInvitesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Invite/sendReminderForInvites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsInviteServiceCreateReminderDtoToJSON(requestParameters.webApiServicesDataTransferObjectsInviteServiceCreateReminderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDtoFromJSON(jsonValue));
    }

    /**
     * Send reminder for invites without reply
     */
    async apiInviteSendReminderForInvitesPost(requestParameters: ApiInviteSendReminderForInvitesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsInviteServiceCreateReminderResultDto> {
        const response = await this.apiInviteSendReminderForInvitesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
