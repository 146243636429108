/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesSubscriptionLevel } from './WebApiModelsEnumerationTypesSubscriptionLevel';
import {
    WebApiModelsEnumerationTypesSubscriptionLevelFromJSON,
    WebApiModelsEnumerationTypesSubscriptionLevelFromJSONTyped,
    WebApiModelsEnumerationTypesSubscriptionLevelToJSON,
} from './WebApiModelsEnumerationTypesSubscriptionLevel';

/**
 * 
 * @export
 * @interface WebApiAdminDtoUserDto
 */
export interface WebApiAdminDtoUserDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiAdminDtoUserDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiAdminDtoUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WebApiAdminDtoUserDto
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebApiAdminDtoUserDto
     */
    company?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiAdminDtoUserDto
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiAdminDtoUserDto
     */
    userId?: number;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesSubscriptionLevel}
     * @memberof WebApiAdminDtoUserDto
     */
    subscriptionLevel?: WebApiModelsEnumerationTypesSubscriptionLevel;
    /**
     * 
     * @type {Date}
     * @memberof WebApiAdminDtoUserDto
     */
    nextSubscriptionDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiAdminDtoUserDto
     */
    memberCount?: number;
}

/**
 * Check if a given object implements the WebApiAdminDtoUserDto interface.
 */
export function instanceOfWebApiAdminDtoUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiAdminDtoUserDtoFromJSON(json: any): WebApiAdminDtoUserDto {
    return WebApiAdminDtoUserDtoFromJSONTyped(json, false);
}

export function WebApiAdminDtoUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiAdminDtoUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'company': !exists(json, 'company') ? undefined : json['company'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'subscriptionLevel': !exists(json, 'subscriptionLevel') ? undefined : WebApiModelsEnumerationTypesSubscriptionLevelFromJSON(json['subscriptionLevel']),
        'nextSubscriptionDate': !exists(json, 'nextSubscriptionDate') ? undefined : (json['nextSubscriptionDate'] === null ? null : new Date(json['nextSubscriptionDate'])),
        'memberCount': !exists(json, 'memberCount') ? undefined : json['memberCount'],
    };
}

export function WebApiAdminDtoUserDtoToJSON(value?: WebApiAdminDtoUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullName': value.fullName,
        'email': value.email,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'company': value.company,
        'companyId': value.companyId,
        'userId': value.userId,
        'subscriptionLevel': WebApiModelsEnumerationTypesSubscriptionLevelToJSON(value.subscriptionLevel),
        'nextSubscriptionDate': value.nextSubscriptionDate === undefined ? undefined : (value.nextSubscriptionDate === null ? null : value.nextSubscriptionDate.toISOString()),
        'memberCount': value.memberCount,
    };
}

