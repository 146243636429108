import { useNavigate } from "react-router-dom";
import '../Pricing/Pricing.css'
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';
import BookyBox from '../../Components/Layout/BookyBox';
import Title from "antd/lib/typography/Title";

const Privacy_En = () => {
  return <Page> <BookyBox> <b>Privacy Policy: Last Updated: 30 August 2023</b> <br /><br />
    <Paragraph>This Privacy Policy describes OneFront360's policies and procedures for the collection, use and disclosure of your information when you use OneFont360 and informs you about your privacy rights and how the law protects you.</Paragraph>
    <Paragraph>We use your personal information to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.</Paragraph>
    <Title level={2}>Interpretation and definitions</Title> <b>Interpretation</b><br /> <Paragraph>Words whose initial letter is verbal have meanings defined under the following conditions. The following definitions shall have the same meaning, whether in singular or plural.</Paragraph> <b>Definitions</b><br />
    For the purposes of this Privacy Policy:
    <ul>
      <li>Account means a unique account created for you to access our Service or parts of our Service.</li>
      <li>Affiliate means an entity that controls, is controlled by or is under joint control with a party, where "control" means ownership of 50 % or more of the shares, shareholders or other securities entitled to vote in the election of board members or other management authority.</li> <li>Company (referred to as either "The Company", "We", "Our" or "Ours" in this Agreement) refers to OneFront360 AB.</li> <li>Cookies are small files that are placed on your computer, mobile device or any other device of a website, and contain information about your browsing history on that website among its many uses.</li> <li>Device means any device that can access the Service, such as a computer, mobile phone or digital tablet.</li> <li>Personal data is any information relating to an identified or identifiable individual.</li> <li>The service refers to the website.</li> <li>Service provider means any natural or legal person who processes the data on behalf of the company. This refers to third-party companies or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company, perform services related to the Service or assist the Company in analyzing how the Service is used.</li> <li>Third-party social media services refer to any web sites or social networking sites through which a user can log in or create an account to use the service.</li> <li>Usage Data refers to data collected automatically, either generated by the use of the Service or from the infrastructure of the service itself (for example, the duration of a page visit).</li> <li>The website refers to OneFront360, available from http://www.OneFront 360.com</li> <li>You mean the individual who has access to or uses the Service, or the company or other legal entity on behalf of which such individual uses or uses it, as applicable.</li>
    </ul>

    <Title level={2}>Collecting and using your personal data</Title> <b>Types of data collected</b><br />
    When we use our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally Identifiable Information may include, but is not limited to:<br />
    <ul>
      <li>E-mail address</li>
      <li>First and last name</li>
      <li >Use data</li > </ul>
    <Title level={2}>Use Data</Title>
    <Paragraph>Usage Data is collected automatically when using the Service.</Paragraph>
    <Paragraph>Usage Data may include information such as your device’s Internet log address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, time spent on those pages, unique device identifier and other diagnostic data. </Paragraph>
    <Paragraph>When you use the Service via or via a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you are using, your mobile device’s unique ID, your Mobile Device’s IP address, your Mobiles operating system, what type of Mobile browser you use, unique device identifiers, and other diagnostic data.</Paragraph>
    <Paragraph>We may also collect information that your browser sends when you visit our service or when you use the service via or via a mobile device.</Paragraph>


    <Title level={2}>Tracking technologies and cookies</Title>
    We use cookies and similar tracking technologies to track activity on our Service and store certain information. Tracking technologies used are beacons, tags and scripts to collect and track information and to improve and analyse our Service. The technologies we use may be: <ul> <li><b>Cookies or browser cookies.</b> A cookie is a small file that is placed on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is sent. However, if you do not accept cookies, you may not be able to use certain parts of our service. Unless you have adjusted your browser settings to refuse cookies, our service may use cookies.
    </li> <li><b>Web Beacons.</b> <br />Some parts of our Service and our emails may contain small electronic files called web beacons (also called clear gifs, pixel tags and single-pixel gifs) that allow the Company to count, for example, users who have visited these pages or opened an email and for other related website statistics (for example, records the popularity of a particular section and verifies the system and server privacy).
      Cookies can be "persistent" or "session" cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your browser.
      </li> <li><b>Necessary/Necessary Cookies</b><br />
        Type: Session cookies
        Managed by: Oss<br />
        Purpose: These cookies are important to provide you with services available through the website and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these cookies, the services you have requested cannot be provided, and we only use these cookies to provide you with these services. </li> <li><b>Cookies Policy / Acceptance of notification Cookies</b><br />
        Type: Permanent cookies<br />
        Managed by: Oss<br />
        Purpose: These cookies identify whether users have accepted the use of cookies on the website. </li> <li><b>Functional cookies</b><br />
        Type: Permanent cookies <br /> Managed by: Us<br />
        Purpose: These cookies allow us to remember choices you make when using the website, such as remembering your login details or language settings. The purpose of these cookies is to provide you with a more personalized experience and to avoid having to re-enter your settings every time you use the site.
        For more information about the cookies we use and your choices regarding cookies, please visit our Cookie Policy or the Cookies section of our Privacy Policy. </li> </ul>


    <Title level={2}> Use of your personal data</Title>
    The Company may use personal data for the following purposes: <ul> <li>To provide and maintain our Service, including to monitor the use of our Service.</li> <li>To manage your account: to manage your registration as a user of the Service. The personal data you provide may give you access to various features of the Service that are available to you as a registered user.</li> <li>For the performance of a contract: the development, enforcement and execution of the purchase contract for the products, items or services you have purchased or of any other agreement with us through the Service.</li> <li>To contact you: To contact you by e-mail, telephone call, SMS or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the features, products or agreed services, including security updates, when necessary or reasonable for implementation.</li> <li>To provide you with news, special offers and general information about other goods, services and events that we offer similar to those that you have already purchased or asked for unless you have opted out of receiving such information.</li> <li>To process your requests: To participate and manage your requests to us.</li> <li>For business transfers: We may use your information to evaluate or carry out a merger, disposal, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, either as a continuing business or as part of a bankruptcy, liquidation, or similar proceedings, where personal information held by us about our service users is among the assets transferred.</li> <li>For other purposes: We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our advertising campaigns and to evaluate and improve our service, products, services, marketing and your experience.
      We may share your personal information in the following situations:With service providers: We may share your personal information with service providers to monitor and analyse the use of our service, to contact you.</li> <li>For business transfers: We may share or transfer your personal information in connection with, or during negotiations on, any merger, sale of the company's assets, financing or acquisition of all or part of our business to another company.</li> <li>With affiliates: We may share your information with our affiliates, in which case we will require these affiliates to respect this privacy policy. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that we control or are under joint control with us.</li> <li>With business partners: We may share your information with our business partners in order to offer you certain products, services or promotions.</li> </ul>


    <Title level={2}>Storage of your personal data</Title> <Paragraph>The Company will only retain your personal data for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your personal information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your information in order to abide by applicable laws), resolve disputes and enforce our legal agreements and policies.The Company will also retain usage data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to enhance security or to improve the functionality of our Service, or when we are legally obliged to retain this data for longer periods of time. We comply with the GDPR when required, see more information on the website. </Paragraph> <Title level={2}>Transfer of your personal data</Title> <Paragraph>Your data, including Personal Data, will be processed at the Company’s office and at all other locations where the parties involved in the processing are located. This means that this information may be transferred to – andined on – computers outside your state, province, country or other governmental jurisdiction where data protection laws may differ from those in your jurisdication.Your consent to this Privacy Policy followed by your submission of such information represents your consent to that transfer.The Company will take all measures reasonably necessary to ensure that your data is processed securely and in accordance with this Privacy Policy and no transfer of your personal data will take place to any organization or country unless there are adequate controls in place, including the security of your data and other personal information. </Paragraph> <Title level={2}> Delete your personal data</Title>
    You have the right to delete or request our assistance in deleting the personal data we have collected about you.
    Please note, however, that we may need to retain certain information when we have a legal obligation or legal basis to do so.




    <Title level={2}>Sharing of your personal data</Title>
    <b>Business transaction</b><br /> <Paragraph>If the company is involved in a merger, acquisition or sale of assets, your personal data may be transferred. We will notify you before your personal data is transferred and will be subject to a different privacy policy.</Paragraph>

    <b>Judiciary</b><br /> <Paragraph>In certain circumstances, we may be required to disclose your personal data if required by law or in response to valid requests from public authorities (e.g. a court or governmental authority).</Paragraph>
    <b>Legal requirements</b><br /> <Paragraph>The Company may share your personal data in good faith that such action is necessary to:</Paragraph>
    <ul> <li>Implement a legal obligation</li><li>Protecting and defending the rights or property of the CompanyPrevent or investigate any errors related to the service</li> <li>Protect the personal security of users of the Service or the public</li> <li>Legal liability protection</li> </ul>

    <Title level={2}>Security of your personal data</Title>
    <Paragraph>The security of your Personal Data is important to us, but please remember that no method of transmission over the Internet or method of electronic storage is 100% secure. Although we strive to use commercially acceptable methods to protect your personal data, we cannot guarantee its absolute security. </Paragraph>

    <b>Links to other websites</b><br />
    <Paragraph>Our Service may contain links to other websites that are not operated by us. If you click on a third party link, you will be redirected to the third party website. We strongly recommend that you read the privacy policy of each website you visit.
    </Paragraph>

    <Paragraph> We have no control over and accept no responsibility for the content, privacy policies or practices of third-party websites or services. </Paragraph>

    <Title level={2}>Changes to this Privacy Policy</Title>
    <Paragraph> We may update our privacy policy when necessary. We will notify you of any changes by posting the new privacy policy on the website.</Paragraph>
    <Paragraph> We will notify you by e-mail and/or a prominent notice of our Service before the change comes into force and update the "Last Updated" date at the top of this Privacy Policy.</Paragraph>
    <Paragraph> You are advised to review this Privacy Policy from time to time for any changes. Changes to this Privacy Policy will enter into force when they are posted on this page.</ Paragraph><br />

    <Title level={2}>Contact Us</Title>
    If you have any questions about this privacy policy, please contact us:<br />
    By email: support@OneFront360.com


  </BookyBox>
  </Page >
};

export default Privacy_En;