import React, { useEffect, useState } from 'react';
import { Popconfirm, Space, Table, } from 'antd';
import { WebApiCompanyDtoCompanyUserDto } from '../../Repository/eventbookyapi';
import { ColumnsType } from 'antd/lib/table';
import { TextService } from '../../Services/TextService';
import { DeleteOutlined } from '@ant-design/icons';
import AddAdmin from './AddAdmin';
import { CompanyRoles } from './Roles';
import { CompanyService } from '../../Services/CompanyService';
import EditAdmin from './EditAdmin';

export const AdminList = (props: any) => {
    const [adminToDelete, setAdmintoDelete] = useState(0 as number);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [adminList, setAdminList] = useState([] as WebApiCompanyDtoCompanyUserDto[]);
    const GetText = TextService.GetText;

    const onDeleteClick = (memberId: number) => {
        setAdmintoDelete(memberId);
    };

    const loadAdmins = () => {
        props.loadAdmins();
    }

    useEffect(() => {
        setAdminList(props.adminResult.administrators);
    }, [props])

    const handleOk = async () => {
        await CompanyService.DeleteCompanyUser(adminToDelete);
        setConfirmLoading(false);
        loadAdmins();
        setAdmintoDelete(0);
    };

    const columns: ColumnsType<WebApiCompanyDtoCompanyUserDto> = [
        {
            title: GetText("AdminList_Email"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiCompanyDtoCompanyUserDto) => (
                <span><b>{member.email ? member.email : member.inviteEmail}</b><br />
                </span>
            ),
        },
        {
            title: GetText("AdminList_Name"),
            dataIndex: 'name',
            key: 'name',
            render: (_, member: WebApiCompanyDtoCompanyUserDto) => (
                <span><b>{member.name ? member.name : <i>{GetText("AdminList_WaitingForUserRegistration")}</i>}</b><br />
                </span>
            ),
        },
        {
            title: GetText("AdminList_Role"),
            dataIndex: 'role',
            key: 'role',
            render: (_, member: WebApiCompanyDtoCompanyUserDto) => (
                <span><b>{member.role == CompanyRoles.GlobalAdmin ? GetText("Common_GlobalPermisison") : ""} {member.role == CompanyRoles.MemberAdmin ? GetText("Common_EditPermission") : ""} {member.role == CompanyRoles.ReadPermission ? GetText("Common_ReadPermission") : ""}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_ActionColumn"),
            key: 'action',
            render: (_, admin) => (
                <Space size="middle">
                    {<EditAdmin loadAdmins={loadAdmins} adminResult={props.adminResult} admin={admin}>Edit</EditAdmin>}
                    <DeleteOutlined style={{ color: "red", fontSize: "20px" }} onClick={() => onDeleteClick(admin?.id!)}></DeleteOutlined>

                    <Popconfirm
                        title={GetText("MemberList_DeleteMemberConfirmMessage")}
                        key={admin.id}
                        open={admin.id === adminToDelete}
                        placement="topRight"
                        onConfirm={handleOk}
                        okButtonProps={{ loading: confirmLoading }}
                        onCancel={() => setAdmintoDelete(0)}
                    >
                    </Popconfirm>
                </Space>
            ),
        },]

    return (
        <>
            <Space direction="vertical">
                <Space wrap>
                    {<AddAdmin user={props.user} loadAdmins={loadAdmins} adminResult={props.adminResult} />}
                </Space>
            </Space>

            <br></br>


            <Table columns={columns} style={{ marginTop: "20px" }} locale={{
                emptyText: GetText("MemberList_EmptyMessage")
            }} className="admin-table" rowKey="uniqueId" dataSource={props.adminResult?.administrators} />
            <i>{GetText("AdminList_MembersFoundCount1")} {props.adminResult.administrators?.length} {GetText("AdminList_MembersFoundCount2")}</i>
        </>

    );
};

export default AdminList;