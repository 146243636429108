import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useLocation } from "react-router-dom";
import { Result } from 'antd';
import { TextService } from "../../Services/TextService";


const EventAnsweredComplete = () => {
  const location = useLocation();
  const name = location.state?.name;
  const GetText = TextService.GetText;

  return <div id="start">
    <Result
      status="success"
      title={GetText("EventAnswerComplete_Title") + name}
      subTitle={GetText("EventAnswerComplete_Description")}
    />
  </div>
};

export default EventAnsweredComplete;