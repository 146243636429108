/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The user role.
 * @export
 * @interface WebApiModelsInvite
 */
export interface WebApiModelsInvite {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiModelsInvite
     */
    id?: number;
    /**
     * Gets or sets the identifier.
     * @type {string}
     * @memberof WebApiModelsInvite
     */
    email?: string | null;
    /**
     * Gets or sets the unique id of time.
     * @type {number}
     * @memberof WebApiModelsInvite
     */
    eventId?: number;
    /**
     * Gets or sets the unique id of time.
     * @type {number}
     * @memberof WebApiModelsInvite
     */
    replyId?: number;
    /**
     * Gets or sets the unique id of time.
     * @type {number}
     * @memberof WebApiModelsInvite
     */
    memberId?: number | null;
    /**
     * Gets or sets the start of the time
     * @type {string}
     * @memberof WebApiModelsInvite
     */
    uniqueId?: string | null;
    /**
     * Gets or sets the end of the time
     * @type {string}
     * @memberof WebApiModelsInvite
     */
    text?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WebApiModelsInvite
     */
    createdAt?: Date;
    /**
     * Gets or sets the date when the role was updated.
     * @type {Date}
     * @memberof WebApiModelsInvite
     */
    updatedAt?: Date | null;
    /**
     * Gets or sets the description.
     * @type {string}
     * @memberof WebApiModelsInvite
     */
    createdBy?: string | null;
    /**
     * Gets or sets the description.
     * @type {string}
     * @memberof WebApiModelsInvite
     */
    updatedBy?: string | null;
}

/**
 * Check if a given object implements the WebApiModelsInvite interface.
 */
export function instanceOfWebApiModelsInvite(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiModelsInviteFromJSON(json: any): WebApiModelsInvite {
    return WebApiModelsInviteFromJSONTyped(json, false);
}

export function WebApiModelsInviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsInvite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function WebApiModelsInviteToJSON(value?: WebApiModelsInvite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'eventId': value.eventId,
        'replyId': value.replyId,
        'memberId': value.memberId,
        'uniqueId': value.uniqueId,
        'text': value.text,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
    };
}

