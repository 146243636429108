import { TextService } from "../Services/TextService";
import { HistoryEventType } from "../Types/HistoryEventTypes";

export const mapHistoryEventTypeToText = (type: HistoryEventType) => {
    const GetText = TextService.GetText;

    switch (type) {
        case HistoryEventType.AddMember:
            return GetText("HistoryEvent_AddMember");
        case HistoryEventType.DeleteMember:
            return GetText("HistoryEvent_DeleteMember");
        case HistoryEventType.UpdatedMemberProperties:
            return GetText("HistoryEvent_UpdateMember");
        case HistoryEventType.UpdatedMemberPropertiesByMember:
            return GetText("HistoryEvent_UpdatedMemberByMember");
        case HistoryEventType.AddMemberByMember:
            return GetText("HistoryEvent_AddMemberByMember");
        case HistoryEventType.MemberIsActivated:
            return GetText("HistoryEvent_MemberActivated");
        default:
            return "Unknown";
    }
}