/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The user role.
 * @export
 * @interface WebApiModelsTime
 */
export interface WebApiModelsTime {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiModelsTime
     */
    id?: number;
    /**
     * Gets or sets the unique id of time.
     * @type {number}
     * @memberof WebApiModelsTime
     */
    eventId?: number;
    /**
     * Gets or sets the start of the time
     * @type {Date}
     * @memberof WebApiModelsTime
     */
    start?: Date;
    /**
     * Gets or sets the end of the time
     * @type {Date}
     * @memberof WebApiModelsTime
     */
    endUtc?: Date;
    /**
     * Gets or sets the start of the time
     * @type {Date}
     * @memberof WebApiModelsTime
     */
    startUtc?: Date;
    /**
     * Gets or sets the end of the time
     * @type {Date}
     * @memberof WebApiModelsTime
     */
    end?: Date;
    /**
     * Is event fullday
     * @type {boolean}
     * @memberof WebApiModelsTime
     */
    isFullDay?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof WebApiModelsTime
     */
    createdAt?: Date;
    /**
     * Gets or sets the date when the role was updated.
     * @type {Date}
     * @memberof WebApiModelsTime
     */
    updatedAt?: Date | null;
    /**
     * Gets or sets the description.
     * @type {string}
     * @memberof WebApiModelsTime
     */
    createdBy?: string | null;
    /**
     * Gets or sets the description.
     * @type {string}
     * @memberof WebApiModelsTime
     */
    updatedBy?: string | null;
}

/**
 * Check if a given object implements the WebApiModelsTime interface.
 */
export function instanceOfWebApiModelsTime(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiModelsTimeFromJSON(json: any): WebApiModelsTime {
    return WebApiModelsTimeFromJSONTyped(json, false);
}

export function WebApiModelsTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'endUtc': !exists(json, 'endUtc') ? undefined : (new Date(json['endUtc'])),
        'startUtc': !exists(json, 'startUtc') ? undefined : (new Date(json['startUtc'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'isFullDay': !exists(json, 'isFullDay') ? undefined : json['isFullDay'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function WebApiModelsTimeToJSON(value?: WebApiModelsTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'eventId': value.eventId,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'endUtc': value.endUtc === undefined ? undefined : (value.endUtc.toISOString()),
        'startUtc': value.startUtc === undefined ? undefined : (value.startUtc.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'isFullDay': value.isFullDay,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
    };
}

