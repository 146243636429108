
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { ChromePicker, SketchPicker } from 'react-color'

export const ColorPicker = (props: any) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false as boolean);
    const [colorCode, setColorCode] = useState(props.color as string);

    useEffect(() => {
        if (props.color != null) {
            setColorCode(props.color);
        }
    }, [props.color]);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const onChangeComplete = (color: any) => {
        setColorCode(color.hex);
        props.onChangeComplete(color.hex);
    };

    return (
        <div>
            <div style={{ display: "inline-block", border: "1px solid black", padding: "5px", backgroundColor: `${colorCode}`, width: "20px", height: "20px" }}></div> <EditOutlined style={{ fontSize: "20px", marginLeft: "10px" }} onClick={handleClick} />
            {displayColorPicker ? <div style={{ position: "absolute", zIndex: "2" }}>
                <div style={{ position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px" }} onClick={handleClose} />
                <SketchPicker color={colorCode} onChangeComplete={onChangeComplete} />
            </div> : null}
        </div>
    )
}

export default ColorPicker