export abstract class GoogleService {
    public static GetGoogleCalenderEvents = async (token: string, start: string, end: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }

        const response = await fetch("https://www.googleapis.com/calendar/v3/calendars/primary/events?key=" + "AIzaSyBIXU9_isiDSSJW4SOVJkU61aA7Vceauqc" + "&timeMin=" + start + "&timeEnd=" + end + "&timezone=utc", options);
        const data = await response.json();

        return data;
    };

    public static GetGoogleContacts = async (token: string, searchText: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }

        const response = await fetch("https://people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses&pageSize=1000", options);
        const data = await response.json();

        return data;
    };
}