import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { Result } from 'antd';
import { TextService } from "../../Services/TextService";
import { useEffect, useState } from "react";
import { CompanyService } from "../../Services/CompanyService";
import { PublicSiteState } from "./Types/PublicSiteState";
import { getCurrentLanguage } from "../../Helpers/RouteHelper";
import { PublicResultType } from "./Types/PublicResultType";
import { PublicSiteService } from "../../Services/PublicSiteService";
import { AuthProvider } from "../../Providers/authProvider";

const PublicSiteNewMemberActivateAccount = () => {
  const [state, setState] = useState(PublicSiteState.Undefined as PublicSiteState);
  const navigation = useNavigate();

  const GetText = TextService.GetText;
  let params = useParams();
  var ranOnce = false;
  useEffect(() => {

    AuthProvider().GetLoggedInUser();
    if (!ranOnce) {

      if (params.companyurl != null) {
        try {
          PublicSiteService.GetCompanyPublicSiteByUrl(params.companyurl).then(async result => {
            if (params.passwordcode != null) {

              const result = await PublicSiteService.ActiveMemberAccount({
                passwordCode: params.passwordcode,
                companyUrl: params.companyurl
              })

              if (result) {
                navigation("/" + getCurrentLanguage() + "/" + params.companyurl + "/success/" + PublicResultType.NewMemberHasActivatedAccount)

              }
              else {
                setState(PublicSiteState.MemberCodeNotFoundOrExpired);
              }

            }
          })
        }
        catch {
          setState(PublicSiteState.CompanyNotFound);
        }
      }
      ranOnce = true;
    }
  }, []);

  return <div id="start">
    {state === PublicSiteState.CompanyFound || state === PublicSiteState.MemberCodeNotFoundOrExpired ?
      <Result
        status="error"
        title={GetText("PublicSiteNewmemberActiveAcount_Failed")}
      /> : ""}
  </div>
};

export default PublicSiteNewMemberActivateAccount;