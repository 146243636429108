import { WebApiModelsMemberField } from "../Repository/eventbookyapi";
import { MemberFieldTypes } from "../Types/MemberFieldTypes";

export const getMemberFieldSetting = (type: MemberFieldTypes, fields: WebApiModelsMemberField[]) => {
    let field: WebApiModelsMemberField = {};

    fields.forEach((element: WebApiModelsMemberField) => {
        if (element.columnType == type) {
            field = element;
        }
    });

    return field;
}