import { TextService } from "../Services/TextService";
import { MemberFieldTypes } from "../Types/MemberFieldTypes"

export const mapDynamicInputName = (type: MemberFieldTypes) => {
    const GetText = TextService.GetText;


    switch (type) {
        case MemberFieldTypes.UniqueId:
            return "uniqueId";
        case MemberFieldTypes.FirstName:
            return "firstName";
        case MemberFieldTypes.LastName:
            return "lastName";
        case MemberFieldTypes.Email:
            return "email"
        case MemberFieldTypes.Email2:
            return "email2"
        case MemberFieldTypes.Address:
            return "address"
        case MemberFieldTypes.PhoneNumber:
            return "phoneNumber"
        case MemberFieldTypes.PostalCode:
            return "postalCode";
        case MemberFieldTypes.PostalArea:
            return "postalArea";
        case MemberFieldTypes.Title:
            return "title";
        case MemberFieldTypes.Country:
            return "country";
        case MemberFieldTypes.Company:
            return "company";
        case MemberFieldTypes.Extra1:
            return "extra1";
        case MemberFieldTypes.Extra2:
            return "extra2";
        case MemberFieldTypes.Extra3:
            return "extra3";
        case MemberFieldTypes.EmailNotificationPreference:
            return "emailNotificationPreference";
        default:
            return "Unknown";
    }
}