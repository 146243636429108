import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useLocation } from "react-router-dom";
import { Result } from 'antd';
import { TextService } from "../../Services/TextService";


const NewUserCreated = () => {
  const GetText = TextService.GetText;
  return <div id="start">
    <Result
      status="success"
      title={GetText("NewUserCreated_Title")}
      subTitle={GetText("NewUserCreated_Description")}
    />
  </div>
};

export default NewUserCreated;