import { useNavigate } from "react-router-dom";
import '../../Pages/Pricing/Pricing.css'
import { useEffect } from "react";
import { TextService } from "../../Services/TextService";

const PagePublicSite = (props: any) => {
    const navigation = useNavigate();

    useEffect(() => {
        document.title = props.pageTitle ?? TextService.GetText("Common_PageTitle");
    }, []);

    const cssClass = "Page PagePublicSite " + props.className;

    return <div className={cssClass}>
        {props.children}
    </div>
};

export default PagePublicSite;