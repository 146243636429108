/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesAnswerOptions } from './WebApiModelsEnumerationTypesAnswerOptions';
import {
    WebApiModelsEnumerationTypesAnswerOptionsFromJSON,
    WebApiModelsEnumerationTypesAnswerOptionsFromJSONTyped,
    WebApiModelsEnumerationTypesAnswerOptionsToJSON,
} from './WebApiModelsEnumerationTypesAnswerOptions';
import type { WebApiModelsEnumerationTypesDisplayType } from './WebApiModelsEnumerationTypesDisplayType';
import {
    WebApiModelsEnumerationTypesDisplayTypeFromJSON,
    WebApiModelsEnumerationTypesDisplayTypeFromJSONTyped,
    WebApiModelsEnumerationTypesDisplayTypeToJSON,
} from './WebApiModelsEnumerationTypesDisplayType';
import type { WebApiModelsEnumerationTypesEventState } from './WebApiModelsEnumerationTypesEventState';
import {
    WebApiModelsEnumerationTypesEventStateFromJSON,
    WebApiModelsEnumerationTypesEventStateFromJSONTyped,
    WebApiModelsEnumerationTypesEventStateToJSON,
} from './WebApiModelsEnumerationTypesEventState';
import type { WebApiServicesDataTransferObjectsUserServiceParticipantDto } from './WebApiServicesDataTransferObjectsUserServiceParticipantDto';
import {
    WebApiServicesDataTransferObjectsUserServiceParticipantDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceParticipantDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsUserServiceParticipantDtoToJSON,
} from './WebApiServicesDataTransferObjectsUserServiceParticipantDto';
import type { WebApiServicesDataTransferObjectsUserServiceTimeResultDto } from './WebApiServicesDataTransferObjectsUserServiceTimeResultDto';
import {
    WebApiServicesDataTransferObjectsUserServiceTimeResultDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceTimeResultDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsUserServiceTimeResultDtoToJSON,
} from './WebApiServicesDataTransferObjectsUserServiceTimeResultDto';

/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.ConfirmResetPasswordAsync(System.String,System.String,System.String) response.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceEventResultDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceEventResultDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    id?: number;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    name?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesEventState}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    state?: WebApiModelsEnumerationTypesEventState;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    location?: string | null;
    /**
     * Gets or sets the Name.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    maxParticipants?: number;
    /**
     * Gets or sets the Name.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    deadline?: Date | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesDisplayType}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    displayType?: WebApiModelsEnumerationTypesDisplayType;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesAnswerOptions}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    answerOptions?: WebApiModelsEnumerationTypesAnswerOptions;
    /**
     * Gets or sets the Name.
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    isActive?: boolean;
    /**
     * Gets or sets the Name.
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    notifyOnReply?: boolean;
    /**
     * Gets or sets the Name.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    automaticReminder?: Date | null;
    /**
     * Gets or sets the Name.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    automaticReminderSent?: Date | null;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    description?: string | null;
    /**
     * Gets or sets the identifier.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    timeZoneId?: string | null;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    uniqueId?: string | null;
    /**
     * List of event times
     * @type {Array<WebApiServicesDataTransferObjectsUserServiceTimeResultDto>}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    times?: Array<WebApiServicesDataTransferObjectsUserServiceTimeResultDto> | null;
    /**
     * 
     * @type {Array<WebApiServicesDataTransferObjectsUserServiceParticipantDto>}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    participants?: Array<WebApiServicesDataTransferObjectsUserServiceParticipantDto> | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    bannerImagePath?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    isCustomBannerImage?: boolean;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    bannerImageColorCode?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    primaryColorCode?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceEventResultDto
     */
    secondaryColorCode?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceEventResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceEventResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceEventResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceEventResultDto {
    return WebApiServicesDataTransferObjectsUserServiceEventResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceEventResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceEventResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'state': !exists(json, 'state') ? undefined : WebApiModelsEnumerationTypesEventStateFromJSON(json['state']),
        'location': !exists(json, 'location') ? undefined : json['location'],
        'maxParticipants': !exists(json, 'maxParticipants') ? undefined : json['maxParticipants'],
        'deadline': !exists(json, 'deadline') ? undefined : (json['deadline'] === null ? null : new Date(json['deadline'])),
        'displayType': !exists(json, 'displayType') ? undefined : WebApiModelsEnumerationTypesDisplayTypeFromJSON(json['displayType']),
        'answerOptions': !exists(json, 'answerOptions') ? undefined : WebApiModelsEnumerationTypesAnswerOptionsFromJSON(json['answerOptions']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'notifyOnReply': !exists(json, 'notifyOnReply') ? undefined : json['notifyOnReply'],
        'automaticReminder': !exists(json, 'automaticReminder') ? undefined : (json['automaticReminder'] === null ? null : new Date(json['automaticReminder'])),
        'automaticReminderSent': !exists(json, 'automaticReminderSent') ? undefined : (json['automaticReminderSent'] === null ? null : new Date(json['automaticReminderSent'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'timeZoneId': !exists(json, 'timeZoneId') ? undefined : json['timeZoneId'],
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
        'times': !exists(json, 'times') ? undefined : (json['times'] === null ? null : (json['times'] as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceTimeResultDtoFromJSON)),
        'participants': !exists(json, 'participants') ? undefined : (json['participants'] === null ? null : (json['participants'] as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceParticipantDtoFromJSON)),
        'bannerImagePath': !exists(json, 'bannerImagePath') ? undefined : json['bannerImagePath'],
        'isCustomBannerImage': !exists(json, 'isCustomBannerImage') ? undefined : json['isCustomBannerImage'],
        'bannerImageColorCode': !exists(json, 'bannerImageColorCode') ? undefined : json['bannerImageColorCode'],
        'primaryColorCode': !exists(json, 'primaryColorCode') ? undefined : json['primaryColorCode'],
        'secondaryColorCode': !exists(json, 'secondaryColorCode') ? undefined : json['secondaryColorCode'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceEventResultDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceEventResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'state': WebApiModelsEnumerationTypesEventStateToJSON(value.state),
        'location': value.location,
        'maxParticipants': value.maxParticipants,
        'deadline': value.deadline === undefined ? undefined : (value.deadline === null ? null : value.deadline.toISOString()),
        'displayType': WebApiModelsEnumerationTypesDisplayTypeToJSON(value.displayType),
        'answerOptions': WebApiModelsEnumerationTypesAnswerOptionsToJSON(value.answerOptions),
        'isActive': value.isActive,
        'notifyOnReply': value.notifyOnReply,
        'automaticReminder': value.automaticReminder === undefined ? undefined : (value.automaticReminder === null ? null : value.automaticReminder.toISOString()),
        'automaticReminderSent': value.automaticReminderSent === undefined ? undefined : (value.automaticReminderSent === null ? null : value.automaticReminderSent.toISOString()),
        'description': value.description,
        'timeZoneId': value.timeZoneId,
        'uniqueId': value.uniqueId,
        'times': value.times === undefined ? undefined : (value.times === null ? null : (value.times as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceTimeResultDtoToJSON)),
        'participants': value.participants === undefined ? undefined : (value.participants === null ? null : (value.participants as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceParticipantDtoToJSON)),
        'bannerImagePath': value.bannerImagePath,
        'isCustomBannerImage': value.isCustomBannerImage,
        'bannerImageColorCode': value.bannerImageColorCode,
        'primaryColorCode': value.primaryColorCode,
        'secondaryColorCode': value.secondaryColorCode,
    };
}

