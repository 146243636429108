import { useEffect, useState } from 'react';
import { Modal, Space } from 'antd';
import { WebApiModelsEmailTemplate } from '../../Repository/eventbookyapi';
import { AuthProvider } from '../../Providers/authProvider';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { TextService } from '../../Services/TextService';
import { CheckOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/lib/table';
import Paragraph from 'antd/lib/typography/Paragraph';
import LeftMenuSettings from './LeftSettings';
import { EmailTemplateService } from '../../Services/EmailTemplateService';
import CreateEmailTemplateButton from './CreateEmailTemplateButton';
import EditEmailTemplate from './EditEmailTemplate';
import React from 'react';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';

const AdminEmailTemplates = () => {
  const [templates, setTemplates] = useState([] as WebApiModelsEmailTemplate[]);
  const [user, setUser] = useState({} as any);
  const GetText = TextService.GetText;
  const { confirm } = Modal;
  const forceUpdate = React.useReducer(bool => !bool, true)[1];

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    reloadTemplate();
  }, []);

  const deleteTemplate = async (id: number) => {
    confirm({
      title: GetText("EmailTemplates_DeleteConfirm"),
      icon: <ExclamationCircleFilled />,
      content: GetText("EmailTemplates_DeleteConfirmMessage"),
      async onOk() {
        await EmailTemplateService.DeleteTemplate(id);
        reloadTemplate();
      },
      onCancel() { },
    });
  }

  const columns: ColumnsType<WebApiModelsEmailTemplate> = [
    {
      title: GetText("Group_NameColumn"),
      dataIndex: 'uniqueId',
      key: 'uniqueId',
      render: (_, template: WebApiModelsEmailTemplate) => (
        <span><b>{template.templateName}</b><br />
        </span>
      ),
    },
    {
      title: GetText("Group_PurposeColumn"),
      dataIndex: 'purpose',
      key: 'purpose',
      render: (_, template: WebApiModelsEmailTemplate) => (
        <span>{template.purpose === 1 ? <CheckOutlined /> : ""}<br />
        </span>
      ),
    },
    {
      title: GetText("MemberList_ActionColumn"),
      key: 'action',
      render: (_, template) => (
        <Space size="middle">
          {<EditEmailTemplate reloadTemplate={reloadTemplate} templates={templates} template={template}>Edit</EditEmailTemplate>}

          <DeleteOutlined style={{ color: "red", fontSize: "20px" }} onClick={() => deleteTemplate(template.id!)}></DeleteOutlined>
        </Space>
      ),
    },]

  const reloadTemplate = () => {
    EmailTemplateService.GetTemplates().then(result => {
      setTemplates(result);
    });
  }

  return <LayoutLeftMenu title={GetText("EmailTemplates_TemplateTitle")} menu={<LeftMenuSettings selected={"emailTemplates"} />}>
    <Space>
      <Paragraph>{GetText("EmailTemplates_Description")}</Paragraph>
    </Space>
    {user.subscriptionLevel == SubscriptionLevel.Free && templates.length == 1 ?
      <i>{GetText("EmailTemplates_UpgradeTo")} <a href='/Pricing'>Premium</a> {GetText("EmailTemplates_UpgradeTo2")}</i> :
      <CreateEmailTemplateButton user={user} reloadTemplate={reloadTemplate} />}

    <Table columns={columns} style={{ marginTop: "20px" }} locale={{
      emptyText: GetText("EmailTemplates_NoGroupsCreated")
    }} className="admin-table" rowKey="Id" dataSource={templates} />
  </LayoutLeftMenu>
};

export default AdminEmailTemplates;