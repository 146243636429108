/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.AuthenticateAsync(WebApi.Services.DataTransferObjects.UserService.AuthenticateReqDto) request.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto {
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto
     */
    email?: string | null;
    /**
     * Gets or sets the password.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto
     */
    password?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto {
    return WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
    };
}

