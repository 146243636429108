/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiMemberDtoCreatePasswordCodeDto
 */
export interface WebApiMemberDtoCreatePasswordCodeDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoCreatePasswordCodeDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoCreatePasswordCodeDto
     */
    companyUrl?: string | null;
}

/**
 * Check if a given object implements the WebApiMemberDtoCreatePasswordCodeDto interface.
 */
export function instanceOfWebApiMemberDtoCreatePasswordCodeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoCreatePasswordCodeDtoFromJSON(json: any): WebApiMemberDtoCreatePasswordCodeDto {
    return WebApiMemberDtoCreatePasswordCodeDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoCreatePasswordCodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoCreatePasswordCodeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'companyUrl': !exists(json, 'companyUrl') ? undefined : json['companyUrl'],
    };
}

export function WebApiMemberDtoCreatePasswordCodeDtoToJSON(value?: WebApiMemberDtoCreatePasswordCodeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'companyUrl': value.companyUrl,
    };
}

