import { Col, Divider, Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import PageHelp from '../Page/PageHelp';
import Page from '../Page/Page';

const LayoutLeftMenu = (props: any) => {
    return <Page>
        {props.hasTitleTop && !props.titleTop ? <Title>&nbsp;</Title> : ""}
        {props.titleTop ? <Title>{props.titleTop}</Title> : ""}
        <Row align="top">
            <Col span={4} className='leftMenu' style={{ minHeight: "450px" }}>
                {props.menu}
            </Col>
            {props.fullpageMain ? props.children :
                <Col className='bookyBox' style={{ marginLeft: "20px", minHeight: "450px" }} span={19}>
                    <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                        <Title>{props.title}</Title>
                        <PageHelp>
                            {props.pageHelpContent}
                        </PageHelp>
                    </Space>
                    {props.children}
                </Col>}
        </Row>
        <Divider />
    </Page>
};

export default LayoutLeftMenu;