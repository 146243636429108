/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.PasswordResetAsync(WebApi.Services.DataTransferObjects.UserService.PasswordResetReqDto) request.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto
 */
export interface WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto {
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto
     */
    email?: string | null;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto
     */
    language?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServicePasswordResetReqDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServicePasswordResetReqDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto {
    return WebApiServicesDataTransferObjectsUserServicePasswordResetReqDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServicePasswordResetReqDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function WebApiServicesDataTransferObjectsUserServicePasswordResetReqDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'language': value.language,
    };
}

