import { useEffect, useState } from 'react';
import { Button, Collapse, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { EventService } from '../../Services/EventService';
import 'react-multi-email/dist/style.css';
import { AuthProvider } from '../../Providers/authProvider';
import { GroupService } from '../../Services/GroupService';
import { BookyTitleWithIcon } from '../../Components/Forms/TitleWithIcon';
import { SendInviteForm } from '../../Components/EventDetails/SendInviteForm';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import LeftMenuEventDetails from './LeftMenuEventDetails';
import { EmailFormType } from '../../Types/EmailFormType';
import { MemberService } from '../../Services/MemberService';
import { EmailTemplateService } from '../../Services/EmailTemplateService';
import { WebApiMemberDtoMemberListResultDto, WebApiModelsEmailTemplate, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../Repository/eventbookyapi';
import { TextService } from '../../Services/TextService';

const MyEventDetailsSendInviteMember = () => {
  const [event, setEvent] = useState([] as any);
  const [emails, setEmails] = useState<string[]>([]);
  const [groups, setGroups] = useState([] as any[]);
  const [user, setUser] = useState({} as AuthenticatedUser | null);
  const [subject, setSubject] = useState("" as string);
  const GetText = TextService.GetText;
  const [selectedGroup, setSelectedGroup] = useState({} as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto);
  const [memberResult, setMemberResult] = useState({} as WebApiMemberDtoMemberListResultDto);
  const [emailTemplates, setEmailTemplates] = useState([] as WebApiModelsEmailTemplate[]);

  let params = useParams();
  const { Panel } = Collapse;

  const loadEvent = (id: string) => {
    EventService.GetEventDetails(id).then(result => {
      setEvent(result);
      setSubject("Inbjudan till " + result.eventName);
    });
  };

  useEffect(() => {

    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    AuthProvider().ValidateUserLogin();
    if (params.id != null) {
      loadEvent(params.id);

      GroupService.GetGroupsIncludingAllMembers().then(result => {

        result.forEach(element => {
          if (element.isDefaultGroup) {
            element.name = GetText("MemberList_DefaultAllMembersName");
          }
        });
        setGroups(result);

        if (result.length > 0) {
          setSelectedGroup(result[0]);
        }
      });

      MemberService.GetAllMembers().then(result => {
        setMemberResult(result);
      });

      EmailTemplateService.GetTemplates().then(result => {
        setEmailTemplates(result);
      });
    }
  }, [params])


  return <LayoutLeftMenu hasTitleTop={true} titleTop={event.eventName} title={GetText("SendInvite_SendInviteMemberTitle")} menu={<LeftMenuEventDetails selected={"sendInviteMember"} />}>
    <SendInviteForm groups={groups} event={event} subject={subject} emailTemplates={emailTemplates} memberResult={memberResult} type={EmailFormType.EmailFormByMember}></SendInviteForm>
  </LayoutLeftMenu>
};

export default MyEventDetailsSendInviteMember;
