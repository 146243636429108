/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesGroupType } from './WebApiModelsEnumerationTypesGroupType';
import {
    WebApiModelsEnumerationTypesGroupTypeFromJSON,
    WebApiModelsEnumerationTypesGroupTypeFromJSONTyped,
    WebApiModelsEnumerationTypesGroupTypeToJSON,
} from './WebApiModelsEnumerationTypesGroupType';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto
     */
    groupName?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesGroupType}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto
     */
    groupType?: WebApiModelsEnumerationTypesGroupType;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto {
    return WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'groupType': !exists(json, 'groupType') ? undefined : WebApiModelsEnumerationTypesGroupTypeFromJSON(json['groupType']),
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'groupName': value.groupName,
        'groupType': WebApiModelsEnumerationTypesGroupTypeToJSON(value.groupType),
    };
}

