import { useEffect, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { MailOutlined, UserOutlined, TeamOutlined, UserSwitchOutlined, IdcardOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { CompanyRole } from '../../Types/CompanyRole';

const LeftMenu = (props: any) => {
    const [current, setCurrent] = useState('1');
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const GetText = TextService.GetText;
    const navigation = useNavigate();
    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        });

        setCurrent(props.selected);
    }, []);

    type MenuItem = Required<MenuProps>['items'][number];

    const onClick: MenuProps['onClick'] = (e: any) => {
        navigation("/" + getCurrentLanguage() + "/" + e.key);
    };

    function getItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const items: MenuItem[] = user?.role == CompanyRole.GlobalAdmin ? [
        getItem(GetText("Users_UsersTitle"), 'users', <UserOutlined />),
        getItem(GetText("Groups_GroupsTitle"), 'groups', <TeamOutlined />),
        getItem(GetText("Users_UserHistoryTitle"), 'userHistory', <IdcardOutlined />),
    ] : [
        getItem(GetText("Users_UsersTitle"), 'users', <UserOutlined />),
        getItem(GetText("Groups_GroupsTitle"), 'groups', <TeamOutlined />),
    ];

    return <Menu
        theme="light"
        onClick={onClick}
        defaultOpenKeys={['sub1']}
        selectedKeys={[current]}
        mode="inline"
        items={items}
    />
};

export default LeftMenu;