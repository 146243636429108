/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto
     */
    year?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto
     */
    paymentDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto
     */
    amount?: number;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto {
    return WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'paymentDate': !exists(json, 'paymentDate') ? undefined : (new Date(json['paymentDate'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberId': value.memberId,
        'year': value.year,
        'paymentDate': value.paymentDate === undefined ? undefined : (value.paymentDate.toISOString()),
        'amount': value.amount,
    };
}

