/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Create invite result dto
 * @export
 * @interface WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto
 */
export interface WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto
     */
    sentCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto
     */
    alreadySentInvites?: Array<string> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto {
    return WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sentCount': !exists(json, 'sentCount') ? undefined : json['sentCount'],
        'alreadySentInvites': !exists(json, 'alreadySentInvites') ? undefined : json['alreadySentInvites'],
    };
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDtoToJSON(value?: WebApiServicesDataTransferObjectsInviteServiceCreateInviteResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sentCount': value.sentCount,
        'alreadySentInvites': value.alreadySentInvites,
    };
}

