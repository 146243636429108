import { IntegrationApi, WebApiIntegrationsDtoConnectIntegrationDto } from "../Repository/eventbookyapi";
import { IntegrationType } from "../Types/IntegrationType";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class IntegrationService {
    public static GetIntegration = async (type: IntegrationType) => {
        return new IntegrationApi(GetApiConfiguration()).apiIntegrationGet({ type });
    };

    public static GetActiveIntegrations = async () => {
        return new IntegrationApi(GetApiConfiguration()).apiIntegrationGetActiveIntegrationsGet();
    };

    public static GetInvoices = async (type: IntegrationType) => {
        return new IntegrationApi(GetApiConfiguration()).apiIntegrationGetInvoicesGet({ type });
    };

    public static RemoveIntegration = async (id: number) => {
        return new IntegrationApi(GetApiConfiguration()).apiIntegrationDelete({ integrationId: id });
    };


    public static ConnectIntegration = async (dto: WebApiIntegrationsDtoConnectIntegrationDto) => {
        return new IntegrationApi(GetApiConfiguration()).apiIntegrationPost({
            webApiIntegrationsDtoConnectIntegrationDto: dto
        });
    };
};