/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDto,
  WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto,
  WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto,
} from '../models';
import {
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDtoToJSON,
    WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDtoToJSON,
    WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDtoFromJSON,
    WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDtoToJSON,
} from '../models';

export interface ApiSubscriptionCreatePostRequest {
    webApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto?: WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto;
}

/**
 * 
 */
export class SubscriptionApi extends runtime.BaseAPI {

    /**
     * Creates new subscription
     */
    async apiSubscriptionCreatePostRaw(requestParameters: ApiSubscriptionCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subscription/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDtoToJSON(requestParameters.webApiServicesDataTransferObjectsReplyServiceCreateSubscriptionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDtoFromJSON(jsonValue));
    }

    /**
     * Creates new subscription
     */
    async apiSubscriptionCreatePost(requestParameters: ApiSubscriptionCreatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDto> {
        const response = await this.apiSubscriptionCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets invites by eventId
     */
    async apiSubscriptionGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDtoFromJSON(jsonValue));
    }

    /**
     * Gets invites by eventId
     */
    async apiSubscriptionGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsSubscriptionServiceAccountInformationResultDto> {
        const response = await this.apiSubscriptionGetRaw(initOverrides);
        return await response.value();
    }

}
