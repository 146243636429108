import { useEffect, useState } from 'react';
import { Button, Col, Divider, Layout, Popconfirm, Result, Row, Space, Table, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CheckCircleFilled, CheckOutlined, ClockCircleOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ColumnsType } from 'antd/lib/table';
import { EventService } from '../../Services/EventService';
import { formatDateString } from '../../Helpers/DateHelpers';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import Page from '../../Components/Page/Page';

const MyEvents = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [events, setEvents] = useState([] as any);
  const [selectedEvent, setSelectedEvent] = useState([] as any);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(undefined as string | undefined);

  const GetText = TextService.GetText;
  let params = useParams();


  useEffect(() => {
    if (params != null) {
      setIsCreated(params.newid);
    }
    loadEvents();
  }, []);

  const loadEvents = () => {
    EventService.GetMyEvents().then(result => {
      const events: ColumnsType<DataType> = result;
      setEvents(events);
    });
  }

  const navigateToEvent = (uniqueId: string) => {
    navigation("/" + getCurrentLanguage() + "/myevents/" + uniqueId);
  }

  const oncreateclick = (uniqueId: string) => {
    navigation("/" + getCurrentLanguage() + "/create/");
  }

  const locale = {
    emptyText: GetText("MyEvents_NoEvents")
  }

  const onDeleteClick = (id: number) => {
    events.forEach((e: { id: number; showDelete: boolean; }) => {
      if (e.id === id) {
        if (e.showDelete === false || e.showDelete === undefined) {
          e.showDelete = true;
          setEvents(events);
          return;
        }
      }
    });

    setSelectedEvent(id);
  };

  const handleOk = async () => {
    await EventService.DeleteEvent(selectedEvent);
    setConfirmLoading(false);
    loadEvents();
  };

  const handleCancel = () => {
    events.forEach((e: { id: number; showDelete: boolean; }) => {
      e.showDelete = false;
    });

    setEvents((events: any) => ([
      ...events,

    ]));
  };

  interface DataType {
    key: string;
    name: string;
    id: number;
    uniqueId: string;
    numberOfTimes: number;
    numberOfReplies: number;
    times: any[];
    tags: string[];
    showDelete: boolean;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: GetText("MyEvents_EventName"),
      dataIndex: 'name',
      key: 'name',
      render: (_, event) => (
        <a onClick={() => navigateToEvent(event.uniqueId)}><b>{event.name}</b></a>
      ),
    },
    {
      title: GetText("MyEvents_SuggestedTimes"),
      dataIndex: 'numberOfTimes',
      key: 'numberOfTimes',
      render: text => <span><ClockCircleOutlined /> {text}</span>,
    },
    {
      title: GetText("MyEvents_Replies"),
      dataIndex: 'numberOfReplies',
      key: 'numberOfReplies',
      render: text => text === 0 ? <span className='no-replies'><UserOutlined /> {text}</span> : <span><UserOutlined /> {text}</span>,
    },
    {
      title: GetText("MyEvents_Created"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => <span>{formatDateString(text)}</span>,
    },
    {
      title: GetText("MyEvents_Action"),
      key: 'action',
      render: (_, _event) => (
        <Space size="middle">
          <Button onClick={() => navigateToEvent(_event.uniqueId)} type="primary">{GetText("MyEvents_ViewDetails")}</Button>
          <Button onClick={() => onDeleteClick(_event.id)} danger>{GetText("MyEvents_Delete")}</Button>

          <Popconfirm
            title={GetText("MyEvents_DeleteEventConfirm")}
            key={_event.id}
            open={_event.showDelete}
            placement="topRight"
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return <Page>
    <Layout>

      <Row justify="center" align="top">
        <Col span={16}>
          {isCreated ? <div style={{ marginBottom: "10px" }}><CheckCircleFilled style={{ color: "green" }} /> <span>{GetText("MyEvents_CreatedText")}</span> <a href={`/${getCurrentLanguage()}/myevents/${isCreated}`}>{GetText("MyEvents_LinkToEvent")}</a></div> : ""}
        </Col>
      </Row>

      <Row justify="center" align="top">
        <Col span={16} className='bookyBox'>
          <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
            <Title>{GetText("MyEvents_MyEvents")}</Title>
            <BookyButton type="default" icon={<PlusOutlined />} text={GetText("TopHeader_CreateEvent")} onClick={oncreateclick} ></BookyButton>
          </Space>
          <Table columns={columns} locale={locale} rowKey="Id" dataSource={events} />
        </Col>
      </Row>
      <Divider />
    </Layout>
  </Page>
};

export default MyEvents;