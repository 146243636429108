import { useEffect, useState } from 'react';
import { Collapse, message, Space, Col, Divider } from 'antd';
import { CopyOutlined, MailOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { EventService } from '../../Services/EventService';
import Paragraph from 'antd/lib/typography/Paragraph';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-multi-email/dist/style.css';
import { AuthProvider } from '../../Providers/authProvider';
import { BookyTitleWithIcon } from '../../Components/Forms/TitleWithIcon';
import { SendInviteForm } from '../../Components/EventDetails/SendInviteForm';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import LeftMenuEventDetails from './LeftMenuEventDetails';
import { EmailFormType } from '../../Types/EmailFormType';
import { EmailTemplateService } from '../../Services/EmailTemplateService';
import { WebApiModelsEmailTemplate } from '../../Repository/eventbookyapi';

const MyEventDetailsSendInviteExternal = () => {
  const [event, setEvent] = useState([] as any);
  const [groups, setGroups] = useState([] as any[]);
  const [subject, setSubject] = useState("" as string);
  const [imageSelect, setImageSelect] = useState("" as string);
  const [user, setUser] = useState({} as AuthenticatedUser | null);
  const GetText = TextService.GetText;
  const [emailTemplates, setEmailTemplates] = useState([] as WebApiModelsEmailTemplate[]);

  let params = useParams();
  const { Panel } = Collapse;

  const loadEvent = (id: string) => {
    EventService.GetEventDetails(id).then(result => {
      setEvent(result);
      setSubject("Inbjudan till " + result.eventName);
    });
  };

  useEffect(() => {

    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    AuthProvider().ValidateUserLogin();
    if (params.id != null) {
      loadEvent(params.id);

      EmailTemplateService.GetTemplates().then(result => {
        setEmailTemplates(result);
      });
    }
  }, [params])


  return <LayoutLeftMenu hasTitleTop={true} titleTop={event.eventName} title={GetText("SendInvite_SendInviteExternalTitle")} menu={<LeftMenuEventDetails selected={"sendInviteExternal"} />}>
    <Col span={16}>
      <Divider style={{ marginTop: "5px" }} />
      Vill du skicka till e-postadresser som inte ligger upplagda i medlemsregistret? Använd formuläret nedan där du kan skicka till externa e-postadresser.
      <br /><br />
      <SendInviteForm emailTemplates={emailTemplates} subject={subject} type={EmailFormType.EmailFormByExternal} event={event}></SendInviteForm>


    </Col >
  </LayoutLeftMenu >
};

export default MyEventDetailsSendInviteExternal;