/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesEmailTemplatePurpose } from './WebApiModelsEnumerationTypesEmailTemplatePurpose';
import {
    WebApiModelsEnumerationTypesEmailTemplatePurposeFromJSON,
    WebApiModelsEnumerationTypesEmailTemplatePurposeFromJSONTyped,
    WebApiModelsEnumerationTypesEmailTemplatePurposeToJSON,
} from './WebApiModelsEnumerationTypesEmailTemplatePurpose';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto
 */
export interface WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto
     */
    templateId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto
     */
    templateName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto
     */
    body?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesEmailTemplatePurpose}
     * @memberof WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto
     */
    purpose?: WebApiModelsEnumerationTypesEmailTemplatePurpose;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDtoFromJSON(json: any): WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto {
    return WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'templateName': !exists(json, 'templateName') ? undefined : json['templateName'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'purpose': !exists(json, 'purpose') ? undefined : WebApiModelsEnumerationTypesEmailTemplatePurposeFromJSON(json['purpose']),
    };
}

export function WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDtoToJSON(value?: WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateId': value.templateId,
        'templateName': value.templateName,
        'subject': value.subject,
        'body': value.body,
        'purpose': WebApiModelsEnumerationTypesEmailTemplatePurposeToJSON(value.purpose),
    };
}

