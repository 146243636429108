export const OneFront_se = [
    { id: "Common_PageTitle", value: "OneFront - Digital Medlemshantering" },
    { id: "Common_Delete", value: "Ta bort" },
    { id: "Common_Premium", value: "Premium" },
    { id: "Common_Save", value: "Spara" },
    { id: "Common_Saved", value: "Sparat!" },
    { id: "Common_Free", value: "Gratis" },
    { id: "Common_Yes", value: "Ja" },
    { id: "Common_No", value: "Nej" },
    { id: "Common_Members", value: "Medlemmar" },
    { id: "Common_Price", value: "Pris" },
    { id: "Common_Email", value: "E-post" },
    { id: "Common_SureToDelete", value: "Är du säker du vill ta bort?" },
    { id: "Common_GlobalPermisison", value: "Fullständig behörighet" },
    { id: "Common_EditPermission", value: "Redigera behörighet" },
    { id: "Common_ReadPermission", value: "Läs behörighet" },
    { id: "Common_PageNotFoundTitle", value: "Sidan hittades inte." },
    { id: "Common_UpgradeToPremium", value: "Uppgradera till premium" },
    { id: "Common_ChooseFile", value: "Välj fil från dator" },
    { id: "Common_UploadComplete", value: "Uppladdning klar" },
    { id: "Common_SettingsUpdated", value: "Inställningar sparat." },
    { id: "Common_InvalidEmail", value: "Ogiltig e-postadress" },
    { id: "Common_InvalidSmsSender", value: "Ogiltig sms-avsändare. Max 11 tecken endast siffror/bokstäver." },
    { id: "DeleteReply_DeleteHeader", value: "Ta bort svar" },
    { id: "Common_Delete", value: "Ta bort" },
    { id: "Common_Cancel", value: "Avbryt" },
    { id: "AutoComplete_Placeholder", value: "E-post adress" },
    { id: "AutoComplete_PlaceholderPhone", value: "Telefonnummer" },
    { id: "AutoComplete_Click", value: "Lägg till e-post" },
    { id: "AutoComplete_PhoneClick", value: "Lägg till telefonnummer" },
    { id: "MemberAutocomplete__Placeholder", value: "Sök namn/e-post" },
    { id: "AutoComplete_HelpTextAddEmail", value: "Lägg till flera e-postadresser samtidigt genom att lägga till kommatecken mellan e-postadresserna: <br />steve.smith@gmail.com;jonas.anderson@outlook.com" },
    { id: "AutoComplete_HelpTextAddPhone", value: "Lägg till flera telefonummer samtidigt genom att lägga till kommatecken mellan telefonnumren: <br />+478348843;+4673848485" },
    { id: "GooglePlaceAutoComplete_Placeholder", value: "Lägg till plats för event" },
    { id: "BookyEventList_EmptyList", value: "Inga datum/tider tillagda ännu. Klicka på kalendern för att lägga till." },
    { id: "BookyEventList_EmptyList_AllDayEvent", value: "Heldags händelse" },
    { id: "WeekCalendar_AllDay", value: "Heldag" },
    { id: "DeleteReply_MessageRequired", value: "Var vänlig fyll i meddelande" },
    { id: "DeleteReply_MessagePlaceHolder", value: "Meddelande till gäst" },
    { id: "EventBranding_SuccessSaved", value: "Sparat!" },
    { id: "EventBranding_ChangeColorEventName", value: "Ändra färg för rubrik:" },
    { id: "EventBranding_ChangeColorEventPrimaryColor", value: "Ändra primärfärg (färg på knappar och länkar):" },
    { id: "EventBranding_ChangeColorEventSecondaryColor", value: "Ändra sekundärfärg (färg på knappens text):" },
    { id: "EventBranding_SaveColors", value: "Spara färger" },
    { id: "EventBranding_ResetColors", value: "Återställ färger" },
    { id: "EventDetails_EnabledNotification", value: "Aktivt" },
    { id: "EventDetails_DisabledNotification", value: "Avstängt" },
    { id: "EventDetails_ResetColors", value: "Notifieringar för eventet är sparat." },
    { id: "EventDetails_NotificationToolTip", value: "Få notifiering via e-post såfort någon skickat svar på detta event" },
    { id: "EventDetails_EventOpenQuestion", value: "Vill du öppna eventet igen?" },
    { id: "EventDetails_PollIsOpen", value: "Eventet är öppet för anmälningar" },
    { id: "EventDetails_PollIsClosed", value: "Eventet är stängt för anmälningar" },
    { id: "EventDetails_EventStatus", value: "Status:" },
    { id: "EventDetails_EventDescription", value: "Beskrivning:" },
    { id: "EventDetails_EventLocation", value: "Plats:" },
    { id: "EventDetails_EventTimeZone", value: "Tidzon:" },
    { id: "EventDetails_EventParticipants", value: "Deltagare (max):" },
    { id: "EventDetails_EventDeadline", value: "Sista tid för anmälan:" },
    { id: "InviteList_EmailColumn", value: "E-post" },
    { id: "InviteList_SentColumn", value: "Skickat" },
    { id: "InviteList_RepliedColumn", value: "Har svarat" },
    { id: "InviteList_LinkColumn", value: 'Länk till inbjudan' },
    { id: "InviteList_InviteLinkText", value: 'Länk inbjudan' },
    { id: "InviteList_NoInvitesYet", value: 'Inga inbjudningar har skickats ännu.' },
    { id: "InviteList_DeleteInviteConfirmMessage", value: "Ta bort inbjudan helt, är du säker?" },
    { id: "ReminderForm_MessageTextDefault1", value: 'Hej, ' },
    { id: "ReminderForm_MessageTextDefault2", value: " har bjudit in dig för att svara på deras event. Var vänlig svara om du kan eller inte, '" },
    { id: "ReminderForm_MessageTextDefault3", value: "' vilken tid som passar bäst för dig." },
    { id: "ReminderForm_RemindersSuccessMessage", value: 'Påminnelser har skickats' },
    { id: "ReminderForm_AutomaticTurnedOnText", value: 'Automatiska påminnelser är aktivt och kommer att skickas ut ' },
    { id: "ReminderForm_AutomaticTurnedOffText", value: 'Automatiska påminnelser är avstängt för detta event.' },
    { id: "ReminderForm_InvitesStillWithoutReply", value: 'inbjudan har forfarande inte svarat. Skriv en text nedan och skicka påminnelse.' },
    { id: "ReminderForm_SendRemindersToColumn", value: "Skicka påminnelse till" },
    { id: "ReminderForm_ReminderListHeader", value: "Skicka påminnelse till epost" },
    { id: "ReminderForm_RemindersMessageFormText", value: "Meddelande" },
    { id: "ReminderForm_RemindersMessageMandatoryText", value: 'Meddelande är obligatoriskt' },
    { id: "ReminderForm_SendButtonText", value: 'Skicka påminnelser' },
    { id: "ReminderForm_UpgradeToPremium", value: 'för att skicka påminnelser.' },
    { id: "ReminderForm_MessageLabel", value: 'Meddelande' },
    { id: "ReplyTable_Headline", value: 'Deltagare' },
    { id: "ReplyTable_EditReplyOnlyForPremium", value: "Redigera svar är bara för Premium konto. Gå till 'Priser'-sidan för att uppgradera" },
    { id: "ReplyTable_EventAlreadyClosed", value: "Detta event är redan stängt." },
    { id: "ReplyTable_EventClosedDefaultText1", value: "Hej,\rVi har nu ett datum bestämt för  " },
    { id: "ReplyTable_EventClosedDefaultText2", value: " event. \rDatumet är " },
    { id: "ReplyTable_EventClosedDefaultText3", value: "\r\rTack alla för ert deltagande!\rVänliga hälsning, " },
    { id: "ReplyTable_EventHasBeenClosed", value: "Ditt event har nu stängts för anmälningar." },
    { id: "ReplyTable_CloseEventModalDescription", value: "Skriv ett meddelande till dina gäster i textrutan nedan för att informera dem om valt datum. Eventet kommer stängas för flera svar." },
    { id: "ReplyTable_EventCloseHelpText", value: "För att stänga eventet: klicka på ett datum och välj det som slutgiltigt." },
    { id: "SendInvite_SendInviteGroupTitle", value: "Skicka till grupp" },
    { id: "SendInvite_SendInviteMemberTitle", value: "Skicka till enskild medlem" },
    { id: "SendInvite_SendInviteExternalTitle", value: "Skicka externt" },
    { id: "SendInviteForm_SendDefaultText0", value: "Hej, " },
    { id: "SendInviteForm_SendDefaultText1", value: "har bjudit in dig för att svara på deras event, '" },
    { id: "SendInviteForm_SendDefaultText2", value: "' Låt dem veta vilken tid som passar bäst för dig." },
    { id: "SendInviteForm_ErrorMaxParticipants", value: 'Max 25 inbjudningar för gratis konto. Uppgradera till Premium för att skicka fler inbjudningar.' },
    { id: "SendInviteForm_ErrorZeroEmails", value: 'Du måste fylla i minst en (1) e-postadress.' },
    { id: "SendInviteForm_ErrorEmptyGoupName", value: 'Du måste fylla i ett gruppnamn.' },
    { id: "SendInviteForm_ConfirmText", value: 'Vill du skicka inbjudingarna?' },
    { id: "SendInviteForm_ConfirmDescription1", value: 'Du har valt ' },
    { id: "SendInviteForm_ConfirmDescription2", value: " gäster." },
    { id: "SendInviteForm_GroupNameAlreadyExist", value: "Gruppnamnet finns redan. Var vänlig pröva ett nytt namn och pröva igen." },
    { id: "SendInviteForm_MessageSuccess", value: 'Inbjudningar skickade' },
    { id: "SendInviteForm_InviteDescription", value: "Med inbjudningar kan du skicka en personlig länk till dina gäster. Och sen hålla koll på vem som har svarat och vem som inte har svarat. Du kan också skicka ut påminnelser på de som inte svarat." },
    { id: "SendInviteForm_GetContactButtonText", value: "Hämta kontakter från Google" },
    { id: "SendInviteForm_GetContactButtonTextLoaded", value: "Kontakter från Google är laddade" },
    { id: "SendInviteForm_GetContactFromGroup", value: "Skicka inbjudningar till en grupp " },
    { id: "SendInviteForm_EmailAdressesForm", value: "E-postadresser" },
    { id: "SendInviteForm_EmailAdressesPlaceHolder", value: "Hämta e-postadress" },
    { id: "SendInviteForm_AddmultipleDescription", value: "Lägg till flera e-postadresser samtidigt genom att fylla i med en colon separerad text:" },
    { id: "SendInviteForm_UpgradeMoreGroups", value: "för att skapa mer än 1 grupp." },
    { id: "SendInviteForm_SaveEmailsAsGroup", value: "Spara e-post ovan som en grupp och återanvänd i andra events senare." },
    { id: "SendInviteForm_GroupNameForm", value: "Gruppnamn" },
    { id: "SendInviteForm_GroupNameMandatory", value: 'Gruppnamn är obligatoriskt' },
    { id: "SendInviteForm_GroupNamePlaceHolder", value: "Ange namnet på den nya gruppen" },
    { id: "SendInviteForm_GroupNameMessage", value: "Meddelande" },
    { id: "SendInviteForm_GroupMessageMandatory", value: 'Gruppnamn är obligatoriskt' },
    { id: "SendInviteForm_SendButtonText", value: "Skicka inbjudan" },
    { id: "SendInviteForm_UpgradeToInviteMoreGuests", value: "för att bjuda in fler än 25 personer." },
    { id: "ReplyTimeForm_Accepted", value: "Svarat ja" },
    { id: "ReplyTimeForm_Tentative", value: "Kanske" },
    { id: "ReplyTimeForm_Rejected", value: "Svarat nej" },
    { id: "ReplyTimeForm_NoAnswers", value: "Inga svar ännu" },
    { id: "ReplyTimeForm_YesButtonText", value: "Ja" },
    { id: "ReplyTimeForm_MaybeButtonText", value: "Kanske" },
    { id: "ReplyTimeForm_NoButtonText", value: "Kan inte delta" },
    { id: "ReplyTimeForm_GuestsAnsweredYes", value: "gäster svarade 'Ja'" },
    { id: "ReplyTimeForm_ShowOtherGuestReplyText", value: "Se vad andra gäster svarade" },
    { id: "ReplyTimeForm_OtherAnswersNameColumn", value: "Namn" },
    { id: "ReplyTimeForm_OtherAnswersEmailColumn", value: "E-post" },
    { id: "ReplyTimeForm_OtherAnswersAnswerColumn", value: "Svar" },
    { id: "BookyPaymentStatusSelect_Select", value: "Betalstatus" },
    { id: "BookyPaymentStatusSelect_Payed", value: "Betald" },
    { id: "BookyPaymentStatusSelect_Unpayed", value: "Obetald" },
    { id: "ReplyTimeForm_OtherAnswersEmailColumn", value: "E-post" },
    { id: "ReplyTimeForm_OtherAnswersAnswerColumn", value: "Svar" },
    { id: "CreateGroup_CreateText", value: "Skapa ny grupp" },
    { id: "CreateGroup_CancelText", value: "Cancel" },
    { id: "CreateGroup_RequiredText", value: "Vänligen fyll i namnet på gruppen" },
    { id: "CreateGroup_PlaceHolderText", value: "Namnet på gruppen" },
    { id: "CreateGroup_ModalHeader", value: "Skapa en ny grupp" },
    { id: "CreateGroup_ErrorAlreadyExist", value: "Gruppnamn finns redan. Ändra namn och försök igen." },
    { id: "CreateGroup_ErrorAlreadIsOneStandardGroup", value: "Det finns redan en grupp som är vald som standardgrupp." },
    { id: "EditMember_ErrorSaving", value: "Fel vid uppdatering av medlem. Försök igen senare." },
    { id: "EditMember_ErrorSaving", value: "Fel vid uppdatering av medlem. Försök igen senare." },
    { id: "EditMember_ModalTitle", value: "Redigera medlem" },
    { id: "EditMember_ModalOk", value: "Spara" },
    { id: "EditMember_ModalCancel", value: "Avbryt" },
    { id: "EditMember_FirstName", value: "Förnamn" },
    { id: "EditMember_Email", value: "E-post" },
    { id: "EditMember_Email2", value: "E-post2" },
    { id: "EditMember_LastName", value: "Efternamn" },
    { id: "EditMember_PhoneNumber", value: "Telefon" },
    { id: "EditMember_UniqueId", value: "Medlemsnummer" },
    { id: "EditMember_Address", value: "Gatuadress" },
    { id: "EditMember_PostalCode", value: "Postnummer" },
    { id: "EditMember_PostalArea", value: "Postadress" },
    { id: "EditMember_Company", value: "Företagsnamn" },
    { id: "EditMember_Country", value: "Land" },
    { id: "EditMember_EditButtonText", value: "Detaljer" },
    { id: "EditMember_Groups", value: "Grupper" },
    { id: "EditMember_Title", value: "Yrke" },
    { id: "EditMember_Mandatory", value: "Obligatoriskt" },
    { id: "EditMember_Extra1", value: "Extra1" },
    { id: "EditMember_Extra2", value: "Extra2" },
    { id: "EditMember_Extra3", value: "Extra3" },
    { id: "EditMember_Status", value: "Status" },
    { id: "EditMember_Payment", value: "Betalning" },
    { id: "EditMember_EmailNotificationPreference", value: "Önskar inte info via e-post" },
    { id: "EditMember_FirstName_Required", value: "Du måste fylla i förnamn." },
    { id: "EditMember_LastName_Required", value: "Du måste fylla i efternamn." },
    { id: "EditMember_Email_Required", value: "Du måste fylla i e-post." },
    { id: "EditMember_Phone_Required", value: "Du måste fylla i telefon." },
    { id: "EditMember_Address_Required", value: "Du måste fylla i Gatuadress." },
    { id: "EditMember_PostalArea_Required", value: "Du måste fylla i Postadress." },
    { id: "EditMember_PostalCode_Required", value: "Du måste fylla i Postnummer." },
    { id: "PublicSiteEditMember_LoginMemberTitle", value: "Inloggning" },
    { id: "PublicSiteEditMemberEditMemberTitle", value: "Redigera kontaktuppgifter" },
    { id: "AddNote_ModalTitle", value: "Anteckningar" },
    { id: "AddNote_NoteColumnPlaceHolder", value: "Lägg till ny anteckning" },
    { id: "AddPayment_ModalTitle", value: "Betalningar" },
    { id: "AddPayment_SaveNote", value: "Spara betalning" },
    { id: "AddPayment_NoteColumnPlaceHolder", value: "Belopp" },
    { id: "AddPayment_YearColumn", value: "År" },
    { id: "AddPayment_NoPayments", value: "Inga tidigare registrerade betalningar" },
    { id: "EditMember_ModalClose", value: "Stäng" },
    { id: "AddPayment_PaymentDateColumn", value: "Inbetalt" },
    { id: "AddPayment_PaymentDatePlaceHolder", value: "Datum" },
    { id: "AddNote_SaveNote", value: "Spara anteckning" },
    { id: "AddNote_NoNotes", value: "Inga anteckningar ännu" },
    { id: "AddNote_NoteColumn", value: "Anteckning" },
    { id: "EditGroup_GroupName", value: "Namn" },
    { id: "EditGroup_ModalTitle", value: "Redigera grupp" },
    { id: "EditGroup_NameExist", value: "Gruppnamnet finns redan" },
    { id: "EditGroup_AlreadyExistStandardgroup", value: "Det finns redan en grupp som är standardgrupp." },
    { id: "EditGroup_Error", value: "Fel inträfade, var vänlig pröva igen senare." },
    { id: "EditAdmin_Admin", value: "Redigera Administratör" },
    { id: "EditAdmin_MoreThenOneGlobalAdmin", value: "Det måste finnas minst 1 administratör med globala rättigheter" },
    { id: "AddMember_ErrorSaving", value: "Misslyckades lägga till medlem. E-postadressen finns redan." },
    { id: "AddMember_Title", value: "Lägg till medlem" },
    { id: "AddMember_Ok", value: "Spara" },
    { id: "AddMember_Cancel", value: "Avbryt" },
    { id: "SmsForm_SendSmsButtonText", value: "Skicka sms" },
    { id: "EmailForm_SelectOneGroup", value: 'Du måste välja minst 1 grupp/kategori.' },
    { id: "SmsForm_SmsIsSent", value: 'Sms har skickats.' },
    { id: "SmsForm_SmsIsSentFailed", value: 'Sms utskick misslyckades.' },
    { id: "SmsForm_MessageIsRequired", value: "Du måste fylla i ett meddelande." },
    { id: "SmsForm_ConfirmText", value: 'Bekräfta sms-utskick' },
    { id: "SmsForm_ConfirmText1", value: 'Sms kommer att skickas till: ' },
    { id: "SmsForm_ConfirmText2", value: ' telefonnummer.' },
    { id: "SmsForm_ExternalPhoneHeader", value: "Skicka sms till telefonnummer" },
    { id: "SmsForm_From", value: "Sms avsändare: " },
    { id: "EmailForm_EmailsSent", value: 'E-postmeddelanden har skickats.' },
    { id: "EmailForm_ExternalMailHeader", value: "Skicka mail till e-post" },
    { id: "EmailForm_Subject", value: "Ämne" },
    { id: "EmailForm_SubjectRequired", value: "Du måste fylla i ett ämne i mailet." },
    { id: "EmailForm_Message", value: "Meddelande" },
    { id: "EmailForm_From", value: "Från:" },
    { id: "EmailForm_AttachFile", value: "Bifoga fil" },
    { id: "EmailForm_MessageRequired", value: 'Meddelande- är obligatoriskt' },
    { id: "EmailForm_AlertSuspendedMessage", value: "E-post till grupper har tillfälligt stängts av för detta konto" },
    { id: "EmailForm_AlertSuspendedDescription", value: "Fler än 30 e-postmeddelanden till gruppen har skickats de senaste 30 dagarna. Denna varning kommer att tas bort automatiskt. Kontakta oss gärna om du har några frågor." },
    { id: "EmailForm_PremiumSendingMessageToGroups", value: ' för att börja skicka e-postmeddelanden till grupper.' },
    { id: "EmailForm_SendEmailToGroupButtonText", value: 'Skicka e-post' },
    { id: "EmailForm_ConfirmText", value: 'Bekräfta e-postutskick' },
    { id: "EmailForm_ConfirmText1", value: 'E-post kommer att skickas till: ' },
    { id: "EmailForm_ConfirmText2", value: ' e-postadresser.' },
    { id: "EmailForm_Group", value: 'Skicka mail till' },
    { id: "EmailForm_SearchUser", value: 'Sök medlem' },
    { id: "EmailForm_SelectGroup", value: 'Välj från kategori' },
    { id: "EmailForm_EmailsSentFailed", value: 'E-post utskick misslyckades.' },
    { id: "EmailForm_ChooseReceiver", value: 'Välj mottagare av e-post' },
    { id: "SmsForm_ChooseReceiver", value: 'Välj mottagare av sms' },
    { id: "EmailForm_ChooseCategories", value: 'Välj grupper/kategorier' },
    { id: "EmailForm_CreateSendList", value: 'Skapa sändlista' },
    { id: "EmailForm_LoadFromEmailTemplate", value: 'Utgå från e-postmall' },
    { id: "EmailForm_Step1", value: 'Steg 1 - Välj mottagare' },
    { id: "EmailForm_Step2", value: 'Steg 2 - Skriv innehåll och skicka' },
    { id: "EmailForm_OverrideEmailSetting", value: 'Skicka även till medlemmar som inte önskar e-post' },
    { id: "EmailForm_ChosenReceivers", value: 'valda mottagare' },
    { id: "UploadFile_SelectFile", value: 'Bifoga fil' },
    { id: "UploadFile_TooBig", value: 'Filen är för stor (max 4MB)' },
    { id: "UploadFile_MaxFiles", value: 'Max 4 bifogade filer.' },
    { id: "MemberList_DefaultAllMembersName", value: "Alla" },
    { id: "MemberList_ErrorFreeVersionMaxGroupMembers", value: "Kan inte skapa fler än 25 medlemmar i gratisversionen. Uppgradera till Premium för att lägga till fler medlemmar." },
    { id: "MemberList_ErrorFreeVersionMaxGroupMembersPremium", value: "Kan inte skapa fler än 999 medlemmar i Premium-versionen." },
    { id: "MemberList_NameColumn", value: "Name" },
    { id: "MemberList_EmailColumn", value: "Email" },
    { id: "MemberList_UniqueIdColumn", value: "Id" },
    { id: "MemberList_LastNameColumn", value: "Efternamn" },
    { id: "MemberList_FirstNameColumn", value: "Förnamn" },
    { id: "MemberList_AdressColumn", value: "Gatuadress" },
    { id: "MemberList_PostalAreaColumn", value: "Postadress" },
    { id: "MemberList_PostalCodeColumn", value: "Postnummer" },
    { id: "MemberList_CountryColumn", value: "Land" },
    { id: "MemberList_PhoneColumn", value: "Telefon" },
    { id: "MemberList_CompanyColumn", value: "Företag" },
    { id: "MemberList_DeleteMemberConfirmMessage", value: "Ta bort medlem helt, är du säker?" },
    { id: "MemberList_AddMemberHeader", value: "Lägg till medlemmar i gruppen" },
    { id: "MemberList_AddMemberBulkHeader", value: "Lägg till medlemmar genom att ladda upp csv/txt" },
    { id: "MemberList_SendEmailHeader", value: "Skicka e-post till " },
    { id: "MemberList_SearchGroupMember", value: "Sök Id / Namn / Telefon / E-post" },
    { id: "MemberList_EmptyMessage", value: "Inga medlemmar hittades." },
    { id: "MemberList_MembersFoundCount1", value: "Total" },
    { id: "MemberList_MembersFoundCount2", value: "medlemmar hittades." },
    { id: "MemberList_ActionColumn", value: "Hantera" },
    { id: "MemberList_GroupsColumn", value: "Grupper" },
    { id: "MemberList_Download", value: "Ladda ner medlemslista" },
    { id: "MemberList_TitleColumn", value: "Yrke" },
    { id: "MemberList_Extra1Column", value: "Extra1" },
    { id: "MemberList_Extra2Column", value: "Extra2" },
    { id: "MemberList_Extra3Column", value: "Extra3" },
    { id: "MemberList_LastModifiedColumn", value: "Senast ändrad" },
    { id: "MemberList_CreatedColumn", value: "Skapad" },
    { id: "MemberList_StatusColumn", value: "Status" },
    { id: "MemberFields_FieldName", value: "Fält" },
    { id: "MemberFields_FieldNameVisible", value: "Synligt fältnamn" },
    { id: "MemberFieldSettings_Title", value: "Medlemsinställningar" },
    { id: "MemberFields_VisibleInAdmin", value: "Synlig admin" },
    { id: "MemberFields_VisibleInPublic", value: "Synlig medlem" },
    { id: "MemberFields_MandatoryInAdmin", value: "Obligatorisk admin" },
    { id: "MemberFields_MandatoryInPublic", value: "Obligatorisk medlem" },
    { id: "MemberFields_HelpTextPublic", value: "Hjälptext medlem" },
    { id: "PublicSiteForm_Title", value: "Din organisations OneFront site" },
    { id: "PublicSiteForm_Description", value: "Skapa din alldeles egna OneFront site för att låta dina användare uppdatera sina kontaktuppgifter." },
    { id: "PublicSiteForm_Description_CreatedSite", value: "En site finns skapad sedan tidigare. Dina medlemmar kommer åt siten på länken nedan." },
    { id: "PublicSite_Delete", value: "Ta bort site" },
    { id: "PublicSite_SiteDescription", value: "Beskrivning till dina medlemmar" },
    { id: "PublicSiteForm_Success_Created", value: "Site skapad" },
    { id: "PublicSite_TitleColumn", value: "Rubrik" },
    { id: "PublicSite_TitleColumn_Validation", value: "Du måste fylla i en titel/rubrik" },
    { id: "PublicSite_SiteUrlColumn", value: "Url" },
    { id: "PublicSite_SiteUrlColumn_Validation", value: "Du måste fylla i en url" },
    { id: "PublicSite_Create", value: "Skapa" },
    { id: "PublicSite_LogoTypeTitle", value: "Logotyp" },
    { id: "PublicSite_EditMember", value: "Medlem kan redigera sina kontaktuppgifter" },
    { id: "PublicSite_NewMember", value: "Medlem kan registrera sig som medlem" },
    { id: "PublicSite_PresentLogo", value: "Nuvarande logotyp" },
    { id: "PublicSite_Description", value: "Beskrivning" },
    { id: "PublicSite_SiteDescriptionHelpText", value: "Denna text kommer ligga ovanför formulären för ny medlem / redigera medlem. Det kan t.ex. vara någon uppmaning eller kontaktinformation." },
    { id: "Users_AdminTitle", value: "Administratör" },
    { id: "Admins_AdminsTitle", value: "Administratörer" },
    { id: "Admins_Description", value: "Välj vilka administratörer som har rättigheter till denna organisation." },
    { id: "AdminList_Email", value: "Email" },
    { id: "AddAdmin_Role", value: "Behörighet" },
    { id: "AddAdmin_Email", value: "Email" },
    { id: "AddAdmin_Title", value: "Lägg till administratör" },
    { id: "AddAdmin_EmailAlreadyExist", value: "Finns redan en administratör med epost " },
    { id: "AddAdmin_MaxAllowedAdmins", value: "Max tillåtna administratörer har redan lagts till i denna prisnivå." },
    { id: "AdminList_Role", value: "Behörighet" },
    { id: "AdminList_Name", value: "Namn" },
    { id: "AdminList_MembersFoundCount1", value: "Total" },
    { id: "AdminList_MembersFoundCount2", value: "administratörer hittades." },
    { id: "AdminList_WaitingForUserRegistration", value: "Väntar på att kontot ska aktiveras av användaren. Mail har skickats." },
    { id: "CreateCompanyUser_Info", value: "Slutför registreringen nedan för att skapa kontot." },
    { id: "AddAdmin_AddButton", value: "Lägg till administratör" },
    { id: "AddMember_AddButton", value: "Lägg till ny medlem" },
    { id: "AddAdmin_MaxAllowedMembers", value: "Max tillåtna medlemmar har redan lagts till i denna prisnivå." },
    { id: "UploadMembers_WrongFormat", value: "Filen var tom eller i fel format." },
    { id: "UploadMembers_Found", value: "Hittad" },
    { id: "UploadMembers_ErrorMoreThenMaxMembersInFree1", value: "i fil. Kan inte skapa fler än 25 medlemmar i gratisversionen. Uppgradera till" },
    { id: "UploadMembers_ErrorMoreThenMaxMembersInFree2", value: "för att lägga till fler medlemmar." },
    { id: "UploadMembers_ErrorMoreThenMaxMembersInPremium", value: "i fil. Kan inte skapa fler än 999 medlemmar i Premium-versionen." },
    { id: "UploadMembers_UploadCsvDescription1", value: "Här kan du ladda upp en fil i csv/excel format med medlemsdata för att snabbt komma igång när du börjar med OneFront360." },
    { id: "UploadMembers_UploadCsvDescription2", value: "Du kan också använda import när du vill lägga till fler medlemmar samtidigt. Se exempel på importfil nedan:" },
    { id: "UploadMembers_UploadFileText", value: "Importera/Exportera medlemmar" },
    { id: "UploadMembers_ModalTitlePreview", value: "Förhandsgranska filuppladdning" },
    { id: "UploadMembers_ModalAdd", value: "Lägg till " },
    { id: "UploadMembers_ModalMembers", value: " medlemmar" },
    { id: "UploadMembers_ModalOk", value: " Ok" },
    { id: "UploadMembers_ModalCancel", value: "Avbryt" },
    { id: "UploadMembers_FoundInFile1", value: "Totalt" },
    { id: "UploadMembers_FoundInFile2", value: "medlemmar hittades i filen." },
    { id: "UploadMembers_FoundInFile3", value: "errors. Se nedan i rött vilka medlemmar som har fel. Klicka på knappen 'Lägg till' för att fortsätta ladda upp medlemmar till gruppen." },
    { id: "UploadMembers_NoMembersFound", value: "Inga medlemmar hittades i filen" },
    { id: "UploadMembers_CSV", value: "Mall Exempel (CSV)" },
    { id: "UploadMembers_XLSX", value: "Mall Exempel (XLSX)" },
    { id: "UploadMembers_SelectGroup", value: "Välj grupp" },
    { id: "UploadMembers_SelectGroupHelpText", value: "Användarna från filen kommer att läggas till i denna grupp" },
    { id: "UploadPayments_PaymentsFromFileTitle", value: "Importera betalningar från fil" },
    { id: "Payments_DownloadPayments", value: "Ladda ner betalningar till fil" },
    { id: "Users_UsersFromFileTitle", value: "Importera medlemmar från fil" },
    { id: "UploadPaymentData_UploadCsvDescription1", value: "Här kan du ladda upp flera medlemmars betalning genom att använda en csv/excelfil som du först laddar ned från OneFront360 och därefter registrerar in betalningarna. Du kan ladda in betalningar för ett år i taget." },
    { id: "UploadPaymentData_UploadCsvDescription2", value: "MedlemsId;År(YYYY);Belopp" },
    { id: "UploadPaymentData_UploadFileText", value: "Importera betalningar från fil" },
    { id: "UploadPaymentData_MemberId", value: "MedlemsId" },
    { id: "UploadPayments_Example", value: "Ladda ner exempel mall (xlsx)" },
    { id: "UploadPaymentData_Year", value: "År" },
    { id: "UploadPaymentData_PaymentDate", value: "Betaldatum" },
    { id: "UploadPaymentData_Amount", value: "Belopp" },
    { id: "UploadPaymentData_FoundInFile1", value: "Totalt" },
    { id: "UploadPaymentData_FoundInFile2", value: "betalningar hittades i filen." },
    { id: "UploadPaymentData_FoundInFile3", value: "fel. Se nedan i rött vilka betalningar som har fel. Klicka på knappen 'Lägg till' för att fortsätta ladda upp betalningar." },
    { id: "UploadPaymentData_NoPaymentsFound", value: "Inga betalningar hittades i filen" },
    { id: "UploadPaymentData_ModalMembers", value: " betalningar" },
    { id: "UploadPaymentData_AmountValidationMessage", value: "Beloppet är inte i giltigt format: " },
    { id: "UploadPaymentData_MemberIdValidationMessage", value: "Ingen medlem hittades med id: " },
    { id: "UploadPaymentData_YearValidationMessage", value: "Året är inte giltigt: " },
    { id: "PublicSiteEditMember_Email", value: "E-post" },
    { id: "PublicSiteEditMember_PlaceHolder_Email", value: "Ange din registrerade e-post" },
    { id: "PublicSiteEditMember_SendEmail", value: "Skicka inloggningslänk" },
    { id: "PublicSiteEditMember_Email_Required", value: "Du måste fylla i e-postadress" },
    { id: "PublicSiteMailHasBeenSent_Title", value: "Ett mail har skickats med inloggningslänk." },
    { id: "PublicSiteMailHasBeenSent_Description", value: "Ett mail har skickats med en inloggningslänk. Öppna din mail och klicka på länken för att komma vidare och uppdatera dina kontaktuppgifter. Mailet kan dröja några minuter. Glöm inte att kolla om mailet råkat hamna i din skräppost." },
    { id: "PublicSiteNewMember_Error", value: "E-postadressen finns redan registrerad." },
    { id: "PublicSiteEditMember_Error", value: "Fel inträffade. Var vänlig kontrollera att uppgifterna stämmer. Kontakta administratör om problemet kvarstår." },
    { id: "PublicSiteEditMember_CompanyNotFound", value: "Denna länk finns inte." },
    { id: "PublicSiteEditMember_MemberNotFoundOrExpired", value: "Denna länk kunde inte hittas. Länken kan ha upphört." },
    { id: "PublicSiteDetailsHaveBeenSaved_Title", value: "Tack! Uppgifterna har sparats." },
    { id: "PublicSiteDetailsHaveBeenSaved_Description", value: "" },
    { id: "PublicSiteRegisterComplete_Title", value: "Tack! Ett mail har nu skickats till din e-postadress." },
    { id: "PublicSiteRegisterComplete_Description", value: "Öppna mailet och klicka på länken för att slutföra din registrering. Glöm inte att kolla din skräppost mapp om du saknar mailet. Det kan dröja några minuter innan mailet kommer fram." },
    { id: "PublicSiteNewmemberActiveAcount_Title", value: "Klart!" },
    { id: "PublicSiteNewmemberActiveAcount_SubTitle", value: "Din registrering är nu slutförd." },
    { id: "PublicSiteNewmemberActiveAcount_Failed", value: "Fel inträffade. Var vänlig försök igen senare." },
    { id: "PublicSiteNewMember_NewMemberTitle", value: "Registrering ny medlem" },
    { id: "HistoryEvent_AddMember", value: "Medlem las till" },
    { id: "HistoryEvent_DeleteMember", value: "Medlem togs bort" },
    { id: "HistoryEvent_UpdateMember", value: "Medlem uppdaterades" },
    { id: "HistoryEvent_UpdatedMemberByMember", value: "Medlem uppdaterade sina uppgifter" },
    { id: "HistoryEvent_AddMemberByMember", value: "Medlem registrerade sig" },
    { id: "HistoryEvent_MemberActivated", value: "Medlem aktiverade sitt konto" },
    { id: "History_Date", value: "Datum" },
    { id: "History_EventType", value: "Typ av händelse" },
    { id: "History_EventText", value: "Beskrivning" },
    { id: "History_NoHistoryYet", value: "Ingen historik finns ännu" },
    { id: "History_ContactDetails", value: " kontaktuppgifter" },
    { id: "History_ContactDetailsUpdate", value: " uppdaterade sina kontaktuppgifter" },
    { id: "History_RegistredAsMember", value: " registrerade sig som medlem" },
    { id: "History_ActivatedAccount", value: " aktiverade sitt konto" },
    { id: "History_Added", value: " lade till " },
    { id: "History_Updated", value: " uppdaterade " },
    { id: "History_Removed", value: " tog bort " },
    { id: "History_AsMember", value: " som medlem" },
    { id: "Member_ChangedTo", value: " ändrades till " },
    { id: "History_Field", value: "Fält" },
    { id: "History_OldValue", value: "Gammalt värde" },
    { id: "History_NewValue", value: "Nytt värde" },
    { id: "Users_UserHistoryTitle", value: "Medlemshistorik" },
    { id: "EmailLogs_Email", value: "E-post / Telefonnummer" },
    { id: "EmailLogs_Date", value: "Datum" },
    { id: "EmailLogs_Subject", value: "Ämne" },
    { id: "EmailLogs_Reason", value: "Status text" },
    { id: "EmailLogs_From", value: "Från" },
    { id: "EmailLogs_Status", value: "Status" },
    { id: "EmailLogs_EmptyMessage", value: "Inga e-epost utskick hittades" },
    { id: "EmailLogs_ShowOnlyError", value: "Visa endast utskick som misslyckades" },
    { id: "EmailLogs_Max90Days", value: "Du kan max söka i ett tidsspann på max 90 dagar i taget." },
    { id: "TopHeader_Home", value: "Start" },
    { id: "TopHeader_Pricing", value: "Priser" },
    { id: "TopHeader_Functions", value: "Funktioner" },
    { id: "TopHeader_FunctionsOrganisations", value: "För föreningar" },
    { id: "TopHeader_FunctionsCompanies", value: "För företag" },
    { id: "TopHeader_MyEvents", value: "Events" },
    { id: "TopHeader_Contact", value: "Kontakt" },
    { id: "TopHeader_Events", value: "Event" },
    { id: "TopHeader_Groups", value: "Grupper" },
    { id: "TopHeader_Users", value: "Medlemmar" },
    { id: "TopHeader_CreateEvent", value: "Skapa nytt event" },
    { id: "TopHeader_Settings", value: "Inställningar" },
    { id: "TopHeader_English", value: "Engelska" },
    { id: "TopHeader_Swedish", value: "Svenska" },
    { id: "TopHeader_Logout", value: "Logga ut" },
    { id: "TopHeader_Login", value: "Logga in" },
    { id: "TopHeader_CreateAccount", value: "Skapa konto" },
    { id: "TopHeader_Communicate", value: "Kommunikation" },
    { id: "Communicate_SendSmsTitle", value: "Skicka sms" },
    { id: "Communicate_SendMailTitle", value: "Skicka e-post" },
    { id: "Communicate_SendCategoryTitle", value: "Grupp/kategori" },
    { id: "Communicate_SendMemberTitle", value: "Enskild medlem" },
    { id: "Communicate_SendExternalTitle", value: "Externt" },
    { id: "EmailLogs_Search", value: "Sök" },
    { id: "EmailLogs_EmailLogsTitle", value: "Uppföljning" },
    { id: "EmailLogs_StartDate", value: "Startdatum" },
    { id: "EmailLogs_EndDate", value: "Slutdatum" },
    { id: "Users_UsersTitle", value: "Medlemmar" },
    { id: "UploadImage_Error", value: "Fel vid uppladdning av filen" },
    { id: "UploadImage_WrongFormat", value: "Du kan bara ladda upp JPG/PNG-fil!" },
    { id: "UploadImage_TooBig", value: "Bilden måste vara mindre än 1MB!" },
    { id: "UploadImage_UploadImageButton", value: "Ladda upp bild" },
    { id: "Calender_AllDay", value: "Hela dagen" },
    { id: "ConfirmEmail_ActiveAccountPaid", value: "Ditt konto är nu aktivt och du kan logga in. Logga in och gå till sidan 'Priser' för att aktivera betald prenumeration." },
    { id: "ConfirmEmail_ActiveAccount", value: "Ditt konto är nu aktivt och du kan börja skapa händelser direkt efter inloggning." },
    { id: "ConfirmEmail_Success", value: "Ditt konto är verifierat!" },
    { id: "ConfirmEmail_Login", value: "Logga in" },
    { id: "Contact_Header", value: "Kontakt" },
    { id: "Contact_Title", value: "OneFront 360 - Kontakt" },
    { id: "Contact_ReachOut", value: "För att nå oss, skicka ett e-postmeddelande till " },
    { id: "Contact_ReachOut2", value: "Vi kommer att göra vårt bästa för att svara inom 24 timmar." },
    { id: "Contact_Question1Question", value: "Varför fick min användare inte e-postinbjudan?" },
    { id: "Contact_Question1Reply", value: "Ibland hamnar e-postmeddelandet i användarens skräppostmapp/skräppost, beroende på vilken e-postleverantör som används." },
    { id: "Create_Name", value: "Namn" },
    { id: "Create_DateTimeTitle", value: "Datum/tider" },
    { id: "Create_Name_Required", value: "Namn på händelsen behövs." },
    { id: "Create_Name_Placeholder", value: "Vad är namnet på händelsen" },
    { id: "Create_Description", value: "Beskriving" },
    { id: "Create_Description_Placeholder", value: "Beskrivning av händelsen, instruktioner, annan information som kan vara bra att känna till för dina kunder/gäster" },
    { id: "Create_Location", value: "Plats" },
    { id: "Create_Times", value: "Lägg till datum/tider" },
    { id: "Create_Duration", value: "Längd på event" },
    { id: "Create_AllDay", value: "Hela dagen" },
    { id: "Create_Timezone", value: "Tidzon" },
    { id: "Create_Timezone_Placeholder", value: "Välj en tidszon" },
    { id: "Create_ImportGoogleCalendar", value: "Visa kalender" },
    { id: "Create_ShowTimezone", value: "Visa/byt tidzon" },
    { id: "Create_HideGoogleCalendar", value: "Göm Google Calender" },
    { id: "Create_YesNoMaybe", value: "Ja / Nej / Kanske" },
    { id: "Create_YesNo", value: "Ja / Nej" },
    { id: "Create_Yes", value: "Ja" },
    { id: "Create_Settings", value: "Inställningar" },
    { id: "Create_Answers", value: "Svar" },
    { id: "Create_Answers_Placeholder", value: "Välj de tillgängliga alternativen som dina gäster kan välja mellan. Standard är Ja/Nej/Kanske" },
    { id: "Create_Deadline", value: "Deadline" },
    { id: "Create_Deadline_Placeholder", value: "Ange en deadline för ditt evenemang. Efter deadline är inga fler bokningar tillåtna." },
    { id: "Create_Deadline_Set", value: "Set deadline" },
    { id: "Create_Deadline_Premium_SetDeadline", value: "att ställa in deadline." },
    { id: "Create_GuestsMax", value: "Gäster (max)" },
    { id: "Create_Guests_Premium_ToAllowMoreGuests", value: "för att tillåta fler än 25 gäster." },
    { id: "Create_Guests_Premium_RestrictNumberOfGuests", value: "Begränsa antalet svar. Inga fler bokningar är tillåtna efter max antal" },
    { id: "Create_Visibility", value: "Synlighet" },
    { id: "Create_Visibility_Placeholder", value: "(Deltagarna kan se vilka andra gäster/kunder som har svarat ja och vilka datum, klicka i detta för att dölja svaren mellan gästerna. Endast du kan se vilka som svarat.)" },
    { id: "Create_Visibility_HideParticipants", value: "Dölj deltagarlista" },
    { id: "Create_AutomaticReminder", value: "Automatisk påminnelse" },
    { id: "Create_AutomaticReminder_Placeholder", value: "(Skicka automatisk påminnelse till de som inte har svarat 2 dagar före deadline eller första event datum)" },
    { id: "Create_AutomaticReminder_Send", value: "Skicka automatisk påminnelse" },
    { id: "Create_AutomaticReminder_Premium_ToSendAutomaticReminder", value: "för att skicka automatisk påminnelse." },
    { id: "Create_NoAds", value: "Få ditt event att se mer professionell ut genom att ta bort annonser." },
    { id: "Create_AutomaticReminder_Premium_ToRemoveAds", value: "för att ta bort annonser." },
    { id: "Create_CreateEvent", value: "Skapa event" },
    { id: "Create_LoginToCreate", value: "Du måste logga in för att skapa event." },
    { id: "Create_CreateNewAccount", value: "Skapa nytt konto" },
    { id: "Toolbar_Next", value: "Nästa" },
    { id: "Toolbar_Previous", value: "Föregående" },
    { id: "Toolbar_Today", value: "Idag" },
    { id: "Toolbar_ChooseAnotherWeek", value: "Välj annan vecka" },
    { id: "EventCreated_CreateEventWithID", value: "Event har nu skapats" },
    { id: "EventCreated_CopiedToClipboard", value: "kopierat till urklipp." },
    { id: "EventCreated_SuccessTitle", value: "Event har skapats med OneFront!" },
    { id: "EventCreated_SuccessDescription", value: "Du kan nu hitta ditt event under 'Events' i.:" },
    { id: "Event_EventNotFound", value: "Denna händelse kunde inte hittas." },
    { id: "Event_EventPassed", value: "Deadline har passerat för denna händelse. Inga fler svar kan skickas." },
    { id: "Event_EventClosed", value: "Det här evenemanget har stängts." },
    { id: "Event_EventLastDayReply", value: "Sista dagen för svar är:" },
    { id: "Event_Name", value: "Namn" },
    { id: "Event_Name_Placeholder", value: "Ange ditt namn" },
    { id: "Event_Email", value: "E-post" },
    { id: "Event_Email_Placeholder", value: "Ange din e-postadress" },
    { id: "Event_MesssageToOrganizer", value: "Meddelande till arrangören" },
    { id: "Event_PlaseAnswerAvailabilityValidationMessage", value: "Vänligen svara på din tillgänglighet" },
    { id: "Event_TimezoneInformation", value: "* Alla tider ovan är i:" },
    { id: "Event_SubmitAnswer", value: "Skicka svar" },
    { id: "Event_UpdateAnswer", value: "Uppdatera svar" },
    { id: "EventAnswerComplete_Title", value: "Tack! Ditt svar har sparats för eventet " },
    { id: "EventAnswerComplete_Description", value: "Ett e-postmeddelande har skickats till arrangören" },
    { id: "FinalizeRegistration_Title", value: "Nästan klar!" },
    { id: "FinalizeRegistration_Description", value: "Nästan klar! Klicka på knappen nedan för att slutföra registreringen och aktivera ditt konto." },
    { id: "FinalizeRegistration_Activate", value: "Fortsätt till betalning" },
    { id: "ForgotPassword_EmailSent", value: "Ett e-postmeddelande har skickats." },
    { id: "ForgotPassword_Title", value: "Glömt lösenord" },
    { id: "ForgotPassword_Description", value: "Återställ ditt lösenord genom att ange din e-postadress nedan" },
    { id: "ForgotPassword_Email", value: "Email" },
    { id: "ForgotPassword_Email_Placeholder", value: "Vänligen ange din e-post" },
    { id: "ForgotPassword_EmailButtonText", value: "Skicka" },
    { id: "Login_Error", value: "Lösenord eller e-post är felaktigt. Kontrollera och försök igen." },
    { id: "Login_UserNotCreatedError", value: "Den här användaren har inte skapats här på OneFront ännu." },
    { id: "Login_Title", value: "Logga in" },
    { id: "Login_TitleDescription", value: "Logga in för att schemalägga ditt nästa evenemang" },
    { id: "Login_Email", value: "E-post" },
    { id: "Login_Email_Placeholder", value: "E-postadress" },
    { id: "Login_Email_Validation", value: "Ange din e-post" },
    { id: "Login_Password", value: "Lösenord" },
    { id: "Login_Password_Placeholder", value: "Ange lösenord" },
    { id: "Login_Password_Validation", value: "Ange ditt lösenord" },
    { id: "Login_LoginButtonText", value: "Logga in" },
    { id: "Login_CreateAccount", value: "Skapa konto" },
    { id: "Login_LoginWithGoogle", value: "Logga in med Google" },
    { id: "Login_ForgotPassword", value: "Har du glömt lösenordet?" },
    { id: "Login_DividerOr", value: "ELLER" },
    { id: "CreateUser_ErrorAlreadyExist", value: "Kontot/e-postadressen finns redan." },
    { id: "CreateUser_Title", value: "Skapa konto" },
    { id: "CreateUser_TitleDescription", value: "Digital medlemshantering" },
    { id: "CreateUser_FirstName", value: "Förnamn" },
    { id: "CreateUser_FirstName_Validation", value: "Vänligen ange ditt förnamn" },
    { id: "CreateUser_LastName", value: "Efternamn" },
    { id: "CreateUser_LastName_Validation", value: "Ange ditt efternamn" },
    { id: "CreateUser_Email", value: "E-post" },
    { id: "CreateUser_Email_Validation", value: "Vänligen ange din e-postadress" },
    { id: "CreateUser_Password", value: "Lösenord" },
    { id: "CreateUser_Password_Validation", value: "Vänligen ange ditt lösenord" },
    { id: "CreateUser_Create", value: "Skapa" },
    { id: "CreateUser_Login", value: "Logga in" },
    { id: "CreateUser_SignUpWithGoogle", value: "Registrera dig med Google konto" },
    { id: "CreateUser_AlreadySignedUp", value: "Redan registrerad?" },
    { id: "CreateUser_Login", value: "Logga in" },
    { id: "CreateCompanyUser_InviteNotFound", value: "Inbjudan kunde inte hittas. Kontakta administratören för inbjudan." },
    { id: "NewUserCreated_Title", value: "Klar! Ditt konto på OneFront är nu skapat" },
    { id: "NewUserCreated_Description", value: "En e-postverifieringslänk har skickats till din e-post för att verifiera ditt konto." },
    { id: "NewUserCreatedWithProvider_Title", value: "Klar! Ditt konto på OneFront är nu skapat" },
    { id: "NewUserCreatedWithProvider_Description", value: "Börja direkt med att klicka på 'Logga in' för att börja." },
    { id: "Pricing_Title", value: "Priser" },
    { id: "Pricing_EmailYearly", value: "Epost/år" },
    { id: "Pricing_Free", value: "Gratis" },
    { id: "Pricing_Free_Description", value: "Gratis prova-på" },
    { id: "Pricing_CurrentPlan", value: "Nuvarande nivå" },
    { id: "Pricing_SignUp", value: "Registrera konto" },
    { id: "Pricing_Basic", value: "Basic" },
    { id: "Pricing_Premium", value: "Premium" },
    { id: "Pricing_Enterprise", value: "Enterprise" },
    { id: "Pricing_FreePayment", value: "0kr" },
    { id: "Pricing_PremiumPayment", value: "3 000 kr/år" },
    { id: "Pricing_BasicPayment", value: "900-1 500 kr/år" },
    { id: "Pricing_BasicDescription", value: "För mindre organisationer" },
    { id: "Pricing_EnterprisePayment", value: "6 000 kr/år" },
    { id: "Pricing_PremiumDescription", value: "Fler funktioner, mer data" },
    { id: "Pricing_BasicDescription", value: "Välj nivå nedan" },
    { id: "Pricing_EnterpriseDescription", value: "För större organisationer" },
    { id: "Pricing_UpgradeToPremium", value: "Uppgradera till Premium" },
    { id: "Pricing_UpgradeToBasic", value: "Uppgradera till Basic" },
    { id: "Pricing_UpgradeToEnterprise", value: "Uppgradera till Enterprise" },
    { id: "PricingComplete_Success", value: "Uppgraderat konto!" },
    { id: "PricingComplete_Description", value: "Ett e-postmeddelande med kvitton har skickats till din e-post." },
    { id: "Start_FeaturesTitle", value: "Funktioner" },
    { id: "Start_Title", value: "Medlemsysstem som förenklar administration" },
    { id: "Start_Description", value: "<b>OneFront360 är en enkel, säker, rationell och kostnadseffektiv tjänst för föreningar och företag.</b><ul><li>Kontaktuppgifter (företag, medlemsförening)</li><li>Medlemshantering</li><li>Eventhantering (bokningssystem)</li><li>Kommunikation (utskick: e-post, SMS)</li></ul>" },
    { id: "Start_Description1", value: "Du har full koll på din organisationens uppgifter och kan när som helst importera/exportera kontaktuppgifter och betalningar via Excel- eller CSV-fil. OneFront360 är modelärt uppbyggt med modern teknik som enkelt kan anpassas efter behov. <br/><br/>Låter ovan intressant, prova gärna en gratisprenumeration eller skicka en intresseanmälan till support@onefront360.com så kontaktar vi dig och kan berätta mera." },
    { id: "Start_Register", value: "Registrera dig gratis" },
    { id: "Start_EventInShort1", value: "Få kontroll och spårbarhet på ditt medlemsregister" },
    { id: "Start_EventInShort2", value: "Inget kreditkort behövs" },
    { id: "Start_EventInShort3", value: "Kom igång på ett par minuter" },
    { id: "Start_EventInShort4", value: "Säker hantering av data" },
    { id: "Start_EventStep1", value: "Medlemsregister" },
    { id: "Start_EventStep2", value: "Evenemang" },
    { id: "Start_EventStep3", value: "Kommunikation" },
    { id: "Start_EventFeatureTitle4", value: "En egen site för dina medlemmar" },
    { id: "Start_EventFeatureDescription4", value: "Webbformulär för integration med din befintliga site för skapa ny medlem/ändra medlem" },
    { id: "Start_EventFeatureTitle2", value: "Eventhantering" },
    { id: "Start_EventFeatureDescription2", value: "Skapa dina evenemang och skicka inbjudningar" },
    { id: "Start_EventFeatureTitle3", value: "Påminnelser" },
    { id: "Start_EventFeatureDescription3", value: "Skicka påminnelser till de som inte har svarat" },
    { id: "Start_EventFeatureTitle1", value: "Medlemsregister" },
    { id: "Start_EventFeatureDescription1", value: "Flexibelt, du bestämmer innehåll och åtkomst" },
    { id: "Start_EventFeatureTitle5", value: "Exportera din data" },
    { id: "Start_EventFeatureDescription5", value: "Exportera din data till Excel" },
    { id: "Start_EventFeatureTitle6", value: "Kommunikation" },
    { id: "Start_EventFeatureDescription6", value: "Kommunicera med dina medlemmar med e-post/sms och spara porto" },
    { id: "Start_EventFeatureTitle6b", value: "Mallar" },
    { id: "Start_EventFeatureDescription6b", value: "Skapa egna mallar för utskick av e-post för betalning, information, påminnelser, m.m." },
    { id: "Start_EventFeatureTitle6c", value: "E-post/sms loggar" },
    { id: "Start_EventFeatureDescription6c", value: "Kontroll, uppföljning och spårbarhet av skickad e-post och sms" },
    { id: "Start_EventFeatureTitle7", value: "Egen färg & logotyp" },
    { id: "Start_EventFeatureDescription7", value: "Branda din organisationslogotyp/bild från bildbank eller ditt eget foto." },
    { id: "Start_EventFeatureTitle8", value: "Hantera betalningar" },
    { id: "Start_EventFeatureDescription8", value: "Du kan enkelt hantera och följa upp betalningar av medlemsavgifter, antingen manuellt eller automatiskt via integration mot affärssystem" },
    { id: "Settings_Account", value: "Konto" },
    { id: "Settings_Payment", value: "Betalning" },
    { id: "Settings_UserName", value: "Namn" },
    { id: "Settings_Subscription", value: "Kontonivå" },
    { id: "Settings_SmsCredits", value: "Tillgängliga sms" },
    { id: "Settings_SmsTitle", value: "Sms" },
    { id: "Settings_SmsDescription", value: "Vill du köpa till fler sms? Det köps i buntar om 500 sms och kostar 250 kr." },
    { id: "Settings_FinishPayment", value: "Slutför betalning" },
    { id: "Settings_ReadMorePremium", value: "Läs mer om Premium" },
    { id: "Settings_ActiveSubscription", value: "Du har en prenumeration på OneFront skapad med:" },
    { id: "Settings_GoToFastSpring", value: "Gå till FastSpring för att hantera din prenumeration:" },
    { id: "Settings_ManageYourSubscription", value: "Hantera din prenumeration" },
    { id: "Settings_ManageYourSubscriptionDescription", value: "Till exempel - ändra kreditkort eller avbryt prenumeration." },
    { id: "Settings_SubscriptionState", value: "State of subscription:" },
    { id: "Settings_FreeAccountDescription", value: "Detta konto är nu gratis. Klicka på knappen för att läsa mer om fördelarna med Premium-kontot och registrering." },
    { id: "Admins_Payments", value: "Betalning" },
    { id: "MyEventDetails_BrandTitle", value: "Skapa/Ändra design" },
    { id: "MyEventDetails_BrandingDescription", value: "Uppdatera formuläret/webbsidan där dina medlemmar ska svara mer personlig. Välj bild eller ladda upp en egen, välj dina egna färger för text och knappar." },
    { id: "MyEventDetails_ClipBoard", value: "Kopierat till urklipp" },
    { id: "MyEventDetails_Saved", value: "Bilden har sparats." },
    { id: "MyEventDetails_SorryOnlyPremium", value: "Tyvärr! Den här funktionen är endast för Premium. Gå till sidan Priser för att uppgradera ditt konto." },
    { id: "MyEventDetails_HasBeenRemoved", value: "Bilden har tagits bort från händelsen." },
    { id: "MyEventDetails_ShareYourEvent", value: "Inbjudningar" },
    { id: "MyEventDetails_ShareYourEventDescription", value: "Dela din inbjudan, kopiera länken nedan och skicka den till dina deltagare:" },
    { id: "MyEventDetails_SendInvitesByEmail", value: "Inbjudningar" },
    { id: "MyEventDetails_Replies", value: "Svar" },
    { id: "MyEventDetails_Download", value: "Ladda ner" },
    { id: "MyEventDetails_NoRepliesFromGuests", value: "Inga svar från gästerna än. Har du skickat länken till dina gäster?" },
    { id: "MyEventDetails_SendInvites", value: "Inbjudningar" },
    { id: "MyEventDetails_Reminders", value: "Påminnelser" },
    { id: "MyEventDetails_BrandEvent", value: "Brandning av ditt event" },
    { id: "MyEventDetails_BannerImage", value: "Välj bild för event" },
    { id: "MyEventDetails_BrandingDescription", value: "Välj en bild från bildbank eller ladda upp en egen bild för ditt event." },
    { id: "MyEventDetails_BrandingCurrentImage", value: "Din nuvarande bannerbild" },
    { id: "MyEventDetails_RemoveBranding", value: "Ta bort bilden från händelsen." },
    { id: "MyEventDetails_SelectNewImage", value: "Välj ny bild" },
    { id: "MyEventDetails_UploadImageOnlyPremium", value: "Ladda upp bild (endast Premium-konto)" },
    { id: "MyEventDetails_SelectImage", value: "Välj bild" },
    { id: "MyEventDetails_SaveNewImage", value: "Spara ny bild" },
    { id: "MyEventDetails_ColorsForYourEvent", value: "Färger för ditt event" },
    { id: "MyEventDetails_SelectColors", value: "Välj färgerna på knappar och länkar." },
    { id: "MyEventsEdit_UpdateEvent", value: "Ändra" },
    { id: "MyEventsEdit_Name_Label", value: "Namn" },
    { id: "MyEventsEdit_Name_Required", value: "Händelsens namn behövs." },
    { id: "MyEventsEdit_Name_Placeholder", value: "Vad är namnet på eventet" },
    { id: "MyEventsEdit_Description_Label", value: "Beskrivning" },
    { id: "MyEventsEdit_Description_Placeholder", value: "Beskrivning av händelsen, instruktioner, annan information" },
    { id: "MyEventsEdit_Location_Label", value: "Location" },
    { id: "MyEventsEdit_Location_Placeholder", value: "Beskrivning av händelsen, instruktioner, annan information" },
    { id: "MyEventsEdit_Deadline_Label", value: "Deadline" },
    { id: "MyEventsEdit_Deadline_LastResponse", value: "Senast svar" },
    { id: "MyEventsEdit_Deadline_Description", value: "Efter detta datum kan inga svar läggas till." },
    { id: "MyEventsEdit_PremiumDescription", value: "att ställa in deadline." },
    { id: "MyEventsEdit_DeadlineDescriptionText", value: "Ange en deadline för ditt evenemang. Efter deadline är inga fler svar tillåtna." },
    { id: "MyEventsEdit_Participants_Label", value: "Deltagare (max)" },
    { id: "MyEventsEdit_Participants_Description", value: "Max antal deltagare" },
    { id: "MyEventsEdit_RestrictAnswers", value: "Begränsa antalet svar. Inga fler bokningar är tillåtna efter max antal." },
    { id: "MyEventsEdit_UpgradeToPremiumToAllowMoreGuests", value: "för att tillåta fler än 25 gäster." },
    { id: "MyEventsEdit_RestrictNumberOfAnswers", value: "Begränsa antalet svar. Inga fler bokningar är tillåtna efter maxantal." },
    { id: "MyEventsEdit_HideParticipantList", value: "Dölj deltagarlista" },
    { id: "MyEventsEdit_HideParticipantList_Description", value: "(Bara du kan se deltagardetaljer)" },
    { id: "MyEventsEdit_HasGuestsLockedChanges", value: "Detta evenemang har redan gäster, datumen kan därför inte ändras." },
    { id: "MyEventsEdit_AddTimes", value: "Lägg till tider" },
    { id: "MyEventsEdit_Timezone_Label", value: "Timezone" },
    { id: "MyEventsEdit_LengthOfEvent", value: "Längd på händelse" },
    { id: "MyEventsEdit_SelectTimezone", value: "Välj en tidszon" },
    { id: "MyEventsEdit_Duration", value: "Längd på event (min)" },
    { id: "MyEventsEdit_CalenderFormat", value: "Kalenderformat" },
    { id: "MyEventsEdit_LocationOfEvent", value: "Plats för händelsen" },
    { id: "MyEventsEdit_UpdateEventButton", value: "Spara" },
    { id: "MyEventsEdit_Events", value: "Tillagda tider" },
    { id: "MyEventsEdit_CustomTime", value: "Egen tid" },
    { id: "MyEventsEdit_AllDay", value: "Hela dagen" },
    { id: "MyEvents_CreatedText", value: "Event skapat! Du kan nu fortsätta arbeta med ditt event genom att ladda upp egen bild, ändra färger m.m. Du kan också komma tillbaka till denna sida senare." },
    { id: "MyEvents_LinkToEvent", value: "Länk till event" },
    { id: "MyEvents_EventName", value: "Namn" },
    { id: "MyEvents_SuggestedTimes", value: "Antal tider" },
    { id: "MyEvents_Replies", value: "Antal svar" },
    { id: "MyEvents_Created", value: "Skapad" },
    { id: "MyEvents_ViewDetails", value: "Visa detaljer" },
    { id: "MyEvents_Delete", value: "Ta bort" },
    { id: "MyEvents_DeleteEventConfirm", value: "Ta bort händelse, är du säker?" },
    { id: "MyEvents_MyEvents", value: "Mina events" },
    { id: "MyEvents_Action", value: "Hantera" },
    { id: "MyEvents_NoEvents", value: "Inga events skapade ännu." },
    { id: "Integrations_IntegrationTitle", value: "Integrationer" },
    { id: "Integrations_Description", value: "Integrationer möjliggör att koppla ihop OneFront 360 med andra system för automatiserade funktioner. T.ex. avprickning av medlemsavgift." },
    { id: "Integrations_Connect", value: "Anslut" },
    { id: "Integrations_RemoveIntegrationConfirm", value: "Är du säker på att du vill ta bort integrationen?" },
    { id: "Integrations_RemoveIntegrationButton", value: "Ta bort integrationen" },
    { id: "EmailTemplates_TemplateTitle", value: "Epostmallar" },
    { id: "EmailTemplates_Description", value: "Använd epostmallar för att återanvända innehåll i mail." },
    { id: "EmailTemplates_UpgradeTo", value: "Uppgradera till" },
    { id: "EmailTemplates_UpgradeTo2", value: "för att skapa mer än 1 epostmall." },
    { id: "EmailTemplates_NoGroupsCreated", value: "Inga epostmallar har skapats ännu" },
    { id: "EmailTemplates_Delete", value: "Ta bort" },
    { id: "EmailTemplates_Members", value: "Medlemmar" },
    { id: "EmailTemplates_NoGroupsSelected", value: "Inga epostmallar har valts" },
    { id: "EmailTemplates_DeleteConfirm", value: "Är du säker på att du vill ta bort epostmallen?" },
    { id: "EmailTemplates_DeleteConfirmMessage", value: "Det kommer inte gå att ångra senare." },
    { id: "EmailTemplates_NameColumn", value: "Namn" },
    { id: "CreateTemplate_CreateText", value: "Skapa ny epostmall" },
    { id: "CreateTemplate_CancelText", value: "Avbryt" },
    { id: "CreateTemplate_RequiredText", value: "Vänligen fyll i namnet på epostmallen" },
    { id: "CreateTemplate_PlaceHolderText", value: "Namnet på epostmallen" },
    { id: "CreateTemplate_NewMemberRegister", value: "Skicka detta mail automatiskt till nya medlemmar som registrerar sig." },
    { id: "CreateTemplate_Subject", value: "Epost ämne" },
    { id: "CreateTemplate_Body", value: "Epost text" },
    { id: "CreateTemplate_ModalHeader", value: "Skapa en ny epostmall" },
    { id: "CreateTemplate_ErrorAlreadyExist", value: "E-postmall med samma namn finns redan. Ändra namn och försök igen." },
    { id: "EditTemplate_CreateText", value: "Spara" },
    { id: "EditTemplate_CancelText", value: "Avbryt" },
    { id: "EditTemplate_ModalHeader", value: "Redigera epostmall" },
    { id: "EmailLogs_Requests", value: "Skickat" },
    { id: "EmailLogs_Delivered", value: "Mottaget" },
    { id: "EmailLogs_Opened", value: "Öppnat" },
    { id: "EmailLogs_Clicks", value: "Klickat" },
    { id: "EmailLogs_Bounce", value: "Studsat" },
    { id: "EmailLogs_HardBounces", value: "Hård studs" },
    { id: "EmailLogs_SoftBounces", value: "Mjuk studs" },
    { id: "EmailLogs_Spam", value: "Skräppost" },
    { id: "EmailLogs_Defered", value: "Stoppat" },
    { id: "EmailLogs_Blocked", value: "Blockerat" },
    { id: "EmailLogs_Error", value: "Fel inträffade" },
    { id: "EditTemplate_ErrorAlreadyExist", value: "Epostmall finns redan. Ändra namn och försök igen." },
    { id: "Groups_GroupsTitle", value: "Grupper" },
    { id: "Groups_Description", value: "Använd grupper/kategorier för att dela upp dina medlemmar." },
    { id: "Groups_UpgradeTo", value: "Uppgradera till" },
    { id: "Groups_UpgradeTo2", value: "för att skapa mer än 1 grupp." },
    { id: "Groups_NoGroupsCreated", value: "Inga grupper har skapats ännu" },
    { id: "Groups_Delete", value: "Ta bort" },
    { id: "Groups_Members", value: "Medlemmar" },
    { id: "Groups_NoGroupsSelected", value: "Inga grupper har valts" },
    { id: "Groups_DeleteConfirm", value: "Är du säker på att du vill ta bort gruppen " },
    { id: "Groups_DeleteConfirmMessage", value: "Det kommer inte gå att ångra senare." },
    { id: "Group_NameColumn", value: "Namn" },
    { id: "Group_IsDefaultColumn", value: "Standardgrupp" },
    { id: "Group_PurposeColumn", value: "Skickas när ny medlem skapas" },
    { id: "Group_NumberOfMembers", value: "Medlemmar" },
    { id: "AccountForm_Swedish", value: "Svenska" },
    { id: "AccountForm_English", value: "Engelska" },
    { id: "AccountForm_LanguageDescription", value: "Ändra språk på detta konto. Detta styr t.ex. språket på de automatiska mail som går ut till medlemmar." },
    { id: "AccountForm_LanguageSave", value: "Uppdatera inställningar" },
    { id: "AccountForm_CompanyEmail", value: "Organisationens e-postadress" },
    { id: "AccountForm_CompanySmsSender", value: "Sms avsändare" },
    { id: "AccountForm_CompanyName", value: "Organisationens namn" },
    { id: "AccountForm_CompanyEmailDescription", value: "Används som avsändare av e-post" },
    { id: "AccountForm_CompanyNameDescription", value: "Används som avsändare av e-post" },
    { id: "Settings_SettingsTitle", value: "Organisationsinställningar" },
    { id: "Settings_SettingsDomainTitle", value: "Domän/E-post inställningar" },
    { id: "HtmlEditor_MemberId", valiue: "{medlemsnummer}" },
    { id: "HtmlEditor_FirstName", valiue: "{förnamn}" },
    { id: "HtmlEditor_LastName", valiue: "{lastname}" },
    { id: "HtmlEditor_Email", valiue: "{E-post}" },
    { id: "HtmlEditor_Adress", valiue: "{Gatuadress}" },
    { id: "HtmlEditor_PostalCode", valiue: "{Postnummer}" },
    { id: "HtmlEditor_PostalArea", valiue: "{Postområde}" },
    { id: "HtmlEditor_Phone", valiue: "{Telefon}" },
    { id: "HtmlEditorParameter_UniqueId", value: "medlemsnummer" },
    { id: "HtmlEditorParameter_FirstName", value: "förnamn" },
    { id: "HtmlEditorParameter_LastName", value: "efternamn" },
    { id: "HtmlEditorParameter_Email", value: "epost" },
    { id: "HtmlEditorParameter_PhoneNumber", value: "telefon" },
    { id: "HtmlEditorParameter_Address", value: "gatuadress" },
    { id: "HtmlEditorParameter_PostalCode", value: "postnummer" },
    { id: "HtmlEditorParameter_PostalArea", value: "postadress" },
    { id: "HtmlEditorParameter_UrlPopupText", value: "Ange webbadressen till länken (http://www) nedan" },
    { id: "HtmlEditorParameter_UrlImagePopupText", value: "Ange webbadressen till bilden (http://www) nedan" },
    { id: "EmailDomainForm_Error", value: "Ogiltig domännamn. Tänk på att inga privat domännamn går att registrera. T.ex. gmail, outlook mfl." },
    { id: "EmailDomainForm_Description", value: "Skickar du e-post från en egen domän? För att signera/verifiera dina e-postutskick med din egen domän, använd formuläret nedan." },
    { id: "EmailDomainForm_AddDomainButton", value: "Lägg till domän" },
    { id: "EmailDomainForm_RemoveDomain", value: "Ta bort domän" },
    { id: "EmailDomainForm_AuthDomain", value: "Autentisera domän" },
    { id: "EmailDomainForm_DomainCreatedDescription", value: "Publicera inställningarna nedan i din domänhanterare.Efter detta är gjort, kom tillbaka till denna sida och klicka nedan på 'Autentisera domän'" },
    { id: "EmailDomainForm_DomainIsAuthenticated", value: "Dina DNS-inställningar är uppsatt korrekt, dina mail skickas nu via din egen domän." },
    { id: "FeaturesMemberOrganisations_Header", value: "Funktioner för föreningar" },
    { id: "FeaturesMemberOrganisations_Description", value: "Har du behov av ett system för att hantera medlemmar på ett digitalt och säkert sätt för din förening? Se nedan vilka funktioner vi på OneFront kan hjälpa till med." },
    { id: "FeaturesMemberOrganisations_Feature1Title", value: "Medlemsregister" },
    { id: "FeaturesMemberOrganisations_Feature1Text", value: "Flexibelt, du bestämmer innehåll och åtkomst" },
    { id: "FeaturesMemberOrganisations_Feature2Title", value: "Kommunikation" },
    { id: "FeaturesMemberOrganisations_Feature2Text", value: "Kommunicera med dina medlemmar med hjälp av e-postutskick." },
    { id: "FeaturesMemberOrganisations_Feature3Title", value: "Integration" },
    { id: "FeaturesMemberOrganisations_Feature3Text", value: "Låt OneFront komplettera din webbsida med hjälp av smarta registreringsformulär.<br/><br/>Låt medlemmarna själva kunna redigera/komma åt sin kontaktinformation. Välj vilka fält medlemmarna själva ska kunna se/ändra i." },
    { id: "FeaturesMemberOrganisations_Feature4Title", value: "Historik" },
    { id: "FeaturesMemberOrganisations_Feature4Text", value: "Spårbarhet och historik över medlemsändringar, e-postutskick m.m." },
    { id: "FeaturesMemberOrganisations_Feature5Title", value: "Behörighet" },
    { id: "FeaturesMemberOrganisations_Feature5Text", value: "Bjud in flera administratörer i föreningen och jobba med samma information." },
    { id: "FeaturesMemberOrganisations_Feature6Title", value: "Hantera medlemsavgift" },
    { id: "FeaturesMemberOrganisations_Feature6Text", value: "Markera vilka medlemmar som har betalt medlemsavgiften. Skicka ut påminnelser med till de som inte ännu betalat." },
    { id: "FeaturesMemberOrganisations_Feature7Title", value: "Events" },
    { id: "FeaturesMemberOrganisations_Feature7Text", value: "Skapa events för medlemmar eller bara styrelsen. Föreslå olika datum och skicka inbjudan och se vilket datum som passar bäst." },
    { id: "FeaturesMemberOrganisations_Feature8Title", value: "Grupper" },
    { id: "FeaturesMemberOrganisations_Feature8Text", value: "Skapa kategorier av medlemmar för att markera t.ex. vilka som är i styrelsen.<br/><br/>Grupperna/kategorierna kan sedan användas för evenemang, e-postutskick m.m." },
    { id: "FeaturesMemberOrganisations_Feature9Title", value: "Exportera data" },
    { id: "FeaturesMemberOrganisations_Feature9Text", value: "Importera och exportera data till och från Excel/csv. T.ex. medlemsregister, betalhistorik m.m." },
    { id: "PageTitle_Start", value: "Medlemshantering för föreningar & företag | OneFront360 SE" },
    { id: "PageTitle_Create", value: "Skapa konto | OneFront360 SE" },
    { id: "PageTitle_Pricing", value: "Priser och kontonivå | OneFront360 SE" },
    { id: "PageTitle_MemberOrganisations", value: "Funktioner för föreningar | OneFront360 SE" },
    { id: "PageTitle_Contact", value: "Kontakta oss | OneFront360 SE" },
]