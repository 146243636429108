import { ApiEmailTemplateGetCommunicationLogsGetRequest, ApiEmailTemplateSendMailToExternalPostRequest, ApiEmailTemplateSendSmsToExternalPostRequest, EmailTemplateApi, WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto, WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class EmailTemplateService {
    public static CreateTemplate = async (request: WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplatePost({
            webApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto: request
        });
    };

    public static GetTemplates = async () => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateGet();
    };


    public static DeleteTemplate = async (templateId: number) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateTemplateIdDelete({
            templateId: templateId
        });
    };

    public static EditTemplate = async (request: WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateUpdatePost({
            webApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto: request
        });
    };

    public static SendMailToExternal = async (request: ApiEmailTemplateSendMailToExternalPostRequest) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateSendMailToExternalPost(
            request
        );
    };

    public static SendSmsToExternal = async (request: ApiEmailTemplateSendSmsToExternalPostRequest) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateSendSmsToExternalPost(
            request
        );
    };

    public static GetEmailLogs = async (request: ApiEmailTemplateGetCommunicationLogsGetRequest) => {
        return new EmailTemplateApi(GetApiConfiguration()).apiEmailTemplateGetCommunicationLogsGet(
            request
        );
    };
};
