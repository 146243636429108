/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * CreateReplyTimeAnswer
 * @export
 * @interface WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer
 */
export interface WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer
     */
    timeId?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer
     */
    customerAnswer?: number;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerFromJSON(json: any): WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer {
    return WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeId': !exists(json, 'timeId') ? undefined : json['timeId'],
        'customerAnswer': !exists(json, 'customerAnswer') ? undefined : json['customerAnswer'],
    };
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerToJSON(value?: WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeId': value.timeId,
        'customerAnswer': value.customerAnswer,
    };
}

