export enum MemberFieldTypes {
    UnSpecified = 0,
    UniqueId = 1,
    FirstName = 2,
    LastName = 3,
    Email = 4,
    Email2 = 5,
    Address = 6,
    PostalArea = 7,
    PostalCode = 8,
    Country = 9,
    PhoneNumber = 10,
    Title = 11,
    Company = 12,
    Extra1 = 13,
    Extra2 = 14,
    Extra3 = 15,
    Status = 16,
    Group = 17,
    Payment = 18,
    EmailNotificationPreference = 19
}