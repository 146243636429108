import { Result, Image } from 'antd';
import { TextService } from "../../Services/TextService";
import { PublicResultType } from "./Types/PublicResultType";
import { useEffect, useState } from "react";
import PagePublicSite from "../../Components/Page/PagePublicSite";
import BookyBox from "../../Components/Layout/BookyBox";
import Title from "antd/lib/typography/Title";
import { PublicSiteService } from "../../Services/PublicSiteService";
import { WebApiCompanyDtoCompanyPublicSiteDto } from "../../Repository/eventbookyapi";
import { useParams } from 'react-router-dom';

const PublicSiteResult = (props: any) => {
    const GetText = TextService.GetText;
    const [title, setTitle] = useState("" as string);
    const [subTitle, setSubTitle] = useState("" as string);
    const [publicSite, setPublicSite] = useState([] as WebApiCompanyDtoCompanyPublicSiteDto);

    let params = useParams();

    const getDownloadUrl = (dto: WebApiCompanyDtoCompanyPublicSiteDto) => {
        return process.env.REACT_APP_API_URL + '/Image/downloadImage?bloburl=' + dto.siteLogoPath;
    };

    useEffect(() => {

        if (params.companyurl != null) {
            try {
                PublicSiteService.GetCompanyPublicSiteByUrl(params.companyurl).then(result => {
                    setPublicSite(result);
                })
            } catch {
                // Todo show error
            }
        }

        if (params.successCode != null) {

            switch (Number(params.successCode)) {
                case PublicResultType.MailHasBeenSent:
                    setTitle(GetText("PublicSiteMailHasBeenSent_Title"));
                    setSubTitle(GetText("PublicSiteMailHasBeenSent_Description"));
                    return;
                case PublicResultType.NewMemberHasBeenRegisted:
                    setTitle(GetText("PublicSiteRegisterComplete_Title"));
                    setSubTitle(GetText("PublicSiteRegisterComplete_Description"));
                    return;
                case PublicResultType.NewMemberHasActivatedAccount:
                    setTitle(GetText("PublicSiteNewmemberActiveAcount_Title"));
                    setSubTitle(GetText("PublicSiteNewmemberActiveAcount_SubTitle"));
                    return;
                case PublicResultType.DetailsHasBeenSaved:
                    setTitle(GetText("PublicSiteDetailsHaveBeenSaved_Title"));
                    setSubTitle(GetText("PublicSiteDetailsHaveBeenSaved_Description"));
                    return;
            }
        }
    }, []);

    return <PagePublicSite>
        <BookyBox>

            {publicSite.siteLogoPath ? <div style={{ marginBottom: "20px" }}>
                <Image preview={false} className='company-logo' src={getDownloadUrl(publicSite)} ></Image>
            </div>
                : <Title style={{ margin: 0 }}>{publicSite.siteTitle}</Title>}


            <Result
                status="success"
                title={title}
                subTitle={subTitle}
            />
        </BookyBox>
    </PagePublicSite>
};

export default PublicSiteResult;