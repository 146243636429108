import { Alert, Button, Divider, Form } from 'antd';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLogin } from '../../Hooks/useLogin';
import Icon from '@ant-design/icons';
import { UserService } from '../../Services/UserService';
import { useEffect, useState } from 'react';
import { GoogleIcon } from '../../Components/Buttons/GoogleButton';
import BookyBox from '../../Components/Layout/BookyBox';
import PageSmall from '../../Components/Page/PageSmall';
import { BookyPageTitle } from '../../Components/Layout/BookyTitle';
import { BookyInput, InputType } from '../../Components/Forms/BookyInput';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';

const Login = () => {
    const GetText = TextService.GetText;
    const { Login } = useLogin();
    const navigation = useNavigate();
    let params = useParams();
    const [searchParams] = useSearchParams();
    const [errorText, setErrorText] = useState("" as string);
    const [loginErrorText, setLoginErrorText] = useState("" as string);
    const [form] = Form.useForm()

    const token = searchParams.get("token")
    const redirect = searchParams.get("redirect")
    const LoginClick = async (formValues: any) => {
        const loginResult = await Login(formValues);
        if (loginResult.success === false) {
            setLoginErrorText(GetText("Login_Error"));
        }
        else {
            navigation(redirect ? redirect : "/" + getCurrentLanguage() + "/users");
        }
    }

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            if (user != null && user.token != null) {
                navigation("/" + getCurrentLanguage() + "/users");
            }
        });

        if (token) {
            UserService.AuthenticateWithToken(token).then(result => {
                navigation(redirect ? redirect : "/" + getCurrentLanguage() + "/users");
            });
        }

        if (params.errorid) {
            setErrorText(GetText("Login_UserNotCreatedError"));
        }
    }, [token, params])

    const loginWithGoogle = () => {
        window.location.href = process.env.REACT_APP_API_URL + "/ExternalUser/loginWithGoogle?language=" + getCurrentLanguage();
    }

    const loginWithMicrosoft = () => {
        window.location.href = process.env.REACT_APP_API_URL + "/ExternalUser/loginWithMicrosoft";
    }

    const forgotPassword = () => {
        navigation("/" + getCurrentLanguage() + "/forgotpassword");
    }

    return (
        <PageSmall>
            <BookyBox>
                <Form
                    data-testid="create-form"
                    onFinish={LoginClick}
                    wrapperCol={{
                        span: 24,
                    }}
                    layout="vertical"
                    autoComplete="off"
                >
                    <BookyPageTitle text={GetText("Login_Title")} subText={GetText("Login_TitleDescription")} />
                    <BookyInput name="email" placeholder={GetText("Login_Email_Placeholder")} type={InputType.email} label={GetText("Login_Email")} requiredMessage={GetText("Login_Email_Validation")} />
                    <BookyInput label={GetText("Login_Password")} placeholder={GetText("Login_Password_Placeholder")} type={InputType.password} name="password" requiredMessage={GetText("Login_Password_Validation")} />

                    <Form.Item>
                        <Button data-testid="submit-button" style={{ float: 'left', width: '100%' }} size="middle" type="primary" htmlType="submit">
                            {GetText("Login_LoginButtonText")}
                        </Button>
                    </Form.Item>

                    {errorText || loginErrorText ? <Form.Item
                        label=" ">
                        <Form.Item>
                            {errorText ? <Alert showIcon={true} type="warning" message={errorText} action={
                                <Button onClick={() => navigation("/" + getCurrentLanguage() + "/createuser")} size="small" type="primary">
                                    {GetText("Login_CreateAccount")}
                                </Button>} /> : ""}
                            {loginErrorText ? <Alert showIcon={true} type="error" message={loginErrorText}></Alert> : ""}
                        </Form.Item>
                    </Form.Item> : ""}

                    <Form.Item colon={false}>
                        <Form.Item colon={false}>
                            <Divider>{GetText("Login_DividerOr")}</Divider><br></br>
                            <Button size="middle" style={{ width: "100%" }} onClick={loginWithGoogle}>
                                <Icon component={GoogleIcon} />
                                {GetText("Login_LoginWithGoogle")}</Button><br>
                            </br>
                        </Form.Item>
                    </Form.Item>
                </Form>
                <a onClick={forgotPassword}>{GetText("Login_ForgotPassword")}</a>
            </BookyBox>
        </PageSmall>)
};

export default Login;