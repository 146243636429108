import { useEffect, useState } from 'react';
import { Col, Form, Modal, message } from 'antd';
import { GroupService } from '../../Services/GroupService';
import { TextService } from '../../Services/TextService';
import { BookyInput } from '../../Components/Forms/BookyInput';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { OneFrontHtmlEditor } from '../../Components/Forms/OneFrontHtmlEditor';
import { BookySelectDropDown } from '../../Components/Forms/BookySelectDropDown';
import { BookyCheckbox } from '../../Components/Forms/BookyCheckbox';
import { EmailTemplateService } from '../../Services/EmailTemplateService';

export const CreateEmailTemplateButton = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [html, setHtml] = useState("" as string);

    const GetText = TextService.GetText;

    const onCancel = () => {
        clearForm();
        setOpen(false);
    };

    const onHtmlChange = (value: string) => {
        setHtml(value);
        form.setFieldValue("body", value);
    }

    useEffect(() => {
    }, [form])

    const onCreate = async (values: any) => {
        try {
            await EmailTemplateService.CreateTemplate({
                templateName: values.templateName,
                subject: values.subject,
                body: values.body,
                purpose: values.sendWhenUserIsCreated === true ? 1 : 0,
            });
        } catch (error) {
            message.warning(GetText("CreateTemplate_ErrorAlreadyExist"));
            return;
        }

        setOpen(false);
        clearForm();
        props.reloadTemplate();
    }

    const clearForm = () => {
        form.setFieldValue("templateName", "");
        form.setFieldValue("subject", "");
        setHtml("");
        form.setFieldValue("sendWhenUserIsCreated", false);
    }

    return (
        <>
            <Modal
                width={900}
                open={open}
                title={GetText("CreateTemplate_ModalHeader")}
                okText={GetText("CreateTemplate_CreateText")}
                cancelText={GetText("CreateTemplate_CancelText")}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={onCancel}
            >
                <Form form={form} onFinish={onCreate} layout='vertical'>
                    <Col span={24}>
                        <BookyInput name="templateName" requiredMessage={GetText("CreateTemplate_RequiredText")} label={GetText("CreateTemplate_PlaceHolderText")} />
                        <BookyInput name="subject" requiredMessage={GetText("CreateTemplate_RequiredText")} label={GetText("CreateTemplate_Subject")} />
                        <OneFrontHtmlEditor initialHtml={html} onHtmlChange={onHtmlChange} label={GetText("CreateTemplate_Body")} name="body" />
                        <BookyCheckbox label={GetText("CreateTemplate_NewMemberRegister")} name="sendWhenUserIsCreated"></BookyCheckbox>
                    </Col>
                </Form>
            </Modal >

            <div>
                <BookyButton
                    type="primary"
                    fullWidth={false}
                    text={GetText("CreateTemplate_CreateText")}
                    onClick={() => {
                        setOpen(true);
                    }}
                >

                </BookyButton>
            </div>
        </>
    );
};

export default CreateEmailTemplateButton;