/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceAddMemberDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    email2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    postalArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    uniqueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    extra1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    extra2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    extra3?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddMemberDto
     */
    groups?: Array<number> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceAddMemberDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceAddMemberDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceAddMemberDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceAddMemberDto {
    return WebApiServicesDataTransferObjectsGroupServiceAddMemberDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceAddMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceAddMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'postalArea': !exists(json, 'postalArea') ? undefined : json['postalArea'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
        'extra1': !exists(json, 'extra1') ? undefined : json['extra1'],
        'extra2': !exists(json, 'extra2') ? undefined : json['extra2'],
        'extra3': !exists(json, 'extra3') ? undefined : json['extra3'],
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceAddMemberDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceAddMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'email2': value.email2,
        'address': value.address,
        'postalCode': value.postalCode,
        'postalArea': value.postalArea,
        'title': value.title,
        'country': value.country,
        'phoneNumber': value.phoneNumber,
        'company': value.company,
        'uniqueId': value.uniqueId,
        'extra1': value.extra1,
        'extra2': value.extra2,
        'extra3': value.extra3,
        'groups': value.groups,
    };
}

