/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesMemberFieldTypes } from './WebApiModelsEnumerationTypesMemberFieldTypes';
import {
    WebApiModelsEnumerationTypesMemberFieldTypesFromJSON,
    WebApiModelsEnumerationTypesMemberFieldTypesFromJSONTyped,
    WebApiModelsEnumerationTypesMemberFieldTypesToJSON,
} from './WebApiModelsEnumerationTypesMemberFieldTypes';

/**
 * 
 * @export
 * @interface WebApiModelsMemberField
 */
export interface WebApiModelsMemberField {
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsMemberField
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsMemberField
     */
    companyId?: number;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesMemberFieldTypes}
     * @memberof WebApiModelsMemberField
     */
    columnType?: WebApiModelsEnumerationTypesMemberFieldTypes;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsMemberField
     */
    nameAdmin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsMemberField
     */
    namePublic?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiModelsMemberField
     */
    visibleInAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiModelsMemberField
     */
    visiblePublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiModelsMemberField
     */
    mandatoryAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiModelsMemberField
     */
    mandatoryPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsMemberField
     */
    helpTextAdmin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsMemberField
     */
    helpTextPublic?: string | null;
}

/**
 * Check if a given object implements the WebApiModelsMemberField interface.
 */
export function instanceOfWebApiModelsMemberField(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiModelsMemberFieldFromJSON(json: any): WebApiModelsMemberField {
    return WebApiModelsMemberFieldFromJSONTyped(json, false);
}

export function WebApiModelsMemberFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsMemberField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'columnType': !exists(json, 'columnType') ? undefined : WebApiModelsEnumerationTypesMemberFieldTypesFromJSON(json['columnType']),
        'nameAdmin': !exists(json, 'nameAdmin') ? undefined : json['nameAdmin'],
        'namePublic': !exists(json, 'namePublic') ? undefined : json['namePublic'],
        'visibleInAdmin': !exists(json, 'visibleInAdmin') ? undefined : json['visibleInAdmin'],
        'visiblePublic': !exists(json, 'visiblePublic') ? undefined : json['visiblePublic'],
        'mandatoryAdmin': !exists(json, 'mandatoryAdmin') ? undefined : json['mandatoryAdmin'],
        'mandatoryPublic': !exists(json, 'mandatoryPublic') ? undefined : json['mandatoryPublic'],
        'helpTextAdmin': !exists(json, 'helpTextAdmin') ? undefined : json['helpTextAdmin'],
        'helpTextPublic': !exists(json, 'helpTextPublic') ? undefined : json['helpTextPublic'],
    };
}

export function WebApiModelsMemberFieldToJSON(value?: WebApiModelsMemberField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyId': value.companyId,
        'columnType': WebApiModelsEnumerationTypesMemberFieldTypesToJSON(value.columnType),
        'nameAdmin': value.nameAdmin,
        'namePublic': value.namePublic,
        'visibleInAdmin': value.visibleInAdmin,
        'visiblePublic': value.visiblePublic,
        'mandatoryAdmin': value.mandatoryAdmin,
        'mandatoryPublic': value.mandatoryPublic,
        'helpTextAdmin': value.helpTextAdmin,
        'helpTextPublic': value.helpTextPublic,
    };
}

