import { useEffect, useState } from 'react';
import { Button, Form, Popconfirm, Space, message } from 'antd';
import 'moment-timezone'
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import { AuthProvider } from '../../Providers/authProvider';
import { BookyInput } from '../../Components/Forms/BookyInput';
import { CompanyService } from '../../Services/CompanyService';
import { DomainState } from '../../Types/DomainState';
import { WebApiEmailDtoEmailDomainCreationDto } from '../../Repository/eventbookyapi';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { BookyButton } from '../../Components/Buttons/BookyButton';

const EmailDomainForm = (props: any) => {
    const [domainState, setDomainState] = useState(DomainState.Unspecified as DomainState);
    const [domainResult, setDomainResult] = useState({} as WebApiEmailDtoEmailDomainCreationDto);

    const [form] = Form.useForm();
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const GetText = TextService.GetText;

    const CreateDomainClick = async (values: any) => {
        try {
            const result = await CompanyService.AddEmailDomain();
            props.loadCompanySettings();
            message.success(GetText("Common_SettingsUpdated"));
        } catch {
            message.warning(GetText("EmailDomainForm_Error"));
        }
    };

    const DeleteDomainClick = async (values: any) => {
        await CompanyService.DeleteEmailDomain();
        message.success(GetText("Common_SettingsUpdated"));
        props.loadCompanySettings();
    };

    const AuthenticateDomainClick = async (values: any) => {
        const result = await CompanyService.AuthenticateDomain();
        message.success(result.message);
    };

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        })

        if (props != null && props.company != null && props.company.email != null) {
            if (props?.company?.domain?.length > 0) {
                setDomainState(DomainState.Created);
                form.setFieldValue("domain", props.company.domain);
                const domainStatus = CompanyService.GetEmailDomain().then(result => {
                    if (result.dnsRecords?.brevoCode?.status === true) {
                        setDomainState(DomainState.Authenticated);
                    }
                    setDomainResult(result);
                });
            }
            else {
                setDomainState(DomainState.NotCreated);
                form.setFieldValue("domain", props.company.email.split("@")[1]);
            }
        }

    }, [props]);

    return (
        <>
            {domainState == DomainState.NotCreated ? <Form
                colon={false}
                form={form}
                labelCol={{
                    span: 3,
                }}
                onFinish={CreateDomainClick}
                wrapperCol={{
                    span: 6,
                }}
                layout="vertical"
            >
                {GetText("EmailDomainForm_Description")}
                <BookyInput label={"Domän"} disabled={true} name="domain" req></BookyInput>
                <Form.Item label={" "}>
                    <Button data-testid="submit-button" style={{ float: 'left' }} size="middle" type="primary" htmlType="submit">
                        {GetText("EmailDomainForm_AddDomainButton")}
                    </Button>
                </Form.Item>
            </Form> : ""}

            {domainState == DomainState.Created || domainState == DomainState.Authenticated ? <Form
                colon={false}
                form={form}
                labelCol={{
                    span: 3,
                }}
                wrapperCol={{
                    span: 6,
                }}
                layout="vertical"
            >
                <Space style={{ width: '100%', justifyContent: 'left', margin: 0, marginBottom: "10px" }}>
                    {domainState == DomainState.Authenticated ? GetText("EmailDomainForm_DomainIsAuthenticated") : GetText("EmailDomainForm_DomainCreatedDescription")}
                </Space>
                {domainResult.dnsRecords?.brevoCode?.status ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleOutlined />} <b>Brevo code</b> <br />
                type: {domainResult.dnsRecords?.brevoCode?.type} <br />
                name: @<br />
                value: <span style={{ backgroundColor: "#e4e4e4", overflowWrap: "break-word" }}>{domainResult.dnsRecords?.brevoCode?.value}</span> <br />

                {domainResult.dnsRecords?.dkimRecord?.status ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleOutlined />} <b>DKIM record</b> <br />
                type: {domainResult.dnsRecords?.dkimRecord?.type} <br />
                name: {domainResult.dnsRecords?.dkimRecord?.hostName}<br />
                value: <span style={{ backgroundColor: "#e4e4e4", overflowWrap: "break-word" }}>{domainResult.dnsRecords?.dkimRecord?.value}</span> <br />

                {domainResult.dnsRecords?.dmarcRecord?.status ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleOutlined />} <b>Dmarc record</b> <br />
                type: {domainResult.dnsRecords?.dmarcRecord?.type} <br />
                name: {domainResult.dnsRecords?.dmarcRecord?.hostName}<br />
                value: <span style={{ backgroundColor: "#e4e4e4", overflowWrap: "break-word" }}>{domainResult.dnsRecords?.dmarcRecord?.value}</span> <br />
                <BookyInput label={"Domän"} disabled={true} name="domain" req></BookyInput>
                <Form.Item label={" "}>
                    <Space>
                        <BookyButton type={"primary"} onClick={AuthenticateDomainClick} disabled={domainResult.dnsRecords?.brevoCode?.status && domainResult.dnsRecords?.dkimRecord?.status} htmlType="submit" text={GetText("EmailDomainForm_AuthDomain")}></BookyButton>

                        {/* <Popconfirm
                            title={GetText("Common_SureToDelete")}
                            onConfirm={DeleteDomainClick}
                            okText={GetText("Common_Yes")}
                            cancelText={GetText("Common_No")}
                        >
                            <BookyButton type={"secondary"} htmlType="submit" text={GetText("EmailDomainForm_RemoveDomain")}></BookyButton>
                        </Popconfirm> */}
                    </Space>
                </Form.Item>
            </Form > : ""
            }

        </>

    )

};

export default EmailDomainForm;