import { TextService } from "../Services/TextService";
import { SubscriptionLevel } from "../Types/SubscriptionLevel";

export const mapSubscriptionLevel = (type: SubscriptionLevel) => {
    const GetText = TextService.GetText;
    switch (type) {
        case SubscriptionLevel.Basic:
            return "Basic";
        case SubscriptionLevel.BasicPlus:
            return "BasicPlus";
        case SubscriptionLevel.BasicAdvanced:
            return "BasicAdvanced";
        case SubscriptionLevel.Premium:
            return "Premium";
        case SubscriptionLevel.Enterprise:
            return "Enterprise"
        default:
            return "Free";
    }
}

export const mapSubscriptionLevelFromString = (subscriptionLevel: string | undefined | null) => {
    switch (subscriptionLevel) {
        case "Basic":
            return SubscriptionLevel.Basic;
        case "BasicPlus":
            return SubscriptionLevel.BasicPlus;
        case "BasicAdvanced":
            return SubscriptionLevel.BasicAdvanced;
        case "Premium":
            return SubscriptionLevel.Premium;
        case "Enterprise":
            return SubscriptionLevel.Enterprise;
        default:
            return SubscriptionLevel.Free;
    }
}
export const mapSubscriptionLevelToFastSpringProductName = (subscriptionLevel: SubscriptionLevel) => {
    switch (subscriptionLevel) {
        case SubscriptionLevel.Basic:
            return "onefront-basic";
        case SubscriptionLevel.Premium:
            return "eventbooky-premium";
        case SubscriptionLevel.Enterprise:
            return "onefront-enterprise";
        default:
            return "Free";
    }
}


