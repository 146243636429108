import { OneFront_en } from "../Texts/OneFront_en";
import { OneFront_se } from "../Texts/OneFront_se";
import { CompanyTypeConfiguration } from "../Types/CompanyTypes";

export abstract class TextService {

    private static isSwedish = () => {
        return window.location.href.indexOf("/se/") !== -1 || window.location.href.endsWith("/se");
    }

    private static isCRM = () => {
        return user?.companyType === CompanyTypeConfiguration.CRM;
    }

    public static GetText = (key: string) => {
        const textItem = this.isSwedish() ?
            OneFront_se.find(a => a.id === key) :
            OneFront_en.find(a => a.id === key);


        if (user !== null) {
            if (this.isCRM() && textItem?.value && this.isSwedish()) {
                textItem.value = textItem?.value.replace("Medlemshantering", "Kundhantering");
                textItem.value = textItem?.value.replace("Medlemsnummer", "Kundnummer");
                textItem.value = textItem?.value.replace("medlemsnummer", "kundnummer");
                textItem.value = textItem?.value.replace("Medlemmar", "Kunder");
                textItem.value = textItem?.value.replace("Medlem", "Kund");
                textItem.value = textItem?.value.replace("medlemmar", "kunder");
                textItem.value = textItem?.value.replace("medlem", "kund");
            }

            if (this.isCRM() && textItem?.value && !this.isSwedish()) {
                textItem.value = textItem?.value.replace("memberId", "customerId");
                textItem.value = textItem?.value.replace("MemberId", "CustomerId");
                textItem.value = textItem?.value.replace("Members", "Customers");
                textItem.value = textItem?.value.replace("members", "customers");
                textItem.value = textItem?.value.replace("member", "customer");
                textItem.value = textItem?.value.replace("Member", "Customer");
            }
        }

        return textItem == null ? "Missing:" + key : textItem.value!;
    };
}