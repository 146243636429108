/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto,
  WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDto,
  WebApiServicesDataTransferObjectsGroupServiceGroupResultDto,
  WebApiServicesDataTransferObjectsGroupServiceSendMailToGroupDto,
  WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto,
  WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto,
} from '../models';
import {
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceGroupResultDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceGroupResultDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceSendMailToGroupDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceSendMailToGroupDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDtoToJSON,
} from '../models';

export interface ApiGroupGetRequest {
    includeAllMembersGroup?: boolean;
}

export interface ApiGroupGroupIdDeleteRequest {
    groupId: number;
}

export interface ApiGroupGroupIdMembersPostRequest {
    groupId: string;
    webApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto?: WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto;
}

export interface ApiGroupGroupidSendMailPostRequest {
    groupid: string;
    webApiServicesDataTransferObjectsGroupServiceSendMailToGroupDto?: WebApiServicesDataTransferObjectsGroupServiceSendMailToGroupDto;
}

export interface ApiGroupIsAllowedToSendGroupMailsGetRequest {
    groupId?: number;
}

export interface ApiGroupPostRequest {
    webApiServicesDataTransferObjectsGroupServiceCreateGroupDto?: WebApiServicesDataTransferObjectsGroupServiceCreateGroupDto;
}

export interface ApiGroupUpdatePostRequest {
    webApiServicesDataTransferObjectsGroupServiceUpdateGroupDto?: WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDto;
}

/**
 * 
 */
export class GroupApi extends runtime.BaseAPI {

    /**
     * Creates new subscription
     */
    async apiGroupGetRaw(requestParameters: ApiGroupGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiServicesDataTransferObjectsGroupServiceGroupResultDto>>> {
        const queryParameters: any = {};

        if (requestParameters.includeAllMembersGroup !== undefined) {
            queryParameters['includeAllMembersGroup'] = requestParameters.includeAllMembersGroup;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Group`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiServicesDataTransferObjectsGroupServiceGroupResultDtoFromJSON));
    }

    /**
     * Creates new subscription
     */
    async apiGroupGet(requestParameters: ApiGroupGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiServicesDataTransferObjectsGroupServiceGroupResultDto>> {
        const response = await this.apiGroupGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiGroupGroupIdDeleteRaw(requestParameters: ApiGroupGroupIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling apiGroupGroupIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Group/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiGroupGroupIdDelete(requestParameters: ApiGroupGroupIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGroupGroupIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiGroupGroupIdMembersPostRaw(requestParameters: ApiGroupGroupIdMembersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling apiGroupGroupIdMembersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Group/{groupId}/members`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceUpdateGroupMembersDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiGroupGroupIdMembersPost(requestParameters: ApiGroupGroupIdMembersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGroupGroupIdMembersPostRaw(requestParameters, initOverrides);
    }

    /**
     * Send mail to group
     */
    async apiGroupGroupidSendMailPostRaw(requestParameters: ApiGroupGroupidSendMailPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.groupid === null || requestParameters.groupid === undefined) {
            throw new runtime.RequiredError('groupid','Required parameter requestParameters.groupid was null or undefined when calling apiGroupGroupidSendMailPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Group/{groupid}/SendMail`.replace(`{${"groupid"}}`, encodeURIComponent(String(requestParameters.groupid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceSendMailToGroupDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceSendMailToGroupDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Send mail to group
     */
    async apiGroupGroupidSendMailPost(requestParameters: ApiGroupGroupidSendMailPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiGroupGroupidSendMailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiGroupIsAllowedToSendGroupMailsGetRaw(requestParameters: ApiGroupIsAllowedToSendGroupMailsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Group/IsAllowedToSendGroupMails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiGroupIsAllowedToSendGroupMailsGet(requestParameters: ApiGroupIsAllowedToSendGroupMailsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiGroupIsAllowedToSendGroupMailsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates new subscription
     */
    async apiGroupPostRaw(requestParameters: ApiGroupPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceCreateGroupDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceCreateGroupDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDtoFromJSON(jsonValue));
    }

    /**
     * Creates new subscription
     */
    async apiGroupPost(requestParameters: ApiGroupPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDto> {
        const response = await this.apiGroupPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates new subscription
     */
    async apiGroupUpdatePostRaw(requestParameters: ApiGroupUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Group/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceUpdateGroupDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceUpdateGroupDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates new subscription
     */
    async apiGroupUpdatePost(requestParameters: ApiGroupUpdatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiGroupUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
