
import { Button, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";

export const BookyButton = (props: any) => {

    const type = props.type ? props.type : "primary";
    return (
        <Button
            danger={props.danger}
            htmlType={props.htmlType}
            style={props.style}
            loading={props.loading}
            shape={props.shape}
            icon={props.icon}
            disabled={props.disabled}
            className={props.fullWidth ? "button-fullwidth" : ""}
            onClick={props.onClick} size="middle"
            type={type}>
            {props.text}
        </Button>);
}

