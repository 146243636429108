import { Button, Col, Form, Image, Row, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import BookyBox from '../../Components/Layout/BookyBox';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import PagePublicSite from '../../Components/Page/PagePublicSite';
import { WebApiCompanyDtoCompanyPublicSiteDto, WebApiModelsMemberField } from '../../Repository/eventbookyapi';
import NotFound from '../../Components/Page/NotFoundPage';
import { BookyDynamicInput } from '../../Components/Forms/BookyDynamicInput';
import { MemberFieldTypes } from '../../Types/MemberFieldTypes';
import { PublicSiteState } from './Types/PublicSiteState';
import { PublicResultType } from './Types/PublicResultType';
import { PublicSiteService } from '../../Services/PublicSiteService';
import { BookyPhoneInput } from '../../Components/Forms/BookyPhoneInput';

const PublicSiteNewMember = () => {

    const [publicSite, setPublicSite] = useState([] as WebApiCompanyDtoCompanyPublicSiteDto);
    const [state, setState] = useState(PublicSiteState.Undefined as PublicSiteState);
    const [memberFieldSettings, setMemberFieldSettings] = useState([] as WebApiModelsMemberField[]);

    let params = useParams();
    const navigation = useNavigate();
    const [form] = Form.useForm();
    const GetText = TextService.GetText;

    useEffect(() => {
        if (params.companyurl != null) {
            try {
                PublicSiteService.GetCompanyPublicSiteByUrl(params.companyurl).then(result => {
                    setPublicSite(result);
                    setState(PublicSiteState.CompanyFound);
                })

                PublicSiteService.GetMemberFieldsByUrl(params.companyurl).then(result => {
                    setMemberFieldSettings(result);
                    setState(PublicSiteState.CompanyFound);
                })
            } catch {
                setState(PublicSiteState.CompanyNotFound);
            }
        }
    }, [params, form])

    const getDownloadUrl = (dto: WebApiCompanyDtoCompanyPublicSiteDto) => {
        return process.env.REACT_APP_API_URL + '/Image/downloadImage?bloburl=' + dto.siteLogoPath;
    }

    const MemberCreateClick = async (formValues: any) => {

        try {
            await PublicSiteService.AddmemberByMember({
                companyUrl: params.companyurl,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                email2: formValues.email2,
                phone: formValues.phoneNumber,
                streetAdress: formValues.address,
                postalArea: formValues.postalArea,
                postalCode: formValues.postalCode,

            });
        } catch (error) {
            message.warning(GetText("PublicSiteNewMember_Error"));
            return;
        }

        navigation("/" + getCurrentLanguage() + "/" + params.companyurl + "/success/" + PublicResultType.NewMemberHasBeenRegisted);
    }

    if (state === PublicSiteState.CompanyNotFound) {
        return <NotFound message={GetText("PublicSiteEditMember_CompanyNotFound")} />
    }

    const onPhoneChange = (value: any) => {
        form.setFieldValue("phoneNumber", value);
    }

    return <PagePublicSite>
        <BookyBox>

            {publicSite.siteLogoPath ? <div style={{ marginBottom: "20px" }}>
                <Image preview={false} className='company-logo' src={getDownloadUrl(publicSite)} ></Image>
            </div>
                : <Title style={{ margin: 0 }}>{publicSite.siteTitle}</Title>}

            <h2>{GetText("PublicSiteNewMember_NewMemberTitle")}</h2>
            {publicSite.siteDescription && publicSite.siteDescription.length > 0 ? <p dangerouslySetInnerHTML={{ __html: publicSite.siteDescription! }}></p> : ""}

            <Form
                data-testid="create-form"
                name="basic"
                form={form}
                colon={false}
                onFinish={MemberCreateClick}
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Row gutter={24}>
                    <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                        <BookyDynamicInput fieldType={MemberFieldTypes.FirstName} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                    </Col>
                    <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                        <BookyDynamicInput fieldType={MemberFieldTypes.LastName} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                    </Col>
                </Row>


                <BookyDynamicInput fieldType={MemberFieldTypes.Email} isAdmin={false} memberFieldSettings={memberFieldSettings} validateEmailIfFilledIn={true} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Email2} isAdmin={false} memberFieldSettings={memberFieldSettings} validateEmailIfFilledIn={true} />
                <BookyPhoneInput label={GetText("EditMember_PhoneNumber")} name="phoneNumber" onchange={onPhoneChange} req placeholder={""} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Address} isAdmin={false} memberFieldSettings={memberFieldSettings} />

                <Row gutter={24}>
                    <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                        <BookyDynamicInput fieldType={MemberFieldTypes.PostalCode} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                    </Col>
                    <Col xs={24} sm={10} md={18} lg={18} xl={18}>
                        <BookyDynamicInput fieldType={MemberFieldTypes.PostalArea} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                    </Col>
                </Row>




                <BookyDynamicInput fieldType={MemberFieldTypes.Title} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Country} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Company} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Extra1} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Extra2} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Extra3} isAdmin={false} memberFieldSettings={memberFieldSettings} />

                <Form.Item
                    label=""
                    name="submit"
                    rules={[
                        {
                            required: false,
                            message: 'submitbutton',
                        },
                    ]}
                >
                    <Button data-testid="submit-button" className='saveButton' size="middle" type="primary" htmlType="submit">
                        {GetText("EditMember_ModalOk")}
                    </Button>
                </Form.Item>
            </Form>
        </BookyBox>
    </PagePublicSite>
}

export default PublicSiteNewMember;