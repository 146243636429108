/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsExternalPayment } from './WebApiModelsExternalPayment';
import {
    WebApiModelsExternalPaymentFromJSON,
    WebApiModelsExternalPaymentFromJSONTyped,
    WebApiModelsExternalPaymentToJSON,
} from './WebApiModelsExternalPayment';
import type { WebApiModelsPayment } from './WebApiModelsPayment';
import {
    WebApiModelsPaymentFromJSON,
    WebApiModelsPaymentFromJSONTyped,
    WebApiModelsPaymentToJSON,
} from './WebApiModelsPayment';

/**
 * 
 * @export
 * @interface WebApiMemberDtoGetPaymentDto
 */
export interface WebApiMemberDtoGetPaymentDto {
    /**
     * 
     * @type {Array<WebApiModelsPayment>}
     * @memberof WebApiMemberDtoGetPaymentDto
     */
    manualPayments?: Array<WebApiModelsPayment> | null;
    /**
     * 
     * @type {Array<WebApiModelsExternalPayment>}
     * @memberof WebApiMemberDtoGetPaymentDto
     */
    externalPayments?: Array<WebApiModelsExternalPayment> | null;
}

/**
 * Check if a given object implements the WebApiMemberDtoGetPaymentDto interface.
 */
export function instanceOfWebApiMemberDtoGetPaymentDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoGetPaymentDtoFromJSON(json: any): WebApiMemberDtoGetPaymentDto {
    return WebApiMemberDtoGetPaymentDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoGetPaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoGetPaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'manualPayments': !exists(json, 'manualPayments') ? undefined : (json['manualPayments'] === null ? null : (json['manualPayments'] as Array<any>).map(WebApiModelsPaymentFromJSON)),
        'externalPayments': !exists(json, 'externalPayments') ? undefined : (json['externalPayments'] === null ? null : (json['externalPayments'] as Array<any>).map(WebApiModelsExternalPaymentFromJSON)),
    };
}

export function WebApiMemberDtoGetPaymentDtoToJSON(value?: WebApiMemberDtoGetPaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'manualPayments': value.manualPayments === undefined ? undefined : (value.manualPayments === null ? null : (value.manualPayments as Array<any>).map(WebApiModelsPaymentToJSON)),
        'externalPayments': value.externalPayments === undefined ? undefined : (value.externalPayments === null ? null : (value.externalPayments as Array<any>).map(WebApiModelsExternalPaymentToJSON)),
    };
}

