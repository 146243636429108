/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesMemberFieldTypes } from './WebApiModelsEnumerationTypesMemberFieldTypes';
import {
    WebApiModelsEnumerationTypesMemberFieldTypesFromJSON,
    WebApiModelsEnumerationTypesMemberFieldTypesFromJSONTyped,
    WebApiModelsEnumerationTypesMemberFieldTypesToJSON,
} from './WebApiModelsEnumerationTypesMemberFieldTypes';

/**
 * 
 * @export
 * @interface WebApiMemberDtoMemberHistoryChangeDto
 */
export interface WebApiMemberDtoMemberHistoryChangeDto {
    /**
     * 
     * @type {WebApiModelsEnumerationTypesMemberFieldTypes}
     * @memberof WebApiMemberDtoMemberHistoryChangeDto
     */
    memberFieldTypes?: WebApiModelsEnumerationTypesMemberFieldTypes;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoMemberHistoryChangeDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoMemberHistoryChangeDto
     */
    newValue?: string | null;
}

/**
 * Check if a given object implements the WebApiMemberDtoMemberHistoryChangeDto interface.
 */
export function instanceOfWebApiMemberDtoMemberHistoryChangeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoMemberHistoryChangeDtoFromJSON(json: any): WebApiMemberDtoMemberHistoryChangeDto {
    return WebApiMemberDtoMemberHistoryChangeDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoMemberHistoryChangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoMemberHistoryChangeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberFieldTypes': !exists(json, 'memberFieldTypes') ? undefined : WebApiModelsEnumerationTypesMemberFieldTypesFromJSON(json['memberFieldTypes']),
        'oldValue': !exists(json, 'oldValue') ? undefined : json['oldValue'],
        'newValue': !exists(json, 'newValue') ? undefined : json['newValue'],
    };
}

export function WebApiMemberDtoMemberHistoryChangeDtoToJSON(value?: WebApiMemberDtoMemberHistoryChangeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberFieldTypes': WebApiModelsEnumerationTypesMemberFieldTypesToJSON(value.memberFieldTypes),
        'oldValue': value.oldValue,
        'newValue': value.newValue,
    };
}

