import { useEffect, useState } from 'react';
import { Col, Divider, Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import { WebApiCompanyDtoCompanyAdministratorResultDto, WebApiCompanyDtoCompanyUserDto } from '../../Repository/eventbookyapi';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import LeftMenu from '../Users/LeftMenu';
import { CompanyService } from '../../Services/CompanyService';
import AdminList from '../../Components/Users/AdminList';
import LeftMenuSettings from './LeftSettings';
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import LeftMenuCommunicate from '../Communicate/LeftCommunicate';

const Administrators = () => {
  const [adminResult, setAdminResult] = useState({} as WebApiCompanyDtoCompanyAdministratorResultDto);
  const [user, setUser] = useState({} as any);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    loadAdmins();
  }, []);

  const loadAdmins = () => {
    CompanyService.GetCompanyAdministrators().then(result => {
      setAdminResult(result);
    });
  }

  return <LayoutLeftMenu title={GetText("Admins_AdminsTitle")} menu={<LeftMenuSettings selected={"admins"} />}>
    <Paragraph>{GetText("Admins_Description")}</Paragraph>
    {
      adminResult != null ? <>

        <AdminList user={user} adminResult={adminResult} loadAdmins={loadAdmins} />
      </>
        : <i>{GetText("Groups_NoGroupsSelected")}</i>
    }
  </LayoutLeftMenu>
};

export default Administrators;