import { Table } from "antd"
import { TextService } from "../../../Services/TextService";
import { WebApiMemberDtoAddMemberObjectDto, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto } from "../../../Repository/eventbookyapi";
import { ColumnsType } from "antd/lib/table";
import { isEmail } from "react-multi-email";
import { mapMemberStatusToString } from "../../../Mappers/mapGroupToListItem";
import { getCurrentYearAsString, getPreviousYear, getUpcomingYear } from "../../../Helpers/DateHelpers";

export const UploadMemberPreviewData = (props: any) => {
    const GetText = TextService.GetText;

    const columns: ColumnsType<WebApiMemberDtoAddMemberObjectDto> = [
        {
            title: GetText("MemberList_UniqueIdColumn"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto) => (
                <span><b>{member.uniqueId}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_FirstNameColumn"),
            dataIndex: 'firstname',
            key: 'firstname',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.firstName}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_LastNameColumn"),
            dataIndex: 'name',
            key: 'name',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.lastName}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_EmailColumn"),
            dataIndex: 'email',
            key: 'email',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{isEmail(member.email!) ? member.email : <span style={{ color: "red" }}>{member.email ? member.email : <i>Missing email</i>}</span>}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_EmailColumn"),
            dataIndex: 'email2',
            key: 'email2',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{isEmail(member.email2!) ? member.email2 : <span style={{ color: "red" }}>{member.email2 ? member.email2 : <i>Missing email</i>}</span>}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_PhoneColumn"),
            dataIndex: 'phone',
            key: 'phone',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.phoneNumber}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_GroupsColumn"),
            dataIndex: 'extra3',
            key: 'extra3',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.groups}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_AdressColumn"),
            dataIndex: 'address',
            key: 'address',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.address}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_PostalAreaColumn"),
            dataIndex: 'address',
            key: 'address',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.postalArea}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_PostalCodeColumn"),
            dataIndex: 'address',
            key: 'address',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.postalCode}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_TitleColumn"),
            dataIndex: 'address',
            key: 'address',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.title}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_CountryColumn"),
            dataIndex: 'country',
            key: 'country',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.country}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_CompanyColumn"),
            dataIndex: 'company',
            key: 'company',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.company}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_Extra1Column"),
            dataIndex: 'extra1',
            key: 'extra1',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.extra1}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_Extra2Column"),
            dataIndex: 'extra2',
            key: 'extra2',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.extra2}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_Extra3Column"),
            dataIndex: 'extra3',
            key: 'extra3',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.extra3}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_GroupsColumn"),
            dataIndex: 'groups',
            key: 'groups',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.groups}</b><br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_StatusColumn"),
            dataIndex: 'status',
            key: 'status',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{mapMemberStatusToString(member.status!)}</b><br />
                </span>
            ),
        },
        {
            title: "Betalning år " + getPreviousYear(2),
            dataIndex: 'paymentMinusTwo',
            key: 'paymentMinusTwo',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.paymentAmountYearMinusTwo}</b><br />
                </span>
            ),
        },
        {
            title: "Betalning år " + getPreviousYear(1),
            dataIndex: 'paymentMinusTwo',
            key: 'paymentMinusTwo',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.paymentAmountYearMinusOne}</b><br />
                </span>
            ),
        },
        {
            title: "Betalning år " + getCurrentYearAsString(),
            dataIndex: 'paymentMinusCurrent',
            key: 'paymentMinusCurrent',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.paymentAmountCurrentYear}</b><br />
                </span>
            ),
        },
        {
            title: "Betalning år " + getUpcomingYear(1),
            dataIndex: 'paymentMinusCurrent',
            key: 'paymentMinusCurrent',
            render: (_, member: WebApiMemberDtoAddMemberObjectDto) => (
                <span><b>{member.paymentAmountNextYear}</b><br />
                </span>
            ),
        },
    ];

    return <Table scroll={{ x: "max-content" }} columns={columns} style={{ marginTop: "20px" }} locale={{
        emptyText: GetText("UploadMembers_NoMembersFound")
    }} className="admin-table" rowKey="Id" dataSource={props.members}></Table>
}