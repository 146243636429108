import { Alert, Button, Divider, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCreateUser } from '../../Hooks/useCreateUser';
import Icon from '@ant-design/icons';
import { GoogleIcon } from '../../Components/Buttons/GoogleButton';
import PageSmall from '../../Components/Page/PageSmall';
import BookyBox from '../../Components/Layout/BookyBox';
import { BookyInput, InputType } from '../../Components/Forms/BookyInput';
import { BookyPageTitle } from '../../Components/Layout/BookyTitle';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import CreateUserForm from '../../Components/CreateUser/CreateUser';

const CreateUser = () => {
    const { CreateUser } = useCreateUser();
    const navigation = useNavigate();
    let params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const subscription = searchParams.get("subscription");
    const [errorText, setErrorText] = useState("" as string);
    const GetText = TextService.GetText;

    const onCreateUserSubmit = async (formValues: any) => {
        let newUser: any = await CreateUser(formValues, subscription ? subscription : "");
        if (newUser && newUser.message != null) {
            setErrorText(newUser.message);
        }
        else {
            navigation("/" + getCurrentLanguage() + "/NewUserCreated");
        }
    }

    useEffect(() => {
        if (params.errorid) {
            setErrorText(GetText("CreateUser_ErrorAlreadyExist"));
        }

        if (token) {
            navigation("/" + getCurrentLanguage() + "/NewUserCreatedWithProvider");
        }
    }, [params])

    const onRedirectToGoogle = () => {
        if (subscription != null) {
            window.location.href = process.env.REACT_APP_API_URL + "/ExternalUser/signUpWithGoogle?subscription=" + subscription + "&language=" + getCurrentLanguage();
        }
        else {
            window.location.href = process.env.REACT_APP_API_URL + "/ExternalUser/signUpWithGoogle?language=" + getCurrentLanguage();
        }
    }

    return <PageSmall pageTitle={GetText("PageTitle_Create")}>
        <BookyBox>
            <CreateUserForm onRedirectToGoogle={onRedirectToGoogle} errorText={errorText} onCreateUserSubmit={onCreateUserSubmit} />
        </BookyBox>
    </PageSmall>
}

export default CreateUser;