import React from "react";
import { CalenderSource } from "../Types/CalendarEvent";

export const eventStyleGetter = (event: any) => {
    var backgroundColor = '#' + event.hexColor;
    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '0px',
        opacity: 1,
        color: 'white',
        border: '0px',
        display: 'block'
    };

    if (event.source == CalenderSource.Google) {
        style.backgroundColor = "#0F9D58    "
    }

    return {
        style: style
    };
}

export const CustomTimeSlotWrapper = (props: any) => {
    if (props.resource === undefined /* gutter */) {
        let label = props.children.props.children?.props.children as string;

        if (label && label.indexOf(":30") != -1) {
            return "";
        }

        return props.children;
    }

    const child = React.Children.only(props.children);
    return React.cloneElement(child, { className: child.props.className });
}