/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebApiIntegrationsObjectsIntegrationType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type WebApiIntegrationsObjectsIntegrationType = typeof WebApiIntegrationsObjectsIntegrationType[keyof typeof WebApiIntegrationsObjectsIntegrationType];


export function WebApiIntegrationsObjectsIntegrationTypeFromJSON(json: any): WebApiIntegrationsObjectsIntegrationType {
    return WebApiIntegrationsObjectsIntegrationTypeFromJSONTyped(json, false);
}

export function WebApiIntegrationsObjectsIntegrationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiIntegrationsObjectsIntegrationType {
    return json as WebApiIntegrationsObjectsIntegrationType;
}

export function WebApiIntegrationsObjectsIntegrationTypeToJSON(value?: WebApiIntegrationsObjectsIntegrationType | null): any {
    return value as any;
}

