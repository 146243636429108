/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesCustomerAnswerType } from './WebApiModelsEnumerationTypesCustomerAnswerType';
import {
    WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSON,
    WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSONTyped,
    WebApiModelsEnumerationTypesCustomerAnswerTypeToJSON,
} from './WebApiModelsEnumerationTypesCustomerAnswerType';

/**
 * The user role.
 * @export
 * @interface WebApiModelsReplyTimes
 */
export interface WebApiModelsReplyTimes {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiModelsReplyTimes
     */
    id?: number;
    /**
     * Gets or sets the unique id of event.
     * @type {number}
     * @memberof WebApiModelsReplyTimes
     */
    timeId?: number;
    /**
     * Gets or sets the unique id of event.
     * @type {number}
     * @memberof WebApiModelsReplyTimes
     */
    replyId?: number;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesCustomerAnswerType}
     * @memberof WebApiModelsReplyTimes
     */
    customerAnswerType?: WebApiModelsEnumerationTypesCustomerAnswerType;
    /**
     * Gets or sets the date when the role was created.
     * @type {Date}
     * @memberof WebApiModelsReplyTimes
     */
    createdAt?: Date;
    /**
     * Gets or sets the date when the role was updated.
     * @type {Date}
     * @memberof WebApiModelsReplyTimes
     */
    updatedAt?: Date | null;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsReplyTimes
     */
    createdBy?: number;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsReplyTimes
     */
    updatedBy?: number;
}

/**
 * Check if a given object implements the WebApiModelsReplyTimes interface.
 */
export function instanceOfWebApiModelsReplyTimes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiModelsReplyTimesFromJSON(json: any): WebApiModelsReplyTimes {
    return WebApiModelsReplyTimesFromJSONTyped(json, false);
}

export function WebApiModelsReplyTimesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsReplyTimes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'timeId': !exists(json, 'timeId') ? undefined : json['timeId'],
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'customerAnswerType': !exists(json, 'customerAnswerType') ? undefined : WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSON(json['customerAnswerType']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function WebApiModelsReplyTimesToJSON(value?: WebApiModelsReplyTimes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'timeId': value.timeId,
        'replyId': value.replyId,
        'customerAnswerType': WebApiModelsEnumerationTypesCustomerAnswerTypeToJSON(value.customerAnswerType),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
    };
}

