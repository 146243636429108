import { useNavigate } from "react-router-dom";
import '../Pricing/Pricing.css'
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';
import BookyBox from '../../Components/Layout/BookyBox';
import Title from "antd/lib/typography/Title";

const Privacy = () => {
  return <Page>
    <BookyBox>
      <b>Integritetspolicys: Senast uppdaterad: 30 augusti 2023</b> <br /><br />
      <Paragraph>Denna integritetspolicy beskriver OneFront360s policyer och procedurer för insamling, användning och avslöjande av din information när du använder OneFront360 och berättar om dina integritetsrättigheter och hur lagen skyddar dig.</Paragraph>
      <Paragraph>Vi använder dina personuppgifter för att tillhandahålla och förbättra Tjänsten. Genom att använda tjänsten samtycker du till insamling och användning av information i enlighet med denna integritetspolicy.</Paragraph>
      <Title level={2}>Tolkning och definitioner</Title>
      <b>Tolkning</b><br />
      <Paragraph>Orden vars initiala bokstav är versal har betydelser definierade under följande villkor. Följande definitioner ska ha samma betydelse oavsett om de förekommer i singular eller plural.</Paragraph>
      <b>Definitioner</b><br />
      För ändamålen med denna integritetspolicy:
      <ul>
        <li>Konto betyder ett unikt konto som skapats för att du ska få tillgång till vår tjänst eller delar av vår tjänst.</li>
        <li>Affiliate betyder en enhet som kontrollerar, kontrolleras av eller är under gemensam kontroll med en part, där "kontroll" betyder ägande av 50 % eller mer av aktierna, aktieägarna eller andra värdepapper som har rätt att rösta för val av styrelseledamöter eller annan förvaltningsmyndighet .</li>
        <li>Företag (referat till som antingen "Bolaget", "Vi", "Oss" eller "Vårt" i detta Avtal) hänvisar till OneFront360 AB.</li>
        <li>Cookies är små filer som placeras på din dator, mobila enhet eller någon annan enhet av en webbplats, och som innehåller information om din webbhistorik på den webbplatsen bland dess många användningsområden.</li>
        <li>Enhet avser vilken enhet som helst som kan komma åt Tjänsten såsom en dator, en mobiltelefon eller en digital surfplatta.</li>
        <li>Personuppgifter är all information som relaterar till en identifierad eller identifierbar individ.</li>
        <li>Tjänsten hänvisar till webbplatsen.</li>
        <li>Tjänsteleverantör avser varje fysisk eller juridisk person som behandlar uppgifterna för företagets räkning. Det avser tredjepartsföretag eller individer som är anställda av Bolaget för att underlätta Tjänsten, tillhandahålla Tjänsten för Bolagets räkning, utföra tjänster relaterade till Tjänsten eller hjälpa Företaget med att analysera hur Tjänsten används.</li>
        <li>Tredjeparts sociala medietjänster hänvisar till alla webb-platser eller sociala nätverkswebbplatser genom vilka en användare kan logga in eller skapa ett konto för att använda tjänsten.</li>
        <li>Användningsdata hänvisar till data som samlas in automatiskt, antingen genererad av användningen av tjänsten eller från själva tjänstens infrastruktur (till exempel varaktigheten av ett sidbesök).</li>
        <li>Webbplatsen hänvisar till OneFront360, tillgänglig från http://www.OneFront360.com</li>
        <li>Du avser individen som har tillgång till eller använder Tjänsten, eller företaget eller annan juridisk person på uppdrag av vilken en sådan individ använder eller använder Tjänsten, beroende på vad som är tillämpligt.</li>
      </ul>

      <Title level={2}>Samla in och använda dina personuppgifter</Title>
      <b>Typer av insamlade data</b><br />
      Personlig information<br />
      När vi använder vår tjänst kan vi be dig att förse oss med viss personligt identifierbar information som kan användas för att kontakta eller identifiera dig. Personligt identifierbar information kan innefatta, men är inte begränsad till:<br />
      <ul>
        <li>E-postadress</li>
        <li>Förnamn och efternamn</li>
        <li>Användningsdata</li>
      </ul>
      <Title level={2}>Användningsdata</Title>
      <Paragraph>Användningsdata samlas in automatiskt när Tjänsten används.</Paragraph>
      <Paragraph>Användningsdata kan inkludera information som din enhets Internetprotokolladress (t.ex. IP-adress), webbläsartyp, webbläsarversion, sidorna i vår tjänst som du besöker, tid och datum för ditt besök, tiden som spenderats på dessa sidor, unik enhet identifierare och andra diagnostiska data.
      </Paragraph>
      <Paragraph>När du använder tjänsten via eller via en mobil enhet kan vi samla in viss information automatiskt, inklusive, men inte begränsat till, typen av mobil enhet du använder, din mobila enhets unika ID, din mobila enhets IP-adress, din mobil operativsystem, vilken typ av mobil webbläsare du använder, unika enhetsidentifierare och annan diagnostisk data.</Paragraph>
      <Paragraph>Vi kan också samla in information som din webbläsare skickar när du besöker vår tjänst eller när du använder tjänsten via eller via en mobil enhet.</Paragraph>

      <Title level={2}>Information från tredje parts sociala medietjänster</Title>
      <Paragraph>Företaget tillåter dig att skapa ett konto och logga in för att använda tjänsten genom följande sociala medietjänster från tredje part:</Paragraph>
      <ul><li>Google</li></ul>
      <Paragraph>Om du bestämmer dig för att registrera dig via eller på annat sätt ge oss åtkomst till en social medietjänst från tredje part, kan vi samla in personuppgifter som redan är kopplade till kontot för din sociala medietjänst från tredje part, såsom ditt namn och e-postadress.
      </Paragraph>
      <Paragraph>Du kan också ha möjlighet att dela ytterligare information med företaget via din tredje parts sociala medietjänsts konto. Om du väljer att tillhandahålla sådan information och personuppgifter, under registreringen eller på annat sätt, ger du företaget tillåtelse att använda, dela och lagra den på ett sätt som överensstämmer med denna sekretesspolicy.
      </Paragraph>

      <Title level={2}>Spårningstekniker och cookies</Title>
      Vi använder cookies och liknande spårningstekniker för att spåra aktiviteten på vår tjänst och lagra viss information. Spårningstekniker som används är beacons, taggar och skript för att samla in och spåra information och för att förbättra och analysera vår tjänst. Teknikerna vi använder kan vara:
      <ul>
        <li><b>Cookies eller webbläsarcookies.</b> En cookie är en liten fil som placeras på din enhet. Du kan instruera din webbläsare att vägra alla cookies eller att indikera när en cookie skickas. Men om du inte accepterar cookies kanske du inte kan använda vissa delar av vår tjänst. Såvida du inte har justerat din webbläsarinställning så att den vägrar cookies, kan vår tjänst använda cookies.
        </li>
        <li><b>Web Beacons.</b> <br />Vissa delar av vår tjänst och våra e-postmeddelanden kan innehålla små elektroniska filer som kallas webbfyrar (även kallade klara gifs, pixeltaggar och single-pixel gifs) som tillåter företaget att till exempel räkna användare som har besökt dessa sidor eller öppnat ett e-postmeddelande och för annan relaterad webbplatsstatistik (till exempel registrerar populariteten för ett visst avsnitt och verifierar systemets och serverns integritet).
          Cookies kan vara "beständiga" eller "sessions" cookies. Beständiga cookies finns kvar på din persondator eller mobila enhet när du går offline, medan sessionscookies raderas så snart du stänger din webbläsare.
        </li>
        <li><b>Nödvändiga/Nödvändiga cookies</b><br />
          Typ: Sessionscookies<br />
          Administreras av: Oss<br />
          Syfte: Dessa cookies är viktiga för att ge dig tjänster som är tillgängliga via webbplatsen och för att du ska kunna använda några av dess funktioner. De hjälper till att autentisera användare och förhindra bedräglig användning av användarkonton. Utan dessa cookies kan de tjänster som du har bett om inte tillhandahållas, och vi använder endast dessa cookies för att tillhandahålla dig dessa tjänster.
        </li>
        <li><b>Cookiespolicy / Acceptans av meddelande Cookies</b><br />
          Typ: Beständiga cookies<br />
          Administreras av: Oss<br />
          Syfte: Dessa cookies identifierar om användare har accepterat användningen av cookies på webbplatsen.
        </li>
        <li><b>Funktionscookies</b><br />
          Typ: Beständiga cookies<br />
          Administreras av: Oss<br />
          Syfte: Dessa cookies tillåter oss att komma ihåg val du gör när du använder webbplatsen, såsom att komma ihåg dina inloggningsuppgifter eller språkinställningar. Syftet med dessa cookies är att ge dig en mer personlig upplevelse och att undvika att du behöver ange dina inställningar igen varje gång du använder webbplatsen.
          För mer information om de cookies vi använder och dina val angående cookies, vänligen besök vår Cookiespolicy eller avsnittet Cookies i vår Sekretesspolicy.
        </li>
      </ul>


      <Title level={2}> Användning av dina personuppgifter</Title>
      Företaget kan använda personuppgifter för följande ändamål:
      <ul>
        <li>För att tillhandahålla och underhålla vår tjänst, inklusive att övervaka användningen av vår tjänst.</li>
        <li>För att hantera ditt konto: för att hantera din registrering som användare av tjänsten. De personuppgifter du tillhandahåller kan ge dig tillgång till olika funktioner i Tjänsten som är tillgängliga för dig som registrerad användare.</li>
        <li>För utförande av ett kontrakt: utveckling, efterlevnad och genomförande av köpekontraktet för de produkter, föremål eller tjänster som du har köpt eller av något annat avtal med oss genom tjänsten.</li>
        <li>För att kontakta dig: För att kontakta dig via e-post, telefonsamtal, SMS eller andra likvärdiga former av elektronisk kommunikation, såsom en mobilapplikations push-meddelanden angående uppdateringar eller informativ kommunikation relaterad till funktionerna, produkterna eller avtalade tjänsterna, inklusive säkerhetsuppdateringarna, när det är nödvändigt eller rimligt för genomförandet.</li>
        <li>För att förse dig med nyheter, specialerbjudanden och allmän information om andra varor, tjänster och evenemang som vi erbjuder som liknar dem som du redan har köpt eller frågat om såvida du inte har valt att inte ta emot sådan information.</li>
        <li>För att hantera dina förfrågningar: För att delta och hantera dina förfrågningar till oss.</li>
        <li>För företagsöverlåtelser: Vi kan använda din information för att utvärdera eller genomföra en fusion, avyttring, omstrukturering, omorganisation, upplösning eller annan försäljning eller överföring av några eller alla av våra tillgångar, vare sig som ett fortsatt företag eller som en del av konkurs, likvidation, eller liknande förfarande, där personuppgifter som innehas av oss om våra tjänstanvändare är bland de tillgångar som överförs.</li>
        <li>För andra ändamål: Vi kan använda din information för andra ändamål, såsom dataanalys, identifiera användningstrender, fastställa effektiviteten av våra reklamkampanjer och för att utvärdera och förbättra vår tjänst, produkter, tjänster, marknadsföring och din upplevelse.</li>
      </ul>
      <br />
      Vi kan komma att dela din personliga information i följande situationer:
      <ul>
        <li>Med tjänsteleverantörer: Vi kan komma att dela din personliga information med tjänsteleverantörer för att övervaka och analysera användningen av vår tjänst, för att kontakta dig.</li>
        <li>För företagsöverlåtelser: Vi kan komma att dela eller överföra din personliga information i samband med, eller under förhandlingar om, eventuell fusion, försäljning av företagets tillgångar, finansiering eller förvärv av hela eller delar av vår verksamhet till ett annat företag.</li>
        <li>Med affiliates: Vi kan komma att dela din information med våra affiliates, i vilket fall vi kommer att kräva att dessa affiliates respekterar denna integritetspolicy. Affiliates inkluderar vårt moderbolag och alla andra dotterbolag, joint venture-partners eller andra företag som vi kontrollerar eller som står under gemensam kontroll med oss.</li>
        <li>Med affärspartners: Vi kan komma att dela din information med våra affärspartners för att erbjuda dig vissa produkter, tjänster eller kampanjer.</li>
      </ul>
      <Title level={2}>Lagring av dina personuppgifter</Title>
      <Paragraph>Företaget kommer endast att behålla dina personuppgifter så länge som är nödvändigt för de syften som anges i denna integritetspolicy. Vi kommer att behålla och använda dina personuppgifter i den utsträckning det är nödvändigt för att uppfylla våra juridiska skyldigheter (till exempel om vi är skyldiga att behålla dina uppgifter för att följa tillämpliga lagar), lösa tvister och upprätthålla våra juridiska avtal och policyer.
      </Paragraph>
      <Paragraph>Företaget kommer också att behålla användningsdata för interna analysändamål. Användningsdata bevaras i allmänhet under en kortare tid, förutom när dessa data används för att stärka säkerheten eller för att förbättra funktionaliteten hos vår tjänst, eller när vi är juridiskt skyldiga att behålla dessa uppgifter under längre tidsperioder. Vi följer GDPR när så krävs, se mer information på webbsidan.
      </Paragraph>
      <Title level={2}>Överföring av dina personuppgifter</Title>
      <Paragraph>Dina uppgifter, inklusive Personuppgifter, behandlas på Bolagets verksamhetskontor och på alla andra platser där de parter som är inblandade i behandlingen finns. Det betyder att denna information kan överföras till – och underhållas på – datorer utanför din stat, provins, land eller annan statlig jurisdiktion där dataskyddslagarna kan skilja sig från de från din jurisdiktion.
      </Paragraph>
      <Paragraph>Ditt samtycke till denna integritetspolicy följt av ditt inlämnande av sådan information representerar ditt samtycke till den överföringen.
      </Paragraph>
      <Paragraph>Företaget kommer att vidta alla åtgärder som rimligen är nödvändiga för att säkerställa att dina uppgifter behandlas säkert och i enlighet med denna sekretesspolicy och ingen överföring av dina personuppgifter kommer att ske till en organisation eller ett land om det inte finns tillräckliga kontroller på plats, inklusive säkerheten för Dina uppgifter och annan personlig information.
      </Paragraph>
      <Title level={2}> Radera dina personuppgifter</Title>
      Du har rätt att radera eller begära att vi hjälper till med att radera de personuppgifter som vi har samlat in om dig.
      Observera dock att vi kan behöva behålla viss information när vi har en juridisk skyldighet eller laglig grund att göra det.


      <Title level={2}>Delning av dina personuppgifter</Title>
      <b>Affärstransaktion</b><br />
      <Paragraph>Om företaget är involverat i en fusion, förvärv eller försäljning av tillgångar kan dina personuppgifter överföras. Vi kommer att meddela innan dina personuppgifter överförs och blir föremål för en annan integritetspolicy.</Paragraph>
      <b>Rättsväsende</b><br />
      <Paragraph>Under vissa omständigheter kan företaget behöva lämna ut dina personuppgifter om det krävs enligt lag eller som svar på giltiga förfrågningar från offentliga myndigheter (t.ex. en domstol eller en statlig myndighet).</Paragraph>
      <b>Lagkrav</b><br />
      <Paragraph>Företaget kan dela dina personuppgifter i god tro att en sådan åtgärd är nödvändig för att:</Paragraph>
      <ul>
        <li>Utföra en laglig skyldighet</li>
        <li>Skydda och försvara företagets rättigheter eller egendom</li>
        <li>Förhindra eller undersöka eventuella fel i samband med tjänsten</li>
        <li>Skydda den personliga säkerheten för användare av tjänsten eller allmänheten</li>
        <li>Skydda mot juridiskt ansvar</li>
      </ul>
      <Title level={2}>Säkerhet för dina personuppgifter</Title>
      <Paragraph>Säkerheten för Dina Personuppgifter är viktig för oss, men kom ihåg att ingen metod för överföring över Internet eller metod för elektronisk lagring är 100 % säker. Även om vi strävar efter att använda kommersiellt acceptabla metoder för att skydda dina personuppgifter, kan vi inte garantera dess absoluta säkerhet.
      </Paragraph>

      <b>Länkar till andra webbplatser</b><br />
      <Paragraph>Vår tjänst kan innehålla länkar till andra webbplatser som inte drivs av oss. Om du klickar på en tredje parts länk kommer du att dirigeras till den tredje parts webbplats. Vi rekommenderar dig starkt att läsa sekretesspolicyn för varje webbplats du besöker.
      </Paragraph>
      <Paragraph>Vi har ingen kontroll över och tar inget ansvar för innehållet, integritetspolicyn eller praxis på tredje parts webbplatser eller tjänster.
      </Paragraph>
      <Title level={2}>Ändringar av denna integritetspolicy</Title>
      <Paragraph>
        <Paragraph> Vi kan uppdatera vår integritetspolicy när så är erforderligt. Vi kommer att meddela dig om eventuella ändringar genom att publicera den nya integritetspolicyn på webbplatsen.</Paragraph>
        <Paragraph> Vi kommer att meddela dig via e-post och/eller ett framträdande meddelande om vår tjänst innan ändringen träder i kraft och uppdaterar datumet "Senast uppdaterad" högst upp i denna sekretesspolicy.</Paragraph>
        <Paragraph> Du rekommenderas att granska denna integritetspolicy med jämna mellanrum för eventuella ändringar. Ändringar av denna integritetspolicy träder i kraft när de publiceras på denna sida.</Paragraph>
      </Paragraph><br />
      <Title level={2}>Kontakta oss</Title>
      Om du har några frågor om denna integritetspolicy kan du kontakta oss:<br />
      Via e-post: support@OneFront360.com


    </BookyBox>
  </Page>
};

export default Privacy;