import { AdminApi, ApiAdminSetUserAsAdminPostRequest } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class AdminService {
    public static GetAllUsers = async () => {
        return new AdminApi(GetApiConfiguration()).apiAdminGetAllUsersGet();
    };

    public static SetUserAsAdmin = async (userId: number) => {
        const body: ApiAdminSetUserAsAdminPostRequest = { body: userId };
        return new AdminApi(GetApiConfiguration()).apiAdminSetUserAsAdminPost(body);
    };

    public static DeleteAllCompanyMembers = async (companyId: number) => {
        return new AdminApi(GetApiConfiguration()).apiAdminDeleteAllMembersDelete({ companyId: companyId });
    };
};