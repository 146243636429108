import { useNavigate } from "react-router-dom";

const BookyBox = (props: any) => {
    const className = props.alignLeft ? "bookyBox bookyboxleft" : "bookyBox bookyboxcenter"

    return <div className={className}>
        {props.children}
    </div>
};

export default BookyBox;