
export abstract class GoogleTokenProvider {
    public static GetToken = async (callback: any, scope: string) => {
        const client = window.google.accounts.oauth2.initTokenClient({
            client_id:
                '804381919949-i60i449se49it5kii8punl3500lt9k5u.apps.googleusercontent.com',
            scope: scope,
            callback: async (response: any) => {
                callback(response);
            },
        });

        client.requestAccessToken();
    };
};

export enum GoogleAuthenticationScope {
    CalendarReadOnly = "https://www.googleapis.com/auth/calendar.readonly",
    ContactsReadOnly = "https://www.googleapis.com/auth/contacts.other.readonly"
}