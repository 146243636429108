export enum EmailEventType {
    Bounces = "Bounces",
    HardBounces = "HardBounces",
    SoftBounces = "SoftBounces",
    Delivered = "Delivered",
    Spam = "Spam",
    Requests = "Requests",
    Opened = "Opened",
    Clicks = "Clicks",
    Invalid = "Invalid",
    Deferred = "Deferred",
    Blocked = "Blocked",
    Unsubscribed = "Unsubscribed",
    Error = "Error",
    LoadedByProxy = "LoadedByProxy",
}