import { Result } from "antd";
import '../../Pages/Pricing/Pricing.css'
import { TextService } from "../../Services/TextService";

const NotFoundPage = (props: any) => {
    const GetText = TextService.GetText;
    return <Result
        status="404"
        title={props.title ?? GetText("Common_PageNotFoundTitle")}
        subTitle={props.message}
    />
};

export default NotFoundPage;