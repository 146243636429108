import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { CalenderSource } from '../../Types/CalendarEvent';
import moment from 'moment'
import 'moment-timezone'
import { CustomTimeSlotWrapper, eventStyleGetter } from '../../Helpers/CalendarHelper';
import { CloseOutlined } from '@ant-design/icons';
import { getTimeRangeString, getTimeStringFromDate } from '../../Helpers/DateHelpers';
import { TextService } from '../../Services/TextService';
import 'moment/locale/sv'
import CustomToolbar from './ToolBar';

export const BookyWeekCalendar = (props: any) => {
    const allViews: View[] = ['week'];
    const DnDCalendar = withDragAndDrop(Calendar);
    const localizer = momentLocalizer(moment);
    const show24hrsFormat = props.show24hrsFormat;
    const GetText = TextService.GetText;

    const CustomEvent = (props: any) => {
        if (props.isAllDay) {
            return <div className="TimeNewBooking AllDay">{GetText("WeekCalendar_AllDay")}
                <div className='TimeDelete'
                    onClick={() => deleteEvent(props.event.id)}><CloseOutlined /></div>
            </div>
        }

        if (props.event.source === CalenderSource.Google) {
            return (<div className="TimeNewBooking">
                {props.title}
            </div>)
        }

        return (<div className="TimeNewBooking">
            {getTimeRangeString(props.event.start, props.event.end, show24hrsFormat)}

            <div className='TimeDelete'
                onClick={() => deleteEvent(props.event.id)}><CloseOutlined /></div>
        </div>)
    }

    const deleteEvent = (id: any) => {
        props.DeleteEvent(id);
    }

    let formats = {
        dateFormat: 'dd',
        timeGutterFormat: props.show24hrsFormat ? 'HH:mm' : "h:mm A",
        dayFormat: (date: any, culture: any, localizer: any) =>
            localizer.format(date, 'dd DD/M', culture),
    }

    return (
        <DnDCalendar
            localizer={localizer}
            formats={formats}
            date={props.startDate}
            messages={{ next: "Nästa", previous: "Föregående", today: "Idag" }}
            events={props.events}
            views={allViews}
            data-testid="create-calendar"
            defaultView={'week'}
            timeslots={2}
            min={new Date(0, 0, 0, 6, 0, 0)}
            max={new Date(0, 0, 0, 23, 0, 0)}
            step={30}
            selectable={true}
            onNavigate={props.onNavigate}
            onSelectSlot={props.CreateEventItem}
            onEventResize={props.UpdateEvent}
            draggableAccessor={(event: any) => event.source === CalenderSource.EventBooky ? true : false}
            onEventDrop={props.UpdateEvent}
            dayLayoutAlgorithm="no-overlap"
            eventPropGetter={(eventStyleGetter)}
            components={{ toolbar: (props) => <CustomToolbar {...props} />, event: (props) => <CustomEvent {...props} />, timeSlotWrapper: (props) => <CustomTimeSlotWrapper {...props} /> }}
        />)
};