import { Space } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";

export const BookyPageTitle = (props: any) => {
    return (
        <>
            <Space style={{ width: '100%', justifyContent: 'center', margin: 0 }}>
                <Title style={{ margin: 0 }}>{props.text}</Title>
            </Space>

            {props.subText ? <Space style={{ width: '100%', justifyContent: 'center', margin: 0 }}>
                <Paragraph>{props.subText}</Paragraph>
            </Space> : ""}
        </>
    );

}