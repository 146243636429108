/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    postalArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto
     */
    uniqueId?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceGroupMemberDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto {
    return WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'postalArea': !exists(json, 'postalArea') ? undefined : json['postalArea'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberId': value.memberId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'address': value.address,
        'postalCode': value.postalCode,
        'postalArea': value.postalArea,
        'country': value.country,
        'phoneNumber': value.phoneNumber,
        'company': value.company,
        'uniqueId': value.uniqueId,
    };
}

