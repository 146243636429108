import { message, Space, } from "antd"
import { useEffect, useState } from "react"
import { EventService } from "../../Services/EventService"
import { BookyButton } from "../Buttons/BookyButton"
import ColorPicker from "../Color/ColorPicker"
import { TextService } from "../../Services/TextService"

export const EventBranding = (props: any) => {
    const [bannerImageColorCode, setBannerImageColorCode] = useState("#000000" as string);
    const [primaryColorCode, setPrimaryColorCode] = useState("#234e70" as string);
    const [secondaryColorCode, setSecondaryColorCode] = useState("#ffffff" as string);
    const GetText = TextService.GetText;

    const saveEventColors = async () => {
        await EventService.UpdateBranding({
            webApiServicesDataTransferObjectsUserServiceUpdateBrandingDto: {
                id: props.event.eventId,
                primaryColorCode: primaryColorCode,
                secondaryColorCode: secondaryColorCode,
                bannerImageColorCode: bannerImageColorCode
            }
        });

        message.success(GetText("EventBranding_SuccessSaved"));
    }

    const resetEventColors = () => {
        setPrimaryColorCode("#ffffff");
        setSecondaryColorCode("#234e70");
        setBannerImageColorCode("#ffffff");
    }

    useEffect(() => {
        if (props.event.primaryColorCode != null) {
            setPrimaryColorCode(props.event.primaryColorCode);
            setSecondaryColorCode(props.event.secondaryColorCode);
            setBannerImageColorCode(props.event.bannerImageColorCode);
        }
    }, [props.event]);


    return <>
        {GetText("EventBranding_ChangeColorEventName")}<br></br>
        <ColorPicker color={bannerImageColorCode} onChangeComplete={(hex: string) => { setBannerImageColorCode(hex) }} /><br></br>

        {GetText("EventBranding_ChangeColorEventPrimaryColor")}<br></br>
        <ColorPicker color={primaryColorCode} onChangeComplete={(hex: string) => { setPrimaryColorCode(hex) }} /><br></br>

        {GetText("EventBranding_ChangeColorEventSecondaryColor")}<br></br>
        <ColorPicker color={secondaryColorCode} onChangeComplete={(hex: string) => { setSecondaryColorCode(hex) }} /><br></br>

        <Space>
            <BookyButton onClick={saveEventColors} text={GetText("EventBranding_SaveColors")} />
            <BookyButton onClick={resetEventColors} text={GetText("EventBranding_ResetColors")} type="default" />
        </Space>
    </>
}