/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Dto for creating invite
 * @export
 * @interface WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto
 */
export interface WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto
     */
    message?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto
     */
    emails?: Array<string> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsInviteServiceCreateReminderDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateReminderDtoFromJSON(json: any): WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto {
    return WebApiServicesDataTransferObjectsInviteServiceCreateReminderDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateReminderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
    };
}

export function WebApiServicesDataTransferObjectsInviteServiceCreateReminderDtoToJSON(value?: WebApiServicesDataTransferObjectsInviteServiceCreateReminderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'message': value.message,
        'emails': value.emails,
    };
}

