/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebApiModelsEnumerationTypesMemberStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type WebApiModelsEnumerationTypesMemberStatus = typeof WebApiModelsEnumerationTypesMemberStatus[keyof typeof WebApiModelsEnumerationTypesMemberStatus];


export function WebApiModelsEnumerationTypesMemberStatusFromJSON(json: any): WebApiModelsEnumerationTypesMemberStatus {
    return WebApiModelsEnumerationTypesMemberStatusFromJSONTyped(json, false);
}

export function WebApiModelsEnumerationTypesMemberStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsEnumerationTypesMemberStatus {
    return json as WebApiModelsEnumerationTypesMemberStatus;
}

export function WebApiModelsEnumerationTypesMemberStatusToJSON(value?: WebApiModelsEnumerationTypesMemberStatus | null): any {
    return value as any;
}

