/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebApiModelsEnumerationTypesAnswerOptions = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type WebApiModelsEnumerationTypesAnswerOptions = typeof WebApiModelsEnumerationTypesAnswerOptions[keyof typeof WebApiModelsEnumerationTypesAnswerOptions];


export function WebApiModelsEnumerationTypesAnswerOptionsFromJSON(json: any): WebApiModelsEnumerationTypesAnswerOptions {
    return WebApiModelsEnumerationTypesAnswerOptionsFromJSONTyped(json, false);
}

export function WebApiModelsEnumerationTypesAnswerOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsEnumerationTypesAnswerOptions {
    return json as WebApiModelsEnumerationTypesAnswerOptions;
}

export function WebApiModelsEnumerationTypesAnswerOptionsToJSON(value?: WebApiModelsEnumerationTypesAnswerOptions | null): any {
    return value as any;
}

