/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesDisplayType } from './WebApiModelsEnumerationTypesDisplayType';
import {
    WebApiModelsEnumerationTypesDisplayTypeFromJSON,
    WebApiModelsEnumerationTypesDisplayTypeFromJSONTyped,
    WebApiModelsEnumerationTypesDisplayTypeToJSON,
} from './WebApiModelsEnumerationTypesDisplayType';
import type { WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto } from './WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto';
import {
    WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoToJSON,
} from './WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceUpdateEventDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    maxParticipants?: number;
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    deadline?: Date | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesDisplayType}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    displayType?: WebApiModelsEnumerationTypesDisplayType;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    timeZoneId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    location?: string | null;
    /**
     * 
     * @type {Array<WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto>}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    times?: Array<WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventDto
     */
    sendAutomaticReminder?: boolean;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceUpdateEventDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceUpdateEventDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateEventDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceUpdateEventDto {
    return WebApiServicesDataTransferObjectsUserServiceUpdateEventDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceUpdateEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'maxParticipants': !exists(json, 'maxParticipants') ? undefined : json['maxParticipants'],
        'deadline': !exists(json, 'deadline') ? undefined : (json['deadline'] === null ? null : new Date(json['deadline'])),
        'displayType': !exists(json, 'displayType') ? undefined : WebApiModelsEnumerationTypesDisplayTypeFromJSON(json['displayType']),
        'timeZoneId': !exists(json, 'timeZoneId') ? undefined : json['timeZoneId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'times': !exists(json, 'times') ? undefined : (json['times'] === null ? null : (json['times'] as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoFromJSON)),
        'sendAutomaticReminder': !exists(json, 'sendAutomaticReminder') ? undefined : json['sendAutomaticReminder'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateEventDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceUpdateEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'maxParticipants': value.maxParticipants,
        'deadline': value.deadline === undefined ? undefined : (value.deadline === null ? null : value.deadline.toISOString()),
        'displayType': WebApiModelsEnumerationTypesDisplayTypeToJSON(value.displayType),
        'timeZoneId': value.timeZoneId,
        'description': value.description,
        'location': value.location,
        'times': value.times === undefined ? undefined : (value.times === null ? null : (value.times as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceCreateEventTimeDtoToJSON)),
        'sendAutomaticReminder': value.sendAutomaticReminder,
    };
}

