/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiMemberDtoAddMemberFromPublicSiteDto
 */
export interface WebApiMemberDtoAddMemberFromPublicSiteDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    companyUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    email2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    streetAdress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    postalArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    extra1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    extra2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoAddMemberFromPublicSiteDto
     */
    extra3?: string | null;
}

/**
 * Check if a given object implements the WebApiMemberDtoAddMemberFromPublicSiteDto interface.
 */
export function instanceOfWebApiMemberDtoAddMemberFromPublicSiteDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoAddMemberFromPublicSiteDtoFromJSON(json: any): WebApiMemberDtoAddMemberFromPublicSiteDto {
    return WebApiMemberDtoAddMemberFromPublicSiteDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoAddMemberFromPublicSiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoAddMemberFromPublicSiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyUrl': !exists(json, 'companyUrl') ? undefined : json['companyUrl'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'streetAdress': !exists(json, 'streetAdress') ? undefined : json['streetAdress'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'postalArea': !exists(json, 'postalArea') ? undefined : json['postalArea'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'extra1': !exists(json, 'extra1') ? undefined : json['extra1'],
        'extra2': !exists(json, 'extra2') ? undefined : json['extra2'],
        'extra3': !exists(json, 'extra3') ? undefined : json['extra3'],
    };
}

export function WebApiMemberDtoAddMemberFromPublicSiteDtoToJSON(value?: WebApiMemberDtoAddMemberFromPublicSiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyUrl': value.companyUrl,
        'email': value.email,
        'email2': value.email2,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phone': value.phone,
        'streetAdress': value.streetAdress,
        'postalCode': value.postalCode,
        'postalArea': value.postalArea,
        'company': value.company,
        'country': value.country,
        'title': value.title,
        'extra1': value.extra1,
        'extra2': value.extra2,
        'extra3': value.extra3,
    };
}

