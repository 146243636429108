/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiEmailDtoBrevoCode
 */
export interface WebApiEmailDtoBrevoCode {
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoBrevoCode
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoBrevoCode
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiEmailDtoBrevoCode
     */
    hostName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiEmailDtoBrevoCode
     */
    status?: boolean;
}

/**
 * Check if a given object implements the WebApiEmailDtoBrevoCode interface.
 */
export function instanceOfWebApiEmailDtoBrevoCode(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiEmailDtoBrevoCodeFromJSON(json: any): WebApiEmailDtoBrevoCode {
    return WebApiEmailDtoBrevoCodeFromJSONTyped(json, false);
}

export function WebApiEmailDtoBrevoCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiEmailDtoBrevoCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'hostName': !exists(json, 'host_name') ? undefined : json['host_name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function WebApiEmailDtoBrevoCodeToJSON(value?: WebApiEmailDtoBrevoCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'value': value.value,
        'host_name': value.hostName,
        'status': value.status,
    };
}

