import { Alert, Button, Divider, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCreateUser } from '../../Hooks/useCreateUser';
import Icon from '@ant-design/icons';
import { GoogleIcon } from '../../Components/Buttons/GoogleButton';
import PageSmall from '../../Components/Page/PageSmall';
import BookyBox from '../../Components/Layout/BookyBox';
import { BookyInput, InputType } from '../../Components/Forms/BookyInput';
import { BookyPageTitle } from '../../Components/Layout/BookyTitle';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';

const CreateUserForm = (props: any) => {
    const navigation = useNavigate();
    const GetText = TextService.GetText;

    return <Form
        data-testid="create-form"
        onFinish={props.onCreateUserSubmit}
        wrapperCol={{
            span: 24,
        }}
        layout="vertical"
        autoComplete="off"
    >
        <BookyPageTitle text={GetText("CreateUser_Title")} subText={GetText("CreateUser_TitleDescription")} />


        {props.finishSetupText ? <Alert message={props.finishSetupText} style={{ marginTop: "10px", marginBottom: "10px" }} type="success" showIcon /> : ""}

        <BookyInput label={GetText("CreateUser_FirstName")} name="firstname" requiredMessage={GetText("CreateUser_FirstName_Validation")}></BookyInput>
        <BookyInput label={GetText("CreateUser_LastName")} name="lastname" requiredMessage={GetText("CreateUser_LastName_Validation")}></BookyInput>
        {props.hideEmailInput ? "" : <BookyInput label={GetText("CreateUser_Email")} type={InputType.email} name="email" requiredMessage={GetText("CreateUser_Email_Validation")} />}
        <BookyInput label={GetText("CreateUser_Password")} type={InputType.password} name="password" placeholder="" requiredMessage={GetText("CreateUser_Password_Validation")} />
        <Form.Item
            label=" "
            name="submit"
            rules={[
                {
                    required: false,
                    message: 'submitbutton',
                },
            ]}
        >
            <Button data-testid="submit-button" style={{ float: 'left', width: "100%" }} size="middle" type="primary" htmlType="submit">
                {GetText("CreateUser_Create")}
            </Button>
        </Form.Item>

        {props.errorText ? <Form.Item
            label=" ">
            <Form.Item>
                {props.errorText ? <Alert showIcon={true} type="warning" message={props.errorText} action={
                    <Button
                        onClick={() => navigation("/" + getCurrentLanguage() + "/Login")} size="small" type="primary">
                        {GetText("CreateUser_Login")}
                    </Button>} /> : ""}
            </Form.Item>
        </Form.Item> : ""}


        {props.hideGoogleOption ? "" : <><Form.Item
            label=" ">
            <Divider>{GetText("Login_DividerOr")}</Divider><br></br>
            <Button size="large" style={{ width: "100%" }} onClick={props.onRedirectToGoogle}>
                <Icon component={GoogleIcon} />
                {GetText("CreateUser_SignUpWithGoogle")}</Button>
        </Form.Item>

            <Form.Item
                label=" ">
                <b>{GetText("CreateUser_AlreadySignedUp")} <u><a href="/Login">{GetText("CreateUser_Login")}</a></u></b>
            </Form.Item></>}
    </Form>
}

export default CreateUserForm;