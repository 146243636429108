import { getCurrentLanguage } from "../Helpers/RouteHelper";
import { UserService } from "../Services/UserService";
import { createUserRequest } from "../Types/RequestResponse/createUserRequest";

export const useCreateUser = () => {
  const CreateUser = async (formValues: any, selectedSubscription: string) => {
    const createUserRequest: createUserRequest = {
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      email: formValues.email,
      password: formValues.password,
      subscription: selectedSubscription,
      language: getCurrentLanguage()
    }

    return await UserService.CreateUser(createUserRequest);
  };

  return {
    CreateUser
  };
};