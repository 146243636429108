/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment } from './WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment';
import {
    WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentFromJSONTyped,
    WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentToJSON,
} from './WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData
 */
export interface WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData
     */
    year?: number;
    /**
     * 
     * @type {Array<WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment>}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData
     */
    payments?: Array<WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentDataFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData {
    return WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentDataFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : json['year'],
        'payments': !exists(json, 'payments') ? undefined : (json['payments'] === null ? null : (json['payments'] as Array<any>).map(WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentFromJSON)),
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentDataToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'payments': value.payments === undefined ? undefined : (value.payments === null ? null : (value.payments as Array<any>).map(WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentToJSON)),
    };
}

