export const FreeFeatureList_SE = [
    "0-25 medlemmar",
    "1 administratör",
    "Möten/evenemang, max 25 deltagare",
]

export const BasicFeatureList_SE = [
    "2 administratörer",
    "Möten/evenemang, max 1000 deltagare",
    "E-postutskick inkl. logg",
    "E-post mallar",
    "Integration med webbsida",
    "Medlemsbetalning",
    "Export av medlemsdata",
]

export const PremiumFeatureList_SE = [
    "0-2500 medlemmar",
    "3 administratörer",
    "Möten/evenemang, max 1000 deltagare",
    "E-postutskick inkl. logg",
    "E-post mallar",
    "Integration med webbsida",
    "Medlemsbetalning",
    "Export av medlemsdata",
]

export const EnterpriseFeatureList_SE = [
    "0-10 000 medlemmar",
    "10 administratörer",
    "Möten/evenemang, max 1000 deltagare",
    "E-postutskick inkl. logg",
    "E-post mallar",
    "Integration med webbsida",
    "Medlemsbetalning",
    "Export av medlemsdata",
]