import 'react-big-calendar/lib/css/react-big-calendar.css';

import moment from 'moment';
import { Navigate, ToolbarProps } from 'react-big-calendar';
import { BookyButton } from '../Buttons/BookyButton';
import { DatePicker, DatePickerProps } from 'antd';
import { TextService } from '../../Services/TextService';
import { CalendarOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
const GetText = TextService.GetText;
const CustomToolbar = (props: ToolbarProps) => {
    const [showWeekCalendarPicker, setShowWeekCalendarPicker] = useState(false as boolean);

    const goToBack = () => {
        props.onNavigate(Navigate.PREVIOUS);
    };

    const goToNext = () => {
        props.onNavigate(Navigate.NEXT);
    };

    const goToToday = () => {
        props.onNavigate(Navigate.TODAY);
    };
    const goToSpecificDate: DatePickerProps['onChange'] = (date) => {
        if (date != null) {
            props.onNavigate(Navigate.DATE, date.toDate());
        }
    }

    return (
        <div>
            <div style={{ marginBottom: "10px" }}>
                <BookyButton style={{ width: "75px", marginRight: "10px" }} type={"default"} text={GetText("Toolbar_Today")} onClick={goToToday} />
                <BookyButton style={{ width: "50px", marginRight: "10px" }} type={"default"} icon={<LeftOutlined />} onClick={goToBack} />
                <BookyButton style={{ width: "50px", marginRight: "10px" }} type={"default"} onClick={(e: any) => setShowWeekCalendarPicker(true)} icon={<CalendarOutlined />} />
                <BookyButton style={{ width: "50px", marginRight: "10px" }} type={"default"} icon={<RightOutlined />} onClick={goToNext} />
                <label><b>V.{moment(props.date).format("W MMMM YYYY ")}</b></label>
            </div>
            <div>
                <DatePicker open={showWeekCalendarPicker} style={{ visibility: "hidden", float: "left", marginLeft: "100px", height: "10px" }} format={"w"} value={dayjs(props.date)} placeholder={GetText("Toolbar_ChooseAnotherWeek")} picker="week" onChange={goToSpecificDate} />
            </div>
        </div>
    );
};

export default CustomToolbar;