/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.RegisterAsync(WebApi.Services.DataTransferObjects.UserService.RegisterReqDto) request.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceRegisterReqDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceRegisterReqDto {
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceRegisterReqDto
     */
    firstName?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceRegisterReqDto
     */
    lastName?: string | null;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceRegisterReqDto
     */
    email?: string | null;
    /**
     * Gets or sets the password.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceRegisterReqDto
     */
    password?: string | null;
    /**
     * Gets or sets the password.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceRegisterReqDto
     */
    subscription?: string | null;
    /**
     * Gets or sets the password.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceRegisterReqDto
     */
    language?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceRegisterReqDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceRegisterReqDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceRegisterReqDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceRegisterReqDto {
    return WebApiServicesDataTransferObjectsUserServiceRegisterReqDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceRegisterReqDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceRegisterReqDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'subscription': !exists(json, 'subscription') ? undefined : json['subscription'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceRegisterReqDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceRegisterReqDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'password': value.password,
        'subscription': value.subscription,
        'language': value.language,
    };
}

