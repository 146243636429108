/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsReplyTimes } from './WebApiModelsReplyTimes';
import {
    WebApiModelsReplyTimesFromJSON,
    WebApiModelsReplyTimesFromJSONTyped,
    WebApiModelsReplyTimesToJSON,
} from './WebApiModelsReplyTimes';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto
 */
export interface WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto
     */
    id?: number;
    /**
     * Gets or sets the name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto
     */
    customerName?: string | null;
    /**
     * Gets or sets the name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto
     */
    customerEmail?: string | null;
    /**
     * Gets or sets the description.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto
     */
    customerAnswer?: string | null;
    /**
     * Gets or sets the description.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto
     */
    authCode?: string | null;
    /**
     * 
     * @type {Array<WebApiModelsReplyTimes>}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto
     */
    replyTime?: Array<WebApiModelsReplyTimes> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto {
    return WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerEmail': !exists(json, 'customerEmail') ? undefined : json['customerEmail'],
        'customerAnswer': !exists(json, 'customerAnswer') ? undefined : json['customerAnswer'],
        'authCode': !exists(json, 'authCode') ? undefined : json['authCode'],
        'replyTime': !exists(json, 'replyTime') ? undefined : (json['replyTime'] === null ? null : (json['replyTime'] as Array<any>).map(WebApiModelsReplyTimesFromJSON)),
    };
}

export function WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDtoToJSON(value?: WebApiServicesDataTransferObjectsReplyServiceGetAnswerResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerName': value.customerName,
        'customerEmail': value.customerEmail,
        'customerAnswer': value.customerAnswer,
        'authCode': value.authCode,
        'replyTime': value.replyTime === undefined ? undefined : (value.replyTime === null ? null : (value.replyTime as Array<any>).map(WebApiModelsReplyTimesToJSON)),
    };
}

