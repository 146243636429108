/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiIntegrationsObjectsIntegrationType } from './WebApiIntegrationsObjectsIntegrationType';
import {
    WebApiIntegrationsObjectsIntegrationTypeFromJSON,
    WebApiIntegrationsObjectsIntegrationTypeFromJSONTyped,
    WebApiIntegrationsObjectsIntegrationTypeToJSON,
} from './WebApiIntegrationsObjectsIntegrationType';

/**
 * 
 * @export
 * @interface WebApiModelsExternalPayment
 */
export interface WebApiModelsExternalPayment {
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsExternalPayment
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsExternalPayment
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsExternalPayment
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsExternalPayment
     */
    externalId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsExternalPayment
     */
    integrationId?: number;
    /**
     * 
     * @type {WebApiIntegrationsObjectsIntegrationType}
     * @memberof WebApiModelsExternalPayment
     */
    integrationType?: WebApiIntegrationsObjectsIntegrationType;
    /**
     * 
     * @type {Date}
     * @memberof WebApiModelsExternalPayment
     */
    invoiceDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WebApiModelsExternalPayment
     */
    payDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WebApiModelsExternalPayment
     */
    dueDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsExternalPayment
     */
    balanceAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiModelsExternalPayment
     */
    totalAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsExternalPayment
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsExternalPayment
     */
    customerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsExternalPayment
     */
    externalReference1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiModelsExternalPayment
     */
    externalReference2?: string | null;
    /**
     * Gets or sets the date when the role was created.
     * @type {Date}
     * @memberof WebApiModelsExternalPayment
     */
    createdAt?: Date;
    /**
     * Gets or sets the date when the role was updated.
     * @type {Date}
     * @memberof WebApiModelsExternalPayment
     */
    updatedAt?: Date | null;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsExternalPayment
     */
    createdBy?: number;
    /**
     * Gets or sets the description.
     * @type {number}
     * @memberof WebApiModelsExternalPayment
     */
    updatedBy?: number | null;
}

/**
 * Check if a given object implements the WebApiModelsExternalPayment interface.
 */
export function instanceOfWebApiModelsExternalPayment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiModelsExternalPaymentFromJSON(json: any): WebApiModelsExternalPayment {
    return WebApiModelsExternalPaymentFromJSONTyped(json, false);
}

export function WebApiModelsExternalPaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsExternalPayment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'integrationId': !exists(json, 'integrationId') ? undefined : json['integrationId'],
        'integrationType': !exists(json, 'integrationType') ? undefined : WebApiIntegrationsObjectsIntegrationTypeFromJSON(json['integrationType']),
        'invoiceDate': !exists(json, 'invoiceDate') ? undefined : (json['invoiceDate'] === null ? null : new Date(json['invoiceDate'])),
        'payDate': !exists(json, 'payDate') ? undefined : (json['payDate'] === null ? null : new Date(json['payDate'])),
        'dueDate': !exists(json, 'dueDate') ? undefined : (json['dueDate'] === null ? null : new Date(json['dueDate'])),
        'balanceAmount': !exists(json, 'balanceAmount') ? undefined : json['balanceAmount'],
        'totalAmount': !exists(json, 'totalAmount') ? undefined : json['totalAmount'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'externalReference1': !exists(json, 'externalReference1') ? undefined : json['externalReference1'],
        'externalReference2': !exists(json, 'externalReference2') ? undefined : json['externalReference2'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function WebApiModelsExternalPaymentToJSON(value?: WebApiModelsExternalPayment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'memberId': value.memberId,
        'companyId': value.companyId,
        'externalId': value.externalId,
        'integrationId': value.integrationId,
        'integrationType': WebApiIntegrationsObjectsIntegrationTypeToJSON(value.integrationType),
        'invoiceDate': value.invoiceDate === undefined ? undefined : (value.invoiceDate === null ? null : value.invoiceDate.toISOString()),
        'payDate': value.payDate === undefined ? undefined : (value.payDate === null ? null : value.payDate.toISOString()),
        'dueDate': value.dueDate === undefined ? undefined : (value.dueDate === null ? null : value.dueDate.toISOString()),
        'balanceAmount': value.balanceAmount,
        'totalAmount': value.totalAmount,
        'customerName': value.customerName,
        'customerId': value.customerId,
        'externalReference1': value.externalReference1,
        'externalReference2': value.externalReference2,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
    };
}

