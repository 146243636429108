import {
    isValidPhoneNumber,
} from 'libphonenumber-js'
import { getCurrentLanguage } from './RouteHelper';

export const validateEmail = (email: string) => {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result: boolean = expression.test(email); // true

    return result;
}

export const isNumeric = (value: string): boolean => {
    return !new RegExp(/[^\d]/g).test(value.trim());
}

export const isContainingOnlyAlphaNumeric = (input: string): boolean => {
    const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
    return alphaNumericRegex.test(input);
};

export const validatePhoneNumber = (value: string): boolean => {
    return isValidPhoneNumber(value, "SE") === true
}