/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesEmailNotificationPreference } from './WebApiModelsEnumerationTypesEmailNotificationPreference';
import {
    WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSON,
    WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSONTyped,
    WebApiModelsEnumerationTypesEmailNotificationPreferenceToJSON,
} from './WebApiModelsEnumerationTypesEmailNotificationPreference';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    email2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    postalArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    extra1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    extra2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    extra3?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesEmailNotificationPreference}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto
     */
    emailNotificationPreference?: WebApiModelsEnumerationTypesEmailNotificationPreference;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto {
    return WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'postalArea': !exists(json, 'postalArea') ? undefined : json['postalArea'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'extra1': !exists(json, 'extra1') ? undefined : json['extra1'],
        'extra2': !exists(json, 'extra2') ? undefined : json['extra2'],
        'extra3': !exists(json, 'extra3') ? undefined : json['extra3'],
        'emailNotificationPreference': !exists(json, 'emailNotificationPreference') ? undefined : WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSON(json['emailNotificationPreference']),
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'email2': value.email2,
        'address': value.address,
        'postalCode': value.postalCode,
        'postalArea': value.postalArea,
        'phoneNumber': value.phoneNumber,
        'title': value.title,
        'country': value.country,
        'company': value.company,
        'extra1': value.extra1,
        'extra2': value.extra2,
        'extra3': value.extra3,
        'emailNotificationPreference': WebApiModelsEnumerationTypesEmailNotificationPreferenceToJSON(value.emailNotificationPreference),
    };
}

