import { Alert, Button, Divider, Form, message, Modal, Select, SelectProps, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { AuthProvider } from "../../Providers/authProvider";
import { AuthenticatedUser } from "../../Types/AuthenticatedUser";
import { BookyInput, InputType } from "../Forms/BookyInput";
import { GroupService } from "../../Services/GroupService";
import { TextService } from "../../Services/TextService";
import { MemberService } from "../../Services/MemberService";
import { WebApiCompanyDtoCompanyDto, WebApiMemberDtoMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from "../../Repository/eventbookyapi";
import { BookyButton } from "../Buttons/BookyButton";
import { MemberAutocomplete } from "../AutoComplete/MemberAutocomplete";
import { MemberTable } from "../Table/MemberTable";
import { MemberPaymentStatus } from "../../Types/MemberPaymentStatus";
import { BookySelectPaymentStatus } from "../Forms/BookyPaymentStatusSelect";
import { EmailFormType } from "../../Types/EmailFormType";
import { CompanyService } from "../../Services/CompanyService";
import { ContactAutoComplete } from "../AutoComplete/ContactAutocomplete";
import { EmailTemplateService } from "../../Services/EmailTemplateService";
import { PaymentConfiguration } from "../../Types/PaymentConfiguration";
import { BookyCheckbox } from "../Forms/BookyCheckbox";
import { EmailNotificationPreference } from "../../Types/EmailNotificationPreference";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { FilterMemberType } from "../../Types/FilterMemberType";
import { AutoCompleteType } from "../../Types/AutoCompleteType";

export const SmsForm = (props: any) => {
    const [form] = useForm();
    const [smsMessage, setSmsMessage] = useState("" as any);
    const [externalPhoneNumbers, setExternalPhoneNumbers] = useState([] as any[]);
    const [smsIsValid, setSmsIsValid] = useState(false as boolean);
    const [company, setCompany] = useState({} as WebApiCompanyDtoCompanyDto);
    const [loading, setLoading] = useState(false as boolean);
    const [showConfirm, setShowConfirm] = useState(false as boolean);
    const [selectedGroup, setSelectedGroup] = useState([] as number[]);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(undefined as MemberPaymentStatus | undefined);
    const [isAllowedToSendGroupMails, setIsAllowedToSendGroupMails] = useState(true as boolean);
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const [selectedMembers, setSelectedMembers] = useState([] as WebApiMemberDtoMemberDto[]);

    const GetText = TextService.GetText;

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);

            if (props.groups != null && props.groups.length > 0) {
                setSelectedGroup(props.groups[0]);
                form.setFieldValue("group", props.groups[0].id)
            }
        });

        CompanyService.GetCompany().then(company => {
            setCompany(company);
        })

        GroupService.IsAllowedToSendGroupMails().then(result => {
            setIsAllowedToSendGroupMails(result.toString() === true.toString());
        });
    }, [props]);

    const onReminderFinish = async (formValues: any) => {

        if (hasNoMembersOrEmails()) {
            message.warning("Du måste skicka till minst en medlem/telefonnummer.");
            return;
        }

        if (stringIsEmpty(smsMessage)) {
            message.warning("Du måste skriva något i meddelandet.");
            return;
        }

        setShowConfirm(true);
    };

    const onFilterMembers = async () => {
        let filteredMembers: WebApiMemberDtoMemberDto[] = [];

        if (!selectedGroup.length) {
            message.warning(GetText("EmailForm_SelectOneGroup"));
        }

        filteredMembers = await MemberService.GetFilteredMembers({
            status: selectedPaymentStatus,
            emailNotificationPreference: form.getFieldValue("emailNotificationPreference") == 0 || !form.getFieldValue("emailNotificationPreference") ? EmailNotificationPreference.Unspecified : EmailNotificationPreference.NoInformationEmails,
            isAllMembers: selectedGroup.length == 0 || selectedGroup.some(p => p == -1) ? true : false,
            groupsIds: selectedGroup,
            filter: FilterMemberType.HasPhoneNumber
        });

        setSelectedMembers(filteredMembers);

        if (filteredMembers.length == 0) {
            message.warning("No memebers found.")
        }
    }


    const onSendConfirmOK = async () => {
        setLoading(true);

        if (selectedMembers.length == 0) {
            message.error("Select at least one member");
            setLoading(false);
            setShowConfirm(false);
            return;
        }

        if (selectedMembers.length > user?.smsCredits!) {
            message.error("Du behöver köpa till fler sms. Köp till fler sms under inställingar > Betalningar");
            setLoading(false);
            setShowConfirm(false);
            return;
        }

        var memberIds: number[] = selectedMembers.map(m => m.memberId!);

        try {
            await MemberService.SendSmsToMembers({
                text: smsMessage,
                memberIds: memberIds
            });
            message.success(GetText("SmsForm_SmsIsSent"));
        } catch {
            message.error(GetText("SmsForm_SmsSentFailed"))
        }

        setLoading(false);
        form.setFieldValue("message", "");
        setShowConfirm(false);
    };

    const onSendConfirmOKExternal = async () => {
        setLoading(true);

        if (externalPhoneNumbers.length == 0) {
            message.error("Select at least one phonenumber");
            setLoading(false);
            setShowConfirm(false);
            return;
        }

        console.log(externalPhoneNumbers.length);
        console.log("SMS: " + user?.smsCredits);
        if (externalPhoneNumbers.length > user?.smsCredits!) {
            message.error("Du behöver köpa till fler sms. Köp till fler sms under inställingar > Betalningar");
            setLoading(false);
            setShowConfirm(false);
            return;
        }

        if (company.smsSender == null || company.smsSender == "") {
            message.error("Du behöver lägga in avsändare av sms. Detta kan du uppdatera genom inställingar > Konto");
            setLoading(false);
            setShowConfirm(false);
            return;
        }


        try {
            await EmailTemplateService.SendSmsToExternal({
                text: smsMessage,
                phoneNumbers: externalPhoneNumbers
            });
            message.success(GetText("SmsForm_SmsIsSent"));
        } catch {
            message.error(GetText("SmsForm_SmsIsSentFailed"))
        }

        setLoading(false);
        form.setFieldValue("message", "");
        setShowConfirm(false);
    }

    const onAutoCompleteSelect = (member: WebApiMemberDtoMemberDto) => {
        let newList: WebApiMemberDtoMemberDto[] = selectedMembers.map(p => p);
        if (!selectedMembers.some((e: WebApiMemberDtoMemberDto) => e.memberId === member.memberId)) {
            newList.push(member);
        }
        setSelectedMembers(newList);
    }

    const removeMember = (memberToRemove: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto) => {
        let newList = selectedMembers.filter(p => p.email != memberToRemove.email);
        setSelectedMembers(newList);
    };


    const groupOptions = () => {
        const options: SelectProps['options'] = [];

        props.groups.forEach((element: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto) => {
            options.push({
                label: element.name,
                value: element.id,
            });
        });

        return options;
    }

    const handleGroupChange = async (value: number[]) => {
        setSelectedGroup(value);
    };

    const onSendConfirmCancel = async () => {
        setShowConfirm(false);
    };

    const onSelectPaymentChanged = async (value: MemberPaymentStatus) => {
        setSelectedPaymentStatus(value);
    };


    const onMessageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const textContainsPhoneNumbers = stringIsEmpty(e.target.value);
        setSmsIsValid(!textContainsPhoneNumbers);
        console.log(e.target.value);
        form.setFieldValue("message", e.target.value);
        setSmsMessage(e.target.value);
    };

    const onEmailsUpdated = async (onEmailsUpdated: any) => {
        setExternalPhoneNumbers(onEmailsUpdated => ([
            ...onEmailsUpdated,
        ]));
    }

    const hasNoMembersOrEmails = () => {
        return (selectedMembers.length == 0 && externalPhoneNumbers.length == 0)
    }

    const stringIsEmpty = (subject: string) => {
        return subject === "";
    }

    return <>
        <Modal
            title={GetText("SmsForm_ConfirmText")}
            open={showConfirm}
            onOk={props.type === EmailFormType.EmailFormByExternal ? onSendConfirmOKExternal : onSendConfirmOK}
            confirmLoading={loading}
            onCancel={onSendConfirmCancel}
        >
            {props.type === EmailFormType.EmailFormByExternal ?
                (GetText("SmsForm_ConfirmText1") + externalPhoneNumbers.length + GetText("SmsForm_ConfirmText2")) :
                (GetText("SmsForm_ConfirmText1") + selectedMembers.length + GetText("SmsForm_ConfirmText2"))}
        </Modal>
        {user?.hasPaidSubscription ?
            <>
                {isAllowedToSendGroupMails === true ? <Form
                    form={form}
                    layout="vertical"
                    onFinish={onReminderFinish}
                    autoComplete="off"
                >
                    <Form.Item
                    >
                        {company.smsSender ? <>{GetText("SmsForm_From")} {company.smsSender}</> : <i>För att börja skicka SMS behöver du lägga till en sms-avsändare under inställningar - konto.</i>}
                    </Form.Item>

                    <h3>{!hasNoMembersOrEmails() ? <CheckCircleFilled style={{ color: "green" }} /> : <CheckCircleOutlined style={{ color: "grey" }} />} {GetText("EmailForm_Step1")}</h3>
                    <Divider style={{ marginTop: "5px" }} />
                    <span style={{ color: "red" }}>*</span> {GetText("SmsForm_ChooseReceiver")}
                    {props.type === EmailFormType.EmailFormByGroup && props.groups.length > 0 ? <><br />
                        <Select placeholder={GetText("EmailForm_ChooseCategories")} onChange={handleGroupChange} style={{ width: '400px', marginBottom: "10px" }} mode="multiple" options={groupOptions()}></Select>
                        <br /> {props.user.paymentConfiguration == PaymentConfiguration.YearlyPayments ? <BookySelectPaymentStatus style={{ width: "400px" }} onSelectPaymentChanged={onSelectPaymentChanged} /> : ""}
                        <br /><BookyCheckbox alignLabelRight={true} label={"Skicka även till medlemmar som inte önskar e-post"} name="emailNotificationPreference"></BookyCheckbox>
                    </> : ""}

                    {props.type === EmailFormType.EmailFormByMember ? <MemberAutocomplete AutoCompleteType={AutoCompleteType.PhoneNumber} showList={false} onAutoCompleteSelect={onAutoCompleteSelect} listHeader={GetText("ReminderForm_ReminderListHeader")} members={props.memberResult.members} /> : ""}
                    {props.type === EmailFormType.EmailFormByGroup ? <><br /><BookyButton style={{ marginTop: "10px" }} text={GetText("EmailForm_CreateSendList")} onClick={onFilterMembers}></BookyButton></> : ""}
                    {props.type === EmailFormType.EmailFormByExternal ? <><br /> <ContactAutoComplete AutoCompleteType={AutoCompleteType.PhoneNumber} descriptionText={GetText("AutoComplete_HelpTextAddPhone")} showList listHeader={GetText("SmsForm_ExternalPhoneHeader")} emails={externalPhoneNumbers} onEmailsUpdated={onEmailsUpdated} /></> : ""}

                    {selectedMembers.length === 0 ? "" : <MemberTable isMail={true} members={selectedMembers} style={{ minWidth: '400px', marginTop: "10px" }} removeMember={removeMember} />}
                    {selectedMembers.length > 0 ? <><br /><i>{selectedMembers.length} {GetText("EmailForm_ChosenReceivers")}</i></> : ""}

                    <h3 style={{ marginTop: "20px" }}>{smsIsValid ? <CheckCircleFilled style={{ color: "green" }} /> : <CheckCircleOutlined style={{ color: "grey" }} />}  {GetText("EmailForm_Step2")}</h3>
                    <Divider style={{ margin: "5px" }} />
                    <BookyInput onChange={onMessageChange} showCount
                        maxLength={160} type={InputType.multiline} label={GetText("EmailForm_Message")} name="message" style={{ width: '400px', marginTop: "10px" }} requiredMessage={GetText("SmsForm_MessageIsRequired")} ></BookyInput>

                    <Form.Item>
                        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                            <Button type="primary" htmlType="submit" disabled={!company.smsSender}>
                                {GetText("SmsForm_SendSmsButtonText")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form> : <Alert
                    message={GetText("EmailForm_AlertSuspendedMessage")}
                    description={GetText("EmailForm_AlertSuspendedDescription")}
                    type="warning"
                />}
            </> : <span><Tag color="#1890ff">Premium</Tag><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a>{GetText("EmailForm_PremiumSendingMessageToGroups")}</span>
        }

    </>
}