/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiIntegrationsObjectsIntegrationState } from './WebApiIntegrationsObjectsIntegrationState';
import {
    WebApiIntegrationsObjectsIntegrationStateFromJSON,
    WebApiIntegrationsObjectsIntegrationStateFromJSONTyped,
    WebApiIntegrationsObjectsIntegrationStateToJSON,
} from './WebApiIntegrationsObjectsIntegrationState';
import type { WebApiIntegrationsObjectsIntegrationType } from './WebApiIntegrationsObjectsIntegrationType';
import {
    WebApiIntegrationsObjectsIntegrationTypeFromJSON,
    WebApiIntegrationsObjectsIntegrationTypeFromJSONTyped,
    WebApiIntegrationsObjectsIntegrationTypeToJSON,
} from './WebApiIntegrationsObjectsIntegrationType';

/**
 * 
 * @export
 * @interface WebApiIntegrationsDtoIntegrationDto
 */
export interface WebApiIntegrationsDtoIntegrationDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiIntegrationsDtoIntegrationDto
     */
    id?: number;
    /**
     * 
     * @type {WebApiIntegrationsObjectsIntegrationType}
     * @memberof WebApiIntegrationsDtoIntegrationDto
     */
    type?: WebApiIntegrationsObjectsIntegrationType;
    /**
     * 
     * @type {string}
     * @memberof WebApiIntegrationsDtoIntegrationDto
     */
    activationUri?: string | null;
    /**
     * 
     * @type {WebApiIntegrationsObjectsIntegrationState}
     * @memberof WebApiIntegrationsDtoIntegrationDto
     */
    state?: WebApiIntegrationsObjectsIntegrationState;
    /**
     * 
     * @type {Date}
     * @memberof WebApiIntegrationsDtoIntegrationDto
     */
    lastRun?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiIntegrationsDtoIntegrationDto
     */
    lastrunResponse?: string | null;
}

/**
 * Check if a given object implements the WebApiIntegrationsDtoIntegrationDto interface.
 */
export function instanceOfWebApiIntegrationsDtoIntegrationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiIntegrationsDtoIntegrationDtoFromJSON(json: any): WebApiIntegrationsDtoIntegrationDto {
    return WebApiIntegrationsDtoIntegrationDtoFromJSONTyped(json, false);
}

export function WebApiIntegrationsDtoIntegrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiIntegrationsDtoIntegrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : WebApiIntegrationsObjectsIntegrationTypeFromJSON(json['type']),
        'activationUri': !exists(json, 'activationUri') ? undefined : json['activationUri'],
        'state': !exists(json, 'state') ? undefined : WebApiIntegrationsObjectsIntegrationStateFromJSON(json['state']),
        'lastRun': !exists(json, 'lastRun') ? undefined : (json['lastRun'] === null ? null : new Date(json['lastRun'])),
        'lastrunResponse': !exists(json, 'lastrunResponse') ? undefined : json['lastrunResponse'],
    };
}

export function WebApiIntegrationsDtoIntegrationDtoToJSON(value?: WebApiIntegrationsDtoIntegrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': WebApiIntegrationsObjectsIntegrationTypeToJSON(value.type),
        'activationUri': value.activationUri,
        'state': WebApiIntegrationsObjectsIntegrationStateToJSON(value.state),
        'lastRun': value.lastRun === undefined ? undefined : (value.lastRun === null ? null : value.lastRun.toISOString()),
        'lastrunResponse': value.lastrunResponse,
    };
}

