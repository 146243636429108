/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto,
  WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto,
  WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto,
  WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto,
  WebApiServicesDataTransferObjectsUserServiceRegisterReqDto,
} from '../models';
import {
    WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceGetDetailsResDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceGetDetailsResDtoToJSON,
    WebApiServicesDataTransferObjectsUserServicePasswordResetReqDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServicePasswordResetReqDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceRegisterReqDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceRegisterReqDtoToJSON,
} from '../models';

export interface ApiUserAuthenticatePostRequest {
    webApiServicesDataTransferObjectsUserServiceAuthenticateReqDto?: WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDto;
}

export interface ApiUserConfirmEmailGetRequest {
    code?: string;
}

export interface ApiUserConfirmPasswordResetPostRequest {
    code?: string;
    email?: string;
    password?: string;
}

export interface ApiUserIdGetRequest {
    id: number;
}

export interface ApiUserPasswordResetPostRequest {
    webApiServicesDataTransferObjectsUserServicePasswordResetReqDto?: WebApiServicesDataTransferObjectsUserServicePasswordResetReqDto;
}

export interface ApiUserRegisterPostRequest {
    webApiServicesDataTransferObjectsUserServiceRegisterReqDto?: WebApiServicesDataTransferObjectsUserServiceRegisterReqDto;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Authenticates the user.
     */
    async apiUserAuthenticatePostRaw(requestParameters: ApiUserAuthenticatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/authenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServiceAuthenticateReqDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServiceAuthenticateReqDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoFromJSON(jsonValue));
    }

    /**
     * Authenticates the user.
     */
    async apiUserAuthenticatePost(requestParameters: ApiUserAuthenticatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto> {
        const response = await this.apiUserAuthenticatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Confirms email address.
     */
    async apiUserConfirmEmailGetRaw(requestParameters: ApiUserConfirmEmailGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/ConfirmEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirms email address.
     */
    async apiUserConfirmEmailGet(requestParameters: ApiUserConfirmEmailGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiUserConfirmEmailGetRaw(requestParameters, initOverrides);
    }

    /**
     * s>
     */
    async apiUserConfirmPasswordResetPostRaw(requestParameters: ApiUserConfirmPasswordResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/ConfirmPasswordReset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * s>
     */
    async apiUserConfirmPasswordResetPost(requestParameters: ApiUserConfirmPasswordResetPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiUserConfirmPasswordResetPostRaw(requestParameters, initOverrides);
    }

    /**
     * Authenticates the user.
     */
    async apiUserGetLoggedInUserGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/GetLoggedInUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceAuthenticateResDtoFromJSON(jsonValue));
    }

    /**
     * Authenticates the user.
     */
    async apiUserGetLoggedInUserGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceAuthenticateResDto> {
        const response = await this.apiUserGetLoggedInUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the user details.
     */
    async apiUserIdGetRaw(requestParameters: ApiUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceGetDetailsResDtoFromJSON(jsonValue));
    }

    /**
     * Gets the user details.
     */
    async apiUserIdGet(requestParameters: ApiUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto> {
        const response = await this.apiUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resets the password by sending email.
     */
    async apiUserPasswordResetPostRaw(requestParameters: ApiUserPasswordResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/PasswordReset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServicePasswordResetReqDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServicePasswordResetReqDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resets the password by sending email.
     */
    async apiUserPasswordResetPost(requestParameters: ApiUserPasswordResetPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiUserPasswordResetPostRaw(requestParameters, initOverrides);
    }

    /**
     * Registers new user.
     */
    async apiUserRegisterPostRaw(requestParameters: ApiUserRegisterPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServiceRegisterReqDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServiceRegisterReqDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Registers new user.
     */
    async apiUserRegisterPost(requestParameters: ApiUserRegisterPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiUserRegisterPostRaw(requestParameters, initOverrides);
    }

}
