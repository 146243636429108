import { Result } from "antd";
import '../../Pages/Pricing/Pricing.css'

const ErrorPage = (props: any) => {
    return <Result
        status="500"
        title="Sorry!"
        subTitle={props.message}
    />
};

export default ErrorPage;