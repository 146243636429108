

import { message, Upload, Button, UploadProps, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';
import { useState } from 'react';

const FileSelect = (formProps: any) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false as boolean);
    const GetText = TextService.GetText;
    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {

            const isLt4M = file.size / 1024 / 1024 <= 4;
            if (!isLt4M) {
                message.error(GetText("UploadFile_TooBig"));
                return false;
            }

            if (fileList.length == 4) {
                message.error(GetText("UploadFile_MaxFiles"));
                return false;
            }

            setFileList([...fileList, file]);
            return true;
        },


        fileList,
    };

    return (<Upload onChange={formProps.onFileSelectChange} {...props}>
        <Button icon={<UploadOutlined />}>{GetText("UploadFile_SelectFile")}</Button>
    </Upload>);
};

export default FileSelect;