import { Divider, Radio, Slider, Space, Table, TableColumnsType, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CheckOutlined } from '@ant-design/icons';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { TextService } from '../../Services/TextService';
import { useEffect, useState } from 'react';
import { mapSubscriptionLevelFromString } from '../../Mappers/mapSubscriptionLevel';
import { DataType, basicDataOptions, getPricingTableColumns } from './PricingTable';
const FeatureBoxBasic = (props: any) => {
    const GetText = TextService.GetText;
    const [selectedSubscriptionLevel, setCurrentSubscriptionLevel] = useState(SubscriptionLevel.Free as SubscriptionLevel);

    useEffect(() => {
        setCurrentSubscriptionLevel(props.user?.subscriptionLevel);

        if (props.user === null) {
            setCurrentSubscriptionLevel(SubscriptionLevel.Basic);
        }
    }, [props.user]);

    const isLevelPossible = (level: Number): boolean => {
        if (props.user === null || props.user?.subscriptionLevel === SubscriptionLevel.Free) {
            return true;
        }

        return props.user?.subscriptionLevel < level;
    }


    const isPossibleToUpgrade = () => {
        if (props.user?.subscriptionLevel === SubscriptionLevel.Free) {
            return true;
        }

        if (props.user?.subscriptionLevel === SubscriptionLevel.Premium || props.user?.subscriptionLevel === SubscriptionLevel.Enterprise) {
            return false;
        }

        if (props.user?.subscriptionLevel === SubscriptionLevel.Basic && (selectedSubscriptionLevel === SubscriptionLevel.BasicAdvanced || selectedSubscriptionLevel === SubscriptionLevel.BasicPlus)) {
            return true;
        }

        if (props.user?.subscriptionLevel === SubscriptionLevel.BasicPlus && selectedSubscriptionLevel === SubscriptionLevel.BasicAdvanced) {
            return true;
        }
    }

    const isCurrentPlan = () => {
        if (props.user?.subscriptionLevel === SubscriptionLevel.Premium || props.user?.subscriptionLevel === SubscriptionLevel.Enterprise) {
            return false;
        }

        return props.user != null && props.user.subscriptionLevel === selectedSubscriptionLevel
    }
    type NewType = DataType;

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: NewType[]) => {
            setCurrentSubscriptionLevel(Number(selectedRows[0].key) as SubscriptionLevel);
        },

        getCheckboxProps: (record: DataType) => ({
            disabled: !isLevelPossible(Number(record.key.toString())), // Column configuration not to be checked
            key: record.key,
        }),
        selectedRowKeys: [selectedSubscriptionLevel?.toString()]
    }



    return <div className="bookyBox pricingBox">
        <Title>{props.title}</Title>
        <div className='pricingDescription'>{props.pricing}</div>
        <div className='pricingDescription'>{props.mail}</div>

        {props.description1 ? <div className="description">{props.description1}</div> : ""}

        <Divider style={{ backgroundColor: "var(--color-primary)", }} />
        {props.description2 ? <div className="description">{props.description2}</div> : ""}
        <>
            <Table
                className='basic-feature-table'
                rowSelection={{
                    type: "radio",
                    ...rowSelection,
                }}
                columns={getPricingTableColumns(TextService)}
                dataSource={basicDataOptions}
                pagination={false}
            />
        </>

        {isCurrentPlan() && !isPossibleToUpgrade()
            ?
            <BookyButton size="large" disabled={true} fullWidth={true} type="primary" text={GetText("Pricing_CurrentPlan")} /> : ""
        }

        {isPossibleToUpgrade() ?
            <BookyButton size="large" disabled={selectedSubscriptionLevel === SubscriptionLevel.Free} fullWidth={true} onClick={() => props.initiatePayment(selectedSubscriptionLevel)} type="primary" text={props.upgradeToText} /> :
            ""
        }


        {props.user == null ?
            <BookyButton size="large" onClick={() => { props.redirectToCreateUser(selectedSubscriptionLevel) }} fullWidth={true} type="primary" text={GetText("Pricing_SignUp")} /> : ""
        }

        {props.user != null && !isCurrentPlan() && !isPossibleToUpgrade() ?
            <BookyButton size="large" disabled={true} onClick={() => { props.redirectToCreateUser(selectedSubscriptionLevel) }} fullWidth={true} type="primary" text={GetText("Pricing_SignUp")} /> : ""
        }

        <Divider style={{ backgroundColor: "var(--color-primary)", }} />
        <div className='pricing-features'>
            {
                props.featureList?.map(function (item: string) {
                    return <><CheckOutlined /> <div className='feature'>{item}</div></>
                })
            }
        </div>
    </div >
};

export default FeatureBoxBasic;