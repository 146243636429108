import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, message } from 'antd';
import { BookyInput } from '../Forms/BookyInput';
import { BookyButton } from '../Buttons/BookyButton';
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';
import { WebApiCompanyDtoCompanyUserDto } from '../../Repository/eventbookyapi';
import { CompanyService } from '../../Services/CompanyService';
import { BookySelectDropDown } from '../Forms/BookySelectDropDown';
import { AvailableRoles } from '../../Helpers/RoleList';

export const AddAdmin = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [adminList, setAdminList] = useState([]);
    const [loading, setLoading] = useState(false as boolean);

    const GetText = TextService.GetText;

    const onCancel = () => {
        form.setFieldValue("groupName", "");
        setOpen(false);
    };

    useEffect(() => {
        let adminList: any = [];
        props.adminResult?.administrators?.forEach((admin: WebApiCompanyDtoCompanyUserDto) => {
            adminList.push({ value: admin.id, label: admin.name });
        });
        setAdminList(adminList);
        form.setFieldValue("role", 1);
    }, [props])

    const setFieldCustomError = (fieldName: string, error: string,) => {
        form.setFields([
            {
                name: fieldName, // required
                errors: [error],
            },
        ]);
    }

    const onCreate = async (values: any) => {
        setLoading(true);

        let foundError = false;
        props.adminResult?.administrators.forEach((member: WebApiCompanyDtoCompanyUserDto) => {
            if (member.email == values.email) {
                setFieldCustomError("email", GetText("AddAdmin_EmailAlreadyExist") + member.email)
                foundError = true;
            }
        });

        if (props.adminResult?.administrators.length >= props.user.maxAdministrators) {
            setFieldCustomError("email", GetText("AddAdmin_MaxAllowedAdmins"))
            foundError = true;
        }

        if (foundError) {
            setLoading(false);
            return true;
        }
        try {
            await CompanyService.AddCompanyUser({
                inviteEmail: values.email,
                role: values.role
            });
        } catch (error) {
            message.warning(GetText("AddMember_ErrorSaving"));
            return;
        }
        setLoading(false);
        setOpen(false);

        props.loadAdmins();
    }

    return (
        <>
            <Modal
                open={open}
                title={GetText("AddAdmin_Title")}
                okText={GetText("AddMember_Ok")}
                confirmLoading={loading}
                cancelText={GetText("AddMember_Cancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form form={form} labelAlign={"left"} labelCol={{ span: 8, offset: 0 }} wrapperCol={{ span: 24 }} onFinish={onCreate}>
                    <Col span={18}>
                        <BookyInput label={GetText("AddAdmin_Email")} name="email" req placeholder={GetText("AddAdmin_Email")} />
                        <BookySelectDropDown label={GetText("AddAdmin_Role")} name="role" options={AvailableRoles} />
                    </Col>
                </Form>
            </Modal>

            <BookyButton
                type="primary"
                fullWidth={true}
                text={GetText("AddAdmin_AddButton")}
                onClick={() => {
                    setOpen(true);
                }}
                icon={<UserAddOutlined />}
            >
            </BookyButton>
        </>
    );
};

export default AddAdmin;