/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebApiModelsEnumerationTypesCompanySubscriptionType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type WebApiModelsEnumerationTypesCompanySubscriptionType = typeof WebApiModelsEnumerationTypesCompanySubscriptionType[keyof typeof WebApiModelsEnumerationTypesCompanySubscriptionType];


export function WebApiModelsEnumerationTypesCompanySubscriptionTypeFromJSON(json: any): WebApiModelsEnumerationTypesCompanySubscriptionType {
    return WebApiModelsEnumerationTypesCompanySubscriptionTypeFromJSONTyped(json, false);
}

export function WebApiModelsEnumerationTypesCompanySubscriptionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsEnumerationTypesCompanySubscriptionType {
    return json as WebApiModelsEnumerationTypesCompanySubscriptionType;
}

export function WebApiModelsEnumerationTypesCompanySubscriptionTypeToJSON(value?: WebApiModelsEnumerationTypesCompanySubscriptionType | null): any {
    return value as any;
}

