import { CheckCircleOutlined, EyeInvisibleOutlined, EyeTwoTone, QuestionCircleOutlined, StopOutlined } from "@ant-design/icons";
import { Collapse, Form, Input, Radio, Space, Table, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ReactElement } from "react";
import { TextService } from "../../Services/TextService";

export enum InputType {
    email = "email",
    password = "password",
    multiline = "multiline"
}

export const ReplyTimeForm = (props: any) => {
    const GetText = TextService.GetText;

    const getAnswer = (answerType: any): ReactElement => {
        var foundResult = <CheckCircleOutlined />;
        switch (answerType) {
            case 0:
                foundResult = <span className='accepted'><CheckCircleOutlined /> {GetText("ReplyTimeForm_Accepted")} </span>
                break;
            case 1:
                foundResult = <span className='tentative'><QuestionCircleOutlined /> {GetText("ReplyTimeForm_Tentative")}</span>
                break;
            case 2:
                foundResult = <span className='rejected'><StopOutlined /> {GetText("ReplyTimeForm_Rejected")}</span>
                break;
        }

        return foundResult
    };

    const { Panel } = Collapse;

    const locale = {
        emptyText: GetText("ReplyTimeForm_NoAnswers")
    }


    const updateAnswerByTimeId = (timeId: string, answer: string) => {
        props.onChangeAnswerComplete(timeId, answer);
    }

    const collapseShowMore = (value: string) => (
        <span style={{ color: props.event.primaryColorCode ? props.event.primaryColorCode : "var(--color-primary)" }}>{value}</span>
    );

    if (props.isFullyBooked) {
        return (<Typography><i>This time is fully booked with {props.time.acceptedCount} participants.</i></Typography>)
    }

    return (<div>
        <Space style={{ width: '100%', justifyContent: 'left' }}>
            {props.event.answerOptions === 0 ?
                <Radio.Group value={props.answer} onChange={e => updateAnswerByTimeId(props.time.id, e.target.value)} className='event-radio-answers' size="large" buttonStyle="solid">
                    <Radio.Button value="0">{GetText("ReplyTimeForm_YesButtonText")}</Radio.Button>
                    <Radio.Button value="1">{GetText("ReplyTimeForm_MaybeButtonText")}</Radio.Button>
                    <Radio.Button value="2">{GetText("ReplyTimeForm_NoButtonText")}</Radio.Button>
                </Radio.Group>
                : ""}

            {props.event.answerOptions === 1 ?
                <Radio.Group value={props.answer} onChange={e => updateAnswerByTimeId(props.time.id, e.target.value)} defaultValue="a" size="large" buttonStyle="solid">
                    <Radio.Button value="0">{GetText("ReplyTimeForm_YesButtonText")}</Radio.Button>
                    <Radio.Button value="2">{GetText("ReplyTimeForm_NoButtonText")}</Radio.Button>
                </Radio.Group>
                : ""}

            {props.event.answerOptions === 2 ?
                <Radio.Group value={props.answer} onChange={e => updateAnswerByTimeId(props.time.id, e.target.value)} defaultValue="a" size="large" buttonStyle="solid">
                    <Radio.Button value="0">{GetText("ReplyTimeForm_YesButtonText")}</Radio.Button>
                </Radio.Group>
                : ""}
        </Space>
        <div className='participant-details'>
            {props.time.participants != null ? <Space style={{ width: '100%', justifyContent: 'left' }}>
                {props.time.acceptedCount} {GetText("ReplyTimeForm_GuestsAnsweredYes")}
            </Space> : null}
            {props.time.participants != null ? <Collapse defaultActiveKey={['1']} ghost>
                <Panel style={{ textAlign: "left" }} header={collapseShowMore(GetText("ReplyTimeForm_ShowOtherGuestReplyText"))} key="0">
                    <Table className='event-showparticipants-table' locale={locale} dataSource={props.time.participants}
                        columns={[{
                            title: GetText("ReplyTimeForm_OtherAnswersNameColumn"), dataIndex: 'name', key: 'name',
                        }, { title: GetText("ReplyTimeForm_OtherAnswersEmailColumn"), dataIndex: 'email', key: 'email' }, {
                            title: GetText("ReplyTimeForm_OtherAnswersAnswerColumn"), dataIndex: 'answer', key: 'answer', render: (_: any, participant: any) => (
                                <span><b>{getAnswer(participant.answer)}</b><br />
                                </span>
                            ),
                        }]}
                    >
                    </Table>
                </Panel>
            </Collapse> : null}
        </div>
    </div>
    );
};
