import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Checkbox, DatePicker, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { validateEmail } from "../../Helpers/Validators";


export const OneFrontDatePicker = (props: any) => {

    return (

        <Form.Item label={props.label} name={props.name}
        >
            <DatePicker placeholder={props.placeholder} style={{ justifyContent: 'left' }} format={"YYYY-MM-DD"} onChange={props.onChange} />
        </Form.Item>
    );

}