import { createEventRequest } from "../Types/RequestResponse/createEventResponse";
import { AuthProvider } from "../Providers/authProvider"
import { updateEventWithBookingsRequest } from "../Types/RequestResponse/updateEventRequest";
import { convertToJsonKeepLocalDate } from "../Helpers/JsonConverter";
import { ApiEventGenerateExcelFromEventGetRequest, ApiEventUpdateBrandingPostRequest, ApiEventUpdateEventBannerImagePostRequest, EventApi, ReplyApi, WebApiServicesDataTransferObjectsUserServiceUpdateEventDto } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class EventService {
    public static CreateEvent = async (request: createEventRequest) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + AuthProvider().GetToken() },
            body: convertToJsonKeepLocalDate(request)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/Event/create', requestOptions);
        const data = await response.json();

        return data;
    };

    public static UpdateBannerImage = async (updateRequest: ApiEventUpdateEventBannerImagePostRequest) => {
        return new EventApi(GetApiConfiguration()).apiEventUpdateEventBannerImagePost(updateRequest);
    };

    public static UpdateBranding = async (updateRequest: ApiEventUpdateBrandingPostRequest) => {
        return new EventApi(GetApiConfiguration()).apiEventUpdateBrandingPost(updateRequest);
    };

    public static OpenEvent = async (eventId: number) => {
        return new EventApi(GetApiConfiguration()).apiEventOpenPost({
            webApiServicesDataTransferObjectsUserServiceCloseEventDto: {
                id: eventId,
                message: "",
            }
        });
    };

    public static UpdateNotificationsForEvent = async (Id: number, notificationsOnReply: boolean) => {
        return new EventApi(GetApiConfiguration()).apiEventUpdateNotificationsAsyncPost({
            webApiServicesDataTransferObjectsEventServiceUpdateNotificationDto: {
                eventId: Id,
                turnOnNotifications: notificationsOnReply
            }
        });
    };

    public static GenerateExcelFromEvent = async (updateRequest: ApiEventGenerateExcelFromEventGetRequest) => {
        return new EventApi(GetApiConfiguration()).apiEventGenerateExcelFromEventGetRaw(updateRequest);
    };

    public static UpdateEvent = async (request: WebApiServicesDataTransferObjectsUserServiceUpdateEventDto) => {
        return new EventApi(GetApiConfiguration()).apiEventUpdatePost({
            webApiServicesDataTransferObjectsUserServiceUpdateEventDto: request
        });

        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + AuthProvider().GetToken() },
        //     body: convertToJsonKeepLocalDate(request)
        // };
        // const response = await fetch(process.env.REACT_APP_API_URL + '/Event/update', requestOptions);
        // const data = await response.json();

        // return data;
    };

    public static UpdateEventWithBookings = async (request: updateEventWithBookingsRequest) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + AuthProvider().GetToken() },
            body: convertToJsonKeepLocalDate(request)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/Event/updateWithBookings', requestOptions);
        const data = await response.json();

        return data;
    };

    public static GetEvent = async (eventId: string) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/Event/' + eventId);
        const data = await response.json();

        return data;
    };

    public static GetEventDetails = async (eventId: string) => {
        return new ReplyApi(GetApiConfiguration()).apiReplyEventIdGet({ eventId: eventId });
    };

    public static CloseEvent = async (eventId: number, message: string) => {
        return new EventApi(GetApiConfiguration()).apiEventClosePost({
            webApiServicesDataTransferObjectsUserServiceCloseEventDto:
            {
                id: eventId,
                message: message
            }
        });
    };


    public static DeleteEvent = async (eventId: number) => {
        var options = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider().GetToken()
            }
        }

        const response = await fetch(process.env.REACT_APP_API_URL + '/Event/' + eventId, options);
        const data = await response.status;

        return data;
    };

    public static GetMyEvents = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider().GetToken()
            }
        }


        const response = await fetch(process.env.REACT_APP_API_URL + '/Event/User', options);
        const data = await response.json();

        return data;
    };
}