/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebApiModelsEnumerationTypesCustomerAnswerType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type WebApiModelsEnumerationTypesCustomerAnswerType = typeof WebApiModelsEnumerationTypesCustomerAnswerType[keyof typeof WebApiModelsEnumerationTypesCustomerAnswerType];


export function WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSON(json: any): WebApiModelsEnumerationTypesCustomerAnswerType {
    return WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSONTyped(json, false);
}

export function WebApiModelsEnumerationTypesCustomerAnswerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsEnumerationTypesCustomerAnswerType {
    return json as WebApiModelsEnumerationTypesCustomerAnswerType;
}

export function WebApiModelsEnumerationTypesCustomerAnswerTypeToJSON(value?: WebApiModelsEnumerationTypesCustomerAnswerType | null): any {
    return value as any;
}

