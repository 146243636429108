/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto,
  WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto,
  WebApiServicesDataTransferObjectsUserServiceCloseEventDto,
  WebApiServicesDataTransferObjectsUserServiceCreateEventDto,
  WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto,
  WebApiServicesDataTransferObjectsUserServiceDeleteEventResultDto,
  WebApiServicesDataTransferObjectsUserServiceEventResultDto,
  WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto,
  WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto,
  WebApiServicesDataTransferObjectsUserServiceUpdateEventDto,
  WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto,
} from '../models';
import {
    WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDtoFromJSON,
    WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDtoToJSON,
    WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDtoFromJSON,
    WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceCloseEventDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceCloseEventDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceCreateEventDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceCreateEventDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceDeleteEventResultDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceDeleteEventResultDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceEventResultDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceEventResultDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceUpdateEventDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceUpdateEventDtoToJSON,
    WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDtoToJSON,
} from '../models';

export interface ApiEventClosePostRequest {
    webApiServicesDataTransferObjectsUserServiceCloseEventDto?: WebApiServicesDataTransferObjectsUserServiceCloseEventDto;
}

export interface ApiEventCreatePostRequest {
    webApiServicesDataTransferObjectsUserServiceCreateEventDto?: WebApiServicesDataTransferObjectsUserServiceCreateEventDto;
}

export interface ApiEventDeleteEventBannerImageEventIdDeleteRequest {
    eventId: number;
}

export interface ApiEventGenerateExcelFromEventGetRequest {
    eventId?: number;
}

export interface ApiEventIdDeleteRequest {
    id: number;
}

export interface ApiEventIdGetRequest {
    id: string;
}

export interface ApiEventOpenPostRequest {
    webApiServicesDataTransferObjectsUserServiceCloseEventDto?: WebApiServicesDataTransferObjectsUserServiceCloseEventDto;
}

export interface ApiEventUpdateBrandingPostRequest {
    webApiServicesDataTransferObjectsUserServiceUpdateBrandingDto?: WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDto;
}

export interface ApiEventUpdateEventBannerImagePostRequest {
    webApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto?: WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto;
}

export interface ApiEventUpdateNotificationsAsyncPostRequest {
    webApiServicesDataTransferObjectsEventServiceUpdateNotificationDto?: WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto;
}

export interface ApiEventUpdatePostRequest {
    webApiServicesDataTransferObjectsUserServiceUpdateEventDto?: WebApiServicesDataTransferObjectsUserServiceUpdateEventDto;
}

export interface ApiEventUpdateWithBookingsPostRequest {
    webApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto?: WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto;
}

/**
 * 
 */
export class EventApi extends runtime.BaseAPI {

    /**
     * Craetes new event with connected times
     */
    async apiEventClosePostRaw(requestParameters: ApiEventClosePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/close`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServiceCloseEventDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServiceCloseEventDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventClosePost(requestParameters: ApiEventClosePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiEventClosePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventCreatePostRaw(requestParameters: ApiEventCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServiceCreateEventDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServiceCreateEventDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON(jsonValue));
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventCreatePost(requestParameters: ApiEventCreatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto> {
        const response = await this.apiEventCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiEventDeleteEventBannerImageEventIdDeleteRaw(requestParameters: ApiEventDeleteEventBannerImageEventIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto>> {
        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling apiEventDeleteEventBannerImageEventIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/DeleteEventBannerImage/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiEventDeleteEventBannerImageEventIdDelete(requestParameters: ApiEventDeleteEventBannerImageEventIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto> {
        const response = await this.apiEventDeleteEventBannerImageEventIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiEventGenerateExcelFromEventGetRaw(requestParameters: ApiEventGenerateExcelFromEventGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/generateExcelFromEvent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiEventGenerateExcelFromEventGet(requestParameters: ApiEventGenerateExcelFromEventGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiEventGenerateExcelFromEventGetRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes the event with connected replies, times
     */
    async apiEventIdDeleteRaw(requestParameters: ApiEventIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceDeleteEventResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiEventIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceDeleteEventResultDtoFromJSON(jsonValue));
    }

    /**
     * Deletes the event with connected replies, times
     */
    async apiEventIdDelete(requestParameters: ApiEventIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceDeleteEventResultDto> {
        const response = await this.apiEventIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the event
     */
    async apiEventIdGetRaw(requestParameters: ApiEventIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceEventResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiEventIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceEventResultDtoFromJSON(jsonValue));
    }

    /**
     * Gets the event
     */
    async apiEventIdGet(requestParameters: ApiEventIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceEventResultDto> {
        const response = await this.apiEventIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventOpenPostRaw(requestParameters: ApiEventOpenPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/open`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServiceCloseEventDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServiceCloseEventDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventOpenPost(requestParameters: ApiEventOpenPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiEventOpenPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventUpdateBrandingPostRaw(requestParameters: ApiEventUpdateBrandingPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/updateBranding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServiceUpdateBrandingDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServiceUpdateBrandingDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventUpdateBrandingPost(requestParameters: ApiEventUpdateBrandingPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiEventUpdateBrandingPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiEventUpdateEventBannerImagePostRaw(requestParameters: ApiEventUpdateEventBannerImagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/UpdateEventBannerImage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDtoToJSON(requestParameters.webApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiEventUpdateEventBannerImagePost(requestParameters: ApiEventUpdateEventBannerImagePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto> {
        const response = await this.apiEventUpdateEventBannerImagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable/Disable notifications on event
     */
    async apiEventUpdateNotificationsAsyncPostRaw(requestParameters: ApiEventUpdateNotificationsAsyncPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/UpdateNotificationsAsync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDtoToJSON(requestParameters.webApiServicesDataTransferObjectsEventServiceUpdateNotificationDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Enable/Disable notifications on event
     */
    async apiEventUpdateNotificationsAsyncPost(requestParameters: ApiEventUpdateNotificationsAsyncPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiEventUpdateNotificationsAsyncPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventUpdatePostRaw(requestParameters: ApiEventUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServiceUpdateEventDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServiceUpdateEventDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON(jsonValue));
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventUpdatePost(requestParameters: ApiEventUpdatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto> {
        const response = await this.apiEventUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventUpdateWithBookingsPostRaw(requestParameters: ApiEventUpdateWithBookingsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/updateWithBookings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDtoToJSON(requestParameters.webApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON(jsonValue));
    }

    /**
     * Craetes new event with connected times
     */
    async apiEventUpdateWithBookingsPost(requestParameters: ApiEventUpdateWithBookingsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto> {
        const response = await this.apiEventUpdateWithBookingsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all events for user
     */
    async apiEventUserGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Event/User`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDtoFromJSON));
    }

    /**
     * Gets all events for user
     */
    async apiEventUserGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto>> {
        const response = await this.apiEventUserGetRaw(initOverrides);
        return await response.value();
    }

}
