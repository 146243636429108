import { Col, message, Modal, Row, } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { SubscriptionLevel } from "../../Types/SubscriptionLevel";
import { ReactElement, useState } from "react";
import { AuthProvider } from "../../Providers/authProvider";
import Table, { ColumnsType } from "antd/lib/table";
import { CheckCircleOutlined, EditOutlined, QuestionCircleOutlined, StarFilled, StopOutlined, UserOutlined } from "@ant-design/icons";
import { formatDateRangeString, formatDateRangeWithDateString, formatDateString } from "../../Helpers/DateHelpers";
import moment from "moment";
import { EventService } from "../../Services/EventService";
import { EventState } from "../../Pages/Event/EventState";
import DeleteReplyIcon from "./DeleteReplyIcon";
import { useNavigate } from "react-router-dom";
import { TextService } from "../../Services/TextService";
import { getCurrentLanguage } from "../../Helpers/RouteHelper";

export const ReplyTable = (props: any) => {
    const [open, setOpen] = useState(false);
    const [closePollMessage, setClosePollMessage] = useState("");
    const navigation = useNavigate();
    const GetText = TextService.GetText;
    interface DataType {
        key: string;
        customerName: string;
        customerEmail: string;
        customerAnswer: string;
        authCode: string;
        id: number;
        times: any[];
        tags: string[];
    }

    const columns: ColumnsType<DataType> = [
        {
            title: () => { return <><UserOutlined /> {GetText("ReplyTable_Headline")}</> },
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, reply) => (
                <Row>
                    <Col span={18}>
                        <span><b>{reply.customerName}</b><br />
                            {reply.customerEmail}<br></br>
                            <i>{reply.customerAnswer}</i>

                        </span>
                    </Col>
                    <Col span={4}>
                        <DeleteReplyIcon onReplyDelete={onReplyDelete} reply={reply} />
                        <EditOutlined onClick={e => onEditClick(reply.authCode)} />
                    </Col>
                </Row>
            ),
        }
    ];

    const onReplyDelete = () => {
        props.onReplyDelete();
    };

    const onEditClick = (authCode: string) => {

        if (props.user.subscriptionLevel == SubscriptionLevel.Free) {
            message.warning(GetText("ReplyTable_EditReplyOnlyForPremium"));
            return;
        }

        navigation("/" + getCurrentLanguage() + "/Event/" + props.event.eventUniqueId + "/Reply/" + authCode)
    };

    const onTimeSelect = (time: any) => {
        if (props.event.eventState === EventState.Closed) {
            message.warning("This event is already closed.");
            return;
        }

        AuthProvider().GetLoggedInUser().then(user => {
            setClosePollMessage(GetText("ReplyTable_EventClosedDefaultText1") + props.event.eventName + GetText("ReplyTable_EventClosedDefaultText2") + formatDateRangeWithDateString(time.start, time.end, time.isFullDay, false) + GetText("ReplyTable_EventClosedDefaultText3") + user?.firstName + " " + user?.lastName);
        });
        setOpen(true);
    }

    const handleOk = async () => {
        const htmlMessage = closePollMessage.replace("\r", "<br>");
        await EventService.CloseEvent(props.event.eventId, htmlMessage);
        setOpen(false);
        message.success(GetText("ReplyTable_EventHasBeenClosed"));
        props.onClosePoll();
    };

    const handleCancel = () => {
        setOpen(false);
    };

    props.event.times?.forEach(function (time: any) {
        columns.push({
            title: () => {
                return <div onClick={() => onTimeSelect(time)} className="date-header">
                    {time.hasMostYesCount ? <span className="date-hasMostYesCount"><StarFilled /></span> : <span style={{ visibility: "hidden" }} className="date-hasMostYesCount"><StarFilled /></span>}
                    <div className="date-month">{moment(time.start).format("MMM")}</div>
                    <div className="date-day">{moment(time.start).format("DD")}</div>
                    <div className="date-weekday">{moment(time.start).format("ddd")}</div>
                    {!time.isFullDay ? <div className="date-weekday">{formatDateRangeString(time.start, time.end, time.isFullDay, false)}</div> : ""}
                    <div className="date-yescount">{time.yesCount == 0 ? <span className='no-replies'><UserOutlined /> {time.yesCount}</span> : <span><UserOutlined /> {time.yesCount}</span>}</div>
                </div>;
            },
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, event) => (
                <div className="reply-answer"><b>{getAnswer(event.customerEmail, time.id, props.event.timeAnswers)}</b></div>
            ),
        })
    });


    const getAnswer = (customerEmail: string, timeId: string, customerAnswers: any): ReactElement => {
        var foundResult = <CheckCircleOutlined />;
        customerAnswers.forEach(function (customerAnswer: any) {
            customerAnswer.replyTime.forEach(function (answer: any) {
                if (customerEmail == customerAnswer.customerEmail && answer.timeId == timeId) {
                    switch (answer.customerAnswerType) {
                        case 0:
                            foundResult = <span className='accepted'><CheckCircleOutlined /> </span>
                            break;
                        case 1:
                            foundResult = <span className='tentative'><QuestionCircleOutlined /></span>
                            break;
                        case 2:
                            foundResult = <span className='rejected'><StopOutlined /></span>
                            break;
                    }
                }
            })
        });

        return foundResult;
    }

    return <>
        <Modal
            title="Close poll"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <p><i>{GetText("ReplyTable_CloseEventModalDescription")}</i></p>
            <TextArea rows={6} value={closePollMessage} onChange={(e) => setClosePollMessage(e.target.value)} style={{ width: "600px" }}>
            </TextArea>
        </Modal>
        <i>{GetText("ReplyTable_EventCloseHelpText")}</i>
        {<Table columns={columns} scroll={{ x: 400 }} className="reply-table" rowKey="Id" dataSource={props.event.timeAnswers} />}
    </>
}