/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto } from './WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto';
import {
    WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoFromJSON,
    WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoToJSON,
} from './WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto';

/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.ConfirmResetPasswordAsync(System.String,System.String,System.String) response.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceTimeResultDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceTimeResultDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceTimeResultDto
     */
    id?: number;
    /**
     * Gets or sets the identifier.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceTimeResultDto
     */
    start?: Date;
    /**
     * Gets or sets the identifier.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceTimeResultDto
     */
    end?: Date;
    /**
     * Gets or sets the identifier.
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceTimeResultDto
     */
    isFullDay?: boolean;
    /**
     * Gets or sets the email.
     * @type {Array<WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto>}
     * @memberof WebApiServicesDataTransferObjectsUserServiceTimeResultDto
     */
    participants?: Array<WebApiServicesDataTransferObjectsUserServiceParticipantReplyDto> | null;
    /**
     * Number of accepted
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceTimeResultDto
     */
    acceptedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceTimeResultDto
     */
    tentativeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceTimeResultDto
     */
    rejectedCount?: number;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceTimeResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceTimeResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceTimeResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceTimeResultDto {
    return WebApiServicesDataTransferObjectsUserServiceTimeResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceTimeResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceTimeResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'isFullDay': !exists(json, 'isFullDay') ? undefined : json['isFullDay'],
        'participants': !exists(json, 'participants') ? undefined : (json['participants'] === null ? null : (json['participants'] as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoFromJSON)),
        'acceptedCount': !exists(json, 'acceptedCount') ? undefined : json['acceptedCount'],
        'tentativeCount': !exists(json, 'tentativeCount') ? undefined : json['tentativeCount'],
        'rejectedCount': !exists(json, 'rejectedCount') ? undefined : json['rejectedCount'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceTimeResultDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceTimeResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'isFullDay': value.isFullDay,
        'participants': value.participants === undefined ? undefined : (value.participants === null ? null : (value.participants as Array<any>).map(WebApiServicesDataTransferObjectsUserServiceParticipantReplyDtoToJSON)),
        'acceptedCount': value.acceptedCount,
        'tentativeCount': value.tentativeCount,
        'rejectedCount': value.rejectedCount,
    };
}

