/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesGroupType } from './WebApiModelsEnumerationTypesGroupType';
import {
    WebApiModelsEnumerationTypesGroupTypeFromJSON,
    WebApiModelsEnumerationTypesGroupTypeFromJSONTyped,
    WebApiModelsEnumerationTypesGroupTypeToJSON,
} from './WebApiModelsEnumerationTypesGroupType';
import type { WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto } from './WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto';
import {
    WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSONTyped,
    WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoToJSON,
} from './WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceGroupResultDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceGroupResultDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupResultDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupResultDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupResultDto
     */
    isDefaultGroup?: boolean;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesGroupType}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupResultDto
     */
    groupType?: WebApiModelsEnumerationTypesGroupType;
    /**
     * 
     * @type {Array<WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto>}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupResultDto
     */
    members?: Array<WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceGroupResultDto
     */
    numberOfMembers?: number;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceGroupResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceGroupResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceGroupResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceGroupResultDto {
    return WebApiServicesDataTransferObjectsGroupServiceGroupResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceGroupResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceGroupResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isDefaultGroup': !exists(json, 'isDefaultGroup') ? undefined : json['isDefaultGroup'],
        'groupType': !exists(json, 'groupType') ? undefined : WebApiModelsEnumerationTypesGroupTypeFromJSON(json['groupType']),
        'members': !exists(json, 'members') ? undefined : (json['members'] === null ? null : (json['members'] as Array<any>).map(WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoFromJSON)),
        'numberOfMembers': !exists(json, 'numberOfMembers') ? undefined : json['numberOfMembers'],
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceGroupResultDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'isDefaultGroup': value.isDefaultGroup,
        'groupType': WebApiModelsEnumerationTypesGroupTypeToJSON(value.groupType),
        'members': value.members === undefined ? undefined : (value.members === null ? null : (value.members as Array<any>).map(WebApiServicesDataTransferObjectsGroupServiceGroupMemberDtoToJSON)),
        'numberOfMembers': value.numberOfMembers,
    };
}

