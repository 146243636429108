/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiCompanyDtoCompanyPublicSiteDto,
  WebApiMemberDtoAddMemberFromPublicSiteDto,
  WebApiMemberDtoCreatePasswordCodeDto,
  WebApiMemberDtoGetMemberByPasswordCodeResultDto,
  WebApiModelsMemberField,
  WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode,
  WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto,
} from '../models';
import {
    WebApiCompanyDtoCompanyPublicSiteDtoFromJSON,
    WebApiCompanyDtoCompanyPublicSiteDtoToJSON,
    WebApiMemberDtoAddMemberFromPublicSiteDtoFromJSON,
    WebApiMemberDtoAddMemberFromPublicSiteDtoToJSON,
    WebApiMemberDtoCreatePasswordCodeDtoFromJSON,
    WebApiMemberDtoCreatePasswordCodeDtoToJSON,
    WebApiMemberDtoGetMemberByPasswordCodeResultDtoFromJSON,
    WebApiMemberDtoGetMemberByPasswordCodeResultDtoToJSON,
    WebApiModelsMemberFieldFromJSON,
    WebApiModelsMemberFieldToJSON,
    WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCodeFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCodeToJSON,
    WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDtoToJSON,
} from '../models';

export interface ApiPublicSiteActivateAccountFromPublicSitePostRequest {
    webApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode?: WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode;
}

export interface ApiPublicSiteAddMemberFromPublicSitePostRequest {
    webApiMemberDtoAddMemberFromPublicSiteDto?: WebApiMemberDtoAddMemberFromPublicSiteDto;
}

export interface ApiPublicSiteCreatePasswordCodePostRequest {
    webApiMemberDtoCreatePasswordCodeDto?: WebApiMemberDtoCreatePasswordCodeDto;
}

export interface ApiPublicSiteGetCompanyPublicSiteByUrlGetRequest {
    url?: string;
}

export interface ApiPublicSiteGetMemberFieldsPublicSiteByUrlGetRequest {
    url?: string;
}

export interface ApiPublicSiteGetMemberFromPasswordCodeGetRequest {
    companyUrl?: string;
    passwordCode?: string;
}

export interface ApiPublicSiteUpdateMemberByPasswordCodePostRequest {
    webApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto?: WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto;
}

/**
 * 
 */
export class PublicSiteApi extends runtime.BaseAPI {

    /**
     * 
     */
    async apiPublicSiteActivateAccountFromPublicSitePostRaw(requestParameters: ApiPublicSiteActivateAccountFromPublicSitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PublicSite/ActivateAccountFromPublicSite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCodeToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiPublicSiteActivateAccountFromPublicSitePost(requestParameters: ApiPublicSiteActivateAccountFromPublicSitePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiPublicSiteActivateAccountFromPublicSitePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiPublicSiteAddMemberFromPublicSitePostRaw(requestParameters: ApiPublicSiteAddMemberFromPublicSitePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PublicSite/AddMemberFromPublicSite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiMemberDtoAddMemberFromPublicSiteDtoToJSON(requestParameters.webApiMemberDtoAddMemberFromPublicSiteDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiPublicSiteAddMemberFromPublicSitePost(requestParameters: ApiPublicSiteAddMemberFromPublicSitePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiPublicSiteAddMemberFromPublicSitePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiPublicSiteCreatePasswordCodePostRaw(requestParameters: ApiPublicSiteCreatePasswordCodePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PublicSite/CreatePasswordCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiMemberDtoCreatePasswordCodeDtoToJSON(requestParameters.webApiMemberDtoCreatePasswordCodeDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiPublicSiteCreatePasswordCodePost(requestParameters: ApiPublicSiteCreatePasswordCodePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiPublicSiteCreatePasswordCodePostRaw(requestParameters, initOverrides);
    }

    /**
     * Get company public site by url
     */
    async apiPublicSiteGetCompanyPublicSiteByUrlGetRaw(requestParameters: ApiPublicSiteGetCompanyPublicSiteByUrlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiCompanyDtoCompanyPublicSiteDto>> {
        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PublicSite/GetCompanyPublicSiteByUrl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiCompanyDtoCompanyPublicSiteDtoFromJSON(jsonValue));
    }

    /**
     * Get company public site by url
     */
    async apiPublicSiteGetCompanyPublicSiteByUrlGet(requestParameters: ApiPublicSiteGetCompanyPublicSiteByUrlGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiCompanyDtoCompanyPublicSiteDto> {
        const response = await this.apiPublicSiteGetCompanyPublicSiteByUrlGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiPublicSiteGetMemberFieldsPublicSiteByUrlGetRaw(requestParameters: ApiPublicSiteGetMemberFieldsPublicSiteByUrlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiModelsMemberField>>> {
        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PublicSite/GetMemberFieldsPublicSiteByUrl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiModelsMemberFieldFromJSON));
    }

    /**
     * 
     */
    async apiPublicSiteGetMemberFieldsPublicSiteByUrlGet(requestParameters: ApiPublicSiteGetMemberFieldsPublicSiteByUrlGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiModelsMemberField>> {
        const response = await this.apiPublicSiteGetMemberFieldsPublicSiteByUrlGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiPublicSiteGetMemberFromPasswordCodeGetRaw(requestParameters: ApiPublicSiteGetMemberFromPasswordCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiMemberDtoGetMemberByPasswordCodeResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.companyUrl !== undefined) {
            queryParameters['companyUrl'] = requestParameters.companyUrl;
        }

        if (requestParameters.passwordCode !== undefined) {
            queryParameters['passwordCode'] = requestParameters.passwordCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PublicSite/GetMemberFromPasswordCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiMemberDtoGetMemberByPasswordCodeResultDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiPublicSiteGetMemberFromPasswordCodeGet(requestParameters: ApiPublicSiteGetMemberFromPasswordCodeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiMemberDtoGetMemberByPasswordCodeResultDto> {
        const response = await this.apiPublicSiteGetMemberFromPasswordCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiPublicSiteUpdateMemberByPasswordCodePostRaw(requestParameters: ApiPublicSiteUpdateMemberByPasswordCodePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/PublicSite/UpdateMemberByPasswordCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiPublicSiteUpdateMemberByPasswordCodePost(requestParameters: ApiPublicSiteUpdateMemberByPasswordCodePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiPublicSiteUpdateMemberByPasswordCodePostRaw(requestParameters, initOverrides);
    }

}
