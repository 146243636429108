import { ApiPublicSiteGetMemberFromPasswordCodeGetRequest, PublicSiteApi, WebApiMemberDtoAddMemberFromPublicSiteDto, WebApiMemberDtoCreatePasswordCodeDto, WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode, WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto, } from "../Repository/eventbookyapi";
import { GetApiConfigurationMember } from "./ServiceConfiguration";

export abstract class PublicSiteService {

    public static CreatePasswordCode = async (request: WebApiMemberDtoCreatePasswordCodeDto) => {
        return new PublicSiteApi(GetApiConfigurationMember()).apiPublicSiteCreatePasswordCodePost({
            webApiMemberDtoCreatePasswordCodeDto: request
        });
    };

    public static GetMemberByPasswordCode = async (request: ApiPublicSiteGetMemberFromPasswordCodeGetRequest) => {
        return new PublicSiteApi(GetApiConfigurationMember()).apiPublicSiteGetMemberFromPasswordCodeGet(request);
    };

    public static UpdateMemberByPasswordCode = async (request: WebApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto) => {
        return new PublicSiteApi(GetApiConfigurationMember()).apiPublicSiteUpdateMemberByPasswordCodePost({
            webApiServicesDataTransferObjectsGroupServiceUpdateMemberByPasswordCodeDto: request
        });
    };

    public static AddmemberByMember = async (request: WebApiMemberDtoAddMemberFromPublicSiteDto) => {
        return new PublicSiteApi(GetApiConfigurationMember()).apiPublicSiteAddMemberFromPublicSitePost({
            webApiMemberDtoAddMemberFromPublicSiteDto: request
        });
    };

    public static GetMemberFieldsByUrl = async (url: string) => {
        return new PublicSiteApi(GetApiConfigurationMember()).apiPublicSiteGetMemberFieldsPublicSiteByUrlGet({ url });
    };


    public static ActiveMemberAccount = async (request: WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode) => {
        return new PublicSiteApi(GetApiConfigurationMember()).apiPublicSiteActivateAccountFromPublicSitePost({
            webApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode: request
        });
    };

    public static GetCompanyPublicSiteByUrl = async (url: string) => {
        return new PublicSiteApi(GetApiConfigurationMember()).apiPublicSiteGetCompanyPublicSiteByUrlGet({ url: url });
    };
};
