import { useEffect, useState } from 'react';
import { Col, Form, Modal, message } from 'antd';
import { GroupService } from '../../Services/GroupService';
import { TextService } from '../../Services/TextService';
import { BookyInput } from '../../Components/Forms/BookyInput';
import { BookyButton } from '../../Components/Buttons/BookyButton';
import { OneFrontHtmlEditor } from '../../Components/Forms/OneFrontHtmlEditor';
import { BookySelectDropDown } from '../../Components/Forms/BookySelectDropDown';
import { BookyCheckbox } from '../../Components/Forms/BookyCheckbox';
import { EmailTemplateService } from '../../Services/EmailTemplateService';
import { EditOutlined } from '@ant-design/icons';

export const EditEmailTemplate = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [initialHtml, setInitialHtml] = useState("" as string);

    const GetText = TextService.GetText;

    const onCancel = () => {
        clearForm();
        setOpen(false);
    };

    const onHtmlChange = (value: string) => {
        form.setFieldValue("body", value);
    }

    const setFieldsFromProps = () => {
        form.setFieldValue("templateName", props.template.templateName);
        form.setFieldValue("subject", props.template.subject);
        setInitialHtml(props.template.body);
        form.setFieldValue("body", props.template.body);
        form.setFieldValue("sendWhenUserIsCreated", props.template.purpose === 1 ? true : false);
    }

    const onCreate = async (values: any) => {
        try {
            await EmailTemplateService.EditTemplate({
                templateId: props.template.id,
                templateName: values.templateName,
                subject: values.subject,
                body: values.body,
                purpose: values.sendWhenUserIsCreated === true ? 1 : 0,
            });

        } catch (error) {
            message.warning(GetText("CreateTemplate_ErrorAlreadyExist"));
            return;
        }

        setOpen(false);
        props.reloadTemplate();
    }

    const clearForm = () => {
        form.setFieldValue("templateName", "");
        form.setFieldValue("subject", "");
        setInitialHtml("");
        form.setFieldValue("sendWhenUserIsCreated", false);
    }

    return (
        <>
            {open ? <Modal
                width={900}
                open={open}
                title={GetText("EditTemplate_ModalHeader")}
                okText={GetText("EditTemplate_CreateText")}
                cancelText={GetText("EditTemplate_CancelText")}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={onCancel}
            >
                <Form form={form} onFinish={onCreate} layout='vertical'>
                    <Col span={24}>
                        <BookyInput name="templateName" requiredMessage={GetText("CreateTemplate_RequiredText")} label={GetText("CreateTemplate_PlaceHolderText")} />
                        <BookyInput name="subject" requiredMessage={GetText("CreateTemplate_RequiredText")} label={GetText("CreateTemplate_Subject")} />
                        <OneFrontHtmlEditor initialHtml={initialHtml} onHtmlChange={onHtmlChange} label={GetText("CreateTemplate_Body")} name="body" requiredMessage={GetText("EmailForm_MessageRequired")} />
                        <BookyCheckbox label={GetText("CreateTemplate_NewMemberRegister")} name="sendWhenUserIsCreated"></BookyCheckbox>
                    </Col>
                </Form>
            </Modal> : ""}

            <EditOutlined
                style={{ fontSize: "20px" }}
                type="primary"
                onClick={() => {
                    setOpen(true);
                    setFieldsFromProps();
                }}
            >
            </EditOutlined>
        </>
    );
};

export default EditEmailTemplate;