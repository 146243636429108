import { Col, Drawer, List, Menu, Row, } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider } from '../../../Providers/authProvider';
import { useEffect, useState } from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';
import { MenuUnfoldOutlined, PlusOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { TextService } from '../../../Services/TextService';
import { getCurrentLanguage } from '../../../Helpers/RouteHelper';
import Cookies from 'universal-cookie';

const MobileDrawer = () => {
    const [user, setUser] = useState([] as any);
    const navigation = useNavigate();
    const history = useLocation();
    const [showMobileDrawer, setShowMobileDrawer] = useState(false);
    const GetText = TextService.GetText;

    const showDrawer = () => {
        setShowMobileDrawer(true);
    };

    const hideDrawer = () => {
        setShowMobileDrawer(false);
    };


    const handleClick = async (key: string) => {

        if (key === "/logout") {
            await AuthProvider().Logout();
            setUser(null);
            navigation("/" + getCurrentLanguage() + "/logout");
        }
        else if (key === "/se" || key === "/en") {

            let oldNav = history.pathname.replace("/en", '');
            oldNav = oldNav.replace("/se", '');

            const cookies = new Cookies();
            cookies.set('language', key.replace("/", ''));
            navigation(key + oldNav);
        }
        else {
            navigation("/" + getCurrentLanguage() + key);
        }
        hideDrawer();
    };

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        });
    }, [history.pathname]);

    const getSelectedClass = (url: string) => {
        if ((window.location.pathname === "/" && url === "/Home") || window.location.pathname.indexOf(url) != -1) {
            return "mobile-navigation-item-selected";
        }

        return "";
    }

    if (user?.token != null) {
        return <>

            <MenuUnfoldOutlined className="mobile-menu-drawer ant-menu-item" onClick={showDrawer} style={{ fontSize: "20px", marginTop: "20px", marginRight: "10px" }} />
            <Drawer
                title="OneFront"
                placement="right"
                open={showMobileDrawer}
                onClose={hideDrawer}
                closable={false}
            >
                <List>
                    <List.Item onClick={opt => { handleClick("/") }} className={getSelectedClass("/")}> {GetText("TopHeader_Home")} </List.Item>
                    <List.Item onClick={opt => { handleClick("/CreateUser") }} className={getSelectedClass("/CreateUser")}> {GetText("TopHeader_CreateAccount")} </List.Item>
                    <List.Item onClick={opt => { handleClick("/Pricing") }} className={getSelectedClass("/Pricing")}> {GetText("TopHeader_Pricing")} </List.Item>
                    <List.Item onClick={opt => { handleClick("/Contact") }} className={getSelectedClass("/Contact")}> {GetText("TopHeader_Contact")} </List.Item>
                    <List.Item onClick={opt => { handleClick("/create") }} className={getSelectedClass("/create")}>{GetText("TopHeader_CreateEvent")}</List.Item>
                    <List.Item onClick={opt => { handleClick("/Groups") }} className={getSelectedClass("/Groups")}> {GetText("TopHeader_Groups")}</List.Item>
                    <List.Item onClick={opt => { handleClick("/Settings") }} className={getSelectedClass("/Settings")}> {GetText("TopHeader_Settings")}</List.Item>

                    <List.Item onClick={opt => { handleClick("/logout") }} className={getSelectedClass("/Logout")}> {GetText("TopHeader_Logout")}</List.Item>
                    <List.Item onClick={opt => { handleClick("/en") }} className={getSelectedClass("/en")}>{GetText("TopHeader_English")}</List.Item>
                    <List.Item onClick={opt => { handleClick("/se") }} className={getSelectedClass("/se")}>{GetText("TopHeader_Swedish")}</List.Item>
                </List>
            </Drawer>
        </>
    }
    else {
        return <>
            <Menu.Item className="mobile-menu-drawer ant-menu-item" key="whatever" onClick={showDrawer}>
                <MenuUnfoldOutlined style={{ fontSize: "30px" }} />
            </Menu.Item>
            <Drawer
                title="Menu"
                placement="right"
                open={showMobileDrawer}
                onClose={hideDrawer}
                closable={false}
            >
                <List>
                    <List.Item onClick={opt => { handleClick("/") }} className={getSelectedClass("/")}> {GetText("TopHeader_Home")} </List.Item>
                    <List.Item onClick={opt => { handleClick("/CreateUser") }} className={getSelectedClass("/CreateUser")}> {GetText("TopHeader_CreateAccount")} </List.Item>
                    <List.Item onClick={opt => { handleClick("/Pricing") }} className={getSelectedClass("/Pricing")}> {GetText("TopHeader_Pricing")} </List.Item>
                    <List.Item onClick={opt => { handleClick("/Contact") }} className={getSelectedClass("/Contact")}> {GetText("TopHeader_Contact")} </List.Item>
                    <List.Item onClick={opt => { handleClick("/create") }} className={getSelectedClass("/create")}>{GetText("TopHeader_CreateEvent")}</List.Item>
                    <List.Item onClick={opt => { handleClick("/logout") }} className={getSelectedClass("/Login")}> {GetText("TopHeader_Login")}</List.Item>
                    <List.Item onClick={opt => { handleClick("/en") }} className={getSelectedClass("/en")}>{GetText("TopHeader_English")}</List.Item>
                    <List.Item onClick={opt => { handleClick("/se") }} className={getSelectedClass("/se")}>{GetText("TopHeader_Swedish")}</List.Item>
                </List>
            </Drawer>
        </>
    }

};

export default MobileDrawer;