

import React, { useEffect, useState } from 'react';
import { Alert, Form, Modal, Table, Upload, message } from 'antd';
import { BookyButton } from '../../Buttons/BookyButton';
import { WebApiMemberDtoMemberDto, WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../../Repository/eventbookyapi';
import { SubscriptionLevel } from '../../../Types/SubscriptionLevel';
import { ColumnsType } from 'antd/lib/table';
import { isNumeric } from '../../../Helpers/Validators';
import { TextService } from '../../../Services/TextService';
import { FileOutlined } from '@ant-design/icons';
import { MemberService } from '../../../Services/MemberService';
import { getDateStringFromDate } from '../../../Helpers/DateHelpers';
import moment from 'moment';
const xlsx = require('xlsx');

const enum UploadErrorType { Unspecified, EmptyFile, FreeTooManyUsers, PremiumTooManyUsers }

export const UploadPaymentButton = (props: any) => {
    const [form] = Form.useForm();
    const [payments, setPayments] = useState([] as WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment[]);
    const [validPayments, setValidPayments] = useState([] as WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment[]);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(UploadErrorType.Unspecified as UploadErrorType);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({} as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto);
    const [disableUploadButton, setDisableUploadButton] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const GetText = TextService.GetText;

    const CloseModalAndClearState = () => {
        setOpen(false);
        setError(UploadErrorType.Unspecified);
        setIsLoading(false);
        setSuccessText(GetText("Common_UploadComplete"));
    }

    const columns: ColumnsType<WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment> = [
        {
            title: GetText("UploadPaymentData_MemberId"),
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, payment: any) => (
                payment.memberIdHasError ? <span className='rejected'>{payment.memberUniqueId}<br /></span> : <span>{payment.memberUniqueId}<br /></span>
            ),
        },
        {
            title: GetText("UploadPaymentData_Year"),
            dataIndex: 'firstname',
            key: 'firstname',
            render: (_, payment: any) => (
                payment.yearHasError ? <span className='rejected'>{payment.year}<br /></span> : <span>{payment.year}<br /></span>
            ),
        },
        {
            title: GetText("UploadPaymentData_Amount"),
            dataIndex: 'name',
            key: 'name',
            render: (_, payment: any) => (
                payment.amountHasError ? <span className='rejected'>{payment.amount}<br /></span> : <span>{payment.amount}<br /></span>
            ),
        },
        {
            title: GetText("UploadPaymentData_PaymentDate"),
            dataIndex: 'name',
            key: 'name',
            render: (_, payment: any) => (
                payment.amountHasError ? <span className='rejected'>{getDateStringFromDate(payment.paymentDate)}<br /></span> : <span>{getDateStringFromDate(payment.paymentDate)}<br /></span>
            ),
        },
    ];

    const showError = (error: UploadErrorType) => {
        let text = "";
        switch (error) {
            case UploadErrorType.EmptyFile:
                text = GetText("UploadMembers_WrongFormat"); break;
            case UploadErrorType.FreeTooManyUsers:
                text = GetText("UploadMembers_Found") + payments.length + GetText("UploadMembers_ErrorMoreThenMaxMembersInFree1") + GetText("UploadMembers_ErrorMoreThenMaxMembersInFree2");
                break;
            case UploadErrorType.PremiumTooManyUsers:
                text = GetText("UploadMembers_Found") + payments.length + GetText("UploadMembers_ErrorMoreThenMaxMembersInPremium");
                break;
        }

        setErrorText(text);
    };

    const trimFromCsv = (value: string) => {
        return value ? value.trim() : ""
    }

    const onFileUpload = (event: any, reader: any) => {
        setDisableUploadButton(false);
        var text = reader.result?.toString();

        const { result } = event.target;
        const workbook = xlsx.read(result, { type: "binary" });

        var allLines = [];
        if (workbook && workbook.SheetNames) {
            for (const Sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(Sheet)) {
                    text = xlsx.utils.sheet_to_csv(workbook.Sheets[Sheet], { FS: ";" });
                }
            }
        }

        allLines = text!.split((/\r|\n|\r/));
        if (!allLines) {
            setDisableUploadButton(true);
            showError(UploadErrorType.EmptyFile);
            return;
        }

        allLines.shift();

        const paymentsArray: any[] = [];
        const validPaymentsArray: WebApiServicesDataTransferObjectsGroupServiceAddBulkPayment[] = [];
        allLines.forEach(function (line: any) {
            const memberInFile = line.split(';');
            let memberUniqueId = trimFromCsv(memberInFile[0]);
            let amount = trimFromCsv(memberInFile[3]);
            let paymentDate = trimFromCsv(memberInFile[4]);

            let yearHasError = false;
            let amountHasError = false;
            let memberIdHasError = false;
            if (!memberUniqueId && !amount) {
                return;
            }

            if (!isNumeric(trimFromCsv(amount))) {
                amount = GetText("UploadPaymentData_AmountValidationMessage") + amount;
                amountHasError = true;
            }

            var date = moment(paymentDate);


            if (!props.members.some((p: WebApiMemberDtoMemberDto) => p.uniqueId == memberUniqueId)) {
                memberUniqueId = GetText("UploadPaymentData_MemberIdValidationMessage") + memberUniqueId;
                memberIdHasError = true;
            }

            if (!amountHasError && !yearHasError && !memberIdHasError) {
                validPaymentsArray.push({
                    memberUniqueId: memberUniqueId,
                    paymentDate: date.isValid() ? new Date(moment(paymentDate).format('YYYY-MM-DD')) : undefined,
                    amount: Number(amount),
                });
            }

            paymentsArray.push({
                yearHasError: yearHasError,
                amountHasError: amountHasError,
                memberIdHasError: memberIdHasError,
                memberUniqueId: memberUniqueId,
                paymentDate: paymentDate,
                amount: amount,
            });
        });

        if (validPaymentsArray.length === paymentsArray.length) {
            setDisableUploadButton(false);
        }
        else {
            setDisableUploadButton(true);
        }

        if (props.user.subscriptionLevel == SubscriptionLevel.Free && (allLines.length + props.totalMemberCount) > 25) {
            showError(UploadErrorType.FreeTooManyUsers);
            return;
        };

        if (user?.hasPaidSubscription && (allLines.length + props.totalMemberCount) > 999) {
            showError(UploadErrorType.PremiumTooManyUsers);
            return;
        };

        setPayments(paymentsArray);
        setValidPayments(validPaymentsArray);
        setOpen(true);
    }

    return (
        <>
            <Upload
                accept=".txt, .csv, .xlsx"
                showUploadList={false}
                beforeUpload={file => {
                    const reader = new FileReader();

                    reader.onload = async e => {
                        onFileUpload(e, reader);
                    };
                    reader.readAsBinaryString(file);
                    return false;
                }}
            >
                <BookyButton style={{ width: "250px" }} icon={<FileOutlined />} text={GetText("Common_ChooseFile")}>
                </BookyButton>
            </Upload>
            <Modal
                open={open}
                title={GetText("UploadMembers_ModalTitlePreview")}
                okText={error == UploadErrorType.Unspecified ? GetText("UploadMembers_ModalAdd") + payments.length + GetText("UploadPaymentData_ModalMembers") : GetText("UploadMembers_ModalOk")}
                cancelText={GetText("UploadMembers_ModalCancel")}
                width={1800}
                confirmLoading={isLoading}
                okButtonProps={{ disabled: disableUploadButton }}
                onCancel={() => { CloseModalAndClearState(); }}
                onOk={async () => {
                    setIsLoading(true);
                    if (validPayments.length > 0 && error == UploadErrorType.Unspecified) {
                        await MemberService.AddPaymentsInBulk({
                            payments: validPayments,
                            year: props.year,
                        })
                        CloseModalAndClearState();
                    }
                    CloseModalAndClearState();
                }}
            >
                {error == UploadErrorType.Unspecified ?
                    <>
                        <i>{GetText("UploadPaymentData_FoundInFile1")} {payments.length} {GetText("UploadPaymentData_FoundInFile2")} {payments.length - validPayments.length} {GetText("UploadPaymentData_FoundInFile3")}</i>
                        <Table scroll={{ x: "max-content" }} columns={columns} style={{ marginTop: "20px" }} locale={{
                            emptyText: GetText("UploadPaymentData_NoPaymentsFound")
                        }} className="admin-table" rowKey="Id" dataSource={payments}></Table>
                    </>
                    : ""}
            </Modal>

            {errorText ? <Alert style={{ marginTop: "20px" }} showIcon={true} type="error" message={errorText}></Alert> : ""}
            {successText ? <Alert style={{ marginTop: "20px" }} showIcon={true} type="success" message={successText}></Alert> : ""}

        </>
    );
};

export default UploadPaymentButton;