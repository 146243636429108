/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiIntegrationsDtoConnectIntegrationDto,
  WebApiIntegrationsDtoIntegrationDto,
  WebApiIntegrationsObjectsIntegrationType,
} from '../models';
import {
    WebApiIntegrationsDtoConnectIntegrationDtoFromJSON,
    WebApiIntegrationsDtoConnectIntegrationDtoToJSON,
    WebApiIntegrationsDtoIntegrationDtoFromJSON,
    WebApiIntegrationsDtoIntegrationDtoToJSON,
    WebApiIntegrationsObjectsIntegrationTypeFromJSON,
    WebApiIntegrationsObjectsIntegrationTypeToJSON,
} from '../models';

export interface ApiIntegrationDeleteRequest {
    integrationId?: number;
}

export interface ApiIntegrationGetRequest {
    type?: WebApiIntegrationsObjectsIntegrationType;
}

export interface ApiIntegrationGetInvoicesGetRequest {
    type?: WebApiIntegrationsObjectsIntegrationType;
}

export interface ApiIntegrationPostRequest {
    webApiIntegrationsDtoConnectIntegrationDto?: WebApiIntegrationsDtoConnectIntegrationDto;
}

export interface ApiIntegrationSyncFortNoxDataPostRequest {
    body?: number;
}

/**
 * 
 */
export class IntegrationApi extends runtime.BaseAPI {

    /**
     * Get integration by type
     */
    async apiIntegrationDeleteRaw(requestParameters: ApiIntegrationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.integrationId !== undefined) {
            queryParameters['integrationId'] = requestParameters.integrationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Integration`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get integration by type
     */
    async apiIntegrationDelete(requestParameters: ApiIntegrationDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiIntegrationDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get integration by type
     */
    async apiIntegrationGetRaw(requestParameters: ApiIntegrationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiIntegrationsDtoIntegrationDto>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Integration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiIntegrationsDtoIntegrationDtoFromJSON(jsonValue));
    }

    /**
     * Get integration by type
     */
    async apiIntegrationGet(requestParameters: ApiIntegrationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiIntegrationsDtoIntegrationDto> {
        const response = await this.apiIntegrationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get integration by type
     */
    async apiIntegrationGetActiveIntegrationsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiIntegrationsDtoIntegrationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Integration/GetActiveIntegrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiIntegrationsDtoIntegrationDtoFromJSON));
    }

    /**
     * Get integration by type
     */
    async apiIntegrationGetActiveIntegrationsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiIntegrationsDtoIntegrationDto>> {
        const response = await this.apiIntegrationGetActiveIntegrationsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiIntegrationGetInvoicesGetRaw(requestParameters: ApiIntegrationGetInvoicesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Integration/GetInvoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiIntegrationGetInvoicesGet(requestParameters: ApiIntegrationGetInvoicesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiIntegrationGetInvoicesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiIntegrationPostRaw(requestParameters: ApiIntegrationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiIntegrationsDtoIntegrationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Integration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiIntegrationsDtoConnectIntegrationDtoToJSON(requestParameters.webApiIntegrationsDtoConnectIntegrationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiIntegrationsDtoIntegrationDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiIntegrationPost(requestParameters: ApiIntegrationPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiIntegrationsDtoIntegrationDto> {
        const response = await this.apiIntegrationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets and Save invoices
     */
    async apiIntegrationSyncFortNoxDataPostRaw(requestParameters: ApiIntegrationSyncFortNoxDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Integration/SyncFortNoxData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Gets and Save invoices
     */
    async apiIntegrationSyncFortNoxDataPost(requestParameters: ApiIntegrationSyncFortNoxDataPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiIntegrationSyncFortNoxDataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
