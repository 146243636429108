/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesCompanyTypeConfiguration } from './WebApiModelsEnumerationTypesCompanyTypeConfiguration';
import {
    WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSON,
    WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSONTyped,
    WebApiModelsEnumerationTypesCompanyTypeConfigurationToJSON,
} from './WebApiModelsEnumerationTypesCompanyTypeConfiguration';
import type { WebApiModelsEnumerationTypesPaymenConfiguration } from './WebApiModelsEnumerationTypesPaymenConfiguration';
import {
    WebApiModelsEnumerationTypesPaymenConfigurationFromJSON,
    WebApiModelsEnumerationTypesPaymenConfigurationFromJSONTyped,
    WebApiModelsEnumerationTypesPaymenConfigurationToJSON,
} from './WebApiModelsEnumerationTypesPaymenConfiguration';

/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.RegisterAsync(WebApi.Services.DataTransferObjects.UserService.RegisterReqDto) request.
 * @export
 * @interface WebApiCompanyDtoCompanyDto
 */
export interface WebApiCompanyDtoCompanyDto {
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyDto
     */
    language?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyDto
     */
    name?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyDto
     */
    email?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyDto
     */
    domain?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiCompanyDtoCompanyDto
     */
    smsSender?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesPaymenConfiguration}
     * @memberof WebApiCompanyDtoCompanyDto
     */
    paymentConfiguration?: WebApiModelsEnumerationTypesPaymenConfiguration;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesCompanyTypeConfiguration}
     * @memberof WebApiCompanyDtoCompanyDto
     */
    companyTypeConfiguration?: WebApiModelsEnumerationTypesCompanyTypeConfiguration;
}

/**
 * Check if a given object implements the WebApiCompanyDtoCompanyDto interface.
 */
export function instanceOfWebApiCompanyDtoCompanyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiCompanyDtoCompanyDtoFromJSON(json: any): WebApiCompanyDtoCompanyDto {
    return WebApiCompanyDtoCompanyDtoFromJSONTyped(json, false);
}

export function WebApiCompanyDtoCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiCompanyDtoCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': !exists(json, 'language') ? undefined : json['language'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'smsSender': !exists(json, 'smsSender') ? undefined : json['smsSender'],
        'paymentConfiguration': !exists(json, 'paymentConfiguration') ? undefined : WebApiModelsEnumerationTypesPaymenConfigurationFromJSON(json['paymentConfiguration']),
        'companyTypeConfiguration': !exists(json, 'companyTypeConfiguration') ? undefined : WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSON(json['companyTypeConfiguration']),
    };
}

export function WebApiCompanyDtoCompanyDtoToJSON(value?: WebApiCompanyDtoCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': value.language,
        'name': value.name,
        'email': value.email,
        'domain': value.domain,
        'smsSender': value.smsSender,
        'paymentConfiguration': WebApiModelsEnumerationTypesPaymenConfigurationToJSON(value.paymentConfiguration),
        'companyTypeConfiguration': WebApiModelsEnumerationTypesCompanyTypeConfigurationToJSON(value.companyTypeConfiguration),
    };
}

