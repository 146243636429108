/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer } from './WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer';
import {
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerFromJSONTyped,
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerToJSON,
} from './WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer';

/**
 * CreateReply dto
 * @export
 * @interface WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto
 */
export interface WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto
     */
    inviteId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto
     */
    answer?: string | null;
    /**
     * 
     * @type {Array<WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer>}
     * @memberof WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto
     */
    timeAnswers?: Array<WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswer> | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsReplyServiceCreateReplyDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateReplyDtoFromJSON(json: any): WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto {
    return WebApiServicesDataTransferObjectsReplyServiceCreateReplyDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateReplyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'inviteId': !exists(json, 'inviteId') ? undefined : json['inviteId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'timeAnswers': !exists(json, 'timeAnswers') ? undefined : (json['timeAnswers'] === null ? null : (json['timeAnswers'] as Array<any>).map(WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerFromJSON)),
    };
}

export function WebApiServicesDataTransferObjectsReplyServiceCreateReplyDtoToJSON(value?: WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'eventId': value.eventId,
        'inviteId': value.inviteId,
        'email': value.email,
        'answer': value.answer,
        'timeAnswers': value.timeAnswers === undefined ? undefined : (value.timeAnswers === null ? null : (value.timeAnswers as Array<any>).map(WebApiServicesDataTransferObjectsReplyServiceCreateReplyTimeAnswerToJSON)),
    };
}

