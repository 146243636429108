/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto,
  WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDto,
  WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto,
  WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto,
  WebApiServicesDataTransferObjectsReplyServiceGetReplyDto,
  WebApiServicesDataTransferObjectsReplyServiceUpdateReplyDto,
} from '../models';
import {
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyDtoToJSON,
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDtoToJSON,
    WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDtoToJSON,
    WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDtoToJSON,
    WebApiServicesDataTransferObjectsReplyServiceGetReplyDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceGetReplyDtoToJSON,
    WebApiServicesDataTransferObjectsReplyServiceUpdateReplyDtoFromJSON,
    WebApiServicesDataTransferObjectsReplyServiceUpdateReplyDtoToJSON,
} from '../models';

export interface ApiReplyCreatePostRequest {
    webApiServicesDataTransferObjectsReplyServiceCreateReplyDto?: WebApiServicesDataTransferObjectsReplyServiceCreateReplyDto;
}

export interface ApiReplyDeleteRequest {
    webApiServicesDataTransferObjectsReplyServiceDeleteReplyDto?: WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDto;
}

export interface ApiReplyEventIdGetRequest {
    eventId: string;
}

export interface ApiReplyGetReplyByInviteGetRequest {
    eventId?: string;
    inviteId?: string;
    authCode?: string;
}

export interface ApiReplyUpdatePostRequest {
    webApiServicesDataTransferObjectsReplyServiceUpdateReplyDto?: WebApiServicesDataTransferObjectsReplyServiceUpdateReplyDto;
}

/**
 * 
 */
export class ReplyApi extends runtime.BaseAPI {

    /**
     * Creates new reply to event
     */
    async apiReplyCreatePostRaw(requestParameters: ApiReplyCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reply/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsReplyServiceCreateReplyDtoToJSON(requestParameters.webApiServicesDataTransferObjectsReplyServiceCreateReplyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDtoFromJSON(jsonValue));
    }

    /**
     * Creates new reply to event
     */
    async apiReplyCreatePost(requestParameters: ApiReplyCreatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDto> {
        const response = await this.apiReplyCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiReplyDeleteRaw(requestParameters: ApiReplyDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsReplyServiceGetReplyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reply`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsReplyServiceDeleteReplyDtoToJSON(requestParameters.webApiServicesDataTransferObjectsReplyServiceDeleteReplyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsReplyServiceGetReplyDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiReplyDelete(requestParameters: ApiReplyDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsReplyServiceGetReplyDto> {
        const response = await this.apiReplyDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates new reply to event
     */
    async apiReplyEventIdGetRaw(requestParameters: ApiReplyEventIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto>> {
        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling apiReplyEventIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reply/{EventId}`.replace(`{${"EventId"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDtoFromJSON(jsonValue));
    }

    /**
     * Creates new reply to event
     */
    async apiReplyEventIdGet(requestParameters: ApiReplyEventIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsReplyServiceGetRepliesResultDto> {
        const response = await this.apiReplyEventIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiReplyGetReplyByInviteGetRaw(requestParameters: ApiReplyGetReplyByInviteGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsReplyServiceGetReplyDto>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['EventId'] = requestParameters.eventId;
        }

        if (requestParameters.inviteId !== undefined) {
            queryParameters['InviteId'] = requestParameters.inviteId;
        }

        if (requestParameters.authCode !== undefined) {
            queryParameters['AuthCode'] = requestParameters.authCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reply/GetReplyByInvite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsReplyServiceGetReplyDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiReplyGetReplyByInviteGet(requestParameters: ApiReplyGetReplyByInviteGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsReplyServiceGetReplyDto> {
        const response = await this.apiReplyGetReplyByInviteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates previous reply
     */
    async apiReplyUpdatePostRaw(requestParameters: ApiReplyUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reply/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsReplyServiceUpdateReplyDtoToJSON(requestParameters.webApiServicesDataTransferObjectsReplyServiceUpdateReplyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDtoFromJSON(jsonValue));
    }

    /**
     * Updates previous reply
     */
    async apiReplyUpdatePost(requestParameters: ApiReplyUpdatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsReplyServiceCreateReplyResultDto> {
        const response = await this.apiReplyUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
