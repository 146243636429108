import { Button, Col, Form, Image, Row, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import BookyBox from '../../Components/Layout/BookyBox';
import { BookyInput } from '../../Components/Forms/BookyInput';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import PagePublicSite from '../../Components/Page/PagePublicSite';
import { WebApiCompanyDtoCompanyPublicSiteDto, WebApiMemberDtoGetMemberByPasswordCodeResultDto, WebApiModelsMemberField } from '../../Repository/eventbookyapi';
import NotFound from '../../Components/Page/NotFoundPage';
import { BookyDynamicInput } from '../../Components/Forms/BookyDynamicInput';
import { MemberFieldTypes } from '../../Types/MemberFieldTypes';
import { PublicResultType } from './Types/PublicResultType';
import { PublicSiteService } from '../../Services/PublicSiteService';
import { BookyPhoneInput } from '../../Components/Forms/BookyPhoneInput';
import { getMemberFieldSetting } from '../../Helpers/MemberFieldsHelper';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getCurrentYearAsString } from '../../Helpers/DateHelpers';
import { BookyDynamicCheckbox } from '../../Components/Forms/BookyDynamicCheckbox';
import { AuthProviderMember } from '../../Providers/authProviderMember';

const PublicSiteEditMember = () => {

    enum PublicSiteState {
        Undefined,
        CompanyNotFound,
        MemberCodeNotFoundOrExpired,
        CompanyFound,
        MemberFoundByPasswordCode
    }


    const [publicSite, setPublicSite] = useState([] as WebApiCompanyDtoCompanyPublicSiteDto);
    const [memberFieldSettings, setMemberFieldSettings] = useState([] as WebApiModelsMemberField[]);
    const [state, setState] = useState(PublicSiteState.Undefined as PublicSiteState);
    const [member, setMember] = useState([] as WebApiMemberDtoGetMemberByPasswordCodeResultDto);
    const [hasPaidCurrentYear, setHasPaidCurrentYear] = useState(false as boolean);
    const [showPaymentStatus, setShowPaymentStatus] = useState(false as boolean);

    let params = useParams();
    const navigation = useNavigate();
    const [form] = Form.useForm();
    const GetText = TextService.GetText;

    useEffect(() => {
        if (params.companyurl != null) {
            try {
                PublicSiteService.GetCompanyPublicSiteByUrl(params.companyurl).then(result => {
                    setPublicSite(result);

                    if (!params.passwordcode) {
                        setState(PublicSiteState.CompanyFound);
                    }
                    else {
                        loadPassCode(params.passwordcode!)
                    }
                })

                PublicSiteService.GetMemberFieldsByUrl(params.companyurl).then(result => {
                    setMemberFieldSettings(result);

                    const field = getMemberFieldSetting(MemberFieldTypes.Payment, result);
                    setShowPaymentStatus(field.visiblePublic!);

                    if (!params.passwordcode) {
                        setState(PublicSiteState.CompanyFound);
                    }
                })
            } catch {
                setState(PublicSiteState.CompanyNotFound);
            }
        }
    }, [params, form])

    const loadPassCode = (passwordcode: string) => {
        if (passwordcode != null) {
            PublicSiteService.GetMemberByPasswordCode({
                companyUrl: params.companyurl,
                passwordCode: params.passwordcode
            }).then(result => {
                AuthProviderMember().SetToken(result.token!);
                setState(PublicSiteState.MemberFoundByPasswordCode);
                setMember(result);
                form.setFieldValue("firstName", result.firstName);
                form.setFieldValue("lastName", result.lastName);
                form.setFieldValue("phoneNumber", result.phone);
                form.setFieldValue("email", result.email);
                form.setFieldValue("email2", result.email2);
                form.setFieldValue("address", result.streetAdress);
                form.setFieldValue("postalCode", result.postalCode);
                form.setFieldValue("postalArea", result.postalArea);
                form.setFieldValue("uniqueId", result.uniqueId);
                form.setFieldValue("company", result.company);
                form.setFieldValue("country", result.country);
                form.setFieldValue("title", result.title);
                form.setFieldValue("extra1", result.extra1);
                form.setFieldValue("extra2", result.extra2);
                form.setFieldValue("extra3", result.extra3);
                form.setFieldValue("extra3", result.extra3);
                form.setFieldValue("emailNotificationPreference", result.emailNotificationPreference == 1 ? true : false);
                setHasPaidCurrentYear(result.hasPaidCurrentYear!);
            }).catch(error => {
                setState(PublicSiteState.MemberCodeNotFoundOrExpired);
            })
        }
    }

    const getDownloadUrl = (dto: WebApiCompanyDtoCompanyPublicSiteDto) => {
        return process.env.REACT_APP_API_URL + '/Image/downloadImage?bloburl=' + dto.siteLogoPath;
    }

    const FormCreateClick = async (formValues: any) => {

        try {
            await PublicSiteService.CreatePasswordCode({
                companyUrl: params.companyurl,
                email: formValues.email
            });
        } catch (error) {
            message.warning(GetText("PublicSiteEditMember_Error"));
            return;
        }

        navigation("/" + getCurrentLanguage() + "/" + params.companyurl + "/success/" + PublicResultType.MailHasBeenSent)
    }

    const MemberCreateClick = async (formValues: any) => {

        try {
            await PublicSiteService.UpdateMemberByPasswordCode({
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                email2: formValues.email2,
                phoneNumber: formValues.phoneNumber,
                address: formValues.address,
                postalArea: formValues.postalArea,
                postalCode: formValues.postalCode,
                country: formValues.country,
                company: formValues.company,
                title: formValues.title,
                extra1: formValues.extra1,
                extra2: formValues.extra2,
                extra3: formValues.extra3,
                emailNotificationPreference: formValues.emailNotificationPreference ? 1 : 0,
            });
        } catch (error) {
            message.warning(GetText("PublicSiteEditMember_Error"));
            return;
        }

        navigation("/" + getCurrentLanguage() + "/" + params.companyurl + "/success/" + PublicResultType.DetailsHasBeenSaved)
    }


    if (state === PublicSiteState.CompanyNotFound) {
        return <NotFound message={GetText("PublicSiteEditMember_CompanyNotFound")} />
    }

    if (state === PublicSiteState.MemberCodeNotFoundOrExpired) {
        return <PagePublicSite>
            <BookyBox>
                <NotFound message={GetText("PublicSiteEditMember_MemberNotFoundOrExpired")} />
                <div style={{ textAlign: "center" }}><a onClick={evt => setState(PublicSiteState.CompanyFound)}>Till inloggningen</a></div>
            </BookyBox>
        </PagePublicSite>
    }

    const onPhoneChange = (value: any) => {
        form.setFieldValue("phoneNumber", value);
    }

    return <PagePublicSite>
        <BookyBox>

            {publicSite.siteLogoPath ? <div style={{ marginBottom: "20px" }}>
                <Image preview={false} className='company-logo' src={getDownloadUrl(publicSite)} ></Image>
            </div>
                : <Title style={{ margin: 0 }}>{publicSite.siteTitle}</Title>}

            {state === PublicSiteState.CompanyFound ? <h2>{GetText("PublicSiteEditMember_LoginMemberTitle")}</h2> : ""}
            {state === PublicSiteState.MemberFoundByPasswordCode ? <h2>{GetText("PublicSiteEditMemberEditMemberTitle")}</h2> : ""}

            {state === PublicSiteState.MemberFoundByPasswordCode &&
                publicSite.siteDescription &&
                publicSite.siteDescription.length > 0 ? <p dangerouslySetInnerHTML={{ __html: publicSite.siteDescription! }}></p> : ""}

            {state === PublicSiteState.MemberFoundByPasswordCode && showPaymentStatus ? <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {hasPaidCurrentYear ? <span><CheckCircleOutlined style={{ color: "green" }} /> Medlemsavgift är betald för {getCurrentYearAsString()}</span> : ""}
                {!hasPaidCurrentYear ? <span><CloseCircleOutlined style={{ color: "red" }} /> Medlemsavgift har inte registrerats som betald för {getCurrentYearAsString()}</span> : ""}<br />
            </div> : ""}

            {state === PublicSiteState.CompanyFound ? <Form
                data-testid="create-form"
                name="basic"
                form={form}
                colon={false}
                labelCol={{
                    span: 7,
                }}
                onFinish={FormCreateClick}
                wrapperCol={{
                    span: 15,
                }}
                layout="horizontal"
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >

                <BookyInput label={GetText("PublicSiteEditMember_Email")} placeholder={GetText("PublicSiteEditMember_PlaceHolder_Email")} validateEmailIfFilledIn={true} name="email" required={true} requiredMessage={GetText("PublicSiteEditMember_PlaceHolder_Email")} />

                <Form.Item
                    label=" "
                    name="submit"
                    rules={[
                        {
                            required: false,
                            message: 'submitbutton',
                        },
                    ]}
                >
                    <Button data-testid="submit-button" style={{ float: 'left' }} size="middle" type="primary" htmlType="submit">
                        {GetText("PublicSiteEditMember_SendEmail")}
                    </Button>
                </Form.Item>
            </Form> : ""}

            {state === PublicSiteState.MemberFoundByPasswordCode ? <Form
                data-testid="create-form"
                name="basic"
                form={form}
                colon={false}
                onFinish={MemberCreateClick}
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <BookyDynamicInput fieldType={MemberFieldTypes.UniqueId} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <Row gutter={24}>
                    <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                        <BookyDynamicInput fieldType={MemberFieldTypes.FirstName} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                    </Col>
                    <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                        <BookyDynamicInput fieldType={MemberFieldTypes.LastName} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                    </Col>
                </Row>
                <BookyDynamicInput fieldType={MemberFieldTypes.Email} isAdmin={false} memberFieldSettings={memberFieldSettings} validateEmailIfFilledIn={true} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Email2} isAdmin={false} memberFieldSettings={memberFieldSettings} validateEmailIfFilledIn={true} />
                <BookyPhoneInput label={GetText("EditMember_PhoneNumber")} name="phoneNumber" onchange={onPhoneChange} req placeholder={getMemberFieldSetting(MemberFieldTypes.PhoneNumber, memberFieldSettings)?.helpTextPublic} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Address} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <Row gutter={24}>
                    <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                        <BookyDynamicInput fieldType={MemberFieldTypes.PostalCode} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                    </Col>
                    <Col xs={24} sm={10} md={18} lg={18} xl={18}>
                        <BookyDynamicInput fieldType={MemberFieldTypes.PostalArea} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                    </Col>
                </Row>
                <BookyDynamicInput fieldType={MemberFieldTypes.Title} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Country} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Company} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Extra1} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Extra2} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicInput fieldType={MemberFieldTypes.Extra3} isAdmin={false} memberFieldSettings={memberFieldSettings} />
                <BookyDynamicCheckbox fieldType={MemberFieldTypes.EmailNotificationPreference} isAdmin={false} memberFieldSettings={memberFieldSettings} />

                <Form.Item
                    label=""
                    name="submit"
                    rules={[
                        {
                            required: false,
                            message: 'submitbutton',
                        },
                    ]}
                >
                    <Button data-testid="submit-button" className='saveButton' size="middle" type="primary" htmlType="submit">
                        {GetText("EditMember_ModalOk")}
                    </Button>
                </Form.Item>
            </Form> : ""}
        </BookyBox>
    </PagePublicSite>
}

export default PublicSiteEditMember;