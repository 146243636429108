import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, message } from 'antd';
import { BookyInput } from '../Forms/BookyInput';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';
import { BookyCheckboxList } from '../Forms/BookyCheckboxList';
import { WebApiCompanyDtoCompanyAdministratorResultDto } from '../../Repository/eventbookyapi';
import { MemberService } from '../../Services/MemberService';
import { CompanyService } from '../../Services/CompanyService';
import { BookySelectDropDown } from '../Forms/BookySelectDropDown';
import { AvailableRoles } from '../../Helpers/RoleList';

export const EditAdmin = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const GetText = TextService.GetText;
    const [admins, setAdmins] = useState([] as WebApiCompanyDtoCompanyAdministratorResultDto);

    const onCancel = () => {
        form.setFieldValue("groupName", "");
        setOpen(false);
    };

    useEffect(() => {
        if (props.adminResult) {
            setAdmins(props.adminResult);
        }

    }, [props])

    useEffect(() => {
        form.setFieldValue("role", props.admin.role);
    }, [form, props.member])

    const setFieldCustomError = (fieldName: string, error: string,) => {
        form.setFields([
            {
                name: fieldName, // required
                errors: [error],
            },
        ]);
    }

    const onCreate = async (values: any) => {

        if (admins.numberOfGlobalAdministrators == 1 && props.admin.role == 0 && values.role == 1) {
            setFieldCustomError("role", GetText("EditAdmin_MoreThenOneGlobalAdmin"));
            return true;
        }

        try {
            await CompanyService.UpdateCompanyUser({
                id: props.admin.id,
                role: values.role
            });
        } catch (error) {
            message.warning(GetText("EditMember_ErrorSaving"));
            return;
        }
        setOpen(false);

        props.loadAdmins();
    }

    return (
        <>
            <Modal
                open={open}
                title={GetText("EditMember_ModalTitle")}
                okText={GetText("EditMember_ModalOk")}
                cancelText={GetText("EditMember_ModalCancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form form={form} labelAlign={"left"} labelCol={{ span: 8, offset: 0 }} wrapperCol={{ span: 16 }} onFinish={onCreate}>
                    <Col span={17}>
                        <BookySelectDropDown label={GetText("AddAdmin_Role")} name="role" options={AvailableRoles} />
                    </Col>
                </Form>
            </Modal>

            <EditOutlined
                style={{ fontSize: "20px" }}
                type="primary"
                onClick={() => {
                    setOpen(true);
                }}
            >
            </EditOutlined>
        </>
    );
};

export default EditAdmin;