import { Button, Col, Progress, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserService } from '../../Services/UserService';
import { TextService } from '../../Services/TextService';
import { mapSubscriptionLevelFromString } from '../../Mappers/mapSubscriptionLevel';
import { WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse } from '../../Repository/eventbookyapi';
import { CompanyService } from '../../Services/CompanyService';
import InnerHTML from 'dangerously-set-html-content'

const FinalizePremiumRegistration = () => {
    const GetText = TextService.GetText;
    let params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const subscription = searchParams.get("subscription");
    const [initiatePayment, setInitiatePayment] = useState({} as WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse);

    const finishPaymentSteps = () => {
        if (token && subscription) {
            UserService.AuthenticateWithToken(token).then(result => {
                const level = mapSubscriptionLevelFromString(subscription);
                CompanyService.InitiatePayment(level).then(result => {
                    setInitiatePayment(result);
                });
            });
        }
    };

    return <div id="start">
        <Row justify="center" align="top">
            <Col span={12} className='bookyBox'>
                <Title>{GetText("FinalizeRegistration_Title")}</Title>

                {initiatePayment?.snippet == null ?
                    <>
                        <Progress percent={90} status="success" /><br></br>
                        {GetText("FinalizeRegistration_Description")}<br></br><br />
                        <Button type="primary" size="large" onClick={finishPaymentSteps}>
                            {GetText("FinalizeRegistration_Activate")}</Button>
                    </> : ""}

                {initiatePayment?.snippet != null ? <InnerHTML className='paymentIntegraitonContainer' html={initiatePayment?.snippet!} /> : ""}

            </Col>
        </Row>

    </div>
}

export default FinalizePremiumRegistration;