/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesDisplayType } from './WebApiModelsEnumerationTypesDisplayType';
import {
    WebApiModelsEnumerationTypesDisplayTypeFromJSON,
    WebApiModelsEnumerationTypesDisplayTypeFromJSONTyped,
    WebApiModelsEnumerationTypesDisplayTypeToJSON,
} from './WebApiModelsEnumerationTypesDisplayType';

/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.RegisterAsync(WebApi.Services.DataTransferObjects.UserService.RegisterReqDto) request.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto
     */
    deadline?: Date;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesDisplayType}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto
     */
    displayType?: WebApiModelsEnumerationTypesDisplayType;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto
     */
    location?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto {
    return WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'deadline': !exists(json, 'deadline') ? undefined : (new Date(json['deadline'])),
        'displayType': !exists(json, 'displayType') ? undefined : WebApiModelsEnumerationTypesDisplayTypeFromJSON(json['displayType']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'location': !exists(json, 'location') ? undefined : json['location'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceUpdateEventWithBookingsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'deadline': value.deadline === undefined ? undefined : (value.deadline.toISOString()),
        'displayType': WebApiModelsEnumerationTypesDisplayTypeToJSON(value.displayType),
        'description': value.description,
        'location': value.location,
    };
}

