import React, { useEffect, useState } from 'react';
import { Col, Form, Row, message } from 'antd';
import { BookyButton } from '../Buttons/BookyButton';
import { UserAddOutlined } from '@ant-design/icons';
import { TextService } from '../../Services/TextService';
import { MemberService } from '../../Services/MemberService';
import { BookyCheckboxList } from '../Forms/BookyCheckboxList';
import { WebApiServicesDataTransferObjectsGroupServiceGroupResultDto, WebApiMemberDtoMemberDto } from '../../Repository/eventbookyapi';
import { BookyDynamicInput } from '../Forms/BookyDynamicInput';
import { MemberFieldTypes } from '../../Types/MemberFieldTypes';
import { BookyPhoneInput } from '../Forms/BookyPhoneInput';
import { OneFrontModal } from '../Modals/OneFrontModal';
import { GroupType } from '../../Types/GroupType';

export const AddMember = (props: any) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false as boolean);

    const GetText = TextService.GetText;

    const onCancel = () => {
        form.setFieldValue("groupName", "");
        setOpen(false);
    };

    useEffect(() => {
        let groups: any = [];
        let defaultGroups: number[] = [];

        props.groups.forEach((group: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto) => {
            if (!group.isDefaultGroup) {
                groups.push({ value: group.id, label: group.name });

                if (group.groupType == GroupType.Default) {
                    defaultGroups.push(group.id!);
                }
            }
        });
        setGroups(groups);
        form.resetFields();
        form.setFieldValue("uniqueId", props.nextMemberIdSuggestion);
        form.setFieldValue("groups", defaultGroups);
    }, [props])

    const setFieldCustomError = (fieldName: string, error: string,) => {
        form.setFields([
            {
                name: fieldName, // required
                errors: [error],
            },
        ]);
    }

    const onCreate = async (values: any) => {
        setLoading(true);



        let foundError = false;
        props.members.forEach((member: WebApiMemberDtoMemberDto) => {
            if (member.uniqueId == values.uniqueId) {
                setFieldCustomError("uniqueId", "Finns redan en medlem med id " + member.uniqueId);
                foundError = true;
            }

            if (values.email?.length > 0 && member.email == values.email) {
                setFieldCustomError("email", "Finns redan en medlem med epost " + member.email)
                foundError = true;
            }
        });

        if (props.members.length >= props.user.maxMembers) {
            setFieldCustomError("email", GetText("AddAdmin_MaxAllowedMembers"))
            foundError = true;
        }

        if (foundError) {
            setLoading(false);
            return true;
        }
        try {
            await MemberService.AddMember({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                email2: values.email2,
                phoneNumber: values.phoneNumber,
                address: values.address,
                postalArea: values.postalArea,
                postalCode: values.postalCode,
                country: values.country,
                company: values.company,
                uniqueId: values.uniqueId,
                groups: values.groups,
                title: values.title,
                extra1: values.extra1,
                extra2: values.extra2,
                extra3: values.extra3,
            });
        } catch (error) {
            message.warning(GetText("AddMember_ErrorSaving"));
            return;
        }
        setLoading(false);
        setOpen(false);

        props.loadMembers();
    }

    const onPhoneChange = (value: any) => {
        form.setFieldValue("phoneNumber", value);
    }

    return (
        <>
            <OneFrontModal
                open={open}
                title={GetText("AddMember_Title")}
                okText={GetText("AddMember_Ok")}
                confirmLoading={loading}
                cancelText={GetText("AddMember_Cancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form colon={false} form={form} labelAlign={"left"} layout="vertical" onFinish={onCreate}>
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.UniqueId} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.FirstName} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.LastName} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.Email} isAdmin={true} memberFieldSettings={props.memberFieldSettings} validateEmailIfFilledIn={true} />
                            </Col>
                            <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.Email2} isAdmin={true} memberFieldSettings={props.memberFieldSettings} validateEmailIfFilledIn={true} />

                            </Col>
                        </Row>

                        <BookyPhoneInput label={GetText("EditMember_PhoneNumber")} name="phoneNumber" onchange={onPhoneChange} req placeholder={""} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Address} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />

                        <Row gutter={24}>
                            <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.PostalCode} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                            </Col>
                            <Col xs={24} sm={10} md={18} lg={18} xl={18}>
                                <BookyDynamicInput fieldType={MemberFieldTypes.PostalArea} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                            </Col>
                        </Row>

                        <BookyDynamicInput fieldType={MemberFieldTypes.Title} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Country} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Company} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Extra1} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Extra2} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyDynamicInput fieldType={MemberFieldTypes.Extra3} isAdmin={true} memberFieldSettings={props.memberFieldSettings} />
                        <BookyCheckboxList label={GetText("EditMember_Groups")} options={groups} name="groups" req placeholder={GetText("EditMember_Groups")} />

                    </Col>
                </Form>
            </OneFrontModal>

            <BookyButton
                type="primary"
                disabled={props.user.hasOnlyReadRights}
                fullWidth={true}
                text={GetText("AddMember_AddButton")}
                onClick={() => {

                    setOpen(true);
                }}
                icon={<UserAddOutlined />}
            >
            </BookyButton>
        </>
    );
};

export default AddMember;