
import { AuthProvider } from "../Providers/authProvider";
import { ApiInviteCreatePostRequest, InviteApi } from "../Repository/eventbookyapi";
import { sendReminderForInvitesRequest } from "../Types/RequestResponse/createInvitesRequest copy";
import { GetApiConfiguration } from "./ServiceConfiguration";

export abstract class InviteService {
    public static CreateInvites = async (request: ApiInviteCreatePostRequest) => {
        return new InviteApi(GetApiConfiguration()).apiInviteCreatePost(request);
    };

    public static SendInviteReminder = async (request: sendReminderForInvitesRequest) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider().GetToken(),
            },

            body: JSON.stringify(request)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/Invite/sendReminderForInvites', requestOptions);
        const data = await response.json();

        return data;
    };


    public static GetInvites = async (eventId: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider().GetToken()
            }
        }

        const response = await fetch(process.env.REACT_APP_API_URL + '/Invite/' + eventId, options);
        const data = await response.json();

        return data;
    };

    public static DeleteInvite = async (inviteId: string) => {
        var options = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider().GetToken()
            }
        }

        const response = await fetch(process.env.REACT_APP_API_URL + '/Invite/' + inviteId, options);
        const data = await response.status;

        return data;
    };
};