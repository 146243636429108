import Cookies from "universal-cookie";

export const getCurrentLanguage = () => {
    if (window.location.href.indexOf("/se") != -1) {
        return "se"
    }
    else if (window.location.href.indexOf("/en") != -1) {
        return "en"
    }
    else {
        const cookieValue = getCookieLanguage();

        if (cookieValue) {
            return cookieValue;
        }

        return "en";
    }
}

export const tryGetUserPrefferedLanguage = () => {
    if (getCookieLanguage() != null) {
        return getCookieLanguage();
    }
    else if (navigator.language === "sv-SE" || navigator.language === "sv") {
        return "se"
    }

    return null;
}

const getCookieLanguage = () => {
    const cookies = new Cookies();
    const langugeCookie = cookies.get('language');
    if (langugeCookie != null && langugeCookie.length > 0) {
        return langugeCookie;
    }

    return null;
}

export const setCookieLanguage = (language: string) => {
    const cookies = new Cookies();
    cookies.set('language', language.replace("/", ''), { maxAge: 31536000 });
}