import { ConfigProvider, Layout, Menu } from 'antd';
import './Styles/App.css';
import { Content, Footer } from 'antd/lib/layout/layout';
import { BrowserRouter, useNavigate } from "react-router-dom";
import TopHeader from './Components/Layout/TopHeader/TopHeader';
import SideNav from './Components/Sidenav/SideNav';
import NavigationRoutes from './NavigationRoutes';
import { getCurrentLanguage } from './Helpers/RouteHelper';

<script src="https://accounts.google.com/gsi/client?sensor=false"></script>
const App = () => {
  const showHeaderFooter = () => {
    return window.location.href.indexOf("/editmember") == -1 &&
      window.location.href.indexOf("/newmember") == -1 &&
      window.location.href.indexOf("/success") == -1
  }

  return <div className="App">
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "white",
          colorPrimary: "#234E70",
        },
        hashed: false
      }
      }
    >
      <BrowserRouter>
        <Layout style={{ minHeight: "100vh" }}>
          {showHeaderFooter() ? <TopHeader /> : ""}
          <Layout>
            <Layout
              style={{

              }}
            >
              <Content
                className={showHeaderFooter() ? "site-layout-background-grey" : "site-layout-background-white"}>
                <SideNav />
                <NavigationRoutes />
              </Content>
            </Layout>
          </Layout>
          {showHeaderFooter() ?
            <Footer className='footer' style={{ textAlign: 'center' }}>OneFront360 © 2024<br></br>
              <div>
                <a href={"/" + getCurrentLanguage() + "/terms"}>Terms of Service</a> |  <a href={"/" + getCurrentLanguage() + "/privacy-policy"}>Privacy Policy</a> | <a href="/Contact">Contact</a>
              </div>
            </Footer> : <Footer className='footer' style={{ textAlign: 'center' }}>A service provided by <a target="_blank" href="/"><u>OneFront 360</u></a> © 2023<br></br>
            </Footer>}

        </Layout>
      </BrowserRouter>
    </ConfigProvider>
  </div >
};

export default App;