/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsTime } from './WebApiModelsTime';
import {
    WebApiModelsTimeFromJSON,
    WebApiModelsTimeFromJSONTyped,
    WebApiModelsTimeToJSON,
} from './WebApiModelsTime';

/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.RegisterAsync(WebApi.Services.DataTransferObjects.UserService.RegisterReqDto) request.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto {
    /**
     * Gets or sets the Name.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    id?: number;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    uniqueId?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    name?: string | null;
    /**
     * Gets max participants
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    maxParticipants?: number;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    email?: string | null;
    /**
     * Gets or sets the password.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    description?: string | null;
    /**
     * Gets or sets the password.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    createdAt?: Date;
    /**
     * A list of available times
     * @type {Array<WebApiModelsTime>}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    times?: Array<WebApiModelsTime> | null;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    numberOfTimes?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto
     */
    numberOfReplies?: number;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto {
    return WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'maxParticipants': !exists(json, 'maxParticipants') ? undefined : json['maxParticipants'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'times': !exists(json, 'times') ? undefined : (json['times'] === null ? null : (json['times'] as Array<any>).map(WebApiModelsTimeFromJSON)),
        'numberOfTimes': !exists(json, 'numberOfTimes') ? undefined : json['numberOfTimes'],
        'numberOfReplies': !exists(json, 'numberOfReplies') ? undefined : json['numberOfReplies'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceGetEventsByUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'uniqueId': value.uniqueId,
        'name': value.name,
        'maxParticipants': value.maxParticipants,
        'email': value.email,
        'description': value.description,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'times': value.times === undefined ? undefined : (value.times === null ? null : (value.times as Array<any>).map(WebApiModelsTimeToJSON)),
        'numberOfTimes': value.numberOfTimes,
        'numberOfReplies': value.numberOfReplies,
    };
}

