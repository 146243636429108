/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiEmailDtoCommunicationEventReportDto,
  WebApiModelsEmailTemplate,
  WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto,
  WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto,
  WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDto,
} from '../models';
import {
    WebApiEmailDtoCommunicationEventReportDtoFromJSON,
    WebApiEmailDtoCommunicationEventReportDtoToJSON,
    WebApiModelsEmailTemplateFromJSON,
    WebApiModelsEmailTemplateToJSON,
    WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDtoFromJSON,
    WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDtoToJSON,
    WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDtoFromJSON,
    WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDtoToJSON,
} from '../models';

export interface ApiEmailTemplateGetCommunicationLogsGetRequest {
    email?: string;
    start?: Date;
    end?: Date;
    onlyError?: boolean;
}

export interface ApiEmailTemplatePostRequest {
    webApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto?: WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto;
}

export interface ApiEmailTemplateSendMailToExternalPostRequest {
    files?: Array<Blob>;
    subject?: string;
    message?: string;
    emails?: Array<string>;
}

export interface ApiEmailTemplateSendSmsToExternalPostRequest {
    text?: string;
    phoneNumbers?: Array<string>;
}

export interface ApiEmailTemplateTemplateIdDeleteRequest {
    templateId: number;
}

export interface ApiEmailTemplateUpdatePostRequest {
    webApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto?: WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto;
}

/**
 * 
 */
export class EmailTemplateApi extends runtime.BaseAPI {

    /**
     * 
     */
    async apiEmailTemplateGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiModelsEmailTemplate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/EmailTemplate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiModelsEmailTemplateFromJSON));
    }

    /**
     * 
     */
    async apiEmailTemplateGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiModelsEmailTemplate>> {
        const response = await this.apiEmailTemplateGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiEmailTemplateGetCommunicationLogsGetRaw(requestParameters: ApiEmailTemplateGetCommunicationLogsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiEmailDtoCommunicationEventReportDto>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        if (requestParameters.onlyError !== undefined) {
            queryParameters['onlyError'] = requestParameters.onlyError;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/EmailTemplate/GetCommunicationLogs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiEmailDtoCommunicationEventReportDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiEmailTemplateGetCommunicationLogsGet(requestParameters: ApiEmailTemplateGetCommunicationLogsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiEmailDtoCommunicationEventReportDto> {
        const response = await this.apiEmailTemplateGetCommunicationLogsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates new subscription
     */
    async apiEmailTemplatePostRaw(requestParameters: ApiEmailTemplatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/EmailTemplate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDtoToJSON(requestParameters.webApiServicesDataTransferObjectsEmailServiceCreateEmailTemplateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDtoFromJSON(jsonValue));
    }

    /**
     * Creates new subscription
     */
    async apiEmailTemplatePost(requestParameters: ApiEmailTemplatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiServicesDataTransferObjectsGroupServiceCreateGroupResultDto> {
        const response = await this.apiEmailTemplatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiEmailTemplateSendMailToExternalPostRaw(requestParameters: ApiEmailTemplateSendMailToExternalPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        if (requestParameters.subject !== undefined) {
            formParams.append('Subject', requestParameters.subject as any);
        }

        if (requestParameters.message !== undefined) {
            formParams.append('Message', requestParameters.message as any);
        }

        if (requestParameters.emails) {
            requestParameters.emails.forEach((element) => {
                formParams.append('emails', element as any);
            })
        }

        const response = await this.request({
            path: `/api/EmailTemplate/SendMailToExternal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiEmailTemplateSendMailToExternalPost(requestParameters: ApiEmailTemplateSendMailToExternalPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiEmailTemplateSendMailToExternalPostRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiEmailTemplateSendSmsToExternalPostRaw(requestParameters: ApiEmailTemplateSendSmsToExternalPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.text !== undefined) {
            formParams.append('Text', requestParameters.text as any);
        }

        if (requestParameters.phoneNumbers) {
            requestParameters.phoneNumbers.forEach((element) => {
                formParams.append('phoneNumbers', element as any);
            })
        }

        const response = await this.request({
            path: `/api/EmailTemplate/SendSmsToExternal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiEmailTemplateSendSmsToExternalPost(requestParameters: ApiEmailTemplateSendSmsToExternalPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiEmailTemplateSendSmsToExternalPostRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiEmailTemplateTemplateIdDeleteRaw(requestParameters: ApiEmailTemplateTemplateIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling apiEmailTemplateTemplateIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/EmailTemplate/{templateId}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiEmailTemplateTemplateIdDelete(requestParameters: ApiEmailTemplateTemplateIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiEmailTemplateTemplateIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Creates new subscription
     */
    async apiEmailTemplateUpdatePostRaw(requestParameters: ApiEmailTemplateUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/EmailTemplate/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDtoToJSON(requestParameters.webApiServicesDataTransferObjectsEmailServiceUpdateEmailTemplateDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates new subscription
     */
    async apiEmailTemplateUpdatePost(requestParameters: ApiEmailTemplateUpdatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiEmailTemplateUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
