/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebApiModelsEnumerationTypesCompanyOrderStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11
} as const;
export type WebApiModelsEnumerationTypesCompanyOrderStatus = typeof WebApiModelsEnumerationTypesCompanyOrderStatus[keyof typeof WebApiModelsEnumerationTypesCompanyOrderStatus];


export function WebApiModelsEnumerationTypesCompanyOrderStatusFromJSON(json: any): WebApiModelsEnumerationTypesCompanyOrderStatus {
    return WebApiModelsEnumerationTypesCompanyOrderStatusFromJSONTyped(json, false);
}

export function WebApiModelsEnumerationTypesCompanyOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsEnumerationTypesCompanyOrderStatus {
    return json as WebApiModelsEnumerationTypesCompanyOrderStatus;
}

export function WebApiModelsEnumerationTypesCompanyOrderStatusToJSON(value?: WebApiModelsEnumerationTypesCompanyOrderStatus | null): any {
    return value as any;
}

