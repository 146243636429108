/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.ConfirmResetPasswordAsync(System.String,System.String,System.String) response.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto
     */
    id?: number;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto
     */
    name?: string | null;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto
     */
    description?: string | null;
    /**
     * Gets or sets the email.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto
     */
    deadline?: Date;
    /**
     * Gets or sets the uniqueId.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto
     */
    uniqueId?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceCreateEventResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto {
    return WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'deadline': !exists(json, 'deadline') ? undefined : (new Date(json['deadline'])),
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceCreateEventResultDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceCreateEventResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'deadline': value.deadline === undefined ? undefined : (value.deadline.toISOString()),
        'uniqueId': value.uniqueId,
    };
}

