/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto
 */
export interface WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto
     */
    bannerImagePath?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto
     */
    isCustomBannerImage?: boolean;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDtoFromJSON(json: any): WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto {
    return WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'bannerImagePath': !exists(json, 'bannerImagePath') ? undefined : json['bannerImagePath'],
        'isCustomBannerImage': !exists(json, 'isCustomBannerImage') ? undefined : json['isCustomBannerImage'],
    };
}

export function WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDtoToJSON(value?: WebApiServicesDataTransferObjectsEventServiceUpdateEventBannerImageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'bannerImagePath': value.bannerImagePath,
        'isCustomBannerImage': value.isCustomBannerImage,
    };
}

