/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WebApiModelsEnumerationTypesCompanyTypeConfiguration = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type WebApiModelsEnumerationTypesCompanyTypeConfiguration = typeof WebApiModelsEnumerationTypesCompanyTypeConfiguration[keyof typeof WebApiModelsEnumerationTypesCompanyTypeConfiguration];


export function WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSON(json: any): WebApiModelsEnumerationTypesCompanyTypeConfiguration {
    return WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSONTyped(json, false);
}

export function WebApiModelsEnumerationTypesCompanyTypeConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiModelsEnumerationTypesCompanyTypeConfiguration {
    return json as WebApiModelsEnumerationTypesCompanyTypeConfiguration;
}

export function WebApiModelsEnumerationTypesCompanyTypeConfigurationToJSON(value?: WebApiModelsEnumerationTypesCompanyTypeConfiguration | null): any {
    return value as any;
}

