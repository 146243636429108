import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import 'moment-timezone'
import { getDateStringFromDate, getTimeRangeString } from '../../Helpers/DateHelpers';
import { CloseOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { TextService } from "../../Services/TextService";
const GetText = TextService.GetText;

export const BookyEventList = (props: any) => {
    const locale = {
        emptyText: GetText("BookyEventList_EmptyList")
    }
    return (<List
        className="calender-event-list"
        itemLayout="horizontal"
        dataSource={props.events}
        locale={locale}
        renderItem={(item: any) => (
            <List.Item className='calendar-table-item'>
                <div className='calender-table-item-time'>
                    <div className="calender-table-item-date">{getDateStringFromDate(item.start.toString())}</div>
                    {!item.allDay ? <span className="calender-table-item-time-description"> {getTimeRangeString(item.start.toString(), item.end.toString(), props.show24hrsFormat)} </span> : <span className='calender-table-item-fullday-description'>{GetText("BookyEventList_EmptyList_AllDayEvent")}</span>}<br></br>
                    <a><CloseOutlined onClick={() => props.onDeleteEvent(item.id)} className='calendar-table-item-delete' /></a>
                </div>
            </List.Item>
        )} />)
}