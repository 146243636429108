/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesEmailNotificationPreference } from './WebApiModelsEnumerationTypesEmailNotificationPreference';
import {
    WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSON,
    WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSONTyped,
    WebApiModelsEnumerationTypesEmailNotificationPreferenceToJSON,
} from './WebApiModelsEnumerationTypesEmailNotificationPreference';
import type { WebApiModelsEnumerationTypesMemberStatus } from './WebApiModelsEnumerationTypesMemberStatus';
import {
    WebApiModelsEnumerationTypesMemberStatusFromJSON,
    WebApiModelsEnumerationTypesMemberStatusFromJSONTyped,
    WebApiModelsEnumerationTypesMemberStatusToJSON,
} from './WebApiModelsEnumerationTypesMemberStatus';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
 */
export interface WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    email2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    postalArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    uniqueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    extra1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    extra2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    extra3?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    groups?: Array<number> | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesMemberStatus}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    status?: WebApiModelsEnumerationTypesMemberStatus;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesEmailNotificationPreference}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto
     */
    emailNotificationPreference?: WebApiModelsEnumerationTypesEmailNotificationPreference;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDtoFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto {
    return WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'postalArea': !exists(json, 'postalArea') ? undefined : json['postalArea'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
        'extra1': !exists(json, 'extra1') ? undefined : json['extra1'],
        'extra2': !exists(json, 'extra2') ? undefined : json['extra2'],
        'extra3': !exists(json, 'extra3') ? undefined : json['extra3'],
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
        'status': !exists(json, 'status') ? undefined : WebApiModelsEnumerationTypesMemberStatusFromJSON(json['status']),
        'emailNotificationPreference': !exists(json, 'emailNotificationPreference') ? undefined : WebApiModelsEnumerationTypesEmailNotificationPreferenceFromJSON(json['emailNotificationPreference']),
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDtoToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberId': value.memberId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'email2': value.email2,
        'address': value.address,
        'postalCode': value.postalCode,
        'postalArea': value.postalArea,
        'title': value.title,
        'country': value.country,
        'phoneNumber': value.phoneNumber,
        'company': value.company,
        'uniqueId': value.uniqueId,
        'extra1': value.extra1,
        'extra2': value.extra2,
        'extra3': value.extra3,
        'groups': value.groups,
        'status': WebApiModelsEnumerationTypesMemberStatusToJSON(value.status),
        'emailNotificationPreference': WebApiModelsEnumerationTypesEmailNotificationPreferenceToJSON(value.emailNotificationPreference),
    };
}

