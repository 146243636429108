/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto
 */
export interface WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto
     */
    eventId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto
     */
    turnOnNotifications?: boolean;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDtoFromJSON(json: any): WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto {
    return WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'turnOnNotifications': !exists(json, 'turnOnNotifications') ? undefined : json['turnOnNotifications'],
    };
}

export function WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDtoToJSON(value?: WebApiServicesDataTransferObjectsEventServiceUpdateNotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'turnOnNotifications': value.turnOnNotifications,
    };
}

