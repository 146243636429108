import { Button, Collapse, Form, message, Space, Tag } from "antd";
import Paragraph from 'antd/lib/typography/Paragraph';
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { InviteService } from "../../Services/InviteService";
import { AuthProvider } from "../../Providers/authProvider";
import { AuthenticatedUser } from "../../Types/AuthenticatedUser";
import { getDateStringFromDate } from "../../Helpers/DateHelpers";
import { ContactAutoComplete } from "../AutoComplete/ContactAutocomplete";
import { TextService } from "../../Services/TextService";
import { OneFrontHtmlEditor } from "../Forms/OneFrontHtmlEditor";
import { AutoCompleteType } from "../../Types/AutoCompleteType";

export const ReminderForm = (props: any) => {
    const [form] = useForm();
    const [reminderText, setReminderText] = useState("" as any);
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const [reminderEmails, setReminderEmails] = useState([] as any[]);
    const GetText = TextService.GetText;

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        });

        let noReplies: string[] = [];
        props.invites?.forEach((invite: any) => {
            if (invite.replyId == 0) {
                noReplies.push(invite.email);
            }
        });
        setReminderEmails(noReplies);
    }, [props]);

    const onReminderFinish = async (formValues: any) => {
        const result = await InviteService.SendInviteReminder({
            message: reminderText,
            eventId: props.event.eventId,
            emails: reminderEmails
        });

        setReminderEmails([]);

        message.success(GetText("ReminderForm_RemindersSuccessMessage"));
    };


    const onEmailsUpdated = async (onEmailsUpdated: any) => {
        setReminderEmails(onEmailsUpdated => ([
            ...onEmailsUpdated,
        ]));
    }

    const onGetSuggestions = async (searchValue: any) => {
        var inviteAutoCompleteSuggestion: any[] = [];

        props.invites.forEach((invite: any) => {
            inviteAutoCompleteSuggestion.push({ "label": invite.email, "value": invite.email });
        });

        return inviteAutoCompleteSuggestion;
    }

    const { Panel } = Collapse;

    return <>
        {user?.hasPaidSubscription ?
            <>
                {props.event.automaticReminder != null ? <i>{GetText("ReminderForm_AutomaticTurnedOnText")} {getDateStringFromDate(props.event.automaticReminder)}</i> : <i>{GetText("ReminderForm_AutomaticTurnedOffText")}</i>}<br></br><br></br>



                {props.invitesNoReply.length > 0 ?
                    <>
                        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                            <Paragraph><span>{props.invitesNoReply.length} {GetText("ReminderForm_InvitesStillWithoutReply")}</span></Paragraph>
                        </Space>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onReminderFinish}
                            autoComplete="off"
                        >

                            <Form.Item
                                name={GetText("ReminderForm_SendRemindersToColumn")}
                                label={GetText("ReminderForm_SendRemindersToColumn")}
                            >
                                <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                                    <ContactAutoComplete AutoCompleteType={AutoCompleteType.Email} descriptionText={GetText("AutoComplete_HelpTextAddEmail")} showList listHeader={GetText("ReminderForm_ReminderListHeader")} emails={reminderEmails} onEmailsUpdated={onEmailsUpdated} getSuggestions={onGetSuggestions} />
                                </Space>
                            </Form.Item>

                            <Form.Item
                                name="Message"
                                label={GetText("ReminderForm_RemindersMessageFormText")}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value != "") {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(GetText("ReminderForm_RemindersMessageMandatoryText"));
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                                    <OneFrontHtmlEditor initialHtml={reminderText} hideVariablesMenu={true} onHtmlChange={(e: any) => setReminderText(e)} name="body" />
                                </Space>
                            </Form.Item>


                            <Form.Item>
                                <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                                    <Button type="primary" htmlType="submit">
                                        {GetText("ReminderForm_SendButtonText")}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form> </> : ""}

            </> : <span><Tag color="#1890ff">{GetText("Common_Premium")}</Tag><a href='/Pricing'>{GetText("Common_UpgradeToPremium")} </a>{GetText("ReminderForm_UpgradeToPremium")}</span>
        }
    </>
}