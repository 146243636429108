
import { Alert, Button, Divider, Form, message, Modal, Select, SelectProps, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { AuthProvider } from "../../Providers/authProvider";
import { AuthenticatedUser } from "../../Types/AuthenticatedUser";
import { BookyInput } from "../Forms/BookyInput";
import { GroupService } from "../../Services/GroupService";
import { TextService } from "../../Services/TextService";
import { MemberService } from "../../Services/MemberService";
import { WebApiCompanyDtoCompanyDto, WebApiMemberDtoMemberDto, WebApiModelsEmailTemplate, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from "../../Repository/eventbookyapi";
import FileSelect from "../Upload/UploadFile";
import { UploadChangeParam } from "antd/lib/upload";
import { BookyButton } from "../Buttons/BookyButton";
import { MemberAutocomplete } from "../AutoComplete/MemberAutocomplete";
import { MemberTable } from "../Table/MemberTable";
import { MemberPaymentStatus } from "../../Types/MemberPaymentStatus";
import { EmailFormType } from "../../Types/EmailFormType";
import { OneFrontHtmlEditor } from "../Forms/OneFrontHtmlEditor";
import { CompanyService } from "../../Services/CompanyService";
import { BookySelectDropDown } from "../Forms/BookySelectDropDown";
import { ContactAutoComplete } from "../AutoComplete/ContactAutocomplete";
import { BookyCheckbox } from "../Forms/BookyCheckbox";
import { EmailNotificationPreference } from "../../Types/EmailNotificationPreference";
import { InviteService } from "../../Services/InviteService";
import { CheckCircleFilled, CheckCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { FilterMemberType } from "../../Types/FilterMemberType";
import { AutoCompleteType } from "../../Types/AutoCompleteType";

export const SendInviteForm = (props: any) => {
    const [form] = useForm();
    const [initialHtml, setInitialHtml] = useState("" as any);
    const [html, setHtml] = useState("" as any);
    const [subject, setSubject] = useState("" as any);
    const [externalEmails, setExternalEmails] = useState([] as any[]);
    const [emailIsvalid, setEmailIsValid] = useState(false as boolean);

    const [company, setCompany] = useState({} as WebApiCompanyDtoCompanyDto);

    const [loading, setLoading] = useState(false as boolean);
    const [showConfirm, setShowConfirm] = useState(false as boolean);
    const [selectedGroup, setSelectedGroup] = useState([] as number[]);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(undefined as MemberPaymentStatus | undefined);

    const [fileResult, setFileList] = useState<UploadChangeParam>();
    const [isAllowedToSendGroupMails, setIsAllowedToSendGroupMails] = useState(true as boolean);
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const [selectedMembers, setSelectedMembers] = useState([] as WebApiMemberDtoMemberDto[]);

    const GetText = TextService.GetText;

    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);

            if (props.groups != null && props.groups.length > 0) {
                setSelectedGroup(props.groups[0]);
                form.setFieldValue("group", props.groups[0].id)
            }
        });

        CompanyService.GetCompany().then(company => {
            setCompany(company);
        })

        GroupService.IsAllowedToSendGroupMails().then(result => {
            setIsAllowedToSendGroupMails(result.toString() === true.toString());
        });

        if (props.subject != null) {
            form.setFieldValue("subject", props.subject);
        }
    }, [props]);

    const onReminderFinish = async (formValues: any) => {

        if (hasNoMembersOrEmails()) {
            message.warning("Du måste skicka till minst en medlem/e-postadress.");
            return;
        }

        if (htmlMessageIsEmpty(html)) {
            message.warning("Du måste skriva något i mailet.");
            return;
        }

        if (stringIsEmpty(subject)) {
            message.warning("Mailet måste ha ett ämne.");
            return;
        }


        setShowConfirm(true);
    };

    const onFileSelectChange = (files: any) => {
        setFileList(files);
    }

    const onFilterMembers = async () => {
        let filteredMembers: WebApiMemberDtoMemberDto[] = [];

        if (!selectedGroup.length) {
            message.warning(GetText("EmailForm_SelectOneGroup"));
        }

        filteredMembers = await MemberService.GetFilteredMembers({
            status: selectedPaymentStatus,
            emailNotificationPreference: form.getFieldValue("emailNotificationPreference") == 0 || !form.getFieldValue("emailNotificationPreference") ? EmailNotificationPreference.Unspecified : EmailNotificationPreference.NoInformationEmails,
            isAllMembers: selectedGroup.length == 0 || selectedGroup.some(p => p == -1) ? true : false,
            groupsIds: selectedGroup,
            filter: FilterMemberType.HasEmail
        });

        setSelectedMembers(filteredMembers);

        if (filteredMembers.length == 0) {
            message.warning("No memebers found.")
        }
    }


    const onSendConfirmOK = async () => {
        setLoading(true);

        if (selectedMembers.length == 0) {
            message.error("Select at least one member");
            return;
        }

        var inviteEmails: string[] = selectedMembers.map(m => m.email!);

        try {
            await InviteService.CreateInvites({
                subject: form.getFieldValue("subject"),
                emails: inviteEmails,
                files: getFileFromUploadControl(),
                message: html,
                eventId: props.event.eventId
            });
            message.success(GetText("SendInviteForm_MessageSuccess"));
        } catch {
            message.error(GetText("EmailForm_EmailsSentFailed"))
        }

        setLoading(false);
        form.setFieldValue("message", "");
        form.setFieldValue("messageFromTemplate", "");
        form.setFieldValue("subject", "");
        setShowConfirm(false);
    };

    const getFileFromUploadControl = (): Blob[] => {
        var files: Blob[] = [];

        fileResult?.fileList.forEach((file: any) => {
            if (file.originFileObj) {
                files.push(file.originFileObj as Blob);
            }
            else {
                files.push(file as Blob);
            }
        });

        return files;
    }

    const onSendConfirmOKExternal = async () => {
        setLoading(true);

        if (externalEmails.length == 0) {
            message.error("Select at least one email");
            return;
        }

        try {
            await InviteService.CreateInvites({
                emails: externalEmails,
                subject: form.getFieldValue("subject"),
                files: getFileFromUploadControl(),
                message: html,
                eventId: props.event.eventId
            });

            message.success(GetText("EmailForm_EmailsSent"));
        } catch {
            message.error(GetText("EmailForm_EmailsSentFailed"))
        }

        setLoading(false);
        form.setFieldValue("message", "");
        form.setFieldValue("messageFromTemplate", "");
        form.setFieldValue("subject", "");
        setShowConfirm(false);
    }

    const onAutoCompleteSelect = (member: WebApiMemberDtoMemberDto) => {
        let newList: WebApiMemberDtoMemberDto[] = selectedMembers.map(p => p);
        if (!selectedMembers.some((e: WebApiMemberDtoMemberDto) => e.email === member.email)) {
            newList.push(member);
        }
        setSelectedMembers(newList);
    }

    const removeMember = (memberToRemove: WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto) => {
        let newList = selectedMembers.filter(p => p.email != memberToRemove.email);
        setSelectedMembers(newList);
    };


    const groupOptions = () => {
        const options: SelectProps['options'] = [];

        props.groups.forEach((element: WebApiServicesDataTransferObjectsGroupServiceGroupResultDto) => {
            options.push({
                label: element.name,
                value: element.id,
            });
        });

        return options;
    }

    const emailTemplatesOptions = () => {
        const options: SelectProps['options'] = [];

        props.emailTemplates.forEach((element: WebApiModelsEmailTemplate) => {
            options.push({
                label: element.templateName,
                value: element.id,
            });
        });

        return options;
    }

    const handleGroupChange = async (value: number[]) => {
        setSelectedGroup(value);
    };

    const handleTemplateChange = async (value: number) => {
        let emailTemplate: WebApiModelsEmailTemplate[] = props.emailTemplates.filter((p: WebApiModelsEmailTemplate) => p.id === value);
        if (emailTemplate && emailTemplate.length > 0) {
            setInitialHtml(emailTemplate[0]?.body);
            setHtml(emailTemplate[0]?.body);
            form.setFieldValue("message", emailTemplate[0]?.body);
            form.setFieldValue("messageFromTemplate", emailTemplate[0]?.body);
            form.setFieldValue("subject", emailTemplate[0]?.subject);

            const emailContainsEmails = stringIsEmpty(emailTemplate[0]?.subject!) || htmlMessageIsEmpty(emailTemplate[0]?.body!);
            setEmailIsValid(!emailContainsEmails);
        }
        else {
            setInitialHtml(undefined);
            form.setFieldValue("subject", "");
        }
    };

    const onSendConfirmCancel = async () => {
        setShowConfirm(false);
    };

    const onSelectPaymentChanged = async (value: MemberPaymentStatus) => {
        setSelectedPaymentStatus(value);
    };

    const onHtmlChange = (html: string) => {
        const emailContainsEmails = stringIsEmpty(subject) || htmlMessageIsEmpty(html);
        setEmailIsValid(!emailContainsEmails);
        form.setFieldValue("message", html);
        setHtml(html);
    }

    const onEmailsUpdated = async (onEmailsUpdated: any) => {
        setExternalEmails(onEmailsUpdated => ([
            ...onEmailsUpdated,
        ]));
    }

    const hasNoMembersOrEmails = () => {
        return (selectedMembers.length == 0 && externalEmails.length == 0)
    }

    const htmlMessageIsEmpty = (html: string) => {
        return html.length < 1 || html == "<p></p>"
    }

    const stringIsEmpty = (subject: string) => {
        return subject === "";
    }

    const onSubjectchange = (e: any) => {
        const emailContainsEmails = stringIsEmpty(e.target.value) || htmlMessageIsEmpty(html);
        setEmailIsValid(!emailContainsEmails);
        setSubject(e.target.value);
    }

    return <>
        <Modal
            title={GetText("EmailForm_ConfirmText")}
            open={showConfirm}
            onOk={props.type === EmailFormType.EmailFormByExternal ? onSendConfirmOKExternal : onSendConfirmOK}
            confirmLoading={loading}
            onCancel={onSendConfirmCancel}
        >
            {props.type === EmailFormType.EmailFormByExternal ?
                (GetText("EmailForm_ConfirmText1") + externalEmails.length + GetText("EmailForm_ConfirmText2")) :
                (GetText("EmailForm_ConfirmText1") + selectedMembers.length + GetText("EmailForm_ConfirmText2"))}
        </Modal>
        {user?.hasPaidSubscription ?
            <>
                {isAllowedToSendGroupMails === true ? <Form
                    form={form}
                    layout="vertical"
                    onFinish={onReminderFinish}
                    autoComplete="off"
                >
                    <Form.Item
                    >
                        {company && company.email && company.name ?
                            <>{GetText("EmailForm_From")} {company.name} {"<" + company.email + ">"}</> :
                            <>{GetText("EmailForm_From")} {user.firstName} {user.lastName} {"<" + user.email + ">"}</>}
                    </Form.Item>

                    <h3> {hasNoMembersOrEmails() ? <CheckCircleOutlined style={{ color: "grey" }} /> : <CheckCircleFilled style={{ color: "green" }} />} {GetText("EmailForm_Step1")}</h3>
                    <Divider style={{ marginTop: "5px" }} />
                    <span style={{ color: "red" }}>*</span> {GetText("EmailForm_ChooseReceiver")}
                    {props.type === EmailFormType.EmailFormByGroup && props.groups.length > 0 ? <><br />
                        <Select placeholder={GetText("EmailForm_ChooseCategories")} onChange={handleGroupChange} style={{ width: '400px', marginBottom: "10px" }} mode="multiple" options={groupOptions()}></Select>
                        <br /><BookyCheckbox alignLabelRight={true} label={"Skicka även till medlemmar som inte önskar e-post"} name="emailNotificationPreference"></BookyCheckbox>
                    </> : ""}

                    {props.type === EmailFormType.EmailFormByMember ? <MemberAutocomplete AutoCompleteType={AutoCompleteType.Email} showList={false} onAutoCompleteSelect={onAutoCompleteSelect} listHeader={GetText("ReminderForm_ReminderListHeader")} members={props.memberResult.members} /> : ""}
                    {props.type === EmailFormType.EmailFormByGroup ? <><br /><BookyButton style={{ marginTop: "10px" }} text={GetText("EmailForm_CreateSendList")} onClick={onFilterMembers}></BookyButton></> : ""}
                    {props.type === EmailFormType.EmailFormByExternal ? <><br /> <ContactAutoComplete AutoCompleteType={AutoCompleteType.Email} descriptionText={GetText("AutoComplete_HelpTextAddEmail")} showList listHeader={GetText("EmailForm_ExternalMailHeader")} emails={externalEmails} onEmailsUpdated={onEmailsUpdated} /></> : ""}

                    {selectedMembers.length === 0 ? "" : <MemberTable isMail={true} members={selectedMembers} style={{ minWidth: '400px', marginTop: "10px" }} removeMember={removeMember} />}
                    {selectedMembers.length > 0 ? <><br /><i>{selectedMembers.length} {GetText("EmailForm_ChosenReceivers")}</i></> : ""}


                    <h3 style={{ marginTop: "20px" }}> {emailIsvalid ? <CheckCircleFilled style={{ color: "green" }} /> : <CheckCircleOutlined style={{ color: "grey" }} />} {GetText("EmailForm_Step2")}</h3>
                    <Divider style={{ margin: "5px" }} />
                    <BookySelectDropDown allowClear={true} placeholder={GetText("EmailForm_LoadFromEmailTemplate")} handleChange={handleTemplateChange} style={{ width: '400px', marginTop: "20px" }} options={emailTemplatesOptions()}></BookySelectDropDown>
                    <BookyInput onChange={onSubjectchange} label={GetText("EmailForm_Subject")} name="subject" style={{ width: '400px', marginTop: "10px" }} requiredMessage={GetText("EmailForm_SubjectRequired")} ></BookyInput>

                    {/* Workaround to get editor to refresh value */}
                    {initialHtml ? <OneFrontHtmlEditor hideVariablesMenu={props.type === EmailFormType.EmailFormByExternal} initialHtml={initialHtml} onHtmlChange={onHtmlChange} label={GetText("EmailForm_Message")} name="messageFromTemplate" requiredMessage={GetText("EmailForm_MessageRequired")} /> :
                        <OneFrontHtmlEditor hideVariablesMenu={props.type === EmailFormType.EmailFormByExternal} initialHtml={initialHtml} onHtmlChange={onHtmlChange} label={GetText("EmailForm_Message")} name="message" requiredMessage={GetText("EmailForm_MessageRequired")} />}

                    <Form.Item
                        name="upload"
                        label={GetText("EmailForm_AttachFile")}
                    >
                        <FileSelect onFileSelectChange={onFileSelectChange} />
                    </Form.Item>

                    <Form.Item>
                        <Space style={{ width: '100%', justifyContent: 'left', margin: 0 }}>
                            <Button type="primary" htmlType="submit">
                                {GetText("SendInviteForm_SendButtonText")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form> : <Alert
                    message={GetText("EmailForm_AlertSuspendedMessage")}
                    description={GetText("EmailForm_AlertSuspendedDescription")}
                    type="warning"
                />}
            </> : <span><Tag color="#1890ff">Premium</Tag><a href='/Pricing'>{GetText("Common_UpgradeToPremium")}</a>{GetText("EmailForm_PremiumSendingMessageToGroups")}</span>
        }

    </>
}