/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode
 */
export interface WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode {
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode
     */
    companyUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode
     */
    passwordCode?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCodeFromJSON(json: any): WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode {
    return WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCodeFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyUrl': !exists(json, 'companyUrl') ? undefined : json['companyUrl'],
        'passwordCode': !exists(json, 'passwordCode') ? undefined : json['passwordCode'],
    };
}

export function WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCodeToJSON(value?: WebApiServicesDataTransferObjectsGroupServiceActiveUserByPasswordCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyUrl': value.companyUrl,
        'passwordCode': value.passwordCode,
    };
}

