
import { Col, Grid, List, Menu, Image, Radio, Space } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';
import { CalendarOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, HomeOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { BlobDto } from '../../Repository/eventbookyapi';
import { ImageService } from '../../Services/ImageService';

const ImageSelect = (props: any) => {

    const [category, setCategory] = useState(0 as number);
    const [images, setImages] = useState<BlobDto[]>([]);

    const getDownloadUrl = (dto: BlobDto) => {
        return process.env.REACT_APP_API_URL + '/Image/downloadImage?bloburl=' + dto.relativeUri;
    }

    const filterImages = (isPublicImage: boolean) => {
        const filteredImages = props.images.filter((item: BlobDto) => item.isPublicImage === isPublicImage);
        setImages(images => ([
            ...filteredImages
        ]));
    }

    const deleteImage = async (item: BlobDto) => {
        await ImageService.DeleteBannerImage(item.relativeUri!);
        props.loadImages();
    }

    useEffect(() => {
        if (category == 1) {
            filterImages(true);
        }
        else if (category == 2) {
            filterImages(false);
        }
        else {
            setImages(props.images);
        }
    }, [category, props]);
    return (
        <>
            <Radio.Group defaultValue={0} onChange={(e) => { setCategory(e.target.value) }}>
                <Space direction="horizontal" onClick={() => { }}>
                    <Radio value={0}>All</Radio>
                    <Radio value={1}>Public photos</Radio>
                    <Radio value={2}>My photos</Radio>
                </Space>

            </Radio.Group>
            <List
                grid={{ gutter: 18, column: 4 }}
                dataSource={images}
                renderItem={(item: BlobDto) => (

                    <List.Item onClick={(e) => { props.onImageSelect(getDownloadUrl(item)) }}>
                        <Image className={props.selectedImage && props.selectedImage.indexOf(item.relativeUri) != -1 ? "image-selected" : ""} width={200} height={130} preview={false} style={{ padding: "10px" }} src={getDownloadUrl(item)} ></Image>
                        {props.selectedImage && props.selectedImage.indexOf(item.relativeUri) != -1 ? <CloseOutlined alt="Delete image" style={{ zIndex: "9999", right: 0, top: 0, color: "red", fontSize: "19px", position: "absolute", marginTop: "5px", marginRight: "5px" }} onClick={() => deleteImage(item)} /> : ""}
                    </List.Item >
                )}
            />
        </>)
};

export default ImageSelect;