/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiModelsEnumerationTypesCompanyOrderStatus } from './WebApiModelsEnumerationTypesCompanyOrderStatus';
import {
    WebApiModelsEnumerationTypesCompanyOrderStatusFromJSON,
    WebApiModelsEnumerationTypesCompanyOrderStatusFromJSONTyped,
    WebApiModelsEnumerationTypesCompanyOrderStatusToJSON,
} from './WebApiModelsEnumerationTypesCompanyOrderStatus';

/**
 * 
 * @export
 * @interface WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse
 */
export interface WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse {
    /**
     * 
     * @type {string}
     * @memberof WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse
     */
    id?: string | null;
    /**
     * 
     * @type {WebApiModelsEnumerationTypesCompanyOrderStatus}
     * @memberof WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse
     */
    status?: WebApiModelsEnumerationTypesCompanyOrderStatus;
    /**
     * 
     * @type {Date}
     * @memberof WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse
     */
    expirationTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse
     */
    snippet?: string | null;
}

/**
 * Check if a given object implements the WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse interface.
 */
export function instanceOfWebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponseFromJSON(json: any): WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse {
    return WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponseFromJSONTyped(json, false);
}

export function WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : WebApiModelsEnumerationTypesCompanyOrderStatusFromJSON(json['status']),
        'expirationTime': !exists(json, 'expirationTime') ? undefined : (new Date(json['expirationTime'])),
        'snippet': !exists(json, 'snippet') ? undefined : json['snippet'],
    };
}

export function WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponseToJSON(value?: WebApiPaymentRepositoryPaysonPaysonCreateCheckoutResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': WebApiModelsEnumerationTypesCompanyOrderStatusToJSON(value.status),
        'expirationTime': value.expirationTime === undefined ? undefined : (value.expirationTime.toISOString()),
        'snippet': value.snippet,
    };
}

