import { Menu, } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider } from '../../../Providers/authProvider';
import { useEffect, useState } from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';
import { CalendarOutlined, CheckOutlined, GlobalOutlined, MailOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { TextService } from '../../../Services/TextService';
import MobileDrawer from './MobileDrawer';
import { tryGetUserPrefferedLanguage, getCurrentLanguage, setCookieLanguage } from '../../../Helpers/RouteHelper';
import { AuthenticatedUser } from '../../../Types/AuthenticatedUser';

const TopHeader = () => {
  const [user, setUser] = useState({} as AuthenticatedUser | null);
  const [currentPathName, setCurrentPathName] = useState("" as string);

  const navigation = useNavigate();
  const history = useLocation();
  const GetText = TextService.GetText;

  const handleClick = async (event: any) => {
    if (event == undefined || event.key == undefined) {
      return;
    }
    if (event.key === "logout") {
      await AuthProvider().Logout();
      setUser(null);
      navigation("/" + getCurrentLanguage() + "/logout");
    }
    else if (event.key === "/se" || event.key === "/en") {
      let oldNav = history.pathname.replace("/en", '');
      oldNav = oldNav.replace("/se", '');

      setCookieLanguage(event.key.replace("/", ''))
      navigation(event.key + oldNav);
    }
    else {
      navigation("/" + getCurrentLanguage() + event.key);
    }
  };

  useEffect(() => {
    if (window.location.pathname == "/" && tryGetUserPrefferedLanguage() === "se") {
      navigation("/" + tryGetUserPrefferedLanguage());
    }
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    setCurrentPathName(window.location.pathname);
  }, [history.pathname]);

  const getSelectedClass = (url: string) => {
    if (((window.location.pathname === "/se/" || window.location.pathname === "/en/" || window.location.pathname === "/") && url === "/Home") || window.location.pathname.indexOf(url) != -1) {
      return "onefront-menu-selected";
    }

    return url == "/Home" ? "" : "hideOnMobile"
  }

  const getLinkWithLanguage = (url: string) => {
    if (getCurrentLanguage() === "se") {
      return "/" + getCurrentLanguage() + url;
    }
    return url;
  }


  if (user?.token != null) {
    return <div id="header">
      <Header className="header">
        <div className="logo"></div>
        <Menu theme="dark" mode="horizontal" onClick={handleClick}>
          <Menu.Item className={getSelectedClass("/Home")} key={'/'}> {GetText("TopHeader_Home")} </Menu.Item>
          <MobileDrawer />
          <Menu.Item className={getSelectedClass("/Pricing")} key={'/Pricing'}> {GetText("TopHeader_Pricing")} </Menu.Item>
          <SubMenu className={getSelectedClass("/features/organisations")} key={"features"} title={GetText("TopHeader_Functions")}>
            <Menu.Item key={'/features/organisations'}>{GetText("TopHeader_FunctionsOrganisations")}</Menu.Item>
          </SubMenu>

          <Menu.Item className={getSelectedClass("/Contact")} key={'/Contact'}> {GetText("TopHeader_Contact")} </Menu.Item>
          {!user!.hasOnlyReadRights ? <Menu.Item className={getSelectedClass("/myevents")} key="/myevents"><CalendarOutlined /> {GetText("TopHeader_MyEvents")}</Menu.Item> : ""}
          <Menu.Item className={getSelectedClass("/users")} key="/users"><TeamOutlined /> {GetText("TopHeader_Users")}</Menu.Item>
          {!user.hasOnlyReadRights ? <Menu.Item className={getSelectedClass("/sendEmail")} key="/sendEmail"><MailOutlined /> {GetText("TopHeader_Communicate")}</Menu.Item> : ""}
          <SubMenu style={{ marginLeft: "auto" }} key="/login" title={<span style={{ fontWeight: "bold" }}> <UserOutlined></UserOutlined> {user.firstName + " " + user.lastName}</span>}>
            <Menu.Item key={'/Settings'}>{GetText("TopHeader_Settings")}</Menu.Item>
            <Menu.Item key="logout">{GetText("TopHeader_Logout")}</Menu.Item>
          </SubMenu>
          <SubMenu className={"menu-right-align"} key={"SomeMenu"} title={<span style={{ fontWeight: "bold" }}> <GlobalOutlined /></span>}>
            <Menu.Item key={'/en'}>{getCurrentLanguage() == "en" ? <CheckOutlined /> : ""} {GetText("TopHeader_English")}</Menu.Item>
            <Menu.Item className={"menu-right-dropdown"} key={'/se'}>{getCurrentLanguage() == "se" ? <CheckOutlined /> : ""} {GetText("TopHeader_Swedish")}</Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    </div>
  }

  return <div id="header">
    <Header className="header">
      <div className="logo"></div>
      <Menu theme="dark" mode="horizontal" onClick={handleClick} className={"menu-container"}>

        <Link to={getLinkWithLanguage("/")}>
          <Menu.Item className={getSelectedClass(("/Home"))} key={'/'}> {GetText("TopHeader_Home")} </Menu.Item>
        </Link>
        <MobileDrawer />
        <Link to={getLinkWithLanguage("/CreateUser")}>
          <Menu.Item className={getSelectedClass(getLinkWithLanguage("/CreateUser"))} key={'/CreateUser'}> {GetText("TopHeader_CreateAccount")} </Menu.Item>
        </Link>
        <Link to={getLinkWithLanguage("/Pricing")}>
          <Menu.Item className={getSelectedClass("/Pricing")} key={'/Pricing'}> {GetText("TopHeader_Pricing")} </Menu.Item>
        </Link>

        <SubMenu className={getSelectedClass(getLinkWithLanguage("/features/organisations"))} key={"/features/organisations"} title={GetText("TopHeader_Functions")}>
          <Link to={getLinkWithLanguage("/features/organisations")}>
            <Menu.Item key={'/features/organisations'}>{GetText("TopHeader_FunctionsOrganisations")}</Menu.Item>
          </Link>
        </SubMenu>
        <Link to={getLinkWithLanguage("/Contact")}>
          <Menu.Item className={getSelectedClass("/Contact")} key={'/Contact'}> {GetText("TopHeader_Contact")} </Menu.Item>
        </Link>
        <Link to={getLinkWithLanguage("/Login")}>
          <Menu.Item className={"menu-right-align " + getSelectedClass("/Login")} key='/login'>{GetText("TopHeader_Login")}</Menu.Item>
        </Link>
        <SubMenu className={"menu-right-align"} key={"SomeMenu"} title={<span style={{ fontWeight: "bold" }}> <GlobalOutlined /></span>}>
          <Menu.Item key={'/en'}>{getCurrentLanguage() == "en" ? <CheckOutlined /> : ""} {GetText("TopHeader_English")}</Menu.Item>
          <Menu.Item key={'/se'}>{getCurrentLanguage() == "se" ? <CheckOutlined /> : ""} {GetText("TopHeader_Swedish")}</Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  </div >
};

export default TopHeader;