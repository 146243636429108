import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";

export const BookyCustomInput = (props: any) => {

    const rules: any = [];
    if (props.requiredMessage) {
        rules.push({
            required: true,
            message: props.requiredMessage,
        });
    }

    return (
        <>
            <Form.Item label={props.label} name={props.name} extra={props.helpText ? <span style={{ justifyContent: 'left', display: "flex", fontSize: "80%" }}>{props.helpText}</span> : ""}
                rules={rules}
            >
                {props.children}
            </Form.Item>
        </>
    );

}