/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.RegisterAsync(WebApi.Services.DataTransferObjects.UserService.RegisterReqDto) request.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceCloseEventDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceCloseEventDto {
    /**
     * 
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCloseEventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceCloseEventDto
     */
    message?: string | null;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceCloseEventDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceCloseEventDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceCloseEventDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceCloseEventDto {
    return WebApiServicesDataTransferObjectsUserServiceCloseEventDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceCloseEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceCloseEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceCloseEventDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceCloseEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'message': value.message,
    };
}

