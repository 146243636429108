/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiMemberDtoGroupDto } from './WebApiMemberDtoGroupDto';
import {
    WebApiMemberDtoGroupDtoFromJSON,
    WebApiMemberDtoGroupDtoFromJSONTyped,
    WebApiMemberDtoGroupDtoToJSON,
} from './WebApiMemberDtoGroupDto';
import type { WebApiMemberDtoMemberDto } from './WebApiMemberDtoMemberDto';
import {
    WebApiMemberDtoMemberDtoFromJSON,
    WebApiMemberDtoMemberDtoFromJSONTyped,
    WebApiMemberDtoMemberDtoToJSON,
} from './WebApiMemberDtoMemberDto';

/**
 * 
 * @export
 * @interface WebApiMemberDtoMemberListResultDto
 */
export interface WebApiMemberDtoMemberListResultDto {
    /**
     * 
     * @type {Array<WebApiMemberDtoGroupDto>}
     * @memberof WebApiMemberDtoMemberListResultDto
     */
    groups?: Array<WebApiMemberDtoGroupDto> | null;
    /**
     * 
     * @type {Array<WebApiMemberDtoMemberDto>}
     * @memberof WebApiMemberDtoMemberListResultDto
     */
    members?: Array<WebApiMemberDtoMemberDto> | null;
    /**
     * 
     * @type {string}
     * @memberof WebApiMemberDtoMemberListResultDto
     */
    nextMemberIdSuggestion?: string | null;
}

/**
 * Check if a given object implements the WebApiMemberDtoMemberListResultDto interface.
 */
export function instanceOfWebApiMemberDtoMemberListResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiMemberDtoMemberListResultDtoFromJSON(json: any): WebApiMemberDtoMemberListResultDto {
    return WebApiMemberDtoMemberListResultDtoFromJSONTyped(json, false);
}

export function WebApiMemberDtoMemberListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiMemberDtoMemberListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groups': !exists(json, 'groups') ? undefined : (json['groups'] === null ? null : (json['groups'] as Array<any>).map(WebApiMemberDtoGroupDtoFromJSON)),
        'members': !exists(json, 'members') ? undefined : (json['members'] === null ? null : (json['members'] as Array<any>).map(WebApiMemberDtoMemberDtoFromJSON)),
        'nextMemberIdSuggestion': !exists(json, 'nextMemberIdSuggestion') ? undefined : json['nextMemberIdSuggestion'],
    };
}

export function WebApiMemberDtoMemberListResultDtoToJSON(value?: WebApiMemberDtoMemberListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groups': value.groups === undefined ? undefined : (value.groups === null ? null : (value.groups as Array<any>).map(WebApiMemberDtoGroupDtoToJSON)),
        'members': value.members === undefined ? undefined : (value.members === null ? null : (value.members as Array<any>).map(WebApiMemberDtoMemberDtoToJSON)),
        'nextMemberIdSuggestion': value.nextMemberIdSuggestion,
    };
}

