/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Data transfer object for the M:WebApi.Services.Interfaces.IUserService.GetDetailsAsync(System.Int32) response.
 * @export
 * @interface WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
 */
export interface WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto {
    /**
     * Gets or sets the identifier.
     * @type {number}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
     */
    id?: number;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
     */
    firstName?: string | null;
    /**
     * Gets or sets the Name.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
     */
    lastName?: string | null;
    /**
     * Gets or sets the email.
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
     */
    email?: string | null;
    /**
     * Gets or sets the date when this user was created.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
     */
    createdAt?: Date;
    /**
     * Gets or sets the date when this user was updated.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
     */
    updatedAt?: Date | null;
    /**
     * Gets or sets the date of the last successful login.
     * @type {Date}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
     */
    lastLoginAt?: Date | null;
    /**
     * Gets or sets a value indicating whether this user is active.
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto
     */
    isActive?: boolean;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsUserServiceGetDetailsResDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsUserServiceGetDetailsResDtoFromJSON(json: any): WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto {
    return WebApiServicesDataTransferObjectsUserServiceGetDetailsResDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsUserServiceGetDetailsResDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'lastLoginAt': !exists(json, 'lastLoginAt') ? undefined : (json['lastLoginAt'] === null ? null : new Date(json['lastLoginAt'])),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function WebApiServicesDataTransferObjectsUserServiceGetDetailsResDtoToJSON(value?: WebApiServicesDataTransferObjectsUserServiceGetDetailsResDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'lastLoginAt': value.lastLoginAt === undefined ? undefined : (value.lastLoginAt === null ? null : value.lastLoginAt.toISOString()),
        'isActive': value.isActive,
    };
}

