import { ReplyService } from "../Services/ReplyService";
import { Time } from "../Types/Time";
import { timeAnswers, updateReplyRequest } from "../Types/RequestResponse/createReplyRequest";
import { createReplyRequest } from "../Types/RequestResponse/createReplyRequest";

export const useSaveReply = () => {
  const SaveReply = async (eventId: number, formValues: any, times: Time[], inviteId?: string, replyId?: number) => {
    let timeAnswers: timeAnswers[] = [];
    times.forEach(function (time) {
      if (formValues[time.id]) {
        timeAnswers.push({
          timeId: time.id,
          customerAnswer: Number(formValues[time.id])
        })
      }
    });

    if (!replyId || replyId == 0) {
      const createEventRequest: createReplyRequest = {
        name: formValues.name,
        eventId: eventId,
        email: formValues.email,
        answer: formValues.answer,
        timeAnswers: timeAnswers,
        inviteId: inviteId
      }

      return await ReplyService.CreateReply(createEventRequest);
    }
    else {
      const createEventRequest: updateReplyRequest = {
        name: formValues.name,
        eventId: eventId,
        email: formValues.email,
        answer: formValues.answer,
        timeAnswers: timeAnswers,
        inviteId: inviteId,
        replyId: replyId
      }

      return await ReplyService.UpdateReply(createEventRequest);
    }


  };
  return {
    SaveReply
  };
};