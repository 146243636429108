import { Form } from "antd";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { getCurrentLanguage } from "../../Helpers/RouteHelper";

export enum InputType {
    email = "email",
    password = "password",
    multiline = "multiline",
    number = "number"
}

export const BookyPhoneInput = (props: any) => {
    const rules: any = [];
    if (props.requiredMessage) {
        rules.push({
            required: true,
            message: props.requiredMessage,
        });
    }

    return (

        <Form.Item style={props.style} label={props.label} name={props.name}
            rules={rules}
        >
            <PhoneInput
                value={props.value}
                onChange={props.onchange}
                placeholder={props.placeholder}
                className="ant-input"
                defaultCountry={getCurrentLanguage().toUpperCase() == "SE" ? "SE" : "US"}
                international={true}
                initialValueFormat="national"
                limitMaxLength={true}
                {...props}
            />
        </Form.Item>
    );

}