import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { EventService } from '../../Services/EventService';
import 'react-multi-email/dist/style.css';
import { AuthProvider } from '../../Providers/authProvider';
import { InviteService } from '../../Services/InviteService';
import { InviteList } from '../../Components/EventDetails/InviteList';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { TextService } from '../../Services/TextService';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import LeftMenuEventDetails from './LeftMenuEventDetails';

const MyEventDetailsParticipants = () => {
  const [event, setEvent] = useState([] as any);
  const [invites, setInvites] = useState([] as any);
  const [user, setUser] = useState({} as AuthenticatedUser | null);
  const GetText = TextService.GetText;

  let params = useParams();

  const loadInvites = (eventUniqueId: string) => {
    InviteService.GetInvites(eventUniqueId).then(inviteResult => {
      setInvites(inviteResult.invites);

      let noReplies: string[] = [];
      inviteResult.invites?.forEach((invite: any) => {
        if (invite.replyId === 0) {
          noReplies.push(invite.email);
        }
      });
    });
  }

  const loadEvent = (id: string) => {
    EventService.GetEventDetails(id).then(result => {
      setEvent(result);
      loadInvites(result.eventUniqueId ? result.eventUniqueId : "");
    });
  };

  useEffect(() => {

    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });

    AuthProvider().ValidateUserLogin();
    if (params.id != null) {
      loadEvent(params.id);
    }
  }, [params])


  return (
    <LayoutLeftMenu hasTitleTop={true} titleTop={event.eventName} title={GetText("MyEventDetails_SendInvites")} fullpageMain={false} menu={<LeftMenuEventDetails selected={"myeventparticipants"} />}>
      <InviteList loadEvent={loadEvent} event={event} invites={invites}></InviteList>
    </LayoutLeftMenu>);
};

export default MyEventDetailsParticipants;