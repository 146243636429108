import { Form, Select } from "antd";


export const BookySelectDropDown = (props: any) => {

    const handleChange = (value: { value: string; label: React.ReactNode }) => {
        props.handleChange(value);
    };

    return (

        <Form.Item label={props.label} name={props.name}
        >
            <Select
                placeholder={props.placeholder}
                style={props.style}
                allowClear={props.allowClear}
                options={props.options}
                onChange={handleChange}
            />
        </Form.Item>
    );

}