export enum EventState {
    Open = 0,
    Closed = 1,
    NotFound = 2,
    DeadlinePassed = 3,
}

export enum EventEditState {
    Found,
    HasBookings,
    NotFound,
}