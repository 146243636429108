import { AuthProvider } from "../Providers/authProvider"

export abstract class DashboardService {
    public static GetDashBoardData = async () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + AuthProvider().GetToken() },
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/Dashboard/', requestOptions);
        const data = await response.json();

        return data;
    };

}