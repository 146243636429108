/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BlobDto } from './BlobDto';
import {
    BlobDtoFromJSON,
    BlobDtoFromJSONTyped,
    BlobDtoToJSON,
} from './BlobDto';

/**
 * 
 * @export
 * @interface WebApiServicesDataTransferObjectsBlobResponseDto
 */
export interface WebApiServicesDataTransferObjectsBlobResponseDto {
    /**
     * 
     * @type {string}
     * @memberof WebApiServicesDataTransferObjectsBlobResponseDto
     */
    status?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApiServicesDataTransferObjectsBlobResponseDto
     */
    error?: boolean;
    /**
     * 
     * @type {BlobDto}
     * @memberof WebApiServicesDataTransferObjectsBlobResponseDto
     */
    blob?: BlobDto;
}

/**
 * Check if a given object implements the WebApiServicesDataTransferObjectsBlobResponseDto interface.
 */
export function instanceOfWebApiServicesDataTransferObjectsBlobResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiServicesDataTransferObjectsBlobResponseDtoFromJSON(json: any): WebApiServicesDataTransferObjectsBlobResponseDto {
    return WebApiServicesDataTransferObjectsBlobResponseDtoFromJSONTyped(json, false);
}

export function WebApiServicesDataTransferObjectsBlobResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiServicesDataTransferObjectsBlobResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'blob': !exists(json, 'blob') ? undefined : BlobDtoFromJSON(json['blob']),
    };
}

export function WebApiServicesDataTransferObjectsBlobResponseDtoToJSON(value?: WebApiServicesDataTransferObjectsBlobResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'error': value.error,
        'blob': BlobDtoToJSON(value.blob),
    };
}

