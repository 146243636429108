export class CalendarEvent {
    title: string;
    id?: string;
    allDay: boolean;
    start: Date;
    end: Date;
    desc: string;
    resourceId?: string;
    tooltip?: string;
    source: CalenderSource;

    constructor(_title: string, _start: Date, _endDate: Date, _source: CalenderSource, _allDay?: boolean, _desc?: string, _resourceId?: string, _id?: string,) {
        this.title = _title;
        this.id = _id;
        this.allDay = _allDay || false;
        this.start = _start;
        this.end = _endDate;
        this.desc = _desc || '';
        this.resourceId = _resourceId;
        this.source = _source;
    }
}

export enum CalenderSource {
    EventBooky, Google
}