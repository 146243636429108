import { useEffect, useState } from 'react';
import { GroupService } from '../../Services/GroupService';
import { WebApiMemberDtoMemberListResultDto, WebApiModelsEmailTemplate, WebApiServicesDataTransferObjectsGroupServiceGroupResultDto } from '../../Repository/eventbookyapi';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { EmailForm } from '../../Components/Users/EmailForm';
import LeftMenuCommunicate from './LeftCommunicate';
import { MemberService } from '../../Services/MemberService';
import { EmailFormType } from '../../Types/EmailFormType';
import { EmailTemplateService } from '../../Services/EmailTemplateService';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';

const AdminSendEmailCategory = () => {
  const [groups, setGroups] = useState([] as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto[]);
  const [selectedGroup, setSelectedGroup] = useState({} as WebApiServicesDataTransferObjectsGroupServiceGroupResultDto);
  const [memberResult, setMemberResult] = useState({} as WebApiMemberDtoMemberListResultDto);
  const [emailTemplates, setEmailTemplates] = useState([] as WebApiModelsEmailTemplate[]);

  const [user, setUser] = useState({} as any);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    GroupService.GetGroupsIncludingAllMembers().then(result => {

      result.forEach(element => {
        if (element.isDefaultGroup) {
          element.name = GetText("MemberList_DefaultAllMembersName");
        }
      });
      setGroups(result);

      if (result.length > 0) {
        setSelectedGroup(result[0]);
      }
    });

    MemberService.GetAllMembers().then(result => {
      setMemberResult(result);
    });

    EmailTemplateService.GetTemplates().then(result => {
      setEmailTemplates(result);
    });
  }, []);

  return <LayoutLeftMenu title={GetText("Communicate_SendMailTitle")} menu={<LeftMenuCommunicate selected={"sendEmail"} />}>
    <EmailForm user={user} groups={groups} emailTemplates={emailTemplates} memberResult={memberResult} type={EmailFormType.EmailFormByGroup} />
  </LayoutLeftMenu>
};

export default AdminSendEmailCategory;