import { UserService } from "../Services/UserService";
import { authenticateRequest } from "../Types/RequestResponse/authenticateRequest";

export const useLogin = () => {
  const Login = async (formValues: any) => {
    const createLoginRequest: authenticateRequest = {
      email: formValues.email,
      password: formValues.password,
    }

    return await UserService.Authenticate(createLoginRequest);
  };
  return {
    Login
  };
};